import { createContext, useContext } from "react";
import AccessoriesListStore from "./accessoriesListStore";
import DashboardStore from "./dashboardStore";
import GSTStore from "./gstStore";
import VehicleModelsStore from "./modelStore";
import ReportsStore from "./reportsStore";
import ResizeDetectorStore from "./resizeDetectorStore";
import TableSizeStore from "./tableSizeStore";
import { TeamStore } from "./teamStore";
import UserStore from "./userStore";
import CommonLanguageStore from "./commonLanguageStore";
import CommonLanguageChangeLogStore from "./commonLanguageChangeLogStore";

const storesContext = createContext({
  userStore: new UserStore(),
  teamStore: new TeamStore(),
  modelStore: new VehicleModelsStore(),
  dashboardStore: new DashboardStore(),
  resizeDetectorStore: new ResizeDetectorStore(),
  tableSizeStore: new TableSizeStore(),
  AccessoriesListStore: new AccessoriesListStore(),
  gstStore: new GSTStore(),
  reportsStore: new ReportsStore(),
  commonLanguageStore: new CommonLanguageStore(),
  commonLanguageChangeLogStore: new CommonLanguageChangeLogStore(),
});

const useStores = () => useContext(storesContext);

export default useStores;
