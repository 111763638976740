import { observer } from "mobx-react-lite";
import React from "react";
import { Checkbox, TableCell } from "vapi-ui-common";
import styles from "./CheckboxCell.module.scss";

export interface CheckboxProps2 extends React.InputHTMLAttributes<HTMLInputElement> {
  labelClassName?: string;
  bold?: boolean;
  wrap?: boolean;
  small?: boolean;
  checked?: boolean
} 
const CheckboxCell = ({
  id,
  defaultChecked,
  checked,
  onChange,
  disabled,
  children,
  onClick,
  ...rest
}: CheckboxProps2) => {
  
  return (
    <TableCell colType="accApplicability" center className={styles.relative}>
      <Checkbox
        id={id}
        className={styles.checkboxCell}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        onClick={onClick}
        {...rest}
      />
      {children}
    </TableCell>
  )
};

export default observer(CheckboxCell);
