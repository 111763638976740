import { AccessoryChangeType } from "../routes/national/components/ChangeLogChanges";

const getFromTo = (
  changeType: string,
  before: string | null | undefined,
  after: string | null | undefined,
  beforeValue?: string | null,
  afterValue?: string | null
) => {
  switch (changeType) {
    case AccessoryChangeType.INACTIVE:
    case AccessoryChangeType.NON_GENUINE:
      return {
        from: JSON.stringify({ value: after === "true" }),
        to: JSON.stringify({ value: before === "true" }),
      };
    case AccessoryChangeType.MODEL_APPLICABILITY:
      return {
        from: JSON.stringify({ value: after ? JSON.parse(after) : null }),
        to: JSON.stringify({ value: before ? JSON.parse(before) : null }),
      };
    case AccessoryChangeType.GRADE_MSRP:
      return {
        from: JSON.stringify({ value: after ? JSON.parse(after) : null }),
        to: JSON.stringify({ value: before ? JSON.parse(before) : null }),
      };
    case AccessoryChangeType.IMAGES:
      return {
        from: JSON.stringify({ value: after ? JSON.parse(after) : null }),
        to: JSON.stringify({ value: before ? JSON.parse(before) : null }),
      };
    case AccessoryChangeType.PRODUCT_TYPE_CHANGED:
      return {
        from: JSON.stringify({ value: afterValue }),
        to: JSON.stringify({ value: beforeValue }),
      };
    default:
      return {
        from: JSON.stringify({ value: after }),
        to: JSON.stringify({ value: before }),
      };
  }
};

export default getFromTo;
