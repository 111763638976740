import React, { HTMLAttributes } from 'react';
import styles from './filterTab.module.scss';

const FilterTabColumns = (props: HTMLAttributes<HTMLElement>) => {
  return <section className={styles.filterTabColumns} {...props} />;
};

const FilterTabColumn = (props: HTMLAttributes<HTMLElement>) => {
  return <section className={styles.filterTabColumn} {...props} />;
};

const FilterTabColumnList = (props: HTMLAttributes<HTMLUListElement>) => {
  return <ul className={styles.filterTabColumnList} {...props} />;
};


export {
  FilterTabColumns,
  FilterTabColumn,
  FilterTabColumnList,
}