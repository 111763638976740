import moment from "moment-timezone";

const SERVER_TIME_FORMAT = "MM/DD/YYYY hh:mm:ss A z";
export const SERVER_GO_LIVE_DATE_FORMAT = "YYYY-MM-DD HH:mm";
// JS Date format YYYY-MM-DDTHH:mm:ss.sssZ

/**
 * Convert Server Date to Local Date
 * @returns string in ISO format
 * @param date
 */
export const convertServerDateToLocalDate = (date: string) => {
  return date ? moment.utc(date, SERVER_TIME_FORMAT).local().format() : date;
};

/**
 * Convert Date to String Date with custom format
 * @returns string date with custom format
 * @param date
 * @param format
 */
export const formatDate = (date: Date, format: string) =>
  moment(date).format(format);

export const isPastDate = (date: string) => {
  const cstTime = moment.tz(new Date(date), "America/Chicago");
  return moment(cstTime).isBefore();
};

export const isFutureDate = (date: string) => {
  const cstTime = moment.tz(new Date(date), "America/Chicago");
  return moment(cstTime).isAfter();
};

export enum GoLiveDateTimeText {
  AM_12 = "12am",
  AM_1 = "1am",
  AM_2 = "2am",
  AM_3 = "3am",
  AM_4 = "4am",
  AM_5 = "5am",
  AM_6 = "6am",
  AM_7 = "7am",
  AM_8 = "8am",
  AM_9 = "9am",
  AM_10 = "10am",
  AM_11 = "11am",
  PM_12 = "12pm",
  PM_1 = "1pm",
  PM_2 = "2pm",
  PM_3 = "3pm",
  PM_4 = "4pm",
  PM_5 = "5pm",
  PM_6 = "6pm",
  PM_7 = "7pm",
  PM_8 = "8pm",
  PM_9 = "9pm",
  PM_10 = "10pm",
  PM_11 = "11pm",
}

export enum GoLiveDateTimeHour {
  AM_12 = 0,
  AM_1 = 1,
  AM_2 = 2,
  AM_3 = 3,
  AM_4 = 4,
  AM_5 = 5,
  AM_6 = 6,
  AM_7 = 7,
  AM_8 = 8,
  AM_9 = 9,
  AM_10 = 10,
  AM_11 = 11,
  PM_12 = 12,
  PM_1 = 13,
  PM_2 = 14,
  PM_3 = 15,
  PM_4 = 16,
  PM_5 = 17,
  PM_6 = 18,
  PM_7 = 19,
  PM_8 = 20,
  PM_9 = 21,
  PM_10 = 22,
  PM_11 = 23,
}

export interface GoLiveDateTime {
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
  text: GoLiveDateTimeText;
}

export type GoLiveDateTimeMap = {
  [key in GoLiveDateTimeHour]: GoLiveDateTime;
};

export const goLiveDateTimeMap: GoLiveDateTimeMap = {
  [GoLiveDateTimeHour.AM_12]: {
    hours: GoLiveDateTimeHour.AM_12,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.AM_12,
  },
  [GoLiveDateTimeHour.AM_1]: {
    hours: GoLiveDateTimeHour.AM_1,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.AM_1,
  },
  [GoLiveDateTimeHour.AM_2]: {
    hours: GoLiveDateTimeHour.AM_2,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.AM_2,
  },
  [GoLiveDateTimeHour.AM_3]: {
    hours: GoLiveDateTimeHour.AM_3,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.AM_3,
  },
  [GoLiveDateTimeHour.AM_4]: {
    hours: GoLiveDateTimeHour.AM_4,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.AM_4,
  },
  [GoLiveDateTimeHour.AM_5]: {
    hours: GoLiveDateTimeHour.AM_5,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.AM_5,
  },
  [GoLiveDateTimeHour.AM_6]: {
    hours: GoLiveDateTimeHour.AM_6,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.AM_6,
  },
  [GoLiveDateTimeHour.AM_7]: {
    hours: GoLiveDateTimeHour.AM_7,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.AM_7,
  },
  [GoLiveDateTimeHour.AM_8]: {
    hours: GoLiveDateTimeHour.AM_8,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.AM_8,
  },
  [GoLiveDateTimeHour.AM_9]: {
    hours: GoLiveDateTimeHour.AM_9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.AM_9,
  },
  [GoLiveDateTimeHour.AM_10]: {
    hours: GoLiveDateTimeHour.AM_10,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.AM_10,
  },
  [GoLiveDateTimeHour.AM_11]: {
    hours: GoLiveDateTimeHour.AM_11,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.AM_11,
  },
  [GoLiveDateTimeHour.PM_12]: {
    hours: GoLiveDateTimeHour.PM_12,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.PM_12,
  },
  [GoLiveDateTimeHour.PM_1]: {
    hours: GoLiveDateTimeHour.PM_1,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.PM_1,
  },
  [GoLiveDateTimeHour.PM_2]: {
    hours: GoLiveDateTimeHour.PM_2,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.PM_2,
  },
  [GoLiveDateTimeHour.PM_3]: {
    hours: GoLiveDateTimeHour.PM_3,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.PM_3,
  },
  [GoLiveDateTimeHour.PM_4]: {
    hours: GoLiveDateTimeHour.PM_4,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.PM_4,
  },
  [GoLiveDateTimeHour.PM_5]: {
    hours: GoLiveDateTimeHour.PM_5,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.PM_5,
  },
  [GoLiveDateTimeHour.PM_6]: {
    hours: GoLiveDateTimeHour.PM_6,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.PM_6,
  },
  [GoLiveDateTimeHour.PM_7]: {
    hours: GoLiveDateTimeHour.PM_7,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.PM_7,
  },
  [GoLiveDateTimeHour.PM_8]: {
    hours: GoLiveDateTimeHour.PM_8,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.PM_8,
  },
  [GoLiveDateTimeHour.PM_9]: {
    hours: GoLiveDateTimeHour.PM_9,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.PM_9,
  },
  [GoLiveDateTimeHour.PM_10]: {
    hours: GoLiveDateTimeHour.PM_10,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.PM_10,
  },
  [GoLiveDateTimeHour.PM_11]: {
    hours: GoLiveDateTimeHour.PM_11,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
    text: GoLiveDateTimeText.PM_11,
  },
};
