import React, { useContext } from "react";
import { HeaderCell } from "vapi-ui-common";
import inputStyles from "../../../../../components/Input/input.module.scss";
import styles from "../../../AccessoriesEntryScreen.module.scss";
import AccessoriesEntryScreenContext from "../AccessoriesEntryScreenContext";

const ActionsHeader = () => {
  const context = useContext(AccessoriesEntryScreenContext);
  const { readOnly, sortMode } = context;

  return (
    <>
      {!readOnly && <HeaderCell cellClassName={styles.stickyHeader} />}
      {sortMode && (
        <HeaderCell className={inputStyles.smallInputHeader}>#</HeaderCell>
      )}
    </>
  );
};

export default ActionsHeader;
