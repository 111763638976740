import React from "react";
import styles from "./NATUpdatesPopover.module.scss";

export interface INatUpdatesPopover {
  lastSyncDate: string;
  lastNATPublishedDate: string;
}

const NATUpdatesPopoverContent = ({
  lastSyncDate,
  lastNATPublishedDate,
}: INatUpdatesPopover) => {
  const getDateString = (date: string) => {
    const parsedDate = new Date(date).toLocaleDateString();
    return parsedDate === "Invalid Date" ? "N/A" : parsedDate;
  };

  const syncDate = () => `Last Synced: ${getDateString(lastSyncDate)}`;

  const publishedDate = () =>
    `Last NAT Published: ${getDateString(lastNATPublishedDate)}`;

  return (
    <section className={styles.dateSection}>
      <div>{syncDate()}</div>
      <div>{publishedDate()}</div>
    </section>
  );
};

export default NATUpdatesPopoverContent;
