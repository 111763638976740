import React from "react";
import ChangeLogRichTextDiff from "../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogRichTextDiff";
import ChangeLogBeforeAfter from "../../../../components/ChangeLogTable/ChangeLogChanges/components/ChangeLogBeforeAfter";
import ChangeLogImage from "../../../../components/ChangeLogTable/ChangeLogChanges/components/ChangeLogImages";
import { Change } from "../../../../gql/generated";

export enum AccessoryACLCompareChangeType {
  SUPPLIER = "Supplier",
  TITLE = "Title",
  TITLE_ES = "Title es",
  COPY = "Copy",
  DISCLOSURE = "Disclosure",
  PRODUCT_TYPE_CHANGED = "Product Type Changed",
  INSTALL_POINT = "Install Point",
  PPO_CODE = "PPO Code",
  DESCRIPTION = "Description",
  DESCRIPTION_ES = "Description es",
  DISCLAIMER = "Disclaimer",
  DISCLAIMER_ES = "Disclaimer es",
  MSRP = "MSRP",
  NOTES = "Notes",
  PART_NUMBER = "Part Number",
  PARTS_ONLY_DEALER_COST = "Parts Only Dealer Cost",
  PARTS_ONLY_MSRP = "Parts Only MSRP",
  INSTALLED_DEALER_COST = "Installed Dealer Cost",
  REQUIRED = "Required",
  CONFLICTS = "Conflicts",
  NON_GENUINE = "Non Genuine",
  IMAGES = "Images",
  WARRANTY = "Warranty",
  WARRANTY_ES = "Warranty es",
  LABOR_TIME = "Labor Time",
}

interface ChangeLogChangesProps {
  changedItem: Change;
}

const ChangeLogChanges = ({ changedItem }: ChangeLogChangesProps) => {
  switch (changedItem.changeType) {
    case AccessoryACLCompareChangeType.SUPPLIER:
    case AccessoryACLCompareChangeType.TITLE:
    case AccessoryACLCompareChangeType.TITLE_ES:
    case AccessoryACLCompareChangeType.COPY:
    case AccessoryACLCompareChangeType.DISCLOSURE:
    case AccessoryACLCompareChangeType.PPO_CODE:
    case AccessoryACLCompareChangeType.DESCRIPTION:
    case AccessoryACLCompareChangeType.DESCRIPTION_ES:
    case AccessoryACLCompareChangeType.DISCLAIMER:
    case AccessoryACLCompareChangeType.DISCLAIMER_ES:
    case AccessoryACLCompareChangeType.NOTES:
    case AccessoryACLCompareChangeType.PART_NUMBER:
    case AccessoryACLCompareChangeType.PARTS_ONLY_DEALER_COST:
    case AccessoryACLCompareChangeType.PARTS_ONLY_MSRP:
    case AccessoryACLCompareChangeType.INSTALLED_DEALER_COST:
    case AccessoryACLCompareChangeType.REQUIRED:
    case AccessoryACLCompareChangeType.CONFLICTS:
    case AccessoryACLCompareChangeType.WARRANTY:
    case AccessoryACLCompareChangeType.LABOR_TIME:
    case AccessoryACLCompareChangeType.WARRANTY_ES: {
      return (
        <ChangeLogRichTextDiff
          before={changedItem.before || ""}
          after={changedItem.after || ""}
        />
      );
    }

    case AccessoryACLCompareChangeType.PRODUCT_TYPE_CHANGED:
    case AccessoryACLCompareChangeType.INSTALL_POINT: {
      return (
        <ChangeLogBeforeAfter
          before={changedItem.beforeValue || changedItem.before || ""}
          after={changedItem.afterValue || changedItem.after || ""}
        />
      );
    }

    case AccessoryACLCompareChangeType.NON_GENUINE: {
      return (
        <ChangeLogBeforeAfter
          before={changedItem.before === "true" ? "true" : "false"}
          after={changedItem.after === "true" ? "true" : "false"}
        />
      );
    }

    case AccessoryACLCompareChangeType.MSRP: {
      return (
        <ChangeLogRichTextDiff
          before={changedItem.before ? changedItem.before.toString() : ""}
          after={changedItem.after ? changedItem.after.toString() : ""}
        />
      );
    }

    case AccessoryACLCompareChangeType.IMAGES: {
      return (
        <ChangeLogImage
          before={changedItem.before ? JSON.stringify(changedItem.before) : ""}
          after={changedItem.after ? JSON.stringify(changedItem.after) : ""}
        />
      );
    }

    default: {
      return <>--</>;
    }
  }
};

export default ChangeLogChanges;
