import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  HeaderRow,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ScrollableContainer,
  Spinner,
  Table,
  TableCell,
} from "vapi-ui-common";
import {
  AccessoryItem,
  Change,
  GradeMsrpItem,
  ModelApplicabilityItem,
  RefItem,
  useGetAapChangesQuery,
} from "../../gql/generated";
import AccessoryItemVM from "../../models/accessories/AccessoryItemVM";
import ChangeLogChanges, {
  AccessoryGSTChangeType,
} from "../../routes/gst/components/ChangeLogChanges";
import { GradeItem } from "../../stores/modelStore";
import ChangeLogRow from "../ChangeLogTable/ChangeLogRow";
import styles from "./SyncNatChangesModal.module.scss";

interface Props {
  variables: {
    brand: string;
    team: string;
    region: string;
    lang: string;
    seriesId: string;
    modelYear: number;
    version: string;
  };
  close: () => void;
  accessory: AccessoryItemVM;
  gradesData: GradeItem[];
  productTypes: RefItem[];
  saveSyncedAAPChanges: (
    item: AccessoryItemVM,
    newProductType: string
  ) => Promise<void>;
}

interface CheckedChanges extends Change {
  checked: boolean;
}

const SyncNatChangesModal = ({
  variables,
  close,
  accessory,
  gradesData,
  productTypes,
  saveSyncedAAPChanges,
}: Props) => {
  const { data } = useGetAapChangesQuery({
    variables: {
      brand: variables.brand,
      seriesId: variables.seriesId,
      modelYear: variables.modelYear,
      team: variables.team,
      id: accessory.id,
    },
    fetchPolicy: "network-only",
  });

  const [isLoaded, setIsLoaded] = useState(false);
  const [logData, setLogData] = useState<CheckedChanges[]>([]);
  const [numberChecked, setNumberChecked] = useState(0);

  useEffect(() => {
    if (data && !isLoaded) {
      const changes: CheckedChanges[] = (
        data.aapChanges?.changes as Change[]
      ).map((change) => {
        return {
          ...change,
          checked: false,
        };
      });
      setLogData(changes);
      setIsLoaded(true);
    }
  }, [data]);

  useEffect(() => {
    setNumberChecked(logData.filter((log) => log.checked).length);
  }, [logData]);

  const handleToggleCheckbox = (changeType: string, selected: boolean) => {
    setLogData(
      logData.map((log) => {
        const l = log;
        if (l.changeType === changeType) {
          l.checked = selected;
        }
        return l;
      })
    );
  };

  const handleToggleAllCheckbox = (selected: boolean) => {
    setLogData(
      logData.map((log) => {
        const l = log;
        l.checked = selected;
        return l;
      })
    );
  };

  const ignoreChanges = () => {
    const a = accessory;
    a.hasAAPSyncChanges = false;
    saveSyncedAAPChanges(a, "");
    close();
  };

  const applyChanges = () => {
    const a = accessory;
    a.hasAAPSyncChanges = false;
    let newProductType = "";
    logData.forEach((log) => {
      if (log.checked) {
        const after = log.after ?? "";
        switch (log.changeType) {
          case AccessoryGSTChangeType.ABB: {
            a.abb = after;
            break;
          }
          case AccessoryGSTChangeType.TITLE: {
            a.title = after;
            break;
          }
          case AccessoryGSTChangeType.TITLE_ES: {
            a.title_es = after;
            break;
          }
          case AccessoryGSTChangeType.DESCRIPTION: {
            a.description = after;
            break;
          }
          case AccessoryGSTChangeType.DESCRIPTION_ES: {
            a.description_es = after;
            break;
          }
          case AccessoryGSTChangeType.DISCLAIMER: {
            a.disclaimer = after;
            break;
          }
          case AccessoryGSTChangeType.DISCLAIMER_ES: {
            a.disclaimer_es = after;
            break;
          }
          case AccessoryGSTChangeType.PART_NUMBER: {
            a.partNumber = after;
            break;
          }
          case AccessoryGSTChangeType.REQUIRED: {
            a.required = after;
            break;
          }
          case AccessoryGSTChangeType.CONFLICTS: {
            a.conflicts = after;
            break;
          }
          case AccessoryGSTChangeType.PRODUCT_TYPE_CHANGED: {
            const foundProductType = productTypes.find((type) => {
              return type.name.toLowerCase() === after.toLowerCase();
            });
            if (foundProductType) {
              a.productType = foundProductType.id;
            } else if (after) {
              newProductType = after;
            } else {
              a.productType = "";
            }
            break;
          }
          case AccessoryGSTChangeType.GRADE_MSRP: {
            a.gradeMsrp = Object.values(JSON.parse(after)) as GradeMsrpItem[];
            break;
          }
          case AccessoryGSTChangeType.MODEL_APPLICABILITY: {
            a.modelApplicability = Object.values(
              JSON.parse(after)
            ) as ModelApplicabilityItem[];
            break;
          }
          case AccessoryGSTChangeType.INACTIVE: {
            a.inactive = after === "true";
            break;
          }
          case AccessoryGSTChangeType.MSRP: {
            a.msrp = after;
            break;
          }
          case AccessoryGSTChangeType.WARRANTY: {
            a.warranty = after;
            break;
          }
          case AccessoryGSTChangeType.WARRANTY_ES: {
            a.warranty_es = after;
            break;
          }
          default:
            break;
        }
      }
    });
    saveSyncedAAPChanges(a, newProductType);
    close();
  };

  return (
    <>
      <ModalHeader onClose={() => close()}>
        Sync Changes from NAT Accessories
      </ModalHeader>
      {!isLoaded ? (
        <Spinner short />
      ) : (
        <>
          <ModalBody>
            <ScrollableContainer style={{ overflow: "scroll" }}>
              <Table fullWidth className={styles.aapTable}>
                <HeaderRow className={styles.stickyNav}>
                  <TableCell className={styles.checkboxCell}>
                    <Checkbox
                      id="addCl-chbox-all"
                      className={styles.checkboxAll}
                      checked={logData.every((log) => log.checked)}
                      onChange={(e) => {
                        handleToggleAllCheckbox(e.currentTarget.checked);
                      }}
                    />
                    <span>Apply?</span>
                  </TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Change Type</TableCell>
                  <TableCell>Changes</TableCell>
                  <TableCell>Published Date</TableCell>
                  <TableCell>Notes</TableCell>
                </HeaderRow>
                <tbody className={styles.appBody}>
                  {data &&
                    logData.map((item) => {
                      let changeTypeDisplay = item.changeType;

                      if (item.changeType === AccessoryGSTChangeType.TITLE) {
                        changeTypeDisplay = "Name";
                      } else if (
                        item.changeType === AccessoryGSTChangeType.TITLE_ES
                      ) {
                        changeTypeDisplay = "Name es";
                      }
                      const accItem = item.changedItem as AccessoryItem;
                      return (
                        <ChangeLogRow
                          key={`${item.changeType}${accItem.id}`}
                          description={accItem.title}
                          changeType={changeTypeDisplay}
                          modifiedBy=""
                          modifiedDate={item.modifiedDate || ""}
                          notes={accItem.notes || ""}
                          canRevert={false}
                          renderChanges={
                            <ChangeLogChanges
                              gradesDataItem={gradesData}
                              changedItem={item}
                              refItems={[]}
                            />
                          }
                          onRevert={() => {}}
                          apply
                          handleToggleCheckbox={(id, selected) => {
                            handleToggleCheckbox(id, selected);
                          }}
                          id={item.changeType}
                          checked={item.checked}
                        />
                      );
                    })}
                </tbody>
              </Table>
            </ScrollableContainer>
          </ModalBody>
          <ModalFooter>
            <Button variant="transparent" onClick={() => ignoreChanges()}>
              Ignore Changes
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                applyChanges();
              }}
              disabled={numberChecked < 1}
            >
              {`Apply (${numberChecked})`}
            </Button>
          </ModalFooter>
        </>
      )}
    </>
  );
};

export default SyncNatChangesModal;
