/* eslint-disable no-param-reassign */
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import {
  ImageItem,
  areRichTextValuesDifferent,
  cleanUpRte,
} from "vapi-ui-common";
import { useMemo } from "react";
import { AccessoryCommonLanguageItem } from "../../../models/commonLanguage.model";
import useStores from "../../../stores/useStores";
import handleErrorResponse from "../../../utils/errorHandlingUtils";
import useSaveCommonLanguage from "./useSaveDraftCL";
import useSaveProductType from "./useSaveProductType";
import {
  AclDocument,
  AclProductTypeItem,
  useArchiveAclDraftMutation,
  useCreateAclDraftMutation,
  useDeleteAclDraftMutation,
} from "../../../gql/generated";
import {
  ACCESSORY_TEAM,
  GST_REGION,
  NATIONAL_REGION,
} from "../../../constants/Constants";

const useRowHandlers = (item: AccessoryCommonLanguageItem) => {
  const saveCommonLanguage = useSaveCommonLanguage(item);
  const { addProductType, updateProductType } = useSaveProductType();

  const { userStore, commonLanguageStore } = useStores();
  const { productTypes } = commonLanguageStore;

  const [deleteACLDraft] = useDeleteAclDraftMutation();
  const [archiveACLDraft] = useArchiveAclDraftMutation();
  const [copyACLDraft] = useCreateAclDraftMutation();

  const oppCtx = {
    brand: userStore.brand,
    region: userStore.isGstUser() ? GST_REGION : NATIONAL_REGION,
    team: ACCESSORY_TEAM,
  };

  const copyItem = async () => {
    try {
      const res = await trackPromise(
        copyACLDraft({
          variables: {
            input: {
              ...oppCtx,
              accessory: item.toAccCommonLanguage(),
            },
          },
        })
      );

      if (res.errors?.length) {
        throw new Error(res.errors[0]?.message || "");
      }

      commonLanguageStore.saveItem(
        AccessoryCommonLanguageItem.fromACLDocument(
          res.data?.createACLDraft as AclDocument
        )
      );

      toast.success("Successfully copied item");
    } catch (e) {
      handleErrorResponse(e, "Error copying common language item");
    }
  };

  const deleteItem = async () => {
    try {
      if (item.publishedDate) {
        await archiveACLDraft({
          variables: {
            input: {
              ...oppCtx,
              id: item.id,
              revId: item.revId,
            },
          },
        });
      } else if (item.id) {
        await trackPromise(
          deleteACLDraft({
            variables: {
              input: {
                ...oppCtx,
                id: item.id,
                revId: item.revId,
              },
            },
          })
        );
      }

      commonLanguageStore.deleteItem(item);

      toast.success(
        `Successfully ${item.publishedDate ? "archived" : "deleted"} item`
      );
    } catch (e) {
      handleErrorResponse(
        e,
        `Error ${
          item.publishedDate ? "archived" : "deleted"
        } common language item`
      );
    }
  };

  // Product Type
  const productTypeMap = useMemo(
    () =>
      productTypes.reduce((map, pt) => {
        map.set(pt.id, pt);
        map.set(pt.name, pt);
        return map;
      }, new Map<string, AclProductTypeItem>()),
    [productTypes]
  );
  const handleProductTypeSelect = (selected: string) => {
    const selectedProductType = productTypeMap.get(selected);
    const currentProductType = productTypeMap.get(item.productType);

    if (
      !selectedProductType ||
      selectedProductType.id === currentProductType?.id
    ) {
      return;
    }

    item.productType = selectedProductType.id;
    saveCommonLanguage();
  };

  const handleProductTypeCreate = async (value: string) => {
    try {
      await addProductType(value);
    } catch (e) {
      handleErrorResponse(e, "Error adding product type");
    }
  };

  const handleProductTypeUpdate = async (selected: string, update: string) => {
    try {
      const productType = productTypes.find((pt) => pt.name === selected);

      if (productType) {
        productType.name = update;
        await updateProductType(productType);
      }
    } catch (e) {
      handleErrorResponse(e, "Error updating product type");
    }
  };

  // Input Changes
  const handleInputChange =
    (key: keyof AccessoryCommonLanguageItem) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value =
        e.currentTarget.type === "checkbox"
          ? (e as React.ChangeEvent<HTMLInputElement>).currentTarget.checked
          : e.currentTarget.value;
      if (item[key] !== value) {
        item[key] = value as never;
        saveCommonLanguage();
      }
    };

  const handleInstallPointSelect = (selected: string) => () => {
    const installPoint = item.installPoint?.split(",").filter(Boolean) || [];
    if (installPoint.includes(selected)) {
      const installPointIndex = installPoint.indexOf(selected);
      installPoint.splice(installPointIndex, 1);
    } else {
      installPoint.push(selected);
    }

    item.installPoint = installPoint.join(",");
    saveCommonLanguage();
  };

  const handleRichTextChange =
    (key: keyof AccessoryCommonLanguageItem) => (value: string) => {
      if (areRichTextValuesDifferent(item[key] as string, value)) {
        item[key] = cleanUpRte(value) as never;
        saveCommonLanguage();
      }
    };

  const handleTextChange =
    (key: keyof AccessoryCommonLanguageItem) => (text: string) => {
      if (item[key] !== text) {
        item[key] = text as never;
        saveCommonLanguage();
      }
    };

  const handleImageChange = (images: ImageItem[]) => {
    if (item.images !== images) {
      item.images = images;
      saveCommonLanguage();
    }
  };

  const handleDeleteImage = () => {
    item.images = [];
    saveCommonLanguage();
  };

  return {
    copyItem,
    deleteItem,
    // Product Type
    handleProductTypeSelect,
    handleProductTypeCreate,
    handleProductTypeUpdate,
    // Input Changes
    handleInputChange,
    handleInstallPointSelect,
    handleRichTextChange,
    handleTextChange,
    handleImageChange,
    handleDeleteImage,
  };
};

export default useRowHandlers;
