import React from "react";
import { Checkbox } from "vapi-ui-common";
import { FilterTabColumn } from "../FilterTab";
import IDValueType from "../../models/common/IDValueType.model";

interface ModelFiltersProps {
  fuelTypes: IDValueType[];
  selectedFuelTypes: string[];
  setSelectedFuelTypes: (list: string[]) => void;
}
const ModelFilters = ({
  fuelTypes,
  selectedFuelTypes,
  setSelectedFuelTypes,
}: ModelFiltersProps) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const item = selectedFuelTypes.find((fuelType) => fuelType === e.target.id);

    if (item) {
      const newList = selectedFuelTypes.filter(
        (fueltType) => fueltType !== e.target.id
      );
      setSelectedFuelTypes(newList);
    } else {
      setSelectedFuelTypes([...selectedFuelTypes, e.target.id]);
    }
  };

  return (
    <FilterTabColumn>
      {fuelTypes.map((fuelType) => (
        <Checkbox
          key={fuelType.id}
          id={fuelType.id}
          checked={!!selectedFuelTypes.find((item) => item === fuelType.id)}
          onChange={onChange}
        >
          {fuelType.value}
        </Checkbox>
      ))}
    </FilterTabColumn>
  );
};

export default ModelFilters;
