/**
 * Omit properties from an object
 *
 * @param obj The object to omit properties from
 * @param keys The keys to omit
 * @returns New object without the omitted properties
 */
const omit = <T extends Record<string, any>, K extends keyof T>(
  obj: T,
  keys: K[]
): Omit<T, K> => {
  const ret = {} as Record<string, any>;
  const keySet = new Set(keys);

  Object.keys(obj).forEach((key) => {
    if (!keySet.has(key as K)) {
      ret[key] = obj[key];
    }
  });
  return ret as Omit<T, K>;
};

export default omit;
