const checkForSyncChanges = (
  changedAttributes: string | string[] | undefined,
  value: string
) => {
  let result = false;
  if (changedAttributes) {
    result = changedAttributes.includes(value);
  }
  return result;
};

export default checkForSyncChanges;
