import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Redirect, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ActionBar,
  ActionBarSection,
  Button,
  convertToRichTextObject,
  Header,
  HeaderCell,
  HeaderRow,
  SearchInput,
  Spinner,
  Table,
  Thead,
  useDebounce,
  Wayfinding,
} from "vapi-ui-common";
import BackButton from "../../components/BackButton/BackButton";
import ChangeLogRow from "../../components/ChangeLogTable/ChangeLogRow";
import PageError from "../../components/PageError";
import VersionsList from "../../components/VersionList";
import { GST_REGION } from "../../constants/Constants";
import {
  AccessoryItem,
  Change,
  useGetAccessoryChangeLogWithProductTypeQuery,
  useRevertAccessoryChangeLogMutation,
} from "../../gql/generated";
import { VehicleTeam } from "../../models/vehicleData/vehicleData.model";
import useStores from "../../stores/useStores";
import handleErrorResponse from "../../utils/errorHandlingUtils";
import getChangeLogExitVersion from "../../utils/getChangeLogExitVersion";
import getFromTo from "../../utils/getFromTo";
import getQueryParams from "../../utils/getQueryParams";
import sortBy from "../../utils/sortBy";
import { AccessoryChangeType } from "../national/components/ChangeLogChanges";
import ChangeLogChanges, {
  AccessoryGSTChangeType,
} from "./components/ChangeLogChanges";

interface RouteParams {
  seriesId: string;
  series: string;
  year: string;
  version: string;
}

const ChangeLogController = () => {
  const params: RouteParams = useParams();
  const { userStore, modelStore, teamStore } = useStores();

  // used for graphql payloads
  const userDetails = {
    brand: userStore.brand,
    team: "AT",
    region: GST_REGION,
    lang: "en",
  };

  const draftDetails = {
    seriesId: params.seriesId,
    modelYear: Number(params.year),
    version: params.version || "DRAFT",
  };

  const { data, loading, error, refetch } =
    useGetAccessoryChangeLogWithProductTypeQuery({
      variables: { ...userDetails, ...draftDetails },
      fetchPolicy: "no-cache",
    });
  const [revertChange] = useRevertAccessoryChangeLogMutation();

  const [logData, setLogData] = useState<Change[]>([]);
  const [readOnly, setReadOnly] = useState(false);
  const [redirectVersion, setRedirectVersion] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState("modifiedDate");
  const [sortReverse, setSortReverse] = useState(true);
  const { debounce } = useDebounce();

  useEffect(() => {
    teamStore.setTeam(
      VehicleTeam.GST_ACC_TEAM,
      userStore.brand,
      userStore.langPermissions
    );
    modelStore.fetchModels(
      draftDetails.seriesId,
      draftDetails.modelYear.toString(),
      userDetails.brand
    );
    if (data && !loading && !error) {
      setRedirectVersion("");
      const changes = data.changes?.changes as Change[];
      setReadOnly(typeof data.accessories?.header?.publishedDate === "string");
      setLogData(changes);
    }
  }, [data]);

  const handleOnRevert = async (item: Change) => {
    try {
      const { from, to } = getFromTo(
        item.changeType,
        item.before,
        item.after,
        item.beforeValue,
        item.afterValue
      );

      const id =
        item.changeType === AccessoryChangeType.PRODUCT_TYPE_CHANGED
          ? item.after || ""
          : (item.changedItem as AccessoryItem).id;

      await trackPromise(
        revertChange({
          variables: {
            ...userDetails,
            seriesId: draftDetails.seriesId,
            modelYear: draftDetails.modelYear,
            changeType: item.changeType,
            id,
            from,
            to,
          },
        })
      );
      toast.success("Change log reverted");
      refetch();
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const compareSearchQuery = (searchText = "", value = "") => {
    return value.toLowerCase().indexOf(searchText) !== -1;
  };

  const handleSearchFilter = (query: string) => {
    debounce(() => {
      setSearchQuery(query.toLowerCase());
    });
  };

  const handleSortColumn = (column: string) => {
    setSortColumn(column);
    setSortReverse(column === sortColumn ? !sortReverse : false);
  };

  const filteredData = useMemo(() => {
    return logData
      .filter((item) => {
        const accessoryItem = item.changedItem as AccessoryItem;
        return (
          compareSearchQuery(
            searchQuery,
            convertToRichTextObject(accessoryItem.title).text
          ) ||
          compareSearchQuery(searchQuery, accessoryItem.notes || "") ||
          compareSearchQuery(searchQuery, item.modifiedBy || "") ||
          compareSearchQuery(searchQuery, item.changeType)
        );
      })
      .sort(sortBy(sortColumn, sortReverse, sortColumn === "modifiedDate"));
  }, [logData, searchQuery, sortColumn, sortReverse]);

  const exitVersion = getChangeLogExitVersion(
    getQueryParams().get("exitVersion"),
    data?.latestVersion?.latestVersion,
    data?.latestVersion?.hasDraft
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <PageError />;
  }

  if (redirectVersion) {
    return (
      <Redirect
        to={`/vehicleData/gst/changelog/${params.seriesId}/${params.year}/${params.series}/${redirectVersion}?exitVersion=${exitVersion}`}
      />
    );
  }

  const exitLink = `/vehicleData/gst/accessories/${params.seriesId}/${params.year}/${params.series}/${exitVersion}`;

  return (
    <div>
      <Header moduleTitle="Accessories" moduleSubTitle="Change Log" />
      <Wayfinding
        year={`${params.year} `}
        seriesName={params.series}
        renderBackButton={<BackButton to="/gst/dashboard" />}
      />
      <ActionBar>
        <ActionBarSection>
          <SearchInput value="" onSearch={handleSearchFilter} />
        </ActionBarSection>
        <ActionBarSection>
          <NavLink to={exitLink}>
            <Button variant="transparent">Exit Change Log</Button>
          </NavLink>
          <VersionsList
            value={draftDetails.version}
            latestVersion={data?.latestVersion?.latestVersion}
            hasDraft={data?.latestVersion?.hasDraft}
            onSelect={setRedirectVersion}
          />
        </ActionBarSection>
      </ActionBar>
      <Table fullWidth>
        <Thead>
          <HeaderRow>
            <HeaderCell>Name</HeaderCell>
            <HeaderCell
              onClick={() => {
                handleSortColumn("changeType");
              }}
            >
              Change Type
            </HeaderCell>
            <HeaderCell>Changes</HeaderCell>
            <HeaderCell
              onClick={() => {
                handleSortColumn("modifiedBy");
              }}
            >
              Modified By
            </HeaderCell>
            <HeaderCell
              onClick={() => {
                handleSortColumn("modifiedDate");
              }}
            >
              Modified Date
            </HeaderCell>
            <HeaderCell>Notes</HeaderCell>
            <HeaderCell />
          </HeaderRow>
        </Thead>
        <tbody>
          {filteredData.map((item) => {
            const accItem = item.changedItem as AccessoryItem;
            let changeTypeDisplay = item.changeType;

            if (item.changeType === AccessoryGSTChangeType.TITLE) {
              changeTypeDisplay = "Name";
            } else if (item.changeType === AccessoryGSTChangeType.TITLE_ES) {
              changeTypeDisplay = "Name es";
            }

            return (
              <ChangeLogRow
                key={item.modifiedDate}
                description={accItem.title}
                changeType={changeTypeDisplay}
                modifiedBy={item.modifiedBy || ""}
                modifiedDate={item.modifiedDate || ""}
                notes={accItem.notes || ""}
                canRevert={
                  !readOnly &&
                  ![
                    AccessoryGSTChangeType.ACCESSORY_DELETED,
                    AccessoryGSTChangeType.COMMON_LANGUAGE,
                  ].includes(item.changeType as AccessoryGSTChangeType)
                }
                renderChanges={
                  <ChangeLogChanges
                    gradesDataItem={modelStore.gradesData}
                    changedItem={item}
                    refItems={data?.refItems?.refItems}
                  />
                }
                onRevert={() => handleOnRevert(item)}
                revertMessage={
                  item.changeType ===
                  AccessoryChangeType.PRODUCT_TYPE_CHANGED ? (
                    <>
                      {`Are you sure you want to revert the Product Type change from "${item.beforeValue}" to "${item.afterValue}"`}
                    </>
                  ) : undefined
                }
              />
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default observer(ChangeLogController);
