import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Button, IconTextButton, Input } from "vapi-ui-common";
import { GradeMsrpItem } from "../../gql/generated";
import { GradeItem } from "../../stores/modelStore";
import msrpPopoverStore, { MsrpVM } from "../../stores/MsrpPopoverStore";
import styles from "./MsrpPopover.module.scss";
import MsrpPopoverDropdown from "./MsrpPopoverDropdown";

interface MsrpProps {
  msrp: string;
  modelGrades?: GradeItem[];
  onUpdate?: (cost: string) => void;
  onGradeUpdate?: (gradeMsrp: GradeMsrpItem[]) => void;
  closePopover?: () => void;
  onCostChange?: (cost: string) => void;
  title?: string;
  data?: GradeMsrpItem[];
  hideAddTrimMsrp: boolean;
}

const MsrpPopoverContent = (props: MsrpProps) => {
  const {
    data,
    msrp,
    modelGrades,
    closePopover,
    onUpdate,
    onGradeUpdate,
    onCostChange,
    title = "",
    hideAddTrimMsrp,
  } = props;

  const [formMsrp, setFormMsrp] = useState(msrp);

  useEffect(() => {
    msrpPopoverStore.reset();
    if (data && data.length) {
      msrpPopoverStore.gradesData = modelGrades ?? [];
      msrpPopoverStore.init(data);
    }
  }, [data]);

  const selectGrade = (item: GradeItem, msrpItem: MsrpVM) => {
    const msrpVM = msrpItem;
    msrpVM.id = item.id;
    msrpVM.value = item.value;
  };

  const renderDropdownContent = () => {
    return (
      <div>
        {msrpPopoverStore.data?.map((msrpItem) => {
          const grades = !modelGrades
            ? []
            : modelGrades.filter((grade) => {
                if (msrpItem.id === grade.id) {
                  return true;
                }
                if (
                  msrpPopoverStore.data?.find((item) => item.id === grade.id)
                ) {
                  return false;
                }
                return true;
              });
          return (
            <MsrpPopoverDropdown
              msrpItem={msrpItem}
              onCostChange={onCostChange}
              modelGrades={grades}
              selectGrade={selectGrade}
            />
          );
        })}
      </div>
    );
  };

  return (
    <section className={styles.msrpSection}>
      <header className={styles.header}>{title}</header>
      <div className={styles.inputContainer}>
        <span className={styles.currencyInput}>
          <span data-for="msrp" className={styles.label}>
            All
          </span>
          <Input
            className={styles.input}
            onChange={(e) => {
              setFormMsrp(e.currentTarget.value);
              if (typeof onCostChange === "function") {
                onCostChange(e.currentTarget.value.trim());
              }
            }}
            value={formMsrp}
          />
          {!hideAddTrimMsrp && (
            <div className={styles.msrpIcon}>
              <IconTextButton
                icon="plus"
                text="Add Different Trim MSRP "
                onClick={() => msrpPopoverStore.addMsrp()}
              />
            </div>
          )}
        </span>
        {renderDropdownContent()}
      </div>
      <footer className={styles.footer}>
        <Button
          variant="transparent"
          onClick={() => {
            if (typeof closePopover === "function") {
              closePopover();
            }
          }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (typeof onUpdate === "function") {
              onUpdate(formMsrp.trim());
              if (onGradeUpdate) {
                onGradeUpdate(msrpPopoverStore.payload() ?? []);
              }
            }
            if (typeof closePopover === "function") {
              closePopover();
            }
          }}
        >
          Save
        </Button>
      </footer>
    </section>
  );
};
MsrpPopoverContent.defaultProps = {
  modelGrades: [],
  onUpdate: undefined,
  onGradeUpdate: undefined,
  closePopover: undefined,
  onCostChange: undefined,
  title: "",
  data: [],
};

export default observer(MsrpPopoverContent);
