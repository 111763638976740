import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "vapi-ui-common";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import styles from "./UploadImage.module.scss";

interface UploadImageProps {
  file?: File | null;
  onUpload?: (file: File) => void;
}

const UploadImage = ({ file = null, onUpload }: UploadImageProps) => {
  const [imageFile, setImageFile] = useState<File | null>(file);
  const [imageData, setImageData] = useState<string | null>(null);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (imageFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageData(reader.result as string);
      };
      reader.readAsDataURL(imageFile);
    }
  }, [imageFile]);

  useEffect(() => {
    if (file && file !== imageFile) {
      setImageFile(file);
    }
  }, [file]);

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const { files } = e.target;
      if (files && files.length) {
        const uploaded = files[0];

        if (uploaded.type !== "image/jpeg") {
          toast.error("Invalid File Format.");
          return;
        }

        setImageFile(uploaded);
        onUpload?.(uploaded);
      }
    },
    []
  );

  const handleClick = useCallback(() => {
    if (ref.current) {
      ref.current.click();
    }
  }, []);

  return (
    <>
      <input
        ref={ref}
        type="file"
        className={styles.imageUploadInput}
        multiple={false}
        onChange={handleChange}
        style={{ display: "none" }}
        accept="image/jpeg"
      />
      <div
        className={clsx(
          styles.imageUploadContainer,
          imageData && styles.withImage
        )}
      >
        {imageData ? (
          <img
            className={styles.imageUploadPreview}
            src={imageData}
            alt="chosen"
          />
        ) : (
          <div className={styles.imageUploadPlaceholder}>
            <FontAwesomeIcon
              icon={faImage}
              className={styles.imageUploadIcon}
              size="6x"
            />
            <div className={styles.imageUploadText}>File type must be .JPG</div>
          </div>
        )}
      </div>
      <div className={styles.imageUploadFooter}>
        <Button variant="primary" onClick={handleClick}>
          Choose file
        </Button>
      </div>
    </>
  );
};

export default UploadImage;
