import React from "react";

interface Props {
  before: string | null | undefined;
  after: string | null | undefined;
}

const ChangeLogCommonLang = ({ before, after }: Props) => {
  const unlinked = !!before && !after;

  return <div>{unlinked ? "Unlinked" : "Linked"}</div>;
};

export default ChangeLogCommonLang;
