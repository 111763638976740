import React, { ReactNode, useEffect } from "react";
import {
  HeaderCell,
  Spinner,
  Table,
  TableRow,
  Thead,
  useIsLoading,
} from "vapi-ui-common";
import useStores from "../../../../stores/useStores";
import styles from "../../AccessoriesEntryScreen.module.scss";
import { CDisclosureHeader } from "./DisclosureColumn";
import { CWarrantyHeader } from "./WarrantyColumn";

interface AccessoriesEntryProps {
  children: ReactNode;
  setSort: (prop: string) => void;
  tHeadStyle?: React.CSSProperties;
}

const AccessoriesEntryScreenTable = ({
  children,
  setSort,
  tHeadStyle,
}: AccessoriesEntryProps) => {
  const { AccessoriesListStore, userStore } = useStores();
  const { isLoading, initLoading } = useIsLoading();

  useEffect(() => {
    initLoading(AccessoriesListStore.fetchData);
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Table fullWidth className={styles.accTable}>
        <Thead style={tHeadStyle}>
          <TableRow style={{ fontWeight: "bold" }}>
            <CDisclosureHeader />

            <HeaderCell>Product Type</HeaderCell>
            <HeaderCell>Install Point</HeaderCell>
            <HeaderCell
              onClick={() => {
                setSort("ppoCode");
              }}
            >
              PPO Code
            </HeaderCell>
            <CWarrantyHeader />
            <HeaderCell>Required/Conflicts</HeaderCell>
            <HeaderCell
              onClick={() => {
                setSort("partNumber");
              }}
            >
              Part Number
            </HeaderCell>
            {(userStore.isToyotaNationalUser() || userStore.isLexusUser()) && (
              <HeaderCell
                onClick={() => {
                  setSort("partsOnlyDealerCost");
                }}
              >
                Dealer Cost
              </HeaderCell>
            )}
            {userStore.isToyotaNationalUser() && (
              <HeaderCell
                onClick={() => {
                  setSort("partsOnlyMSRP");
                }}
              >
                Parts Only MSRP
              </HeaderCell>
            )}
            <HeaderCell />
          </TableRow>
        </Thead>

        <tbody>{children}</tbody>
      </Table>
    </>
  );
};

export default AccessoriesEntryScreenTable;
