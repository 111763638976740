import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "clsx";
import React, { useCallback, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownContent,
  Modal,
  useComponentVisible,
} from "vapi-ui-common";
import { AclDocument, RefItem } from "../../gql/generated";
import AccessoryItemVM from "../../models/accessories/AccessoryItemVM";
import CommonLanguageLink from "../CommonLanguageLink";
import CommonLanguageUnlinkModal from "../CommonLanguageUnlinkModal/CommonLanguageUnlinkModal";
import CompareAccToACLModal from "../CompareAccToACLModal";
import styles from "./CommonLanguageBadge.module.scss";

interface ICommonLanguageBadgeProps {
  variables: {
    brand: string;
    team: string;
    region: string;
    lang: string;
    seriesId: string;
    modelYear: number;
    version: string;
  };
  accessoryItem: AccessoryItemVM;
  productTypes: RefItem[];
  disabled: boolean;
  readOnly: boolean;
  onUnlink: () => void;
  onSync: (item: AccessoryItemVM, newProductType: string) => Promise<void>;
  accessories: AccessoryItemVM[];
  onLink: (aclDoc: AclDocument) => void;
  sortMode?: boolean;
}

const CommonLanguageBadge = ({
  variables,
  accessoryItem,
  productTypes,
  disabled,
  readOnly,
  onUnlink,
  onSync,
  accessories,
  onLink,
  sortMode,
}: ICommonLanguageBadgeProps) => {
  const {
    ref,
    isComponentVisible: showUnlinkCommonLanguageMenu,
    setIsComponentVisible: setShowUnlinkCommonLanguageMenu,
  } = useComponentVisible(false);
  const [showUnlinkCommonLanguageModal, setShowUnlinkCommonLanguageModal] =
    useState(false);
  const [showSyncModal, setShowSyncModal] = useState(false);

  const onCommonLanguageClick = useCallback(
    () =>
      accessoryItem.hasComLangChanges
        ? setShowSyncModal(true)
        : setShowUnlinkCommonLanguageMenu(true),
    [
      setShowUnlinkCommonLanguageMenu,
      accessoryItem.hasComLangChanges,
      setShowSyncModal,
    ]
  );

  const onShowUnlinkCommonLanguageModal = useCallback(
    () => setShowUnlinkCommonLanguageModal(true),
    [setShowUnlinkCommonLanguageModal]
  );

  const onCloseUnlinkCommonLanguageModal = useCallback(
    () => setShowUnlinkCommonLanguageModal(false),
    [setShowUnlinkCommonLanguageModal]
  );

  if (!accessoryItem.comLangId) {
    if (!readOnly && !accessoryItem.nationalId) {
      return <CommonLanguageLink accessories={accessories} onLink={onLink} />;
    }
    return null;
  }

  return (
    <>
      <Dropdown
        className={cx(styles.dropdown, {
          [styles.readOnlyDropdown]: readOnly,
          [styles.sortMode]: sortMode,
        })}
      >
        <Button
          toggled
          onClick={onCommonLanguageClick}
          disabled={disabled}
          className={cx(styles.comLangBtn, {
            [styles.hasUpdatesBtn]:
              !readOnly && accessoryItem.hasComLangChanges,
            [styles.noUpdatesBtn]: readOnly || !accessoryItem.hasComLangChanges,
          })}
        >
          {accessoryItem.hasComLangChanges && !readOnly && (
            <FontAwesomeIcon
              className={styles.warningIcon}
              icon={faExclamationTriangle}
            />
          )}
          Common Language
        </Button>
        <DropdownContent
          ref={ref}
          open={showUnlinkCommonLanguageMenu}
          menuClass={styles.dropdownContent}
        >
          <div className={styles.unlinkTextDropdown}>
            <b>Linked Common Language</b>
          </div>
          <div className={styles.unlinkBtnDropdown}>
            <Button
              toggled
              onClick={onShowUnlinkCommonLanguageModal}
              variant="primary"
            >
              Unlink Language
            </Button>
          </div>
        </DropdownContent>
      </Dropdown>

      <Modal
        size="auto"
        open={showUnlinkCommonLanguageModal}
        onClose={onCloseUnlinkCommonLanguageModal}
      >
        <CommonLanguageUnlinkModal
          accessoryItem={accessoryItem}
          onUnlink={onUnlink}
          onClose={onCloseUnlinkCommonLanguageModal}
        />
      </Modal>

      <Modal
        size="auto"
        open={showSyncModal}
        onClose={() => setShowSyncModal(false)}
      >
        <CompareAccToACLModal
          accessory={accessoryItem}
          close={() => setShowSyncModal(false)}
          productTypes={productTypes}
          saveACLChanges={onSync}
          variables={variables}
        />
      </Modal>
    </>
  );
};

export default CommonLanguageBadge;
