import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  ActionBar,
  ActionBarSection,
  Button,
  convertToRichTextObject,
  Header,
  HeaderCell,
  HeaderRow,
  SearchInput,
  Spinner,
  Table,
  Thead,
  useDebounce,
  Wayfinding,
} from "vapi-ui-common";
import BackButton from "../../components/BackButton/BackButton";
import ChangeLogRow from "../../components/ChangeLogTable/ChangeLogRow";
import PageError from "../../components/PageError";
import VersionsList from "../../components/VersionList";
import { NATIONAL_REGION } from "../../constants/Constants";
import {
  AccessoryItem,
  Change,
  useGetAccessoryChangeLogWithProductTypeQuery,
  useRevertAccessoryChangeLogMutation,
} from "../../gql/generated";
import { Language } from "../../models/user/user.model";
import { VehicleTeam } from "../../models/vehicleData/vehicleData.model";
import useStores from "../../stores/useStores";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import handleErrorResponse from "../../utils/errorHandlingUtils";
import getChangeLogExitVersion from "../../utils/getChangeLogExitVersion";
import getFromTo from "../../utils/getFromTo";
import getQueryParams from "../../utils/getQueryParams";
import sortBy from "../../utils/sortBy";
import { getVersionInfoFromParams } from "../../utils/vehicleDataUtils";
import styles from "./ChangeLog.module.scss";
import ChangeLogChanges, {
  AccessoryChangeType,
} from "./components/ChangeLogChanges";

interface RouteParams {
  seriesId: string;
  series: string;
  year: string;
  version: string;
  team: VehicleTeam;
}

const ChangeLogController = () => {
  const params: RouteParams = useParams();
  const { userStore, modelStore, teamStore } = useStores();

  const isSpanish = params.team === VehicleTeam.SPANISH;
  const teamLang = isSpanish ? Language.ES : Language.EN;
  const vdVersionInfo = getVersionInfoFromParams(params.version);
  const [version, setVersion] = useState(
    (vdVersionInfo[teamLang] as string) || "DRAFT"
  );

  // used for graphql payloads
  const userDetails = {
    brand: userStore.brand,
    team: "AT",
    region: NATIONAL_REGION,
    lang: teamLang.toLowerCase(),
  };

  const draftDetails = {
    seriesId: params.seriesId,
    modelYear: Number(params.year),
    version,
  };

  const { data, loading, error, refetch } =
    useGetAccessoryChangeLogWithProductTypeQuery({
      variables: { ...userDetails, ...draftDetails },
      fetchPolicy: "no-cache",
    });

  const [revertChange] = useRevertAccessoryChangeLogMutation();

  const [logData, setLogData] = useState<Change[]>([]);
  const [readOnly, setReadOnly] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortColumn, setSortColumn] = useState("modifiedDate");
  const [sortReverse, setSortReverse] = useState(true);

  const { debounce } = useDebounce();

  useEffect(() => {
    teamStore.setTeam(params.team, userStore.brand, userStore.langPermissions);
    modelStore.fetchModels(
      draftDetails.seriesId,
      draftDetails.modelYear.toString(),
      userDetails.brand
    );
    if (data && !loading && !error) {
      const changes = data.changes?.changes as Change[];
      setReadOnly(typeof data.accessories?.header?.publishedDate === "string");
      setLogData(changes);
    }
  }, [data]);

  const handleOnRevert = async (item: Change) => {
    try {
      const { from, to } = getFromTo(
        item.changeType,
        item.before,
        item.after,
        item.beforeValue,
        item.afterValue
      );
      const id =
        item.changeType === AccessoryChangeType.PRODUCT_TYPE_CHANGED
          ? item.after || ""
          : (item.changedItem as AccessoryItem).id;

      await trackPromise(
        revertChange({
          variables: {
            ...userDetails,
            seriesId: draftDetails.seriesId,
            modelYear: draftDetails.modelYear,
            changeType: item.changeType,
            id,
            from,
            to,
          },
        })
      );
      toast.success("Change log reverted");
      refetch();
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const compareSearchQuery = (searchText = "", value = "") => {
    return value.toLowerCase().indexOf(searchText) !== -1;
  };

  const handleSearchFilter = (query: string) => {
    debounce(() => {
      setSearchQuery(query.toLowerCase());
    });
  };

  const handleSortColumn = (column: string) => {
    setSortColumn(column);
    setSortReverse(column === sortColumn ? !sortReverse : false);
  };

  const getAlternativeRevertMessage = (changeItem: Change): JSX.Element | undefined => {
    if (changeItem.changeType === AccessoryChangeType.PRODUCT_TYPE_CHANGED) {
      return (
        <>
          {`Are you sure you want to revert the Product Type change from "${changeItem.beforeValue}" to "${changeItem.afterValue}"`}
        </>
      )
    }

    if (changeItem.changeType === AccessoryChangeType.GO_LIVE_DATE) {
      return (
        <>
          Are you sure you want to revert the change of Go Live Date for this model?
        </>
      )
    }

    return undefined;
  };

  const filteredData = useMemo(() => {
    return logData
      .filter((item) => {
        const accessoryItem = item.changedItem as AccessoryItem;
        return (
          compareSearchQuery(
            searchQuery,
            convertToRichTextObject(accessoryItem.title).text
          ) ||
          compareSearchQuery(searchQuery, accessoryItem.notes || "") ||
          compareSearchQuery(searchQuery, item.modifiedBy || "") ||
          compareSearchQuery(searchQuery, item.changeType)
        );
      })
      .sort(sortBy(sortColumn, sortReverse, sortColumn === "modifiedDate"));
  }, [logData, searchQuery, sortColumn, sortReverse]);

  const exitVersion = getChangeLogExitVersion(
    getQueryParams().get("exitVersion"),
    data?.latestVersion?.latestVersion,
    data?.latestVersion?.hasDraft
  );

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <PageError />;
  }

  const exitLink = `/vehicleData/${params.team}/accessories/${params.seriesId}/${params.year}/${params.series}/${exitVersion}`;
  const teamTitle = `${capitalizeFirstLetter(params.team)} Accessories Team`;

  return (
    <div>
      <Header moduleTitle={teamTitle} moduleSubTitle="Change Log" />
      <Wayfinding
        year={`${params.year} `}
        seriesName={params.series}
        renderBackButton={<BackButton to={`/${params.team}/dashboard`} />}
      />
      <ActionBar>
        <ActionBarSection>
          <SearchInput value="" onSearch={handleSearchFilter} />
        </ActionBarSection>
        <ActionBarSection>
          <NavLink to={exitLink}>
            <Button variant="transparent">Exit Change Log</Button>
          </NavLink>
          <VersionsList
            className={styles.dropdownList}
            value={draftDetails.version}
            latestVersion={data?.latestVersion?.latestVersion}
            hasDraft={data?.latestVersion?.hasDraft}
            onSelect={setVersion}
          />
        </ActionBarSection>
      </ActionBar>
      <Table fullWidth>
        <Thead>
          <HeaderRow>
            <HeaderCell>Accessory Title</HeaderCell>
            <HeaderCell
              onClick={() => {
                handleSortColumn("changeType");
              }}
            >
              Change Type
            </HeaderCell>
            <HeaderCell>Changes</HeaderCell>
            <HeaderCell
              onClick={() => {
                handleSortColumn("modifiedBy");
              }}
            >
              Modified By
            </HeaderCell>
            <HeaderCell
              onClick={() => {
                handleSortColumn("modifiedDate");
              }}
            >
              Modified Date
            </HeaderCell>
            <HeaderCell>Notes</HeaderCell>
            <HeaderCell />
          </HeaderRow>
        </Thead>
        <tbody>
          {filteredData.map((item) => {
            const accItem = item.changedItem as AccessoryItem;

            return (
              <React.Fragment key={`row-change-${accItem.id}-${uuidv4()}`}>
                {item.changeType !== AccessoryChangeType.SPECS && (
                  <ChangeLogRow
                    key={`${item.modifiedDate}${item.changeType}${accItem.id}`}
                    description={accItem.title}
                    changeType={item.changeType}
                    modifiedBy={item.modifiedBy || ""}
                    modifiedDate={item.modifiedDate || ""}
                    notes={(item.changedItem as AccessoryItem).notes || ""}
                    canRevert={
                      !readOnly &&
                      ![
                        AccessoryChangeType.ACCESSORY_DELETED,
                        AccessoryChangeType.COMMON_LANGUAGE,
                        isSpanish ? AccessoryChangeType.ACCESSORY_ADDED : "",
                      ].includes(item.changeType as AccessoryChangeType)
                    }
                    renderChanges={
                      <ChangeLogChanges
                        gradesDataItem={modelStore.gradesData}
                        changedItem={item}
                        refItems={data?.refItems?.refItems}
                      />
                    }
                    onRevert={() => handleOnRevert(item)}
                    revertMessage={getAlternativeRevertMessage(item)}
                  />
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default observer(ChangeLogController);
