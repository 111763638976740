/* eslint-disable react/require-default-props */
import React from "react";
import { ChecklistItem } from "vapi-ui-common/dist/src/hooks/useChecklist/useChecklist";
import { Checkbox } from "vapi-ui-common";
import { FilterTabColumn, FilterTabColumnList } from "../FilterTab";

export interface AppliedFilters {
  productFilters?: string[];
  inProgress: boolean;
  syncChanges: boolean;
  active?: boolean;
  dio?: boolean;
  ppo?: boolean;
}

interface FiltersProps {
  checklist: ChecklistItem[];
  isAllSelected: boolean;
  isSpanish?: boolean;
  disableDio?: boolean;
  disablePpo?: boolean;
  inProgress?: boolean;
  active?: boolean;
  dio?: boolean;
  ppo?: boolean;
  syncChanges: boolean;
  selectAll: (selected: boolean) => void;
  selectItem: (item: ChecklistItem, selected: boolean) => void;
  setInProgress: (val: boolean) => void;
  setActive: (val: boolean) => void;
  setDio: (val: boolean) => void;
  setPpo: (val: boolean) => void;
  setSyncChanges: (val: boolean) => void;
}
const AccessoryFilters = ({
  checklist = [],
  isAllSelected = false,
  isSpanish = false,
  disableDio = false,
  disablePpo = false,
  inProgress = false,
  active = false,
  dio = false,
  ppo = false,
  syncChanges,
  selectAll,
  selectItem,
  setInProgress,
  setActive,
  setDio,
  setPpo,
  setSyncChanges,
}: FiltersProps) => {
  return (
    <>
      {checklist.length > 0 && (
        <FilterTabColumn>
          <Checkbox
            id="AllProductTypes"
            checked={isAllSelected}
            onChange={(e) => selectAll(e.currentTarget.checked)}
          >
            All ProductTypes
          </Checkbox>
          <FilterTabColumnList>
            {checklist.map((item) => (
              <li key={item.name}>
                <Checkbox
                  id={`chboxCat${item.name}`}
                  checked={item.selected}
                  onChange={(e) => selectItem(item, e.currentTarget.checked)}
                >
                  {item.name}
                </Checkbox>
              </li>
            ))}
          </FilterTabColumnList>
        </FilterTabColumn>
      )}
      <FilterTabColumn>
        <Checkbox
          id="inProgressChbox"
          checked={inProgress}
          onChange={(e) => {
            setInProgress(e.currentTarget.checked);
            setActive(false);
          }}
        >
          Inactive
        </Checkbox>
        <Checkbox
          id="activeChbox"
          checked={active}
          onChange={(e) => {
            setActive(e.currentTarget.checked);
            setInProgress(false);
          }}
        >
          Active
        </Checkbox>
        {!disableDio && (
          <Checkbox
            id="dioChbox"
            checked={dio}
            onChange={(e) => setDio(e.currentTarget.checked)}
          >
            DIO
          </Checkbox>
        )}
        {!disablePpo && (
          <Checkbox
            id="ppoChbox"
            checked={ppo}
            onChange={(e) => setPpo(e.currentTarget.checked)}
          >
            PPO
          </Checkbox>
        )}
      </FilterTabColumn>
      {isSpanish && (
        <FilterTabColumn>
          <Checkbox
            id="syncChbox"
            checked={syncChanges}
            onChange={(e) => setSyncChanges(e.currentTarget.checked)}
          >
            Sync Updates
          </Checkbox>
        </FilterTabColumn>
      )}
    </>
  );
};

AccessoryFilters.defaultProps = {
  isSpanish: false,
};

export default AccessoryFilters;
