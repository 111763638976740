import React from "react";
import { Icon, Status, StatusComponent } from "vapi-ui-common";
import { SeriesMyItem } from "../../../gql/generated";

interface StatusModelProps {
  model: SeriesMyItem;
}

const StatusModel = ({ model }: StatusModelProps) => {
  const {
    spanishVersion,
    publishDownstreamVersion,
    skippedLivePublish,
    previewDownstreamVersion,
    isDraft,
    previewDownstreamDate,
    publishDownstreamDate,
    createdDate,
    spanishPublishedDate,
  } = model;

  if (isDraft) {
    return (
      <StatusComponent
        statusText="Draft"
        status={Status.DRAFT}
        statusIcon={Icon.CIRCLE}
        date={createdDate as string}
      />
    );
  }

  if (!spanishVersion) return null;

  if (spanishVersion === publishDownstreamVersion) {
    return (
      <StatusComponent
        statusText={`Published (${skippedLivePublish ? "Prevlive" : "Live"})`}
        status={Status.PUBLISHED}
        statusIcon={Icon.CIRCLE}
        date={publishDownstreamDate ?? ""}
      />
    );
  }

  if (spanishVersion === previewDownstreamVersion) {
    return (
      <StatusComponent
        statusText="Published (Preview)"
        status={Status.PUBLISHED}
        statusIcon={Icon.CIRCLE}
        date={previewDownstreamDate ?? ""}
      />
    );
  }

  return (
    <StatusComponent
      statusText="Certified"
      status={Status.CERTIFIED}
      statusIcon={Icon.CIRCLE}
      date={spanishPublishedDate ?? ""}
    />
  );
};

export default StatusModel;
