import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button } from "vapi-ui-common";
import { NATIONAL_REGION } from "../../constants/Constants";
import { RouteParams } from "../../models/routes.model";
import { Language } from "../../models/user/user.model";
import AccessoriesEntryScreenService from "../../routes/accessories/AccessoriesEntryScreenService";
import useStores from "../../stores/useStores";
import { getVersionInfoFromParams } from "../../utils/vehicleDataUtils";
import {
  SyncAllChangesActions,
  SyncAllChangesContext,
} from "./SyncAllChangesContext";
import SyncAllChangesModal from "./SyncAllChangesModal";

const useVariables = () => {
  const { dispatch } = useContext(SyncAllChangesContext);

  const params: RouteParams = useParams();
  const { userStore, teamStore, modelStore } = useStores();

  const teamLang = Language.EN;
  const vdVersionInfo = getVersionInfoFromParams(params.version);
  const version = (vdVersionInfo[teamLang] as string) || "DRAFT";

  const userDetails = {
    brand: userStore.brand,
    team: "AT",
    region: NATIONAL_REGION,
    lang: teamLang.toLowerCase(),
  };

  const draftDetails = {
    seriesId: params.seriesId,
    modelYear: Number(params.year),
    version,
  };

  const variables = { ...userDetails, ...draftDetails };

  const { readOnly, productTypes, isLoaded } = AccessoriesEntryScreenService({
    variables,
    vdVersionInfo,
  });

  const showSyncAdmin =
    teamStore.team.showSyncAdmin &&
    process.env.REACT_APP_SYNC_ADMIN === "true" &&
    !readOnly &&
    !!teamStore.team.langPermissions?.[Language.EN]?.canEdit;

  useEffect(() => {
    if (!isLoaded) return;

    dispatch({
      type: SyncAllChangesActions.INIT,
      payload: {
        showSyncAdmin,
        variables,
        productTypes,
        gradesData: modelStore.gradesData,
      },
    });
  }, [productTypes, modelStore.gradesData, isLoaded]);

  const onOpen = () =>
    dispatch({
      type: SyncAllChangesActions.SHOW_MODAL,
    });

  return { onOpen, showSyncAdmin };
};

const SyncAllChangesCTA = () => {
  const { onOpen, showSyncAdmin } = useVariables();

  if (!showSyncAdmin) {
    return null;
  }

  return (
    <>
      <Button variant="primary" onClick={onOpen}>
        Sync Updates Admin
      </Button>

      <SyncAllChangesModal />
    </>
  );
};

export default SyncAllChangesCTA;
