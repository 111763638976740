import React, { useContext, useEffect, useMemo } from "react";
import { Modal, Spinner } from "vapi-ui-common";
import {
  SyncAllChangesActions,
  SyncAllChangesContext,
} from "./SyncAllChangesContext";
import SyncAllChangesModalBody from "./SyncAllChangesModalBody";
import SyncAllChangesModalFooter from "./SyncAllChangesModalFooter";
import SyncAllChangesModalHeader from "./SyncAllChangesModalHeader";

const useVariables = () => {
  const { state, dispatch } = useContext(SyncAllChangesContext);

  const {
    data,
    isLoaded: isLoadedCL,
    showModal,
    isLoadedAdmin,
    dataAdmin,
  } = state;

  useEffect(() => {
    if (data && !isLoadedCL) {
      dispatch({
        type: SyncAllChangesActions.IS_LOADED,
      });
    }
  }, [data, isLoadedCL]);

  useEffect(() => {
    if (dataAdmin && !isLoadedAdmin) {
      dispatch({
        type: SyncAllChangesActions.IS_LOADED_ADMIN,
      });
    }
  }, [dataAdmin, isLoadedAdmin]);

  const onClose = () =>
    dispatch({
      type: SyncAllChangesActions.HIDE_MODAL,
    });

  const isLoaded = useMemo(
    () => isLoadedCL && isLoadedAdmin,
    [isLoadedCL, isLoadedAdmin]
  );

  return {
    isLoaded,
    showModal,
    onClose,
  };
};

const SyncAllChangesModal = () => {
  const { isLoaded, showModal, onClose } = useVariables();

  return (
    <Modal size="xl" open={showModal} onClose={onClose}>
      <SyncAllChangesModalHeader />
      {!isLoaded ? (
        <Spinner short />
      ) : (
        <>
          <SyncAllChangesModalBody />
          <SyncAllChangesModalFooter />
        </>
      )}
    </Modal>
  );
};

export default SyncAllChangesModal;
