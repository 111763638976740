import { observable } from "mobx";

class TableSizeStore {
  @observable tableWidth = 0;

  @observable mainWidth = 0;

  @observable tableRowHeight = 0;
}

export default TableSizeStore;
