import React from "react";
import { Icon, Status, StatusComponent } from "vapi-ui-common";
import { SeriesItem, SeriesMyItem } from "../../../gql/generated";
import useStores from "../../../stores/useStores";

interface AdminOutOfSyncStatusProps {
  model: SeriesMyItem;
  seriesItem: SeriesItem;
}

const AdminOutOfSyncStatus = ({
  model,
  seriesItem,
}: AdminOutOfSyncStatusProps) => {
  const { teamStore } = useStores();
  const { comLangIds, year, isDraft } = model;

  const showSyncAdmin =
    teamStore.team.showSyncAdmin && process.env.REACT_APP_SYNC_ADMIN === "true";

  if (showSyncAdmin && comLangIds?.length) {
    const hasDraft = seriesItem.years?.find(
      (my) => my.year === year && my.isDraft
    );

    if (!isDraft && hasDraft) return null;

    return (
      <StatusComponent
        statusText="Go Live Dates out of sync"
        status={Status.DRAFT}
        statusIcon={Icon.EXCLAMATION_TRIANGLE}
      />
    );
  }

  return null;
};

export default AdminOutOfSyncStatus;
