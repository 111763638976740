/* eslint-disable react/jsx-one-expression-per-line */
import {
  Checkbox,
  FilterTabColumn,
  FilterTabColumnList,
  useChecklist,
} from "vapi-ui-common";
import React, { useEffect } from "react";

interface FilterProps {
  id: string;
  title: string;
  items: string[];
  filters: string[];
  onChange: (filters: string[]) => void;
}

const Filter = ({ id, title, items, filters, onChange }: FilterProps) => {
  const { checklist, setChecklist, isAllSelected, selectAll, selectItem } =
    useChecklist();

  useEffect(() => {
    setChecklist(
      items.map((item) => ({
        name: item,
        selected: filters.indexOf(item) !== -1,
      }))
    );
  }, [setChecklist, items, filters]);

  const processFilters = () => {
    return checklist.filter((item) => item.selected).map((item) => item.name);
  };

  const handleOnChecked = (item: any, checked: boolean) => {
    selectItem(item, checked);
    onChange(processFilters());
  };

  const handleOnAllChecked = (checked: boolean) => {
    selectAll(checked);
    onChange(processFilters());
  };

  return (
    <FilterTabColumn>
      <Checkbox
        id={id}
        checked={isAllSelected}
        onChange={(e) => handleOnAllChecked(e.currentTarget.checked)}
        bold
      >
        All {title}
      </Checkbox>

      <FilterTabColumnList>
        {checklist &&
          checklist.map((item) => (
            <li key={item.name}>
              <Checkbox
                id={`chboxCat${item.name}`}
                checked={item.selected}
                onChange={(e) => handleOnChecked(item, e.currentTarget.checked)}
              >
                {item.name}
              </Checkbox>
            </li>
          ))}
      </FilterTabColumnList>
    </FilterTabColumn>
  );
};

export default Filter;
