import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  ActionBar,
  ActionBarSection,
  Button,
  Checkbox,
  convertToRichTextObject,
  DescriptionText,
  HeaderRow,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NotesPopover,
  ScrollableContainer,
  SearchInput,
  Spinner,
  Table,
  TableCell,
  TableRow,
} from "vapi-ui-common";
import { NATIONAL_REGION } from "../../constants/Constants";
import {
  AccessoryItem,
  RefItem,
  useGetAccessoriesWithProductTypeQuery,
} from "../../gql/generated";
import AAPAccessory from "../../models/accessories/AAPAccessory";
import AccessoryItemVM from "../../models/accessories/AccessoryItemVM";
import AccessoryRichText from "../AccessoryRichText";
import styles from "./Aap.module.scss";

interface Props {
  variables: {
    brand: string;
    team: string;
    region: string;
    lang: string;
    seriesId: string;
    modelYear: number;
    version: string;
  };
  close: () => void;
  addAAPAccessories: (
    items: AccessoryItemVM[],
    productTypes: RefItem[]
  ) => Promise<void>;
  accessories: AccessoryItemVM[];
}

const AapModal = ({
  variables,
  close,
  addAAPAccessories,
  accessories,
}: Props) => {
  const { data } = useGetAccessoriesWithProductTypeQuery({
    variables: {
      ...variables,
      getLatestVersion: true,
      isNonGenAccessory: true,
      region: NATIONAL_REGION,
    },
    fetchPolicy: "network-only",
  });

  const [isLoaded, setIsLoaded] = useState(false);
  const [aapAccessories, setAAPAccessories] = useState<AAPAccessory[]>([]);
  const [filteredAAPAccessories, setFilteredAAPAccessories] = useState<
    AAPAccessory[]
  >([]);
  const [nationalProductTypes, setNationalProductTypes] = useState<RefItem[]>(
    []
  );
  const [searchText, setSearchText] = useState("");

  const compareFilteredItems = (
    searchTextParam: string,
    item: string | undefined | null
  ) => {
    return (
      item &&
      convertToRichTextObject(item)
        .text.toLowerCase()
        .indexOf(searchTextParam) !== -1
    );
  };

  const updateAccessories = (
    updatedAccessories: AAPAccessory[],
    newSearchText: string
  ) => {
    setAAPAccessories(updatedAccessories);
    setSearchText(newSearchText);
    const loweredSearchText = newSearchText.toLowerCase();
    setFilteredAAPAccessories(
      updatedAccessories.filter(
        (item) =>
          compareFilteredItems(loweredSearchText, item.abb) ||
          compareFilteredItems(loweredSearchText, item.description) ||
          compareFilteredItems(loweredSearchText, item.disclaimer) ||
          compareFilteredItems(loweredSearchText, item.title)
      )
    );
  };

  useEffect(() => {
    if (data && !isLoaded) {
      const importedAccMap: { [id: string]: string } = {};
      accessories.forEach((acc) => {
        if (acc.nationalId) {
          importedAccMap[acc.nationalId] = acc.nationalId;
        }
      });

      const acc = (data.accessories?.accessories as AccessoryItem[]) || [];
      updateAccessories(
        [
          ...acc.map((item) => {
            const newItem = new AAPAccessory();
            Object.assign(newItem, item);
            newItem.uid = uuidv4();
            newItem.previouslyImported = !!importedAccMap[newItem.id];
            newItem.checked = false;
            return newItem;
          }),
        ],
        ""
      );
      setNationalProductTypes(data.refItems?.refItems || ([] as RefItem[]));
      setIsLoaded(true);
    }
  }, [data]);

  const addSelectedAccessories = () => {
    const selectedAccessories = aapAccessories.filter(
      (acc) => !acc.previouslyImported && acc.checked
    );
    if (selectedAccessories.length) {
      addAAPAccessories(
        selectedAccessories.map((acc) => {
          return {
            ...acc,
            copy: "",
            disclosure: "",
            installPoint: "",
            partsOnlyDealerCost: "",
            partsOnlyMSRP: "",
            installedDealerCost: "",
            images: [],
            ppoCode: "",
          };
        }),
        nationalProductTypes
      );
    }
  };

  const handleToggleCheckbox = (accessoryId: string, selected: boolean) => {
    updateAccessories(
      aapAccessories.map((aapAcc) => {
        const a = aapAcc;
        if (aapAcc.id === accessoryId) {
          a.checked = selected;
        }
        return a;
      }),
      searchText
    );
  };

  const handleToggleAllCheckbox = (selected: boolean) => {
    updateAccessories(
      aapAccessories.map((aapAcc) => {
        const a = aapAcc;
        if (!a.previouslyImported) {
          a.checked = selected;
        }
        return a;
      }),
      searchText
    );
  };

  return (
    <>
      <ModalHeader onClose={() => close()}>Add AAP Accessory</ModalHeader>
      {!isLoaded ? (
        <Spinner short />
      ) : (
        <>
          <ModalBody>
            <ActionBar>
              <ActionBarSection>
                <SearchInput
                  value=""
                  onSearch={(value) => {
                    updateAccessories(aapAccessories, value);
                  }}
                />
              </ActionBarSection>
            </ActionBar>
            <ScrollableContainer style={{ overflow: "scroll" }}>
              <Table fullWidth className={styles.aapTable}>
                <HeaderRow className={styles.stickyNav}>
                  <TableCell>
                    <Checkbox
                      id="addCl-chbox-all"
                      className={styles.checkboxAll}
                      checked={filteredAAPAccessories.every(
                        (acc) => acc.checked || acc.previouslyImported
                      )}
                      onChange={(e) => {
                        handleToggleAllCheckbox(e.currentTarget.checked);
                      }}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Abb</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Disclaimer</TableCell>
                  <TableCell>Previously Imported?</TableCell>
                  <TableCell>Published</TableCell>
                  <TableCell>Notes</TableCell>
                </HeaderRow>
                <tbody className={styles.appBody}>
                  {data &&
                    filteredAAPAccessories.map((acc, index) => (
                      <TableRow zebra key={acc.id}>
                        {/* checkbox */}
                        <TableCell center>
                          <Checkbox
                            key={index.toString()}
                            id={`addCl-chbox-${acc.id}`}
                            checked={acc.checked}
                            disabled={acc.previouslyImported}
                            onChange={(e) => {
                              handleToggleCheckbox(
                                acc.id,
                                e.currentTarget.checked
                              );
                            }}
                          />
                        </TableCell>
                        {/* name */}
                        <TableCell>
                          <DescriptionText size="sm">
                            {acc.title ? JSON.parse(acc.title).text : ""}
                          </DescriptionText>
                        </TableCell>
                        {/* abb */}
                        <TableCell className={styles.smallCells}>
                          <AccessoryRichText value={acc.abb || ""} disabled />
                        </TableCell>
                        {/* description */}
                        <TableCell>
                          <AccessoryRichText
                            value={acc.description || ""}
                            disabled
                          />
                        </TableCell>
                        {/* disclaimer */}
                        <TableCell large>
                          <AccessoryRichText
                            value={acc.disclaimer || ""}
                            disabled
                          />
                        </TableCell>

                        {/* previously imported? */}
                        <TableCell className={styles.smallCells}>
                          <DescriptionText size="sm">
                            {acc.previouslyImported ? "Y" : "N"}
                          </DescriptionText>
                        </TableCell>
                        {/* published */}
                        <TableCell className={styles.smallCells}>
                          <DescriptionText size="sm">{}</DescriptionText>
                        </TableCell>
                        {/* notes */}
                        <TableCell className={styles.notePopover}>
                          <NotesPopover
                            notes={acc.notes || ""}
                            handleUpdateItem={() => {}}
                            readOnly
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                </tbody>
              </Table>
            </ScrollableContainer>
          </ModalBody>
          <ModalFooter>
            <Button variant="transparent" onClick={() => close()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                close();
                addSelectedAccessories();
              }}
            >
              Add Accessory(s)
            </Button>
          </ModalFooter>
        </>
      )}
    </>
  );
};

export default AapModal;
