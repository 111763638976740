import React, { useContext } from "react";
import {
  ModalBody,
  ScrollableContainer,
  SecondaryHeader,
} from "vapi-ui-common";
import {
  SyncAllChangesActions,
  SyncAllChangesContext,
} from "./SyncAllChangesContext";
import SyncAllChangesModalBodyAdminTab from "./SyncAllChangesModalBodyAdminTab";
import SyncAllChangesModalBodyCLTab from "./SyncAllChangesModalBodyCLTab";
import SyncAllChangesModalBodyHeader from "./SyncAllChangesModalBodyHeader";

const useVariables = () => {
  const { state, dispatch } = useContext(SyncAllChangesContext);

  const { selectedTab, tabs } = state;

  const setSelectedTab = (tab: string) =>
    dispatch({
      type: SyncAllChangesActions.SET_SELECTED_TAB,
      payload: {
        tab,
      },
    });

  return {
    selectedTab,
    tabs,
    setSelectedTab,
  };
};

const SyncAllChangesModalBody = () => {
  const { selectedTab, tabs, setSelectedTab } = useVariables();

  return (
    <ModalBody>
      <SyncAllChangesModalBodyHeader />

      <SecondaryHeader
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        tabs={tabs}
        renderButtons={() => null}
      />

      <ScrollableContainer style={{ overflow: "scroll" }}>
        <SyncAllChangesModalBodyCLTab />
        <SyncAllChangesModalBodyAdminTab />
      </ScrollableContainer>
    </ModalBody>
  );
};

export default SyncAllChangesModalBody;
