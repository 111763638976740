import cx from "clsx";
import React from "react";
import { Button, ButtonProps } from "vapi-ui-common";
import styles from "./menuButton.module.scss";

interface MenuButtonProps extends ButtonProps {}

const MenuButton = ({ toggled, ...rest }: MenuButtonProps) => {
  return (
    <Button
      className={cx(styles.menuButton, toggled && styles.toggled)}
      {...rest}
    />
  );
};

export default MenuButton;
