import cx from "clsx";
import React from "react";
import styles from "./input.module.scss";

export interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  required?: boolean;
  label?: string;
}

const Input = React.forwardRef(
  (props: IInput, ref?: React.Ref<HTMLInputElement>) => {
    const { className, onChange, onBlur, required, value, label, ...rest } =
      props;
    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(event);
      }
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      const e = event;
      e.currentTarget.value = e.currentTarget.value.trim();
      if (onBlur) {
        onBlur(e);
      }
    };

    const hasError = () => {
      if (required && !value) {
        return true;
      }
      return false;
    };

    const getCssClass = () => {
      return cx(styles.input, hasError() && styles.error, className);
    };

    return (
      <>
        <label htmlFor={rest.id}>{label || ""}</label>
        <input
          ref={ref}
          value={value}
          type="text"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          className={getCssClass()}
          {...rest}
        />
      </>
    );
  }
);

export default Input;
