/* eslint-disable no-param-reassign */
import cx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import { TableCell, TableRow } from "vapi-ui-common";
import styles from "./commonLanguageTable.module.scss";
import { AccessoryCommonLanguageItem } from "../../../../models/commonLanguage.model";
import AccessoryRichText from "../../../../components/AccessoryRichText";
import ContextMenuCell from "../../../../components/tableCells/tableCells/ContextMenuCell/ContextMenuCell";
import useRowHandlers from "../../hooks/useRowHandlers";
import StatusIndicator from "../../../../components/StatusIndicator/StatusIndicator";

interface GstLeftRowProps {
  item: AccessoryCommonLanguageItem;
  show: {
    name: boolean;
    nameEs: boolean;
  };
  readonly?: boolean;
}

const GstLeftRow = ({ item, show, readonly }: GstLeftRowProps) => {
  const { handleRichTextChange, copyItem, deleteItem } = useRowHandlers(item);

  return (
    <TableRow>
      {!readonly && (
        <TableCell className={cx(styles.cell, styles.actionButtons)}>
          <ContextMenuCell
            disabled={readonly}
            description={item.title}
            itemType="Common Language"
            deleteItemText={item.publishedDate ? "Archive" : "Delete"}
            deleteItem={deleteItem}
            copyItem={copyItem}
          />
        </TableCell>
      )}

      {/* Supplier */}
      <TableCell className={cx(styles.cell, styles.textArea)}>
        {item.isArchived && <StatusIndicator>Archived</StatusIndicator>}
        <AccessoryRichText
          value={item.supplier}
          onBlur={handleRichTextChange("supplier")}
          disabled={readonly}
        />
      </TableCell>

      {/* Name */}
      {show.name && (
        <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
          <AccessoryRichText
            value={item.title}
            onBlur={handleRichTextChange("title")}
            disabled={readonly}
            required={!item.title}
          />
        </TableCell>
      )}

      {/* Name Es */}
      {show.nameEs && (
        <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
          <AccessoryRichText
            value={item.title_es}
            onBlur={handleRichTextChange("title_es")}
            disabled={readonly}
            required={!item.title_es}
          />
        </TableCell>
      )}
    </TableRow>
  );
};

export default observer(GstLeftRow);
