import { ImageItem } from "vapi-ui-common/dist/src/models/ImageItem";
import {
  AccessoryItem,
  GoLiveDate,
  GradeMsrpItem,
  Maybe,
  ModelApplicabilityItem,
} from "../../gql/generated";

type AccessoryItemVMProps = {
  accessoryItem?: AccessoryItem;
  sortOrder?: number;
  uid?: string;
};

export interface AccessoryItemBase {
  uid: string;
  id: string;
  revId: string;
  title: string;
  title_es?: string | null;
  copy?: string | null;
  disclosure?: string | null;
  productType?: string | null;
  installPoint?: string | null;
  ppoCode?: string | null;
  abb?: string | null;
  supplier?: string | null;
  description?: string | null;
  description_es?: string | null;
  disclaimer?: string | null;
  disclaimer_es?: string | null;
  msrp?: string | null;
  notes?: string | null;
  images?: ImageItem[] | null;
  inactive?: boolean | null;
  modelApplicability?: ModelApplicabilityItem[] | null;
  partNumber?: string | null;
  partsOnlyDealerCost?: string | null;
  partsOnlyMSRP?: string | null;
  installedDealerCost?: string | null;
  required?: string | null;
  conflicts?: string | null;
  gradeMsrp?: Omit<GradeMsrpItem, "__typename">[] | null;
  sortOrder?: number | string;
  isNonGenAccessory?: boolean | null;
  warranty?: string | null;
  warranty_es?: string | null;
  nationalId?: string | null;
  hasAAPSyncChanges?: boolean | null;
  changedAttributes?: string[];
  comLangId?: string;
  comLangVersion?: number;
  hasComLangChanges?: boolean;
}

export interface GoLiveDateItem {
  goLiveDate: string;
  id: string;
  revId?: string;
}

export type GoLiveDateGQL = Maybe<
  {
    __typename?: "GoLiveDate" | undefined;
  } & {
    __typename?: "GoLiveDate" | undefined;
  } & Pick<GoLiveDate, "goLiveDate" | "id" | "revId">
>;

class AccessoryItemVM implements AccessoryItem, AccessoryItemBase {
  uid = ""; // al
  id = ""; // al
  revId = ""; // al
  title = ""; // al
  title_es: string | null = null; // gs
  copy: string | null = null; // na
  disclosure: string | null = null; // na
  productType: string | null = null; // al
  installPoint: string | null = null; // na
  ppoCode: string | null = null; // na
  abb: string | null = null; // gs
  supplier: string | null = null; // gs
  description: string | null = null; // gs
  description_es: string | null = null; // gs
  disclaimer: string | null = null; // gs
  disclaimer_es: string | null = null; // gs
  msrp: string | null = null; // al
  notes: string | null = null; // al
  images: ImageItem[] | null = null; // na
  inactive: boolean | null = false; // al
  modelApplicability: ModelApplicabilityItem[] | null = []; // al
  partNumber: string | null = null; // al
  partsOnlyDealerCost: string | null = null; // na
  partsOnlyMSRP: string | null = null; // na
  installedDealerCost: string | null = null; // na
  required: string | null = null; // al
  conflicts: string | null = null; // al
  gradeMsrp: GradeMsrpItem[] | null = []; // al
  sortOrder: number | string = 0; // al
  isNonGenAccessory: boolean = false; // na
  warranty: string | null = null; // al
  warranty_es: string | null = null; // gs
  nationalId: string | null = null; // gs
  hasAAPSyncChanges: boolean | null = null; // gs
  changedAttributes?: string[]; // spanish // fi
  comLangId?: string; // gs // na
  comLangVersion?: number; // gs // na
  hasComLangChanges?: boolean; // gs // na
  laborTime?: string; // lexus

  constructor(props?: AccessoryItemVMProps) {
    const { accessoryItem, sortOrder, uid } = props ?? {};
    if (accessoryItem) {
      Object.assign(this, accessoryItem);
    }

    if (uid) {
      this.uid = uid;
    }

    if (sortOrder) {
      this.sortOrder = sortOrder;
    }
  }
}

export default AccessoryItemVM;
