import React, { useCallback, useContext } from "react";
import { HeaderCell } from "vapi-ui-common";
import cx from "clsx";
import styles from "../../../AccessoriesEntryScreen.module.scss";
import AccessoriesEntryScreenContext from "../AccessoriesEntryScreenContext";

interface ICopyHeader {
  sortMode: boolean;
}

const CCopyHeader = ({ sortMode }: ICopyHeader) => {
  const context = useContext(AccessoriesEntryScreenContext);

  const { selectedLanguages, setSort } = context;
  const onClick = useCallback(() => setSort("copy"), [setSort]);

  return (
    <>
      {selectedLanguages.map((language) => {
        return (
          <HeaderCell
            key={`${language}-copy-header`}
            onClick={onClick}
            cellClassName={cx(
              styles.stickyHeader,
              !sortMode ? styles.copyHeaderWidth : styles.sortCopyHeaderWidth
            )}
          >
            {`${language} Copy`}
          </HeaderCell>
        );
      })}
    </>
  );
};

export default CCopyHeader;
