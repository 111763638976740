import cx from "clsx";
import React, { useCallback, useContext } from "react";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import {
  Dropdown,
  DropdownButton,
  DropdownEdit,
  useComponentVisible,
} from "vapi-ui-common";
import {
  RefItem,
  useCreateProductTypeMutation,
} from "../../../../../gql/generated";
import { Language } from "../../../../../models/user/user.model";
import handleErrorResponse from "../../../../../utils/errorHandlingUtils";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";
import CDropdownEditorList from "./CDropdownEditorList";
import styles from "./DropdownEditorCell.module.scss";

type TDropdownEditorProps = {
  language: Language;
};

const CDropdownEditor = ({ language }: TDropdownEditorProps) => {
  const [createProductType] = useCreateProductTypeMutation();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const {
    addProductType,
    userDetails,
    draftDetails,
    accessoriesLangMap,
    languagePermissions,
    readOnly,
    productTypeLangMap,
  } = useContext(AccessoriesEntryScreenContext);

  const {
    accessoryItem: { id: accessoryItemId },
    index,
  } = useContext(AccessoryContext);

  const onClick = useCallback(() => setIsComponentVisible(true), []);
  const onClose = useCallback(() => setIsComponentVisible(false), []);

  const renderList = useCallback(() => {
    return <CDropdownEditorList onClose={onClose} language={language} />;
  }, [language, onClose]);

  const onAddProductType = useCallback(
    async (name: string) => {
      try {
        await trackPromise(
          createProductType({
            variables: {
              ...userDetails,
              ...draftDetails,
              lang: language.toLowerCase(),
              name,
            },
            update: (_cache, { data: newData }) => {
              addProductType(newData?.createRefItem.refItem as RefItem);
            },
          })
        );
        toast.success("Successfully added Product Type");
      } catch (e) {
        handleErrorResponse(e);
      }
    },
    [createProductType, addProductType, draftDetails, userDetails, language]
  );

  const accessoryItem = accessoriesLangMap[accessoryItemId]?.langs?.[language];

  const productTypeItem =
    productTypeLangMap[accessoryItem.productType ?? ""]?.langs?.[language];

  const userPermissions = languagePermissions?.[language];
  const disabled = !userPermissions?.canEdit || readOnly;

  return (
    <Dropdown className={cx(styles.root)}>
      <DropdownButton
        tabIndex={index + 1}
        disabled={disabled}
        className={styles.tableDropdown}
        onClick={onClick}
      >
        {productTypeItem?.name}
      </DropdownButton>
      <DropdownEdit
        ref={ref}
        open={isComponentVisible}
        addBtnText="Product Type"
        onAdd={onAddProductType}
        renderList={renderList}
      />
    </Dropdown>
  );
};

export default CDropdownEditor;
