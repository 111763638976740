import React, { useState } from "react";
import {
  ActionBar,
  ActionBarFilterButton,
  ActionBarSection,
  Dropdown,
  DropdownContent,
  IconTextButton,
  Modal,
  ResetModal,
  SearchInput,
  useComponentVisible,
} from "vapi-ui-common";
import CommonLanguageFilter from "../CommonLanguageFilter/CommonLanguageFilter";

interface ActionBarCommonLanguageProps {
  searchText: string;
  productTypes: string[];
  productTypeFilters: string[];
  installPoints?: string[];
  installPointFilters?: string[];
  totalChanges?: number;
  setFilters: (
    productTypeFilters: string[],
    installPointFilers: string[]
  ) => void;
  onResetDraft?: () => void;
  onSearchChange: (value: string) => void;
  onCreateCommonLanguage?: () => void;
  readonly?: boolean;
}

const ActionBarCommonLanguage = ({
  searchText,
  onSearchChange,
  productTypes,
  productTypeFilters,
  installPoints,
  installPointFilters,
  totalChanges,
  setFilters,
  onResetDraft,
  onCreateCommonLanguage,
  readonly,
}: ActionBarCommonLanguageProps) => {
  const [openResetModal, setOpenResetModal] = useState(false);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <ActionBar>
      <ActionBarSection>
        <SearchInput
          value={searchText}
          onSearch={(value) => onSearchChange(value)}
        />
        <Dropdown>
          <ActionBarFilterButton
            onClick={() => setIsComponentVisible(!isComponentVisible)}
          >
            Filter
          </ActionBarFilterButton>
          <DropdownContent ref={ref} open={isComponentVisible}>
            <CommonLanguageFilter
              productTypes={productTypes}
              productTypeFilters={productTypeFilters}
              installPoints={installPoints}
              installPointFilters={installPointFilters}
              onClose={() => setIsComponentVisible(false)}
              setFilters={setFilters}
            />
          </DropdownContent>
        </Dropdown>
        {!readonly && (
          <IconTextButton
            icon="plus"
            text="Add Common Language"
            onClick={onCreateCommonLanguage}
          />
        )}
      </ActionBarSection>
      {!readonly && onResetDraft && (
        <ActionBarSection>
          <IconTextButton
            icon="undo"
            text="Reset Draft"
            onClick={() => setOpenResetModal(true)}
          />
          <Modal open={openResetModal} onClose={() => setOpenResetModal(false)}>
            <ResetModal
              changeLogLocation="/commonLanguage/changeLog"
              close={() => setOpenResetModal(false)}
              pageName="Common Language"
              updateCount={totalChanges ?? 0}
              resetDraft={onResetDraft}
            />
          </Modal>
        </ActionBarSection>
      )}
    </ActionBar>
  );
};
export default ActionBarCommonLanguage;
