import changeRegistery from ".";
import { ChangeLogTypes } from "../../../../models/accessories/changeLog.model";
import ChangeLogRichTextDiff from "../ChangeLogRichTextDiff";

const registerChangeType = (
  changelogType: ChangeLogTypes,
  func: ({ before, after }: { before: string; after: string }) => any
) => {
  // check if change type exists
  // check to see if func is comp
  // add it to registery
  changeRegistery[changelogType] = func;
};

export default registerChangeType;

registerChangeType(
  ChangeLogTypes.SPLIT_SHORT_DESCRIPTION,
  ChangeLogRichTextDiff
);
registerChangeType(
  ChangeLogTypes.SPLIT_LONG_DESCRIPTION,
  ChangeLogRichTextDiff
);
registerChangeType(ChangeLogTypes.MODEL_ADDED, ChangeLogRichTextDiff);
registerChangeType(ChangeLogTypes.DESCRIPTION, ChangeLogRichTextDiff);
registerChangeType(ChangeLogTypes.LONG_DESCRIPTION, ChangeLogRichTextDiff);
registerChangeType(ChangeLogTypes.SHORT_DESCRIPTION, ChangeLogRichTextDiff);
registerChangeType(ChangeLogTypes.SPLIT_DESCRIPTION, ChangeLogRichTextDiff);
