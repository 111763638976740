/* eslint-disable no-param-reassign */
import cx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";
import { TableCell, TableRow } from "vapi-ui-common";
import styles from "./commonLanguageTable.module.scss";
import { AccessoryCommonLanguageItem } from "../../../../models/commonLanguage.model";
import AccessoryRichText from "../../../../components/AccessoryRichText";
import ContextMenuCell from "../../../../components/tableCells/tableCells/ContextMenuCell/ContextMenuCell";
import useRowHandlers from "../../hooks/useRowHandlers";
import StatusIndicator from "../../../../components/StatusIndicator/StatusIndicator";

interface CLLeftRowProps {
  item: AccessoryCommonLanguageItem;
  readonly?: boolean;
}

const CLLeftRow = ({ item, readonly }: CLLeftRowProps) => {
  const { handleRichTextChange, copyItem, deleteItem } = useRowHandlers(item);

  return (
    <TableRow>
      {!readonly && (
        <TableCell className={cx(styles.cell, styles.actionButtons)}>
          <ContextMenuCell
            disabled={readonly}
            description={item.title}
            itemType="Common Language"
            deleteItem={deleteItem}
            copyItem={copyItem}
            deleteItemText={item.publishedDate ? "Archive" : "Delete"}
          />
        </TableCell>
      )}

      {/* Accessory Name */}
      <TableCell className={cx(styles.cell, styles.textArea)}>
        {item.isArchived && <StatusIndicator>Archived</StatusIndicator>}
        <AccessoryRichText
          value={item.title}
          onBlur={handleRichTextChange("title")}
          disabled={readonly}
          required={!item.title}
        />
      </TableCell>

      {/* Copy */}
      <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
        <AccessoryRichText
          value={item.copy}
          onBlur={handleRichTextChange("copy")}
          disabled={readonly}
          required={!item.copy}
        />
      </TableCell>
    </TableRow>
  );
};

export default observer(CLLeftRow);
