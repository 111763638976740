/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";
import { observer } from "mobx-react-lite";
import { NavLink } from "react-router-dom";
import { ModalHeader, ModalBody, ModalFooter, Button } from "vapi-ui-common";
import styles from "./resetModal.module.scss";

type ResetModalProps = {
  changeLogLocation: string;
  resetDraft: () => void;
  updateCount: number;
  pageName: string;
  close: () => void;
};
const ResetModal = ({
  pageName,
  resetDraft,
  updateCount,
  changeLogLocation,
  close = () => {},
}: ResetModalProps) => (
  <>
    <ModalHeader onClose={close}>Reset Draft</ModalHeader>
    <ModalBody>
      Are you sure you want to reset this draft? If you reset the draft, you
      will lose <span className={styles.bold}>{updateCount}</span> changes made
      to <span className={styles.bold}>{pageName}</span>.
    </ModalBody>
    <ModalFooter className={styles.footer}>
      <Button
        variant="transparent"
        onClick={() => {
          close();
        }}
      >
        Cancel
      </Button>
      <div className={styles.ctaContainer}>
        <Button
          variant="primary"
          onClick={() => {
            resetDraft();
            close();
          }}
        >
          Reset
        </Button>
        <NavLink to={changeLogLocation}>
          <Button
            variant="full"
            onClick={() => close()}
            className={styles.viewChangesButton}
          >
            View Changes
          </Button>
        </NavLink>
      </div>
    </ModalFooter>
  </>
);
export default observer(ResetModal);
