import React from "react";
import {
  Button,
  ImageItem,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "vapi-ui-common";
import styles from "../Images.module.scss";
import { uncachedPartNumberImage } from "../../../utils/partNumberImage";

interface IEditImageModal {
  close: () => void;
  targetObj: ImageItem;
  onClickBrowse: () => void;
}

const EditImageModal = ({
  close,
  onClickBrowse,
  targetObj,
}: IEditImageModal) => {
  return (
    <>
      <ModalHeader onClose={close}>Edit Image URL</ModalHeader>
      <ModalBody>
        <span className={styles.tableHeader}>Web_docs</span>
        <div>
          <Input readOnly className={styles.EditInput} value={targetObj.name} />
          <Button
            className={styles.browseBtn}
            variant="primary"
            onClick={() => {
              close();
              onClickBrowse();
            }}
          >
            Browse
          </Button>
        </div>
        <div className={styles.editImageModalTable}>
          <img src={uncachedPartNumberImage(targetObj.image)} alt="" />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={() => close()}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
};

export default EditImageModal;
