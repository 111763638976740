import useStores from "../../../stores/useStores";

const useActionBarCLProps = () => {
  const { userStore, commonLanguageStore } = useStores();

  const handleSearchTextChange = (value: string) => {
    commonLanguageStore.searchText = value;
    commonLanguageStore.filterData();
  };

  return {
    searchText: commonLanguageStore.searchText,
    productTypes: commonLanguageStore.productTypes.map((item) => item.name),
    productTypeFilters: commonLanguageStore.productTypeFilters,
    installPoints: userStore.isGstUser() ? undefined : ["PPO", "DIO"],
    installPointFilters: userStore.isGstUser()
      ? undefined
      : commonLanguageStore.installPointFilters,
    totalChanges: commonLanguageStore.totalChanges,
    onSearchChange: handleSearchTextChange,
    setFilters: commonLanguageStore.setFilters,
  };
};

export default useActionBarCLProps;
