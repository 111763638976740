import React, { useCallback } from "react";
import cx from "clsx";
import "react-calendar/dist/Calendar.css";
import { DropdownList } from "vapi-ui-common";
import {
  GoLiveDateTime,
  GoLiveDateTimeHour,
  goLiveDateTimeMap,
} from "../../../../../utils/dateUtils";
import GoLiveDateTimePickerList from "./GoLiveDateTimePickerList";
import styles from "./GoLiveDateTimePicker.module.scss";

export interface IGoLiveDateTimePicker {
  currentTime?: GoLiveDateTimeHour;
  onChange: (time: GoLiveDateTime) => void;
  timePicker?: boolean;
  goLiveDateAdmin: string;
  disabled?: boolean;
  hideAdminDate?: boolean;
}

const GoLiveDateTimePicker = ({
  currentTime = GoLiveDateTimeHour.AM_5,
  onChange,
  timePicker,
  disabled,
  goLiveDateAdmin,
  hideAdminDate,
}: IGoLiveDateTimePicker) => {
  const contentElement = useCallback(
    (ref: any, open: boolean, closeDropdown: () => void) => (
      <GoLiveDateTimePickerList
        open={open}
        closeDropdown={closeDropdown}
        currentTime={goLiveDateTimeMap[currentTime]}
        onChange={onChange}
        ref={ref}
      />
    ),
    [onChange, currentTime]
  );

  if (!timePicker) return null;

  const getAdminDateTime = useCallback(() => {
    let text = "";

    if (goLiveDateAdmin) {
      let time = "";

      if (goLiveDateAdmin.split(" ")[1]) {
        const timeMap =
          goLiveDateTimeMap[
            new Date(goLiveDateAdmin).getHours() as GoLiveDateTimeHour
          ];
        time = timeMap ? `${timeMap.text.toLocaleUpperCase()} CST` : "";
      }

      text = `${new Date(goLiveDateAdmin).toLocaleDateString()} ${time}`;
    }

    return text;
  }, [goLiveDateAdmin]);

  return (
    <div className={styles.timePicker} data-testid="go-live-time-picker">
      <DropdownList
        value={goLiveDateTimeMap[currentTime].text}
        className={styles.time}
        btnClass={styles.cta}
        contentElement={contentElement}
        disabled={disabled}
      />
      <span className={styles.lightText}>Central Time Zone</span>
      {!hideAdminDate && (
        <div className={styles.adminDate}>
          <div className={styles.lightText}>Admin Go Live:</div>
          <div className={cx(styles.darkText, styles.boldText)}>
            {getAdminDateTime()}
          </div>
        </div>
      )}
    </div>
  );
};

export default GoLiveDateTimePicker;
