import { CommonLanguageVehicle } from "../models/commonLanguage.model";
import { VehicleTeam } from "../models/vehicleData/vehicleData.model";
import API from "./api";

const RESOURCE_PATH = "/vehicle-admin";
const DATA_PATH = "/data";

const getAvailableModels = (
  brand: string,
  team: VehicleTeam = VehicleTeam.PRODUCT_TEAM
) => {
  return API.get<CommonLanguageVehicle[]>(
    `${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/available-models`
  );
};

export default getAvailableModels;
