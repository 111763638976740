import React from "react";
import {
  Dropdown,
  DropdownButton,
  DropdownEdit,
  DropdownEditItem,
  useComponentVisible,
} from "vapi-ui-common";
import cx from "clsx";
import styles from "./productTypeDropdown.module.scss";
import { getSortedListByString } from "../../../../utils/sortUtils";
import { AclProductTypeItem } from "../../../../gql/generated";

interface ProductTypeDropdownProps {
  value?: string | null;
  productTypes: AclProductTypeItem[];
  onAdd: (value: string) => void;
  onSelect: (value: string) => void;
  onEdit?: (oldValue: string, newValue: string) => void;
  disabled?: boolean;
}

function ProductTypeDropdown({
  value,
  productTypes,
  disabled,
  onAdd,
  onSelect,
  onEdit,
}: ProductTypeDropdownProps) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <Dropdown className={styles.dropdown}>
      <DropdownButton
        disabled={disabled}
        className={cx(styles.dropdownButton, {
          [styles.error]: !value,
        })}
        onClick={() => setIsComponentVisible(true)}
      >
        {value}
      </DropdownButton>
      <DropdownEdit
        ref={ref}
        open={isComponentVisible}
        addBtnText="Product Type"
        onAdd={onAdd}
        renderList={() => {
          return getSortedListByString(productTypes, "value").map(
            (productType) => (
              <DropdownEditItem
                key={productType.id}
                value={productType.name}
                isSelected={value === productType.name}
                onEdit={onEdit}
                onSelect={() => onSelect(productType.name)}
                onClose={() => {
                  setIsComponentVisible(false);
                }}
              />
            )
          );
        }}
      />
    </Dropdown>
  );
}

export default ProductTypeDropdown;
