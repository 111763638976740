import cx from "clsx";
import moment from "moment-timezone";
import React from "react";
import { ModelChange } from "../../../../models/accessories/changeLog.model";
import {
  GoLiveDateTimeHour,
  goLiveDateTimeMap,
} from "../../../../utils/dateUtils";
import styles from "../changeLogChanges.module.scss";

interface Props {
  log: ModelChange;
}

const GoLiveTimeChangeLog = ({ log }: Props) => {
  const beforeDate = moment(new Date(log.before as string)).isValid()
    ? new Date(log.before as string)
    : null;
  const afterDate = moment(new Date(log.after as string)).isValid()
    ? new Date(log.after as string)
    : null;

  const beforeTimeText = beforeDate
    ? `${
        goLiveDateTimeMap[beforeDate.getHours() as GoLiveDateTimeHour].text
      } CST`
    : "";

  const afterTimeText = afterDate
    ? `${
        goLiveDateTimeMap[afterDate.getHours() as GoLiveDateTimeHour].text
      } CST`
    : "";

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const beforeDateText = beforeDate?.toLocaleDateString("en-US", options) ?? "";
  const afterDateText = afterDate?.toLocaleDateString("en-US", options) ?? "";

  const hasDateChanges = beforeDateText !== afterDateText;
  const hasTimeChanges = beforeTimeText !== afterTimeText;

  return (
    <>
      {beforeDate && (
        <p className={cx(styles.noWrap)} data-testid="log-before-p">
          <span
            className={cx({ [styles.changeRemoved]: hasDateChanges })}
            data-testid="log-before-p-span-before-date"
          >
            {beforeDateText}
          </span>
          <span
            className={cx(styles.zone, {
              [styles.changeRemoved]: hasTimeChanges,
            })}
            data-testid="log-before-p-span-before-time"
          >
            {beforeTimeText}
          </span>
        </p>
      )}

      {afterDate && (
        <p className={cx(styles.noWrap)} data-testid="log-after-p">
          <span
            className={cx({ [styles.changeAdded]: hasDateChanges })}
            data-testid="log-after-p-span-after-date"
          >
            {afterDateText}
          </span>
          <span
            className={cx(styles.zone, {
              [styles.changeAdded]: hasTimeChanges,
            })}
            data-testid="log-after-p-span-after-time"
          >
            {afterTimeText}
          </span>
        </p>
      )}
    </>
  );
};

export default GoLiveTimeChangeLog;
