import React, { ChangeEvent, ChangeEventHandler, useState } from "react";
import { Checkbox } from "vapi-ui-common";
import styles from "./languageToggle.module.scss";

export const useLanguageToggle = (): Omit<LanguageToggleProps, "id"> => {
  const [enChecked, setEnChecked] = useState(true);
  const [esChecked, setEsChecked] = useState(true);

  const handleEnChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setEnChecked(checked);

    if (!checked && !esChecked) {
      setEsChecked(true);
    }
  };

  const handleEsChecked = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    setEsChecked(checked);

    if (!checked && !enChecked) {
      setEnChecked(true);
    }
  };

  return {
    enChecked,
    esChecked,
    onEnChange: handleEnChecked,
    onEsChange: handleEsChecked,
  };
};

export interface LanguageToggleProps {
  id: string;
  enChecked: boolean;
  esChecked: boolean;
  onEsChange: ChangeEventHandler<HTMLInputElement>;
  onEnChange: ChangeEventHandler<HTMLInputElement>;
}

const LanguageToggle = ({
  id,
  enChecked,
  esChecked,
  onEnChange,
  onEsChange,
}: LanguageToggleProps) => {
  return (
    <div className={styles.container}>
      <Checkbox
        id={`en-${id}`}
        className={styles.checkbox}
        checked={enChecked}
        onChange={onEnChange}
      >
        <span className={styles.label}>English</span>
      </Checkbox>
      <Checkbox
        id={`es-${id}`}
        className={styles.checkbox}
        checked={esChecked}
        onChange={onEsChange}
      >
        <span className={styles.label}>Spanish</span>
      </Checkbox>
    </div>
  );
};

export default LanguageToggle;
