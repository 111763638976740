enum UserGroups {
  VAPI_TOYOTA_USER = "VAPI_toyota_user",
  VAPI_PR_USER = "VAPI_pr_user",
  VAPI_COMMON_LANGUAGE_EDITOR = "VAPI_common_language_editor",
  VAPI_PRODUCT_TEAM_EDITOR = "VAPI_product_team_editor",
  VAPI_PRODUCT_TEAM_REVIEWER = "VAPI_product_team_reviewer",
  VAPI_PRODUCT_TEAM_READ_ONLY = "VAPI_product_team_read_only",
  VAPI_AGENCY_TEAM_EDITOR = "VAPI_agency_team_editor",
  VAPI_LCV_TEAM = "VAPI_lcv_team",
  VAPI_SC_TEAM = "VAPI_sc_team",
  VAPI_CUV_TEAM = "VAPI_cuv_team",
  VAPI_TSUV_TEAM = "VAPI_tsuv_team",
  VAPI_ACC_EDITOR = "VAPI_acc_editor",
  VAPI_ACC_NTNL_USER = "VAPI_acc_ntnl_user"
}

export default UserGroups;
