import React, { useCallback, useContext } from "react";
import { Checkbox, TableCell } from "vapi-ui-common";
import { Language } from "../../../../../models/user/user.model";
import styles from "../../../AccessoriesEntryScreen.module.scss";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";
import AccessoryRichTextLanguage from "./CAccessoryRichTextLanguage";

const CColumn = () => {
  const {
    accessoriesLangMap,
    selectedLanguages,
    readOnly,
    saveAccessoryItem,
    languagePermissions,
  } = useContext(AccessoriesEntryScreenContext);

  const {
    accessoryItem: { id: accessoryItemId },
  } = useContext(AccessoryContext);

  const accessoryLangItem = accessoriesLangMap[accessoryItemId]?.langs;

  const userPermissionsEN = languagePermissions?.[Language.EN];
  const accessoryItemEN = accessoryLangItem[Language.EN];
  const disabled = !userPermissionsEN?.canEdit || readOnly;

  const onChange = useCallback(() => {
    const acc = accessoryItemEN;
    acc.isNonGenAccessory = !acc.isNonGenAccessory;
    saveAccessoryItem(acc, Language.EN);
  }, [saveAccessoryItem, accessoryItemEN]);

  return (
    <>
      {selectedLanguages.map((language) => {
        const accessoryItem = accessoryLangItem[language];
        const isEnglish = language === Language.EN;

        return (
          <TableCell key={`${language}-warranty-column-${accessoryItemId}`}>
            {isEnglish && (
              <div>
                <Checkbox
                  id={`non-gen${accessoryItem.uid}`}
                  checked={accessoryItem.isNonGenAccessory}
                  onChange={onChange}
                  disabled={disabled}
                >
                  <span className={styles.installPointLabels}>AAP</span>
                </Checkbox>
              </div>
            )}

            <AccessoryRichTextLanguage language={language} />
          </TableCell>
        );
      })}
    </>
  );
};

export default CColumn;
