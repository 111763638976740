import React from "react";
import ChangeLogRichTextDiff from "../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogRichTextDiff";
import ChangeLogBeforeAfter from "../../../../components/ChangeLogTable/ChangeLogChanges/components/ChangeLogBeforeAfter";
import ChangeLogImage from "../../../../components/ChangeLogTable/ChangeLogChanges/components/ChangeLogImages";
import { AclChange, AclProductTypeItem } from "../../../../gql/generated";

export enum ACLChangeType {
  ACL_ADDED = "Accessory Common Language Added",
  ACL_ARCHIVED = "Accessory Common Language Archived",
  ACL_DELETED = "Accessory Common Language Deleted",
  DRAFT_RESET = "Draft Reset",
  TITLE = "Title",
  TITLE_ES = "Title es",
  COPY = "Copy",
  DISCLOSURE = "Disclosure",
  PRODUCT_TYPE = "Product Type",
  INSTALL_POINT = "Install Point",
  PPO_CODE = "PPO Code",
  DIO_CODE = "DIO Code",
  IMAGES = "Images",
  SUPPLIER = "Supplier",
  DESCRIPTION = "Description",
  DESCRIPTION_ES = "Description es",
  DISCLAIMER = "Disclaimer",
  DISCLAIMER_ES = "Disclaimer es",
  MSRP = "MSRP",
  NOTES = "Notes",
  PART_NUMBER = "Part Number",
  PARTS_ONLY_DEALER_COST = "Parts Only Dealer Cost",
  PARTS_ONLY_MSRP = "Parts Only MSRP",
  INSTALLED_DEALER_COST = "Installed Dealer Cost",
  REQUIRED = "Required",
  CONFLICTS = "Conflicts",
  INACTIVE = "Inactive",
  GRADE_MSRP = "Grade MSRP",
  NON_GENUINE = "Non Genuine",
  WARRANTY = "Warranty",
  WARRANTY_ES = "Warranty es",
  LABOR_TIME = "Labor Time",
}

export const ACL_CHANGE_TYPE_ATTRIBUTE_MAP: Map<string, string> = new Map<
  string,
  string
>([
  [ACLChangeType.TITLE, "title"],
  [ACLChangeType.TITLE_ES, "title_es"],
  [ACLChangeType.COPY, "copy"],
  [ACLChangeType.DISCLOSURE, "disclosure"],
  [ACLChangeType.PRODUCT_TYPE, "productType"],
  [ACLChangeType.INSTALL_POINT, "installPoint"],
  [ACLChangeType.PPO_CODE, "ppoCode"],
  [ACLChangeType.DIO_CODE, "dioCode"],
  [ACLChangeType.IMAGES, "images"],
  [ACLChangeType.SUPPLIER, "supplier"],
  [ACLChangeType.DESCRIPTION, "description"],
  [ACLChangeType.DESCRIPTION_ES, "description_es"],
  [ACLChangeType.DISCLAIMER, "disclaimer"],
  [ACLChangeType.DISCLAIMER_ES, "disclaimer_es"],
  [ACLChangeType.MSRP, "msrp"],
  [ACLChangeType.NOTES, "notes"],
  [ACLChangeType.PART_NUMBER, "partNumber"],
  [ACLChangeType.PARTS_ONLY_DEALER_COST, "partsOnlyDealerCost"],
  [ACLChangeType.PARTS_ONLY_MSRP, "partsOnlyMSRP"],
  [ACLChangeType.INSTALLED_DEALER_COST, "installedDealerCost"],
  [ACLChangeType.REQUIRED, "required"],
  [ACLChangeType.CONFLICTS, "conflicts"],
  [ACLChangeType.INACTIVE, "inactive"],
  [ACLChangeType.GRADE_MSRP, "gradeMsrp"],
  [ACLChangeType.NON_GENUINE, "isNonGenAccessory"],
  [ACLChangeType.WARRANTY, "warranty"],
  [ACLChangeType.WARRANTY_ES, "warranty_es"],
]);

interface ChangeLogChangesProps {
  changedItem: AclChange;
  productTypes: AclProductTypeItem[] | null | undefined;
}

const ChangeLogChanges = ({
  changedItem,
  productTypes,
}: ChangeLogChangesProps) => {
  switch (changedItem.changeType) {
    case ACLChangeType.TITLE:
    case ACLChangeType.TITLE_ES:
    case ACLChangeType.COPY:
    case ACLChangeType.DISCLOSURE:
    case ACLChangeType.PPO_CODE:
    case ACLChangeType.DIO_CODE:
    case ACLChangeType.SUPPLIER:
    case ACLChangeType.DESCRIPTION:
    case ACLChangeType.DESCRIPTION_ES:
    case ACLChangeType.DISCLAIMER:
    case ACLChangeType.DISCLAIMER_ES:
    case ACLChangeType.NOTES:
    case ACLChangeType.PART_NUMBER:
    case ACLChangeType.PARTS_ONLY_DEALER_COST:
    case ACLChangeType.PARTS_ONLY_MSRP:
    case ACLChangeType.INSTALLED_DEALER_COST:
    case ACLChangeType.REQUIRED:
    case ACLChangeType.CONFLICTS:
    case ACLChangeType.WARRANTY:
    case ACLChangeType.LABOR_TIME:
    case ACLChangeType.WARRANTY_ES: {
      return (
        <ChangeLogRichTextDiff
          before={changedItem.before || ""}
          after={changedItem.after || ""}
        />
      );
    }

    case ACLChangeType.INSTALL_POINT: {
      return (
        <ChangeLogBeforeAfter
          before={changedItem.before || ""}
          after={changedItem.after || ""}
        />
      );
    }

    case ACLChangeType.PRODUCT_TYPE: {
      const beforeVal =
        productTypes?.find((item) => item.id === changedItem.before)?.name ||
        "";
      const afterVal =
        productTypes?.find((item) => item.id === changedItem.after)?.name || "";
      return <ChangeLogBeforeAfter before={beforeVal} after={afterVal} />;
    }

    case ACLChangeType.INACTIVE:
    case ACLChangeType.NON_GENUINE: {
      return (
        <ChangeLogBeforeAfter
          before={changedItem.before === true ? "true" : "false"}
          after={changedItem.after === true ? "true" : "false"}
        />
      );
    }

    case ACLChangeType.MSRP: {
      return (
        <ChangeLogRichTextDiff
          before={changedItem.before ? changedItem.before.toString() : ""}
          after={changedItem.after ? changedItem.after.toString() : ""}
        />
      );
    }

    case ACLChangeType.IMAGES: {
      return (
        <ChangeLogImage
          before={changedItem.before ? JSON.stringify(changedItem.before) : ""}
          after={changedItem.after ? JSON.stringify(changedItem.after) : ""}
        />
      );
    }

    default: {
      return <>--</>;
    }
  }
};

export default ChangeLogChanges;
