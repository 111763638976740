import cx from "clsx";
import React, { useContext } from "react";
import {
  Checkbox,
  HeaderRow,
  TabPanel,
  Table,
  TableCell,
  TableRow,
  Thead,
} from "vapi-ui-common";
import { GoLiveTimeChangeLog } from "../ChangeLogTable/ChangeLogChanges";
import styles from "./SyncAllChanges.module.scss";
import {
  SyncAllChangesActions,
  SyncAllChangesContext,
} from "./SyncAllChangesContext";

const useVariables = () => {
  const { state, dispatch } = useContext(SyncAllChangesContext);

  const { logDataAdmin, gradesData, selectedTab, tabAdmin } = state;

  const toggleCheckbox = (
    id: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch({
      type: SyncAllChangesActions.TOGGLE_CHECKBOX_ADMIN,
      payload: {
        id,
        checked: e.currentTarget.checked,
      },
    });
  };

  const toggleAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: SyncAllChangesActions.TOGGLE_CHECKBOX_ALL_ADMIN,
      payload: {
        checked: e.currentTarget.checked,
      },
    });
  };

  const toggleCheckboxIgnore = (
    id: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch({
      type: SyncAllChangesActions.TOGGLE_CHECKBOX_ADMIN_IGNORE,
      payload: {
        id,
        checked: e.currentTarget.checked,
      },
    });
  };

  const toggleAllCheckboxIgnore = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: SyncAllChangesActions.TOGGLE_CHECKBOX_ALL_ADMIN_IGNORE,
      payload: {
        checked: e.currentTarget.checked,
      },
    });
  };

  return {
    logDataAdmin,
    gradesData,
    toggleAllCheckbox,
    toggleCheckbox,
    toggleAllCheckboxIgnore,
    toggleCheckboxIgnore,
    selectedTab,
    tabAdmin,
  };
};

const SyncAllChangesModalBodyAdminTab = () => {
  const {
    logDataAdmin,
    toggleAllCheckbox,
    toggleCheckbox,
    toggleAllCheckboxIgnore,
    toggleCheckboxIgnore,
    selectedTab,
    tabAdmin,
  } = useVariables();

  return (
    <TabPanel tab={tabAdmin} selected={selectedTab === tabAdmin}>
      <Table fullWidth className={styles.syncAllChangesTable}>
        <Thead>
          <HeaderRow>
            <TableCell className={cx(styles.checkboxCell)}>
              <Checkbox
                id="apply-chbox-all-admin"
                className={styles.checkboxAll}
                labelClassName={styles.checkboxLabel}
                checked={logDataAdmin.every((log) => log.checked)}
                onChange={toggleAllCheckbox}
              >
                <span>Apply</span>
              </Checkbox>
            </TableCell>

            <TableCell className={cx(styles.checkboxCell)}>
              <Checkbox
                id="ignore-chbox-all-admin"
                className={styles.checkboxAll}
                labelClassName={styles.checkboxLabel}
                checked={logDataAdmin.every((log) => log.checkedIgnore)}
                onChange={toggleAllCheckboxIgnore}
              >
                <span>Ignore</span>
              </Checkbox>
            </TableCell>

            <TableCell>Model</TableCell>

            <TableCell>Model Code</TableCell>

            <TableCell>Go Live Date</TableCell>
          </HeaderRow>
        </Thead>

        <tbody>
          {logDataAdmin.map((item) => {
            const { id } = item.changedItem;

            return (
              <TableRow zebra>
                <TableCell center className={cx(styles.checkBox)}>
                  <Checkbox
                    id={`apply-chbox-admin-${id}`}
                    checked={item.checked}
                    disabled={false}
                    onChange={(e) => toggleCheckbox(id, e)}
                  />
                </TableCell>

                <TableCell center className={styles.checkBox}>
                  <Checkbox
                    id={`ignore-chbox-admin-${id}`}
                    checked={item.checkedIgnore}
                    disabled={false}
                    onChange={(e) => toggleCheckboxIgnore(id, e)}
                  />
                </TableCell>

                <TableCell>{item.changedItem.model}</TableCell>
                <TableCell>{item.changedItem.modelCode}</TableCell>

                <TableCell large className={cx(styles.goLiveDate)}>
                  <GoLiveTimeChangeLog log={item} />
                </TableCell>
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </TabPanel>
  );
};

export default SyncAllChangesModalBodyAdminTab;
