import { useEffect } from "react";
import { toast } from "react-toastify";
import useStores from "../../../stores/useStores";
import logger from "../../../log";
import {
  AclDocument,
  useAclDocumentsQuery,
  useGetAclProductTypesQuery,
} from "../../../gql/generated";
import {
  ACCESSORY_TEAM,
  DRAFT_CL,
  GST_REGION,
  NATIONAL_REGION,
} from "../../../constants/Constants";
import { AccessoryCommonLanguageItem } from "../../../models/commonLanguage.model";

const useGetDraftCLData = () => {
  const { userStore, commonLanguageStore } = useStores();

  const oppCtx = {
    brand: userStore.brand,
    region: userStore.isGstUser() ? GST_REGION : NATIONAL_REGION,
    team: ACCESSORY_TEAM,
  };

  const getAclDraftResult = useAclDocumentsQuery({
    skip: !oppCtx.brand || !oppCtx.region,
    fetchPolicy: "network-only",
    variables: {
      input: {
        ...oppCtx,
        version: DRAFT_CL,
      },
    },
    onError: (e) => {
      logger.error(e);
      toast.error("Error loading common language data");
    },
  });
  const getAclProductTypesResult = useGetAclProductTypesQuery({
    skip: !oppCtx.brand || !oppCtx.region,
    fetchPolicy: "network-only",
    variables: {
      input: oppCtx,
    },
    onError: (e) => {
      logger.error(e);
      toast.error("Error loading product type data");
    },
  });

  useEffect(() => {
    const aclItems = getAclDraftResult.data?.aclDocuments;
    const productTypes = getAclProductTypesResult.data?.getACLProductTypes;

    if (!aclItems || !productTypes) {
      commonLanguageStore.isFetching = true;

      return () => {};
    }

    const accCLItems = aclItems.map((item) => {
      return AccessoryCommonLanguageItem.fromACLDocument(item as AclDocument);
    });

    commonLanguageStore.setData(accCLItems, productTypes, DRAFT_CL);

    return () => {
      commonLanguageStore.reset();
    };
  }, [getAclDraftResult.data, getAclProductTypesResult.data]);
};

export default useGetDraftCLData;
