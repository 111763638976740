import cx from "clsx";
import React from "react";
import { SortType } from "../../../models/sort.model";
import styles from "./sortSelect.module.scss";

interface SortSelectProps {
  list: SortType[];
  onClick: (val: SortType) => () => void;
  className?: string;
}

const SortSelect = ({ list = [], onClick, className }: SortSelectProps) => {
  return (
    <ul className={cx(styles.list, className)}>
      {list &&
        list.map((item) => (
          <li role="presentation" key={item} onClick={onClick(item)}>
            {item}
          </li>
        ))}
    </ul>
  );
};

export default SortSelect;
