import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ACCESSORY_TEAM,
  GST_REGION,
  NATIONAL_REGION,
} from "../../../constants/Constants";
import {
  AclDocument,
  RefItem,
  useAclCurrentVersionDocumentsQuery,
  useCreateAccFromAclMutation,
} from "../../../gql/generated";
import logger from "../../../log";
import AccessoryItemVM from "../../../models/accessories/AccessoryItemVM";
import { AccessoryCommonLanguageItem } from "../../../models/commonLanguage.model";
import { RouteParams } from "../../../models/routes.model";
import useStores from "../../../stores/useStores";
import handleErrorResponse from "../../../utils/errorHandlingUtils";

type UseAccessoryACLProps = {
  addNewAccessories: (items: AccessoryItemVM[]) => void;
  addNewProductTypes: (items: RefItem[]) => void;
};

const useAccessoryACL = ({
  addNewAccessories,
  addNewProductTypes,
}: UseAccessoryACLProps) => {
  const { userStore, teamStore } = useStores();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [aclListPublished, setAclListPublished] = useState<
    AccessoryCommonLanguageItem[]
  >([]);

  const [createAccFromAclMutation] = useCreateAccFromAclMutation();
  const params: RouteParams = useParams();

  const queryCtx = {
    brand: userStore.brand,
    region: userStore.isGstUser() ? GST_REGION : NATIONAL_REGION,
    team: ACCESSORY_TEAM,
  };

  const mutationCtx = {
    ...queryCtx,
    seriesId: params.seriesId,
    modelYear: Number(params.year),
    lang: teamStore.team.defaultLanguage.toLocaleLowerCase(),
  };

  const addCommonLanguage = async (comLangIds: string[]) => {
    try {
      await createAccFromAclMutation({
        variables: {
          input: {
            ...mutationCtx,
            comLangIds,
          },
        },
        update: (_cache, { data }) => {
          const newData = data?.createAccFromACL.accessories;
          if (!newData) return;
          const prodTypes = data?.createAccFromACL.productTypes ?? [];
          if (prodTypes.length) {
            addNewProductTypes(prodTypes);
          }

          addNewAccessories(
            newData.map(
              (accessoryItem) => new AccessoryItemVM({ accessoryItem })
            )
          );
        },
      });

      toast.success("Successfully added ACLs to Accessories");
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const getAclResult = useAclCurrentVersionDocumentsQuery({
    skip: !queryCtx.brand || !queryCtx.region,
    fetchPolicy: "network-only",
    variables: queryCtx,
    onError: (e) => {
      logger.error(e);
      toast.error("Error loading common language data");
    },
  });

  useEffect(() => {
    const aclItems = getAclResult.data?.aclCurrentVersionDocuments;

    if (!aclItems) {
      return;
    }

    const accCLItems = aclItems.map((item) => {
      return AccessoryCommonLanguageItem.fromACLDocument(item as AclDocument);
    });

    setAclListPublished(accCLItems);
    setIsLoaded(true);
  }, [getAclResult.data]);

  return { isLoaded, aclListPublished, addCommonLanguage };
};

export default useAccessoryACL;
