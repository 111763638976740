import { observable } from "mobx";
import { v4 as uuidv4 } from "uuid";

export default class IDValueType<T = any> {
  uid = uuidv4();

  id = "";

  @observable value: T;

  /**
   * T = Return type
   */

  constructor(id: string, value: T) {
    this.id = id;
    this.value = value;
  }
}
