import cx from "clsx";
import React from "react";
import { Language } from "../../../../models/user/user.model";
import LanguageCheckboxComponent from "./LanguageCheckbox";
import styles from "./languagesCheckbox.module.scss";
import { LanguagesCheckbox } from "./useTeamLanguagesCheckbox";

type LanguagesCheckboxProps = {
  languages: Language[];
  languagesCheckbox: LanguagesCheckbox;
  setLanguageCheckboxSelected: (language: Language, selected: boolean) => void;
};

const LanguagesCheckboxComponent = ({
  languages,
  languagesCheckbox,
  setLanguageCheckboxSelected,
}: LanguagesCheckboxProps) => {
  if (languages.length <= 1) {
    return <></>;
  }

  return (
    <div className={cx(styles.languagesCheckbox)}>
      {languages.map((language) => (
        <LanguageCheckboxComponent
          key={`chbox${language}`}
          languageCheckbox={languagesCheckbox[language]}
          setLanguageCheckboxSelected={setLanguageCheckboxSelected}
        />
      ))}
    </div>
  );
};

export default LanguagesCheckboxComponent;
