import { action, observable } from "mobx";
import sortBy from "../utils/sortBy";
import { isSortReverse } from "../utils/sortUtils";
import { AccessoryCommonLanguageItem } from "../models/commonLanguage.model";
import { filterAccessoriesCommonLanguage } from "../utils/commonLanguageUtils";
import { AclProductTypeItem } from "../gql/generated";
import { ProductTypeLangMap } from "../models/accessories/accessories.models";

export default class CommonLanguageStore {
  @observable data: AccessoryCommonLanguageItem[] = [];
  @observable reverseSort = false;
  @observable sortField = "id";
  @observable searchText = "";
  @observable filteredData: AccessoryCommonLanguageItem[] = [];
  @observable productTypes: AclProductTypeItem[] = [];
  @observable productTypeFilters: string[] = [];
  @observable installPointFilters: string[] = [];
  @observable currentVersion = "";
  @observable productTypeLangMap: ProductTypeLangMap = {};
  @observable isFetching = false;
  @observable isLoaded = false;
  @observable totalChanges = 0;

  @action addItem = (item?: AccessoryCommonLanguageItem) => {
    const newItem = new AccessoryCommonLanguageItem(item);

    this.data = [newItem, ...this.data];
    this.filteredData = [newItem, ...this.filteredData];
  };

  @action deleteItem = async (itemToRemove: AccessoryCommonLanguageItem) => {
    this.data = this.data.filter((item) => item.id !== itemToRemove.id);
    this.filteredData = this.filteredData.filter(
      (item) => item.id !== itemToRemove.id
    );
  };

  @action setFilters = (
    productTypeFilters: string[],
    installPointFilters: string[]
  ) => {
    this.productTypeFilters = productTypeFilters;
    this.installPointFilters = installPointFilters;
    this.filterData();
  };

  @action setSort = (sortField: string) => {
    this.reverseSort = isSortReverse(
      this.sortField,
      sortField,
      this.reverseSort
    );
    this.sortField = sortField;
    this.filterData();
  };

  @action setIsFetching = (isFetching: boolean) => {
    this.isFetching = isFetching;
  };

  @action setIsLoaded = (isLoaded: boolean) => {
    this.isLoaded = isLoaded;
  };

  @action setSearchText = (searchText: string) => {
    this.searchText = searchText;
    this.filterData();
  };

  @action filterData = () => {
    const sortedData = this.data
      .slice()
      .sort(
        sortBy(
          this.sortField,
          this.reverseSort,
          this.sortField === "lastPublishedDate"
        )
      );

    this.filteredData = filterAccessoriesCommonLanguage(
      this.searchText,
      this.productTypeFilters,
      this.installPointFilters,
      sortedData,
      this.productTypes
    );
  };

  @action countChanges = () => {
    this.totalChanges = this.data
      .map((item) => item.changedAttributes?.length ?? 0)
      .reduce((a, b) => a + b, 0);
  };

  @action setData = async (
    data: AccessoryCommonLanguageItem[],
    productTypes: AclProductTypeItem[],
    version: string
  ) => {
    this.data = data;
    this.filteredData = data;
    this.productTypes = productTypes;
    this.isFetching = false;
    this.isLoaded = true;
    this.currentVersion = version;
    this.countChanges();
  };

  @action resetDraft = async () => {
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  };

  @action saveItem = (item: AccessoryCommonLanguageItem) => {
    const index = this.data.findIndex((i) => i.uid === item.uid);
    if (index === -1) {
      this.data = [item, ...this.data];
      this.filteredData = [item, ...this.filteredData];
    } else {
      this.data[index] = item;

      const filteredIndex = this.filteredData.findIndex(
        (i) => i.uid === item.uid
      );
      this.filteredData[filteredIndex] = item;

      this.data = this.data.slice();
      this.filteredData = this.filteredData.slice();
    }

    this.countChanges();
  };

  @action archiveItem = async (itemToArchive: AccessoryCommonLanguageItem) => {
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });

    const data = this.data.filter(
      (item) =>
        item.id !== itemToArchive.id && item.revId !== itemToArchive.revId
    );

    this.data = data;
    this.filteredData = data;
    this.countChanges();
  };

  @action addProductType = (productType: string) => {
    this.productTypes = [...this.productTypes, { id: "", name: productType }];
  };

  @action updateProductType = (productType: AclProductTypeItem) => {
    const index = this.productTypes.findIndex(
      (item) => item.id === productType.id
    );
    if (index !== -1) {
      this.productTypes[index] = productType;
    }
  };

  @action reset = () => {
    this.data = [];
    this.filteredData = [];
    this.totalChanges = 0;
    this.sortField = "id";
    this.searchText = "";
    this.reverseSort = false;
    this.productTypes = [];
    this.productTypeFilters = [];
    this.installPointFilters = [];
    this.isFetching = false;
    this.isLoaded = false;
  };
}
