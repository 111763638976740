import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  HeaderRow,
  ImageItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ScrollableContainer,
  Spinner,
  Table,
  TableCell,
} from "vapi-ui-common";
import {
  AccessoryItem,
  Change,
  RefItem,
  useCompareAccToAclChangesQuery,
} from "../../gql/generated";
import AccessoryItemVM from "../../models/accessories/AccessoryItemVM";
import ChangeLogChanges, {
  AccessoryACLCompareChangeType,
} from "../../routes/aclCompare/components/ChangeLogChanges";
import ChangeLogRow from "../ChangeLogTable/ChangeLogRow";
import styles from "./CompareAccToACLModal.module.scss";

interface Props {
  variables: {
    brand: string;
    team: string;
    region: string;
    lang: string;
    seriesId: string;
    modelYear: number;
    version: string;
  };
  close: () => void;
  accessory: AccessoryItemVM;
  productTypes: RefItem[];
  saveACLChanges: (
    item: AccessoryItemVM,
    newProductType: string
  ) => Promise<void>;
}

const CompareAccToACLModal = ({
  variables,
  close,
  accessory,
  productTypes,
  saveACLChanges,
}: Props) => {
  const { data } = useCompareAccToAclChangesQuery({
    variables: {
      brand: variables.brand,
      region: variables.region,
      lang: variables.lang,
      seriesId: variables.seriesId,
      modelYear: variables.modelYear,
      team: variables.team,
      id: accessory.id,
    },
    fetchPolicy: "network-only",
  });

  const [isLoaded, setIsLoaded] = useState(false);
  const [logData, setLogData] = useState<Change[]>([]);

  useEffect(() => {
    if (data && !isLoaded) {
      setLogData(data.compareAccToACL?.changes as Change[]);
      setIsLoaded(true);
    }
  }, [data]);

  const unlinkFromCommonLanguage = () => {
    const a = accessory;
    a.comLangId = "";
    delete a.hasComLangChanges;
    delete a.comLangVersion;
    saveACLChanges(a, "");
    close();
  };

  const applyChanges = () => {
    const a = accessory;
    a.hasComLangChanges = false;
    let newProductType = "";
    logData.forEach((log) => {
      const after = log.after ?? "";
      switch (log.changeType) {
        case AccessoryACLCompareChangeType.SUPPLIER: {
          a.supplier = after;
          break;
        }
        case AccessoryACLCompareChangeType.TITLE: {
          a.title = after;
          break;
        }
        case AccessoryACLCompareChangeType.TITLE_ES: {
          a.title_es = after;
          break;
        }
        case AccessoryACLCompareChangeType.COPY: {
          a.copy = after;
          break;
        }
        case AccessoryACLCompareChangeType.DISCLOSURE: {
          a.disclosure = after;
          break;
        }
        case AccessoryACLCompareChangeType.PRODUCT_TYPE_CHANGED: {
          const foundProductType = productTypes.find((type) => {
            return type.name.toLowerCase() === after.toLowerCase();
          });
          if (foundProductType) {
            a.productType = foundProductType.id;
          } else if (after) {
            newProductType = after;
          } else {
            a.productType = "";
          }
          break;
        }
        case AccessoryACLCompareChangeType.INSTALL_POINT: {
          a.installPoint = after;
          break;
        }
        case AccessoryACLCompareChangeType.PPO_CODE: {
          a.ppoCode = after;
          break;
        }
        case AccessoryACLCompareChangeType.DESCRIPTION: {
          a.description = after;
          break;
        }
        case AccessoryACLCompareChangeType.DESCRIPTION_ES: {
          a.description_es = after;
          break;
        }
        case AccessoryACLCompareChangeType.DISCLAIMER: {
          a.disclaimer = after;
          break;
        }
        case AccessoryACLCompareChangeType.DISCLAIMER_ES: {
          a.disclaimer_es = after;
          break;
        }
        case AccessoryACLCompareChangeType.MSRP: {
          a.msrp = after;
          break;
        }
        case AccessoryACLCompareChangeType.NOTES: {
          a.msrp = after;
          break;
        }
        case AccessoryACLCompareChangeType.PART_NUMBER: {
          a.partNumber = after;
          break;
        }
        case AccessoryACLCompareChangeType.PARTS_ONLY_DEALER_COST: {
          a.uid = uuidv4();
          a.partsOnlyDealerCost = after;
          break;
        }
        case AccessoryACLCompareChangeType.PARTS_ONLY_MSRP: {
          a.partsOnlyMSRP = after;
          break;
        }
        case AccessoryACLCompareChangeType.INSTALLED_DEALER_COST: {
          a.installedDealerCost = after;
          break;
        }
        case AccessoryACLCompareChangeType.REQUIRED: {
          a.required = after;
          break;
        }
        case AccessoryACLCompareChangeType.CONFLICTS: {
          a.conflicts = after;
          break;
        }
        case AccessoryACLCompareChangeType.NON_GENUINE: {
          a.isNonGenAccessory = !a.isNonGenAccessory;
          break;
        }
        case AccessoryACLCompareChangeType.IMAGES: {
          a.images = Object.values(JSON.parse(after)) as ImageItem[];
          break;
        }
        case AccessoryACLCompareChangeType.WARRANTY: {
          a.warranty = after;
          break;
        }
        case AccessoryACLCompareChangeType.WARRANTY_ES: {
          a.warranty_es = after;
          break;
        }
        case AccessoryACLCompareChangeType.LABOR_TIME: {
          a.laborTime = after;
          break;
        }
        default:
          break;
      }
    });
    saveACLChanges(a, newProductType);
    close();
  };

  return (
    <>
      <ModalHeader onClose={() => close()}>
        Changes From Common Language
      </ModalHeader>
      {!isLoaded ? (
        <Spinner short />
      ) : (
        <>
          <ModalBody>
            <ScrollableContainer style={{ overflow: "scroll" }}>
              <Table fullWidth className={styles.aapTable}>
                <HeaderRow className={styles.stickyNav}>
                  <TableCell>Title</TableCell>
                  <TableCell>Change Type</TableCell>
                  <TableCell>Changes</TableCell>
                  <TableCell>Published Date</TableCell>
                  <TableCell>Notes</TableCell>
                </HeaderRow>
                <tbody className={styles.appBody}>
                  {data &&
                    logData.map((item) => {
                      let changeTypeDisplay = item.changeType;

                      if (
                        item.changeType === AccessoryACLCompareChangeType.TITLE
                      ) {
                        changeTypeDisplay = "Name";
                      } else if (
                        item.changeType ===
                        AccessoryACLCompareChangeType.TITLE_ES
                      ) {
                        changeTypeDisplay = "Name es";
                      }
                      const accItem = item.changedItem as AccessoryItem;
                      return (
                        <ChangeLogRow
                          key={`${item.changeType}${accItem.id}`}
                          description={accItem.title}
                          changeType={changeTypeDisplay}
                          modifiedBy=""
                          modifiedDate={item.modifiedDate || ""}
                          notes={accItem.notes || ""}
                          canRevert={false}
                          renderChanges={
                            <ChangeLogChanges changedItem={item} />
                          }
                          onRevert={() => {}}
                          id={item.changeType}
                          hideModifiedBy
                        />
                      );
                    })}
                </tbody>
              </Table>
            </ScrollableContainer>
          </ModalBody>
          <ModalFooter className={styles.footer}>
            <div>
              <Button
                variant="transparent"
                onClick={() => unlinkFromCommonLanguage()}
              >
                Unlink From Common Language
              </Button>
            </div>
            <div>
              <Button variant="transparent" onClick={() => close()}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  applyChanges();
                }}
              >
                Accept All
              </Button>
            </div>
          </ModalFooter>
        </>
      )}
    </>
  );
};

export default CompareAccToACLModal;
