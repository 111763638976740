import React from "react";
import { observer } from "mobx-react-lite";
import { Table, Thead, TableRow, HeaderCell } from "vapi-ui-common";
import styles from "./reportsTable.module.scss";
import ReportRow from "./ReportsRow";
import { ReportItem } from "../../models/reports/reports.model";

interface ReportsTableProps {
  reports: ReportItem[];
  onDownload: (item: ReportItem) => void;
}

const ReportsTable = observer(({ reports, onDownload }: ReportsTableProps) => {
  return (
    <Table fullWidth className={styles.table}>
      <Thead>
        <TableRow>
          <HeaderCell>Download Status</HeaderCell>
          <HeaderCell>Type</HeaderCell>
          <HeaderCell>Series</HeaderCell>
          <HeaderCell>Year</HeaderCell>
          <HeaderCell>Version</HeaderCell>
          <HeaderCell>Date</HeaderCell>
          <HeaderCell>User</HeaderCell>
        </TableRow>
      </Thead>
      <tbody className={styles.body}>
        {reports.map((report) => (
          <ReportRow key={report.uid} report={report} onDownload={onDownload} />
        ))}
      </tbody>
    </Table>
  );
});

export default ReportsTable;
