/* eslint-disable import/prefer-default-export */
import toLowerCase from "./toLowerCase";
import { AccessoryCommonLanguageItem } from "../models/commonLanguage.model";
import { AclProductTypeItem } from "../gql/generated";

const matchSearchTextToAccessoryCommonLanguageItem = (
  searchText: string,
  item: AccessoryCommonLanguageItem
) => {
  return (
    toLowerCase(item.title ?? "").includes(searchText) ||
    toLowerCase(item.title_es ?? "").includes(searchText) ||
    toLowerCase(item.description ?? "").includes(searchText) ||
    toLowerCase(item.description_es ?? "").includes(searchText) ||
    toLowerCase(item.copy ?? "").includes(searchText) ||
    toLowerCase(item.disclaimer ?? "").includes(searchText) ||
    toLowerCase(item.disclaimer_es ?? "").includes(searchText) ||
    toLowerCase(item.supplier ?? "").includes(searchText) ||
    toLowerCase(item.warranty ?? "").includes(searchText) ||
    toLowerCase(item.warranty_es ?? "").includes(searchText) ||
    toLowerCase(item.productType ?? "").includes(searchText) ||
    toLowerCase(item.installPoint ?? "").includes(searchText) ||
    toLowerCase(item.ppoCode ?? "").includes(searchText) ||
    toLowerCase(item.notes ?? "").includes(searchText) ||
    toLowerCase(item.partNumber ?? "").includes(searchText) ||
    toLowerCase(item.required ?? "").includes(searchText) ||
    toLowerCase(item.conflicts ?? "").includes(searchText)
  );
};

const matchProductTypeFiltersToAccessoryCommonLanguageItem = (
  productTypeFilters: string[],
  productType: string
) => {
  return productTypeFilters.includes(productType);
};

const matchInstallPointFilersToAccessoryCommonLanguageItem = (
  installPointFilers: string[],
  item: AccessoryCommonLanguageItem
) => {
  return installPointFilers.some((installPoint) => {
    return item.installPoint?.includes(installPoint);
  });
};

export const filterAccessoriesCommonLanguage = (
  searchText: string,
  productTypeFilters: string[],
  installPointFilers: string[],
  data: AccessoryCommonLanguageItem[],
  productTypes: AclProductTypeItem[]
) => {
  const loweredSearchText = toLowerCase(searchText);

  return data.filter((item) => {
    if (
      loweredSearchText.length > 0 &&
      !matchSearchTextToAccessoryCommonLanguageItem(loweredSearchText, item)
    ) {
      return false;
    }

    if (
      productTypeFilters.length > 0 &&
      !matchProductTypeFiltersToAccessoryCommonLanguageItem(
        productTypeFilters,
        productTypes.find((pt) => pt.id === item.productType)?.name ?? ""
      )
    ) {
      return false;
    }

    if (
      installPointFilers.length > 0 &&
      !matchInstallPointFilersToAccessoryCommonLanguageItem(
        installPointFilers,
        item
      )
    ) {
      return false;
    }

    return true;
  });
};
