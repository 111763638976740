import React, { useState } from "react";
import { faArrowsAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dropdown,
  DropdownButton,
  DropdownContent,
  useComponentVisible,
} from "vapi-ui-common";
import SortSelect from "../SortSelect";
import { SortType } from "../../../models/sort.model";
import styles from "./sortContent.module.scss";

interface SortItemProps {
  label: string;
  list?: SortType[];
  onSelect?: (val: SortType) => () => void;
}

const SortItem = ({ label, list, onSelect }: SortItemProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const onClick = (val: SortType) => {
    return () => {
      if (onSelect) {
        onSelect(val)();
      }
      setSelectedIndex(list?.indexOf(val) || 0);
      setIsComponentVisible(false);
    };
  };

  const getDropDown = () => {
    if (!list || !onSelect) {
      return null;
    }

    return (
      <Dropdown>
        <DropdownButton
          placeholder="Select Sort type"
          className={styles.dropdownBtn}
          onClick={() => setIsComponentVisible(true)}
        >
          {list[selectedIndex || 0]}
        </DropdownButton>
        <DropdownContent open={isComponentVisible} ref={ref}>
          <SortSelect list={list} onClick={onClick} />
        </DropdownContent>
      </Dropdown>
    );
  };

  return (
    <div className={styles.itemContaimer}>
      <FontAwesomeIcon className={styles.dragIcon} icon={faArrowsAlt} />
      <div className={styles.label}>{label}</div>
      {getDropDown()}
    </div>
  );
};

export default SortItem;
