import { action, observable } from "mobx";

class AccessoriesListStore {
  @observable data: any = [];

  @action fetchData = () => {
    const data = [
      {
        id: "1",
        item: "item 1",
        value: "value 1",
      },
      {
        id: "2",
        item: "item 2",
        value: "value 2",
      },
      {
        id: "3",
        item: "item 3",
        value: "value 3",
      },
    ];
    this.data = data;
  };
}

export default AccessoriesListStore;
