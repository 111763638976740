import { useEffect, useState } from "react";
import { Language } from "../../../../models/user/user.model";

export type LanguageCheckbox = {
  selected: boolean;
  description: string;
  language: Language;
};

export type LanguagesCheckbox = {
  [k in Language]: LanguageCheckbox;
};

type UseLanguagesCheckboxProps = {
  languages: Language[];
};

const useTeamLanguagesCheckbox = ({
  languages = [],
}: UseLanguagesCheckboxProps) => {
  const defaultLanguages: LanguagesCheckbox = {
    EN: {
      description: "English",
      selected: false,
      language: Language.EN,
    },
    ES: {
      description: "Spanish",
      selected: false,
      language: Language.ES,
    },
  };

  const [languagesCheckbox, setLanguagesCheckbox] =
    useState<LanguagesCheckbox>(defaultLanguages);

  useEffect(() => {
    const langs = { ...defaultLanguages };
    languages.forEach((language) => {
      langs[language].selected = true;
    });
    setLanguagesCheckbox(langs);
  }, [languages, setLanguagesCheckbox]);

  const setLanguageCheckboxSelected = (language: Language, selected: boolean) =>
    setLanguagesCheckbox({
      ...languagesCheckbox,
      [language]: { ...languagesCheckbox[language], selected },
    });

  const getLanguageSelected = () =>
    Object.values(languagesCheckbox)
      .filter((lang) => lang.selected)
      .map((lang) => lang.language);

  return {
    languagesCheckbox,
    setLanguageCheckboxSelected,
    getLanguageSelected,
  };
};

export default useTeamLanguagesCheckbox;
