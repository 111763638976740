import React, { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { Button } from "vapi-ui-common";
import SortItem from "./SortItem";
import { SortType } from "../../../models/sort.model";
import { RefItem } from "../../../gql/generated";
import styles from "./sortContent.module.scss";
import { RefItemSortInput } from "../../../models/refItem/refItem.model";

interface SortGenuineAAP {
  [val: string]: SortGenuineAAPData;
}

interface SortGenuineAAPData {
  selectedSortType: SortType;
  sortKeys: SortType[];
}

interface DropdownListProps {
  sortType: SortType;
  itemList?: RefItem[];
  onClose?: () => void;
  onSave?: (sortList: RefItem[], subSortList: RefItemSortInput[]) => void;
}

const SortContent = ({
  sortType,
  itemList,
  onClose,
  onSave,
}: DropdownListProps) => {
  const [list, setList] = useState([] as RefItem[]);
  const [sortTypes, setSortTypes] = useState<SortGenuineAAP>({});

  useEffect(() => {
    let sortKeys: SortType[] = [];

    switch (sortType) {
      case SortType.PRODUCT_TYPE:
        sortKeys = [SortType.GENUINE, SortType.AAP];
        break;
      case SortType.GENUINE_AAP:
        sortKeys = itemList
          ? itemList.map((item) => item.name as SortType)
          : [];
        break;
      default:
    }

    const obj: SortGenuineAAP = {};

    if (itemList && sortType === SortType.PRODUCT_TYPE) {
      setList(itemList.slice());
      itemList.slice().forEach((item) => {
        obj[item.name] = {
          selectedSortType: SortType.ALPHABETICAL,
          sortKeys,
        };
      });
    } else if (sortType === SortType.GENUINE_AAP) {
      setList([
        {
          id: SortType.GENUINE,
          name: SortType.GENUINE,
          revId: SortType.GENUINE,
        },
        {
          id: SortType.AAP,
          name: SortType.AAP,
          revId: SortType.AAP,
        },
      ]);
      obj[SortType.GENUINE] = {
        selectedSortType: SortType.ALPHABETICAL,
        sortKeys,
      };

      obj[SortType.AAP] = {
        selectedSortType: SortType.ALPHABETICAL,
        sortKeys,
      };
    }
    setSortTypes(obj);
  }, [itemList]);

  const onDragEndCallback = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }

    const [removed] = list.splice(result.source.index, 1);
    list.splice(result.destination.index, 0, removed);

    return list;
  };

  const getDragEndCallback = (key: string) => {
    return (result: DropResult) => {
      if (!result.destination) {
        return undefined;
      }

      const { selectedSortType } = sortTypes[key];
      const sortKeys = sortTypes[key].sortKeys.slice();
      const [removed] = sortKeys.splice(result.source.index, 1);
      sortKeys.splice(result.destination.index, 0, removed);
      setSortTypes({
        ...sortTypes,
        [key]: {
          selectedSortType,
          sortKeys,
        },
      });

      return sortTypes[key].sortKeys;
    };
  };

  const onSaveCallback = () => {
    if (onSave) {
      const listNew: RefItemSortInput[] = list.map((item) => {
        const subSortType = sortTypes[item.name].selectedSortType;

        return {
          item,
          subSortTypes:
            subSortType === SortType.ALPHABETICAL
              ? [subSortType]
              : sortTypes[item.name].sortKeys,
        };
      });

      onSave(list, listNew);
    }

    if (onClose) {
      onClose();
    }
  };

  const hasSubSort = (data: SortGenuineAAPData) => {
    return (
      data.selectedSortType === SortType.GENUINE_AAP ||
      data.selectedSortType === SortType.PRODUCT_TYPE
    );
  };

  const getSortByDisplay = (dropdownList: SortType[]) => {
    return (
      <DragDropContext onDragEnd={onDragEndCallback}>
        <Droppable droppableId="sortDroppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {list.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided1) => (
                    <div
                      key={item.id}
                      style={provided1.draggableProps.style}
                      {...provided1.draggableProps}
                      {...provided1.dragHandleProps}
                    >
                      <div ref={provided1.innerRef}>
                        <SortItem
                          label={item.name}
                          list={dropdownList}
                          onSelect={(val: SortType) => {
                            let obj = {};
                            return () => {
                              obj = {
                                ...sortTypes,
                                [item.name]: {
                                  ...sortTypes[item.name],
                                  selectedSortType: val,
                                },
                              };
                              setSortTypes(obj);
                            };
                          }}
                        />
                        {hasSubSort(sortTypes[item.name]) && (
                          <div className={styles.subContainer}>
                            <DragDropContext
                              onDragEnd={getDragEndCallback(item.name)}
                            >
                              <Droppable droppableId="sortDroppableSub">
                                {(providedSub) => (
                                  <div
                                    ref={providedSub.innerRef}
                                    {...providedSub.droppableProps}
                                  >
                                    {sortTypes[item.name].sortKeys.map(
                                      (key, i) => (
                                        <Draggable
                                          key={item.id + key}
                                          draggableId={item.id + key}
                                          index={i}
                                        >
                                          {(providedSub1) => (
                                            <div
                                              key={item.id + key}
                                              style={
                                                providedSub1.draggableProps
                                                  .style
                                              }
                                              {...providedSub1.draggableProps}
                                              {...providedSub1.dragHandleProps}
                                            >
                                              <div ref={providedSub1.innerRef}>
                                                <SortItem label={key} />
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      )
                                    )}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

  const getDisplay = () => {
    switch (sortType) {
      case SortType.GENUINE_AAP:
        return getSortByDisplay([SortType.ALPHABETICAL, SortType.PRODUCT_TYPE]);
      case SortType.PRODUCT_TYPE:
        return getSortByDisplay([SortType.ALPHABETICAL, SortType.GENUINE_AAP]);
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      {getDisplay()}
      <div className={styles.buttonContainer}>
        <Button variant="transparent" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSaveCallback}>
          Save sort
        </Button>
      </div>
    </div>
  );
};

export default SortContent;
