import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { ApolloProvider } from "@apollo/client";
import React, { HTMLAttributes, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import apolloClient from "./apolloClient";
import App from "./App";
import "./index.scss";
import { UserInfo } from "./models/user/user.model";
import useStores from "./stores/useStores";
import { getPermissions } from "./webservices/adminApi";
import { getToken, getUser, msalInstance } from "./authConfig";

const getUserInfo = async () => {
  const token = await getToken();
  const user = await getUser();
  const objectId = user?.localAccountId ?? '';
  const response = await getPermissions({ token, objectId });

  return { ...response.data, objectId } as UserInfo;
};

const UserInfoComponent = ({ children }: HTMLAttributes<HTMLDivElement>) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { userStore } = useStores();

  useEffect(() => {
    getUserInfo().then((userInfo) => {
      userStore.setUserInfo(userInfo);
      setIsLoaded(true);
    });
  }, [userStore]);

  return <>{isLoaded && children}</>;
};

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
    >
      <ApolloProvider client={apolloClient}>
        <UserInfoComponent>
          <App />
        </UserInfoComponent>
      </ApolloProvider>
    </MsalAuthenticationTemplate>
  </MsalProvider>,
  document.getElementById("root")
);
