import React from "react";
import { v4 as uuidv4 } from "uuid";
import { GradeItem, ModelItem } from "../../../../stores/modelStore";
import styles from "../changeLogChanges.module.scss";

interface Props {
  before: string;
  after: string;
  gradesDataItem: GradeItem[];
}

const ChangeLogApplicability = ({ before, after, gradesDataItem }: Props) => {
  const modelItems: ModelItem[] = [];
  gradesDataItem.forEach((gradeItem) => {
    gradeItem.modelItems.forEach((item) => {
      modelItems.push(item);
    });
  });
  const beforeObj = before ? JSON.parse(before) : undefined;
  const afterObj = after ? JSON.parse(after) : undefined;

  // keys are the actual model ids
  const beforeKeys = beforeObj ? Object.keys(beforeObj) : [];
  const afterKeys = afterObj ? Object.keys(afterObj) : [];

  const keyHelperFunc = (obj: string[]) => {
    return obj.map((key) => {
      const item = modelItems.find((modelItem) => modelItem.modelId === key);
      if (item) {
        return item.code;
      }
      return key;
    });
  };

  const beforeValues = keyHelperFunc(beforeKeys);
  const afterValues = keyHelperFunc(afterKeys);

  return (
    <>
      <div>
        <span>Before: </span>
        <span>
          {beforeValues.map((item, index) => (
            <span
              key={`before-${item}-${uuidv4()}`}
              className={
                afterValues.indexOf(item) === -1
                  ? styles.changeRemoved
                  : undefined
              }
            >
              {item}
              {index !== beforeValues.length - 1 && ", "}
            </span>
          ))}
        </span>
      </div>
      <div>
        <span>After: </span>
        <span>
          {afterValues.map((item, index) => (
            <span
              key={`after-${item}-${uuidv4()}`}
              className={
                beforeValues.indexOf(item) === -1
                  ? styles.changeAdded
                  : undefined
              }
            >
              {item}
              {index !== afterValues.length - 1 && ", "}
            </span>
          ))}
        </span>
      </div>
    </>
  );
};

export default ChangeLogApplicability;
