import React, { useContext } from "react";
import { TableCell } from "vapi-ui-common";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";
import CAccessoryRichTextLanguage from "./CAccessoryRichTextLanguage";

const CCopyColumn = () => {
  const { selectedLanguages } = useContext(AccessoriesEntryScreenContext);

  const {
    accessoryItem: { id: accessoryItemId },
  } = useContext(AccessoryContext);

  return (
    <>
      {selectedLanguages.map((language) => {
        return (
          <TableCell
            largeNat
            key={`${language}-copy-column-${accessoryItemId}`}
          >
            <CAccessoryRichTextLanguage language={language} />
          </TableCell>
        );
      })}
    </>
  );
};

export default CCopyColumn;
