import cx from "clsx";
import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from "react-beautiful-dnd";
import { trackPromise } from "react-promise-tracker";
import ResizeDetector from "react-resize-detector";
import { Redirect, withRouter } from "react-router";
import { useParams } from "react-router-dom";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  AccessoriesTableHeaderDetail,
  AccessoriesTableHeaderRow,
  AccessoriesTableTrimHeaderName,
  Button,
  Checkbox,
  DropdownEditItem,
  DropdownEditorCell,
  Header,
  HeaderRow,
  LeftTableTH,
  Modal,
  Spinner,
  StickyContainer,
  StickyHeaderContainer,
  StickyHeaderSection,
  Table,
  TableCell,
  TableDragIcon,
  TableRow,
  Thead,
  TwoTableWrapper,
  Wayfinding,
  areRichTextValuesDifferent,
  cleanUpRte,
  useDebounce,
} from "vapi-ui-common";
import { CheckboxProps } from "vapi-ui-common/dist/src/components/Checkbox/Checkbox";
import AapModal from "../../components/AapModal";
import AccessoryRichText from "../../components/AccessoryRichText";
import BackButton from "../../components/BackButton/BackButton";
import CommonLanguageModal from "../../components/CommonLanguageModal";
import Input from "../../components/Input";
import inputStyles from "../../components/Input/input.module.scss";
import LeftTable from "../../components/LeftTable";
import MsrpFlagsCell from "../../components/MsrpFlagsCell/MsrpFlagcell";
import NATUpdatesPopover from "../../components/NATUpdatesPopover";
import PageError from "../../components/PageError";
import SortButton from "../../components/sortModule/SortButton";
import SortDropdown from "../../components/sortModule/SortDropdown/SortDropdown";
import RightTableSizer from "../../components/table/RightTableSizer/RightTableSizer";
import ContextMenuCell from "../../components/tableCells/tableCells/ContextMenuCell";
import { GST_REGION } from "../../constants/Constants";
import {
  GradeMsrpItem,
  ModelApplicabilityItem,
  RefItem,
  useCreateProductTypeMutation,
  usePublishAccessoryDraftMutation,
  useUpdateAapLastSyncDateMutation,
  useUpdateAclSyncMutation,
  useUpdateProductTypeMutation,
} from "../../gql/generated";
import useInactiveMapper from "../../hooks/useInactiveMapper";
import useSortedProductTypes from "../../hooks/useSortedProductTypes";
import AccessoryItemVM from "../../models/accessories/AccessoryItemVM";
import { RefItemSortInput } from "../../models/refItem/refItem.model";
import { SortType } from "../../models/sort.model";
import { Language } from "../../models/user/user.model";
import { VehicleTeam } from "../../models/vehicleData/vehicleData.model";
import { GradeItem } from "../../stores/modelStore";
import useStores from "../../stores/useStores";
import handleErrorResponse from "../../utils/errorHandlingUtils";
import { handleOnSortNumberUpdate } from "../../utils/sortBy";
import { getSortPayload } from "../../utils/sortUtils";
import { getVersionInfoFromParams } from "../../utils/vehicleDataUtils";
import AccessoriesEntryScreenService from "../accessories/AccessoriesEntryScreenService";
import styles from "./accessories.module.scss";
import AccessoriesTable from "./components/AccessoriesTable";
import ActionBarAccessories from "./components/GSTActionBarAccessories";
import TitleColumn from "./components/TitleColumn";
import useAccessoryFilter from "../../hooks/useAccessoryFilter";

interface RouteParams {
  seriesId: string;
  series: string;
  version: string;
  year: string;
}

export const CheckboxCell = ({
  id,
  checked,
  defaultChecked,
  onChange,
  disabled,
  children,
  className,
  onClick,
}: CheckboxProps) => (
  <TableCell colType="accApplicability" center className={className}>
    <Checkbox
      id={id}
      className={styles.checkboxCell}
      checked={checked}
      defaultChecked={defaultChecked}
      onChange={onChange}
      onClick={onClick}
      disabled={disabled}
    />
    {children}
  </TableCell>
);

const Accessories = () => {
  const params: RouteParams = useParams();
  const { inactiveMappers, inactiveMappersRef, setInactiveMappers } =
    useInactiveMapper();

  const { userStore, modelStore, tableSizeStore, teamStore } = useStores();
  const { debounce } = useDebounce({ delay: 2000 });

  const [createProductType] = useCreateProductTypeMutation();
  const [updateProductType] = useUpdateProductTypeMutation();
  const [updateAAPLastSyncDate] = useUpdateAapLastSyncDateMutation();
  const [updateAclSync] = useUpdateAclSyncMutation();

  const [toggleTitleEn, setToggleTitleEn] = useState(true);
  const [toggleTitleEs, setToggleTitleEs] = useState(true);
  const [toggleDescEn, setToggleDescEn] = useState(true);
  const [toggleDescEs, setToggleDescEs] = useState(false);

  const [toggleDiscEn, setToggleDiscEn] = useState(true);
  const [toggleDiscEs, setToggleDiscEs] = useState(false);

  const [toggleWarrEn, setToggleWarrEng] = useState(true);
  const [toggleWarrEs, setToggleWarrEs] = useState(false);

  const [canPublish, setCanPublish] = useState(false);
  // search and filters
  const [searchText, setSearchText] = useState("");
  const [inProgressFilter, setInProgressFilter] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState(false);

  // Sorting
  const [sortMode, setSortMode] = useState(false);

  const [tooltipHover, setTooltipHover] = useState(false);

  const [sortType, setSortType] = useState<SortType | undefined>(undefined);

  const ref = useRef<HTMLDivElement>(null);

  const teamLang = Language.EN;
  const vdVersionInfo = getVersionInfoFromParams(params.version);
  const version = (vdVersionInfo[teamLang] as string) || "DRAFT";

  const userDetails = {
    brand: userStore.brand,
    team: "AT",
    region: GST_REGION,
    lang: teamLang.toLowerCase(),
  };

  const draftDetails = {
    seriesId: params.seriesId,
    modelYear: Number(params.year),
    version,
  };

  const [publishRedirect, setPublishRedirect] = useState(false);
  const [showAapModal, setShowAapModal] = useState(false);
  const [showCommonLanguageModal, setShowCommonLanguageModal] = useState(false);

  const {
    allData,
    filteredData,
    productTypes,
    error,
    isLoaded,
    readOnly,
    setSort,
    filterItems,
    updateSort,
    forceUpdate,
    addEmptyItem,
    addCopiedItem,
    addAccessoryItem,
    addProductType,
    deleteItem,
    deleteAccessoryItem,
    updateAccessoryItem,
    filterItemsGST,
    sortByTitle,
    sortByNationalId,
    sortByNoneNationalId,
    sortBySortOrder,
    updateProductTypeSort,
    addNewAccessories,
    lastSyncDate,
    lastNATPublishedDate,
    updateLastSyncDate,
    setDefaultLanguage,
    commonLanguageUpdatedIds,
    cleanCommonLanguageUpdatedIds,
    addProductTypes,
  } = AccessoriesEntryScreenService({
    variables: { ...userDetails, ...draftDetails },
    vdVersionInfo,
  });

  const handleApplyFilters = (
    inProgressFilterParam: boolean,
    isActiveFilterParam: boolean
  ) => {
    setInProgressFilter(inProgressFilterParam);
    setIsActiveFilter(isActiveFilterParam);
    filterItemsGST(searchText, inProgressFilterParam, isActiveFilterParam);
  };

  const onApplyFilters = ({ inProgress = false, active = false }) => {
    handleApplyFilters(inProgress, active);
  };

  const { getFilterDisplay, modelItemList } = useAccessoryFilter(
    modelStore,
    isActiveFilter,
    inProgressFilter,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    true,
    true,
    onApplyFilters
  );

  const { sortedProductTypes, setIsUpdated } =
    useSortedProductTypes(productTypes);

  const [publishDraft] = usePublishAccessoryDraftMutation();

  const onResize = (width: number, height: number) => {
    tableSizeStore.tableRowHeight = height;
    tableSizeStore.mainWidth = width;
  };
  const handleSearchFilter = (query: string) => {
    setSearchText(query);
    filterItemsGST(query, inProgressFilter, isActiveFilter);
  };

  const saveAccessoryItem = async (item: AccessoryItemVM) => {
    forceUpdate();
    debounce(async () => {
      try {
        if (item.revId) {
          await trackPromise(updateAccessoryItem(item, Language.EN));
          toast.success("Successfully updated item");
          setInactiveMappers(
            inactiveMappersRef.current.filter((ele) => ele.id !== item.id)
          );
        } else {
          await trackPromise(addAccessoryItem(item));
          toast.success("Successfully added item");
        }
      } catch (e) {
        handleErrorResponse(e);
        if (item.id) {
          setInactiveMappers(
            inactiveMappersRef.current.filter((ele) => ele.id !== item.id)
          );
        }
      }
    }, item.uid);
  };

  const saveSyncAccessory = async (
    item: AccessoryItemVM,
    newProductType: string
  ) => {
    forceUpdate();
    const a = item;
    try {
      if (newProductType) {
        await trackPromise(
          createProductType({
            variables: {
              ...userDetails,
              ...draftDetails,
              name: newProductType,
            },
            update: (_cache, { data: newData }) => {
              addProductType(newData?.createRefItem.refItem as RefItem);
              if (newData) {
                a.productType = newData.createRefItem.refItem.id;
              }
            },
          })
        );
      }
      await trackPromise(updateAccessoryItem(a, Language.EN));
      toast.success("Successfully updated item");
      setInactiveMappers(
        inactiveMappersRef.current.filter((ele) => ele.id !== item.id)
      );
    } catch (e) {
      handleErrorResponse(e);
      if (item.id) {
        setInactiveMappers(
          inactiveMappersRef.current.filter((ele) => ele.id !== item.id)
        );
      }
    }
  };

  const addAAPAccessories = async (
    items: AccessoryItemVM[],
    nationalProductTypes: RefItem[]
  ) => {
    const nationalProdTypeMap: { [id: string]: RefItem } = {};
    nationalProductTypes.forEach((prodType) => {
      nationalProdTypeMap[prodType.id] = prodType;
    });
    forceUpdate();
    try {
      const newProductTypes: { [name: string]: AccessoryItemVM[] } = {};
      const newProductTypePromises: Promise<any>[] = [];
      const accessories = items;
      accessories.forEach((acc) => {
        acc.nationalId = acc.id;
        acc.uid = uuidv4();
        acc.id = uuidv4();
        acc.revId = "";

        const productTypeId = acc.productType;
        acc.productType = "";
        if (productTypeId) {
          const targetProductTypeName = nationalProdTypeMap[productTypeId].name;
          const foundProductType = productTypes.find(
            (type) =>
              type.name.toLowerCase() === targetProductTypeName.toLowerCase()
          );
          acc.productType = foundProductType?.id ?? "";
          if (!foundProductType) {
            if (!newProductTypes[targetProductTypeName]) {
              newProductTypes[targetProductTypeName] = [];
            }
            newProductTypes[targetProductTypeName].push(acc);
          }
        }
      });

      Object.keys(newProductTypes).forEach((name) => {
        newProductTypePromises.push(
          createProductType({
            variables: {
              ...userDetails,
              ...draftDetails,
              name,
            },
            update: (_cache, { data: newData }) => {
              addProductType(newData?.createRefItem.refItem as RefItem);
              if (newData) {
                newProductTypes[name].forEach((acc) => {
                  acc.productType = newData.createRefItem.refItem.id;
                });
              }
            },
          })
        );
      });

      await Promise.all(newProductTypePromises);
      await addAccessoryItem(accessories, true);

      addNewAccessories(items);
      toast.success("Successfully added items");
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const toggleAll = (item: AccessoryItemVM) => {
    return () => {
      const newModelApp: ModelApplicabilityItem[] = [];
      const filteredModels = modelItemList;

      modelStore.data.forEach((x) => {
        const foundModel = filteredModels.find(
          (filteredModel) => filteredModel.modelId === x.id
        );

        const isChecked =
          (item.modelApplicability &&
            item.modelApplicability.some(
              (maItem) => maItem.modelId === x.id
            )) ||
          false;

        if (foundModel || isChecked) {
          newModelApp.push({ modelId: x.id } as ModelApplicabilityItem);
        }
      });
      const noReassign = item;
      noReassign.modelApplicability = newModelApp;
      forceUpdate();
      saveAccessoryItem(item);
    };
  };

  const toggleChecked = (accessory: AccessoryItemVM, modelId: string) => {
    return (a: { currentTarget: { checked: any } }) => {
      let item = [...(accessory.modelApplicability || [])];
      if (a.currentTarget.checked) {
        item.push({
          modelId,
        } as ModelApplicabilityItem);
      } else {
        item = item.filter((x) => x.modelId !== modelId) || null;
      }
      const noParamReassign = accessory;
      noParamReassign.modelApplicability = item;
      saveAccessoryItem(accessory);
    };
  };

  const handleInActiveChange = (item: AccessoryItemVM) => {
    const acc = item;
    acc.inactive = !acc.inactive;
    setInactiveMappers([
      ...inactiveMappersRef.current.filter((ele) => ele.id !== item.id),
      { id: item.id, inactive: acc.inactive },
    ]);
    saveAccessoryItem(acc);
  };

  const handleOnNotesChange = (item: AccessoryItemVM) => {
    return (notes: string) => {
      if (notes !== item.notes) {
        const acc = item;
        acc.notes = notes;
        saveAccessoryItem(acc);
      }
    };
  };
  const handleOnExtraCostChange = (msrp: string, item: AccessoryItemVM) => {
    if (msrp !== item.msrp) {
      const acc = item;
      acc.msrp = msrp;
      saveAccessoryItem(acc);
    }
  };

  const handleDeleteItem = async (item: AccessoryItemVM) => {
    if (!item.revId) {
      deleteItem(item);
    } else {
      try {
        await trackPromise(deleteAccessoryItem(item));
        toast.success("Successfully deleted item");
      } catch (e) {
        handleErrorResponse(e);
      }
    }
  };

  const handleOnPublish = async () => {
    try {
      await trackPromise(
        publishDraft({
          variables: {
            ...userDetails,
            seriesId: params.seriesId || "",
            modelYear: Number(params.year),
          },
        })
      );
      toast.success("Successfully certified draft");
      setPublishRedirect(true);
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const handleAddProduct = async (name: string) => {
    try {
      await trackPromise(
        createProductType({
          variables: {
            ...userDetails,
            ...draftDetails,
            name,
          },
          update: (_cache, { data: newData }) => {
            addProductType(newData?.createRefItem.refItem as RefItem);
          },
        })
      );
      toast.success("Successfully added Product Type");
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const handleUpdateProductType = async (name: string, item: RefItem) => {
    try {
      await trackPromise(
        updateProductType({
          variables: {
            ...userDetails,
            ...draftDetails,
            id: item.id,
            revId: item.revId,
            name,
          },
          update: (_cache, { data: newData }) => {
            const pt = item;
            pt.name = name;
            pt.revId = newData?.updateRefItem.refItem.revId || "";
          },
        })
      );
      toast.success("Successfully updated Product Type");
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  const handleOnProductTypeSelect = (
    productType: RefItem,
    item: AccessoryItemVM
  ) => {
    if (productType.id !== item.productType) {
      const acc = item;
      acc.productType = productType.id;
      saveAccessoryItem(acc);
    }
  };

  const handleGradeIdExtraCostChange = (
    gradeMsrp: GradeMsrpItem[],
    item: AccessoryItemVM
  ) => {
    const acc = item;
    acc.gradeMsrp = gradeMsrp;
    saveAccessoryItem(acc);
  };

  const languageToggles = (
    one: boolean,
    setOne: (one: boolean) => void,
    two: boolean,
    setTwo: (two: boolean) => void
  ) => {
    const newVal = !one;
    setOne(newVal);
    if (newVal === false) {
      setTwo(true);
    }
  };

  // load vehicle models
  useEffect(() => {
    teamStore.setTeam(
      VehicleTeam.GST_ACC_TEAM,
      userStore.brand,
      userStore.langPermissions
    );
    setDefaultLanguage(teamStore.team.defaultLanguage);
    modelStore.reset();
    (async () => {
      try {
        await Promise.all([
          modelStore.fetchModels(params.seriesId, params.year, userStore.brand),
          modelStore.fetchFuelTypes(userStore.brand),
        ]);
        modelStore.filterFuelTypes();
        setCanPublish(modelStore.data.length > 0);
      } catch (e: any) {
        const errorMessage = e.response?.data?.message || "";
        if (errorMessage.indexOf("No vehicle data") === -1) {
          handleErrorResponse(e);
        }
      }
    })();
  }, []);

  const handleAddEmptyItem = () => {
    setToggleTitleEn(true);
    setToggleTitleEs(true);
    addEmptyItem();
  };

  const handleOpenAapModal = () => {
    setShowAapModal(!showAapModal);
  };

  const handleOpenCommonLanguageModal = () => {
    setShowCommonLanguageModal(!showCommonLanguageModal);
  };

  const onStopSorting = async () => {
    setSortMode(false);
    try {
      await updateSort(false, allData);
    } catch (e) {
      handleErrorResponse(e, "Error updating feature sort");
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }

    // we need to update allData instead of filteredData, so sorted doesn't get lost when filter updates
    const sourceIndex = allData.findIndex(
      (x) => x.uid === filteredData[result.source.index].uid
    );

    const destinationIdex = allData.findIndex(
      (x) => x.uid === filteredData[result.destination?.index ?? -1].uid
    );

    const [removed] = allData.splice(sourceIndex, 1);

    allData.splice(destinationIdex, 0, removed);
    allData.forEach((item: { sortOrder: number | string }, index: number) => {
      const acc = item;
      acc.sortOrder = index + 1;
    });

    filterItems("", [], false); // forces ui to re-render correctly and update filteredData
    // onDragEnd requires us to return at least something
    return allData;
  };

  const errorOnDuplicateAbbModelApplicability = () => {
    const map: { [abb: string]: { [modelCode: string]: true } } = {};
    const modelIdMap: { [modelId: string]: true } = {};
    let hasDuplicate = false;
    modelStore.modelItems.forEach((modelItem) => {
      modelIdMap[modelItem.modelId] = true;
    });
    filteredData.forEach((item) => {
      if (item.abb) {
        let parsedAbb: string;
        if (typeof JSON.parse(item.abb) === "object") {
          parsedAbb = JSON.parse(item.abb.toLowerCase()).text;
        } else {
          parsedAbb = item.abb;
        }
        if (!map[parsedAbb]) {
          map[parsedAbb] = {};
        }
        if (item.modelApplicability) {
          item.modelApplicability.forEach((model) => {
            if (modelIdMap[model.modelId]) {
              if (!map[parsedAbb][model.modelId]) {
                map[parsedAbb][model.modelId] = true;
              } else {
                hasDuplicate = true;
              }
            }
          });
        }
      }
    });
    return hasDuplicate;
  };

  const getInactiveStatus = (item: AccessoryItemVM) => {
    const inactiveMapper = inactiveMappers.find((ele) => ele.id === item.id);
    if (inactiveMapper) {
      return inactiveMapper.inactive;
    }
    return item.inactive || false;
  };

  const onNATUpdatesHover = (isOn: boolean) => {
    setTooltipHover(isOn);
  };

  const onClickNATUpdates = async () => {
    await trackPromise(
      updateAAPLastSyncDate({
        variables: {
          brand: userDetails.brand,
          team: userDetails.team,
          seriesId: draftDetails.seriesId,
          modelYear: draftDetails.modelYear,
        },
        update: (_cache, { data: newData }) => {
          if (newData) {
            const accessories: { [id: string]: string } = {};
            newData.updateAAPLastSync.accessories?.forEach((acc) => {
              accessories[acc.id] = acc.revId;
            });
            allData.forEach((acc) => {
              if (accessories[acc.id]) {
                acc.hasAAPSyncChanges = true;
                acc.revId = accessories[acc.id];
              }
            });

            updateLastSyncDate(newData.updateAAPLastSync.lastSyncDate);
          }
        },
      })
    );
  };

  const onSyncUpdates = async () => {
    await onClickNATUpdates();
    await trackPromise(
      updateAclSync({
        variables: {
          input: {
            ...userDetails,
            seriesId: draftDetails.seriesId,
            modelYear: draftDetails.modelYear,
          },
        },
        update: (_cache, { data: newData }) => {
          if (!newData) return;

          const updates =
            newData.updateACLSync.accessories?.reduce<
              Map<
                string,
                {
                  revId?: string;
                  comLangId?: string;
                  comLangVersion?: number;
                  hasComLangChanges?: boolean;
                }
              >
            >((acc, item) => {
              acc.set(item.id, {
                revId: item.revId ?? undefined,
                comLangId: item.comLangId ?? undefined,
                comLangVersion: item.comLangVersion ?? undefined,
                hasComLangChanges: item.hasComLangChanges ?? undefined,
              });
              return acc;
            }, new Map()) ?? new Map();

          allData.forEach((acc) => {
            const update = updates.get(acc.id);

            if (!update) return;

            acc.revId = update.revId;
            acc.comLangId = update.comLangId;
            acc.comLangVersion = update.comLangVersion;
            acc.hasComLangChanges = update.hasComLangChanges;
          });

          cleanCommonLanguageUpdatedIds();
        },
      })
    );
  };

  const handleCopyItem = async (item: AccessoryItemVM) => {
    if (item.id) {
      try {
        addCopiedItem(item);
        toast.success("Successfully Copied Item");
      } catch (e) {
        handleErrorResponse(e, "Item failed to copy");
      }
    }
  };

  if (publishRedirect) {
    return <Redirect to="/gst/dashboard" />;
  }

  if (!isLoaded) {
    return <Spinner />;
  }

  if (error) {
    return <PageError />;
  }

  const showCommonLanguage =
    teamStore.team.showCommonLanguage &&
    process.env.REACT_APP_COMMON_LANGUAGE === "true" &&
    !!commonLanguageUpdatedIds.length &&
    !readOnly &&
    !!teamStore.team.langPermissions?.[Language.EN]?.canEdit;

  const onSaveSort = async (
    sortList: RefItem[],
    subSortList: RefItemSortInput[]
  ) => {
    try {
      const copy = allData.slice();
      const sortedAccessory: AccessoryItemVM[] = [];

      if (sortType === SortType.PRODUCT_TYPE) {
        subSortList.forEach((item) => {
          const newAccessoryArray = copy.filter(
            (acc) => acc.productType === item.item.id
          );

          if (item.subSortTypes[0] === SortType.ALPHABETICAL) {
            sortedAccessory.push(...newAccessoryArray.sort(sortByTitle));
          } else if (item.subSortTypes[0] === SortType.GENUINE) {
            sortedAccessory.push(
              ...newAccessoryArray.sort(sortByNoneNationalId)
            );
          } else if (item.subSortTypes[0] === SortType.AAP) {
            sortedAccessory.push(...newAccessoryArray.sort(sortByNationalId));
          }
        });
      } else if (sortType === SortType.GENUINE_AAP) {
        subSortList.forEach((item) => {
          let newAccessoryArray: AccessoryItemVM[] = [];

          if (item.item.name === SortType.AAP) {
            newAccessoryArray = copy.filter((acc) => acc.isNonGenAccessory);
          } else if (item.item.name === SortType.GENUINE) {
            newAccessoryArray = copy.filter((acc) => !acc.isNonGenAccessory);
          }

          if (item.subSortTypes[0] === SortType.ALPHABETICAL) {
            sortedAccessory.push(...newAccessoryArray.sort(sortByTitle));
          } else {
            const miniList: AccessoryItemVM[] = [];
            item.subSortTypes.forEach((name) => {
              const productId = productTypes.find(
                (product) => product.name === name
              )?.id;

              miniList.push(
                ...newAccessoryArray
                  .filter((newItem) => newItem.productType === productId)
                  .sort(sortByTitle)
              );
            });

            sortedAccessory.push(...miniList);
          }
        });
      }

      const payload = getSortPayload(
        sortedAccessory.length ? sortedAccessory : copy
      );

      if (payload) {
        allData.forEach((item: AccessoryItemVM) => {
          Object.keys(payload.sortList).forEach((key) => {
            if (item.id === key) {
              const acc = item;
              acc.sortOrder = payload.sortList[item.id] + 1;
            }
          });
        });
        updateSort(false, allData.slice().sort(sortBySortOrder), true);

        if (sortType === SortType.PRODUCT_TYPE) {
          updateProductTypeSort(sortList);
        }
      }
    } catch (e) {
      handleErrorResponse(e, "Error updating category sort");
    }
  };

  return (
    <>
      <StickyContainer>
        <StickyHeaderContainer>
          <Header
            moduleTitle="GST Accessories"
            moduleSubTitle={
              version === "DRAFT" ? "Draft" : `Published V${version}`
            }
          />
          {params.year && params.series && params.seriesId && (
            <Wayfinding
              year={`${params.year} `}
              seriesName={params.series}
              renderBackButton={<BackButton to="/gst/dashboard" />}
            />
          )}

          <ActionBarAccessories
            readOnly={readOnly}
            changeLogLink={`/vehicleData/gst/changelog/${params.seriesId}/${params.year}/${params.series}/${draftDetails.version}?exitVersion=${params.version}`}
            onAdd={handleAddEmptyItem}
            onOpen={handleOpenAapModal}
            onOpenCommonLanguage={handleOpenCommonLanguageModal}
            onSearch={handleSearchFilter}
            onPublish={handleOnPublish}
            errorOnDuplicateAbbModelApplicability={
              errorOnDuplicateAbbModelApplicability
            }
            canPublish={canPublish}
            renderFilter={(onClose) => getFilterDisplay(onClose)}
            renderButtons={
              <>
                {sortMode && !readOnly && (
                  <SortButton toggled onClick={onStopSorting}>
                    Stop Sorting
                  </SortButton>
                )}
                {!sortMode && !readOnly && (
                  <SortDropdown
                    buttonText="Sort"
                    list={[
                      SortType.ROWS,
                      SortType.PRODUCT_TYPE,
                      SortType.ALPHABETICAL,
                      SortType.GENUINE_AAP,
                    ]}
                    onSelect={(value) => {
                      switch (value) {
                        case SortType.ROWS: {
                          setSortMode(true);
                          break;
                        }
                        case SortType.ALPHABETICAL: {
                          updateSort(true, allData.slice().sort(sortByTitle));
                          filterItemsGST("", false, false);
                          break;
                        }
                        case SortType.PRODUCT_TYPE: {
                          setSortType(SortType.PRODUCT_TYPE);
                          break;
                        }
                        case SortType.GENUINE_AAP: {
                          setSortType(SortType.GENUINE_AAP);
                          break;
                        }
                        default:
                          break;
                      }
                    }}
                    sortType={sortType}
                    setSortType={setSortType}
                    itemList={productTypes}
                    onSave={onSaveSort}
                    sortSelectClassName={styles.sortSelectClassName}
                  />
                )}

                {!showCommonLanguage && (
                  <Button
                    variant="primary"
                    onMouseOver={() => {
                      onNATUpdatesHover(true);
                    }}
                    onMouseOut={() => {
                      onNATUpdatesHover(false);
                    }}
                    onClick={() => (!readOnly ? onClickNATUpdates() : () => {})}
                  >
                    NAT updates
                    {tooltipHover && (
                      <NATUpdatesPopover
                        lastSyncDate={lastSyncDate}
                        lastNATPublishedDate={lastNATPublishedDate}
                      />
                    )}
                  </Button>
                )}

                {showCommonLanguage && (
                  <Button variant="primary" onClick={onSyncUpdates}>
                    Sync Updates
                  </Button>
                )}
              </>
            }
            sortMode={sortMode}
          />
        </StickyHeaderContainer>

        <Modal
          size="auto"
          open={showAapModal}
          onClose={() => setShowAapModal(false)}
          className={styles.appModal}
        >
          <AapModal
            variables={{ ...userDetails, ...draftDetails }}
            close={() => setShowAapModal(false)}
            addAAPAccessories={addAAPAccessories}
            accessories={allData}
          />
        </Modal>

        <Modal
          size="auto"
          open={showCommonLanguageModal}
          onClose={() => setShowCommonLanguageModal(false)}
        >
          <CommonLanguageModal
            close={() => setShowCommonLanguageModal(false)}
            addNewAccessories={addNewAccessories}
            accessories={allData}
            addNewProductTypes={addProductTypes}
          />
        </Modal>

        <TwoTableWrapper className={styles.threeTable}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Table className={styles.stickySection}>
              <Thead className={styles.removeBorderRight}>
                <HeaderRow>
                  {!readOnly && <LeftTableTH />}
                  {sortMode && (
                    <LeftTableTH
                      className={cx(
                        inputStyles.smallInputHeader,
                        sortMode && styles.sortOrderWidth
                      )}
                    >
                      #
                    </LeftTableTH>
                  )}
                  <LeftTableTH
                    className={styles.abbTCWidth}
                    onClick={() => setSort("abb")}
                  >
                    ABB
                  </LeftTableTH>
                  <LeftTableTH
                    className={styles.supplierTCWidth}
                    onClick={() => setSort("supplier")}
                  >
                    Supplier
                  </LeftTableTH>
                  <LeftTableTH
                    className={cx(
                      styles.nameTCWidth,
                      (!toggleTitleEs || !toggleTitleEn) &&
                        styles.collapseToggleWidth
                    )}
                  >
                    <div
                      onClick={() => setSort("title")}
                      className={cx(
                        styles.headerLabel,
                        styles.nameHeader,
                        styles.clickable
                      )}
                      role="columnheader"
                      tabIndex={0}
                      onKeyDown={() => setSort("title")}
                    >
                      Name
                    </div>
                    <Checkbox
                      id="title-en"
                      className={styles.descCheckbox}
                      checked={toggleTitleEn}
                      onChange={() => {
                        const newEn = !toggleTitleEn;
                        setToggleTitleEn(newEn);
                        if (!newEn && !toggleTitleEs) {
                          setToggleTitleEs(true);
                        }
                      }}
                    >
                      <span className={styles.checkboxLabel}>English</span>
                    </Checkbox>
                    <Checkbox
                      id="title-es"
                      className={styles.descCheckbox}
                      checked={toggleTitleEs}
                      onChange={() => {
                        const newEs = !toggleTitleEs;
                        setToggleTitleEs(newEs);
                        if (!newEs && !toggleTitleEn) {
                          setToggleTitleEn(true);
                        }
                      }}
                    >
                      <span className={styles.checkboxLabel}>Spanish</span>
                    </Checkbox>
                  </LeftTableTH>
                </HeaderRow>
              </Thead>
              <Droppable
                droppableId="accessoriesDroppable"
                isDropDisabled={!sortMode}
              >
                {(provided) => (
                  <tbody
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={styles.positionAbsolute}
                  >
                    {filteredData.length === 0 && (
                      <TableRow className={styles.noTable}>
                        {!readOnly && (
                          <td>
                            <div />
                          </td>
                        )}
                        <td>
                          <div className={styles.noDataCells} />
                        </td>
                        <td>
                          <div className={styles.noDataCells} />
                        </td>
                        <td>
                          <div className={styles.noDataCells}>No Data</div>
                        </td>
                      </TableRow>
                    )}
                    {filteredData.map((item: AccessoryItemVM, index) => (
                      <Draggable
                        key={item.uid}
                        draggableId={item.uid}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <TableRow
                            className={styles.headerRow}
                            key={item.uid}
                            innerRef={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                          >
                            {!readOnly && !sortMode && (
                              <ContextMenuCell
                                hide={!!item.nationalId}
                                description={item.title}
                                deleteItem={() => handleDeleteItem(item)}
                                copyItem={() => handleCopyItem(item)}
                                itemType="Product Type"
                              />
                            )}
                            {!readOnly && sortMode && (
                              <>
                                <TableCell
                                  {...draggableProvided.dragHandleProps}
                                  border
                                  center
                                >
                                  <TableDragIcon />
                                </TableCell>
                                <TableCell border center>
                                  <input
                                    className={cx(
                                      inputStyles.input,
                                      inputStyles.smallInput
                                    )}
                                    value={item.sortOrder}
                                    onBlur={(newIndex) => {
                                      handleOnSortNumberUpdate(
                                        allData,
                                        newIndex.target.value,
                                        index,
                                        filterItems
                                      );
                                    }}
                                    onChange={(e) => {
                                      const tempItem = item;
                                      const newValue =
                                        parseInt(e.currentTarget.value, 10) > 0
                                          ? parseInt(e.currentTarget.value, 10)
                                          : "";
                                      tempItem.sortOrder = newValue;

                                      filterItems("", [], false);
                                    }}
                                  />
                                </TableCell>
                              </>
                            )}
                            <TitleColumn
                              accessories={allData}
                              accessoryItem={item}
                              index={index}
                              productTypes={productTypes}
                              readOnly={readOnly}
                              saveAccessoryItem={saveAccessoryItem}
                              saveSyncAccessory={saveSyncAccessory}
                              sortMode={sortMode}
                            />
                            <TableCell large className={styles.stickySection}>
                              <AccessoryRichText
                                tabIndex={index + 1}
                                value={item.supplier || ""}
                                disabled={readOnly}
                                onBlur={(supplier) => {
                                  if (
                                    areRichTextValuesDifferent(
                                      item.supplier || "",
                                      supplier
                                    )
                                  ) {
                                    const acc = item;
                                    acc.supplier = cleanUpRte(supplier);
                                    saveAccessoryItem(acc);
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell large>
                              <div className={styles.langBoxes}>
                                {toggleTitleEn && (
                                  <AccessoryRichText
                                    tabIndex={index + 1}
                                    value={item.title || ""}
                                    disabled={readOnly}
                                    required={!item.title}
                                    onBlur={(title) => {
                                      if (
                                        areRichTextValuesDifferent(
                                          item.title || "",
                                          title
                                        )
                                      ) {
                                        const acc = item;
                                        acc.title = cleanUpRte(title);
                                        saveAccessoryItem(acc);
                                      }
                                    }}
                                  />
                                )}
                                {toggleTitleEs && (
                                  <AccessoryRichText
                                    tabIndex={index + 1}
                                    value={item.title_es || ""}
                                    required={!item.title_es}
                                    disabled={readOnly}
                                    onBlur={(title_es) => {
                                      if (
                                        areRichTextValuesDifferent(
                                          item.title_es || "",
                                          title_es
                                        )
                                      ) {
                                        const acc = item;
                                        acc.title_es = cleanUpRte(title_es);
                                        saveAccessoryItem(acc);
                                      }
                                    }}
                                  />
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                  </tbody>
                )}
              </Droppable>
            </Table>

            <LeftTable>
              <AccessoriesTable
                setSort={setSort}
                toggleDescEs={toggleDescEs}
                toggleDiscEs={toggleDiscEs}
                toggleDescEn={toggleDescEn}
                toggleDiscEn={toggleDiscEn}
                toggleWarrEn={toggleWarrEn}
                toggleWarrEs={toggleWarrEs}
                toggleDescLangES={() => {
                  languageToggles(
                    toggleDescEs,
                    setToggleDescEs,
                    toggleDescEn,
                    setToggleDescEn
                  );
                }}
                toggleDiscLangES={() => {
                  languageToggles(
                    toggleDiscEs,
                    setToggleDiscEs,
                    toggleDiscEn,
                    setToggleDiscEn
                  );
                }}
                toggleWarrLangES={() => {
                  languageToggles(
                    toggleWarrEs,
                    setToggleWarrEs,
                    toggleWarrEn,
                    setToggleWarrEng
                  );
                }}
                toggleDescLangEN={() => {
                  languageToggles(
                    toggleDescEn,
                    setToggleDescEn,
                    toggleDescEs,
                    setToggleDescEs
                  );
                }}
                toggleDiscLangEN={() => {
                  languageToggles(
                    toggleDiscEn,
                    setToggleDiscEn,
                    toggleDiscEs,
                    setToggleDiscEs
                  );
                }}
                toggleWarrLangEN={() => {
                  languageToggles(
                    toggleWarrEn,
                    setToggleWarrEng,
                    toggleWarrEs,
                    setToggleWarrEs
                  );
                }}
              >
                {filteredData.length === 0 && (
                  <>
                    <TableRow className={styles.noTable}>
                      <td>
                        <div className={styles.noDataCells}> </div>
                      </td>
                      <td>
                        <div className={styles.noDataCells}> </div>
                      </td>
                      <td>
                        <div className={styles.noDataCells}> </div>
                      </td>
                    </TableRow>
                  </>
                )}

                {filteredData.map((item: AccessoryItemVM, index: number) => (
                  <TableRow key={item.uid} className={styles.removeBorderLeft}>
                    <DropdownEditorCell
                      tabIndex={index + 1}
                      className={styles.productTypeColumn}
                      disabled={readOnly}
                      addBtnText="Product Type"
                      onAdd={handleAddProduct}
                      value={(() => {
                        const found = productTypes.find(
                          (pt) => pt.id === item.productType
                        );
                        return found?.name || "";
                      })()}
                      renderList={(onClose: () => void) => (
                        <>
                          {sortedProductTypes?.map((productType: RefItem) => (
                            <DropdownEditItem
                              key={productType.id}
                              value={productType.name}
                              isSelected={item.productType === productType.id}
                              onEdit={(_oldValue: string, newValue: string) => {
                                if (productType.name !== newValue) {
                                  handleUpdateProductType(
                                    newValue,
                                    productType
                                  );
                                  setIsUpdated(true);
                                }
                              }}
                              onClose={() => onClose()}
                              onSelect={() => {
                                handleOnProductTypeSelect(productType, item);
                                onClose();
                              }}
                            />
                          ))}
                        </>
                      )}
                    />

                    <TableCell large>
                      <div className={styles.langBoxes}>
                        {toggleDescEn && (
                          <AccessoryRichText
                            tabIndex={index + 1}
                            value={item.description || ""}
                            disabled={readOnly}
                            onBlur={(description) => {
                              if (
                                areRichTextValuesDifferent(
                                  item.description || "",
                                  description
                                )
                              ) {
                                const acc = item;
                                acc.description = cleanUpRte(description);
                                saveAccessoryItem(acc);
                              }
                            }}
                          />
                        )}

                        {toggleDescEs && (
                          <AccessoryRichText
                            tabIndex={index + 1}
                            value={item.description_es || ""}
                            disabled={readOnly}
                            onBlur={(description_es) => {
                              if (
                                areRichTextValuesDifferent(
                                  item.description_es || "",
                                  description_es
                                )
                              ) {
                                const acc = item;
                                acc.description_es = cleanUpRte(description_es);
                                saveAccessoryItem(acc);
                              }
                            }}
                          />
                        )}
                      </div>
                    </TableCell>

                    <TableCell large>
                      <div className={styles.langBoxes}>
                        {toggleDiscEn && (
                          <AccessoryRichText
                            tabIndex={index + 1}
                            value={item.disclaimer || ""}
                            disabled={readOnly}
                            onBlur={(disclaimer) => {
                              if (
                                areRichTextValuesDifferent(
                                  item.disclaimer || "",
                                  disclaimer
                                )
                              ) {
                                const acc = item;
                                acc.disclaimer = cleanUpRte(disclaimer);
                                saveAccessoryItem(acc);
                              }
                            }}
                          />
                        )}
                        {toggleDiscEs && (
                          <AccessoryRichText
                            tabIndex={index + 1}
                            value={item.disclaimer_es || ""}
                            disabled={readOnly}
                            onBlur={(disclaimer_es) => {
                              if (
                                areRichTextValuesDifferent(
                                  item.disclaimer_es || "",
                                  disclaimer_es
                                )
                              ) {
                                const acc = item;
                                acc.disclaimer_es = cleanUpRte(disclaimer_es);
                                saveAccessoryItem(acc);
                              }
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell large>
                      <div className={styles.langBoxes}>
                        {toggleWarrEn && (
                          <AccessoryRichText
                            tabIndex={index + 1}
                            value={item.warranty || ""}
                            disabled={readOnly}
                            onBlur={(warranty) => {
                              if (
                                areRichTextValuesDifferent(
                                  item.warranty || "",
                                  warranty
                                )
                              ) {
                                const acc = item;
                                acc.warranty = cleanUpRte(warranty);
                                saveAccessoryItem(acc);
                              }
                            }}
                          />
                        )}
                        {toggleWarrEs && (
                          <AccessoryRichText
                            tabIndex={index + 1}
                            value={item.warranty_es || ""}
                            disabled={readOnly}
                            onBlur={(warranty_es) => {
                              if (
                                areRichTextValuesDifferent(
                                  item.warranty_es || "",
                                  warranty_es
                                )
                              ) {
                                const acc = item;
                                acc.warranty_es = cleanUpRte(warranty_es);
                                saveAccessoryItem(acc);
                              }
                            }}
                          />
                        )}
                      </div>
                    </TableCell>
                    <TableCell large>
                      <Input
                        id="accessory-required-input"
                        label="Required"
                        disabled={readOnly}
                        defaultValue={item.required || ""}
                        onChange={(event) => {
                          const val = event.target.value;
                          if (
                            areRichTextValuesDifferent(item.required || "", val)
                          ) {
                            const acc = item;
                            acc.required = val;
                            saveAccessoryItem(acc);
                          }
                        }}
                      />
                      <div className={styles.separator} />
                      <Input
                        id="accessory-conflicts-input"
                        label="Conflicts"
                        disabled={readOnly}
                        defaultValue={item.conflicts || ""}
                        onChange={(event) => {
                          const val = event.target.value;
                          if (
                            areRichTextValuesDifferent(
                              item.conflicts || "",
                              val
                            )
                          ) {
                            const acc = item;
                            acc.conflicts = val;
                            saveAccessoryItem(acc);
                          }
                        }}
                      />
                    </TableCell>

                    {/* Part Number */}
                    <TableCell large>
                      <AccessoryRichText
                        tabIndex={index + 1}
                        value={item.partNumber || ""}
                        disabled={readOnly}
                        onBlur={(partNumber) => {
                          if (
                            areRichTextValuesDifferent(
                              item.partNumber || "",
                              partNumber
                            )
                          ) {
                            const acc = item;
                            acc.partNumber = cleanUpRte(partNumber);
                            saveAccessoryItem(acc);
                          }
                        }}
                      />
                    </TableCell>

                    <MsrpFlagsCell
                      align="right"
                      msrpPopover
                      hideAddTrimMsrp={false}
                      disabled={readOnly}
                      index={index}
                      notes={item.notes || ""}
                      modelGrades={modelStore.gradesData}
                      msrp={item.msrp || ""}
                      gradeMsrp={item.gradeMsrp || []}
                      highlighted={false}
                      inProgress={getInactiveStatus(item)}
                      inProgressText="Inactive"
                      toggleInProgress={() => handleInActiveChange(item)}
                      onNotesChange={handleOnNotesChange(item)}
                      onExtraCostChange={(msrp: string) => {
                        handleOnExtraCostChange(msrp, item);
                      }}
                      onGradeIdExtraCostChange={(
                        gradeMsrp: GradeMsrpItem[]
                      ) => {
                        handleGradeIdExtraCostChange(gradeMsrp, item);
                      }}
                    />
                  </TableRow>
                ))}
              </AccessoriesTable>
            </LeftTable>
          </DragDropContext>
          <ScrollSync>
            <RightTableSizer>
              <StickyHeaderSection>
                <ScrollSyncPane group="horizontal">
                  <div ref={null} style={{ overflowX: "auto" }}>
                    <Table>
                      <Thead>
                        <tr>
                          <>
                            <AccessoriesTableHeaderRow>
                              {modelItemList.map((model) => (
                                <Fragment key={model.modelId}>
                                  <AccessoriesTableTrimHeaderName
                                    className={
                                      styles.modelApplicabilityGradeHeader
                                    }
                                  >
                                    {model.grade}
                                  </AccessoriesTableTrimHeaderName>
                                </Fragment>
                              ))}
                            </AccessoriesTableHeaderRow>
                            <AccessoriesTableHeaderRow>
                              {modelItemList.map((model) => (
                                <Fragment key={model.modelId}>
                                  <AccessoriesTableHeaderDetail
                                    className={styles.modelApplicabilityHeader}
                                    key={model.code}
                                    modelCode={Number(model.code)}
                                  >
                                    <div>{model.code}</div>
                                  </AccessoriesTableHeaderDetail>
                                </Fragment>
                              ))}
                            </AccessoriesTableHeaderRow>
                          </>
                        </tr>
                      </Thead>
                    </Table>
                  </div>
                </ScrollSyncPane>
                <ResizeDetector
                  handleHeight
                  onResize={(width: number, height: number) => {
                    onResize(width, height);
                  }}
                  targetDomEl={
                    ref === null ? undefined : (ref.current as HTMLElement)
                  }
                />
              </StickyHeaderSection>
              <ScrollSyncPane group="horizontal">
                <div style={{ overflowX: "scroll" }}>
                  <Table>
                    <tbody>
                      {filteredData.length === 0 && (
                        <>
                          <TableRow className={styles.noTable}>
                            <React.Fragment key={0}>
                              {modelStore.gradesData.map(
                                (gradeItem: GradeItem) => (
                                  <React.Fragment key={gradeItem.value}>
                                    {gradeItem.modelItems.map(() => (
                                      <td>
                                        <div className={styles.noDataCells} />
                                      </td>
                                    ))}
                                  </React.Fragment>
                                )
                              )}
                            </React.Fragment>
                          </TableRow>
                        </>
                      )}
                      {filteredData.map((item) => (
                        <TableRow key={item.uid} className={styles.bodyRow}>
                          {modelItemList.map((model, i2) => {
                            const key = i2 + 1;
                            return (
                              <React.Fragment key={model.code}>
                                <CheckboxCell
                                  key={`key${model.code}${item.id}${key}`}
                                  id={`chboxGrd${model.code}${item.id}${key}`}
                                  disabled={readOnly}
                                  checked={
                                    item.modelApplicability?.some(
                                      (maItem) =>
                                        maItem.modelId === model.modelId
                                    ) ?? false
                                  }
                                  onChange={toggleChecked(item, model.modelId)}
                                  className={styles.relative}
                                  data-model={model.modelId}
                                  data-item={item.id}
                                >
                                  {i2 === 0 && !readOnly && (
                                    <div className={styles.wrapper}>
                                      <div
                                        className={styles.applyAllBtn}
                                        role="button"
                                        tabIndex={0}
                                        onKeyPress={toggleAll(item)}
                                        onClick={toggleAll(item)}
                                      >
                                        Apply all
                                      </div>
                                    </div>
                                  )}
                                </CheckboxCell>
                              </React.Fragment>
                            );
                          })}
                        </TableRow>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </ScrollSyncPane>
            </RightTableSizer>
          </ScrollSync>
        </TwoTableWrapper>
      </StickyContainer>
    </>
  );
};

export default withRouter(observer(Accessories));
