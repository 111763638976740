import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { ConfirmModal, Modal } from "vapi-ui-common";
import {
  SyncAllChangesActions,
  SyncAllChangesContext,
} from "../../../../components/SyncAllChangesModal";
import { ModelItem } from "../../../../stores/modelStore";
import useStores from "../../../../stores/useStores";
import handleErrorResponse from "../../../../utils/errorHandlingUtils";
import AccessoriesEntryScreenContext from "../AccessoriesEntryScreenTable/AccessoriesEntryScreenContext";
import EditGoLiveDateModal from "./EditGoLiveDateModal";
import styles from "./GoLiveDate.module.scss";
import GoLiveDateText from "./GoLiveDateText";

interface Props {
  readOnly?: boolean;
  all?: boolean;
  model?: ModelItem;
  hideAdminDate?: boolean;
}

const useGoLiveDate = ({ readOnly, all, model }: Props) => {
  const { dispatch } = useContext(SyncAllChangesContext);

  const {
    teamStore: {
      team: { allowEditGoLiveDate: canEditGoLiveDate = false },
    },
  } = useStores();

  const { updateGoLiveDates, goLiveDateLangEnMapData, modelItemList } =
    useContext(AccessoriesEntryScreenContext);

  const allowEditGoLiveDate =
    process.env.REACT_APP_GOLIVE === "true" && canEditGoLiveDate;
  const canClickGoLiveDate = !readOnly && allowEditGoLiveDate;

  const [editGoLiveDateModalOpen, setEditGoLiveDateModalOpen] =
    useState<boolean>(false);
  const [confirmEditModal, setConfirmEditModal] = useState<boolean>(false);

  const currentGoLiveDate = model && goLiveDateLangEnMapData[model.modelId];
  const goLiveDate = currentGoLiveDate?.goLiveDate?.length
    ? new Date(currentGoLiveDate.goLiveDate)
    : null;

  const onCloseEditModal = useCallback(
    () => setEditGoLiveDateModalOpen(false),
    [setEditGoLiveDateModalOpen]
  );

  const onSubmit = useCallback(
    async (gLiveDate: string) => {
      try {
        const payload = [];

        if (!all) {
          if (!model) {
            throw new Error(
              "Error updating Go live date - model is not defined"
            );
          }

          const cGoLiveDate = goLiveDateLangEnMapData[model.modelId] ?? {
            id: model.modelId,
          };

          payload.push({ ...cGoLiveDate, goLiveDate: gLiveDate });
        } else {
          modelItemList.forEach((modelItem) => {
            const cGoLiveDate = goLiveDateLangEnMapData[modelItem.modelId] ?? {
              id: modelItem.modelId,
            };

            payload.push({ ...cGoLiveDate, goLiveDate: gLiveDate });
          });
        }

        onCloseEditModal();
        await updateGoLiveDates(payload);

        toast.success("Go live date updated successfully");
      } catch (error) {
        handleErrorResponse(error, "Error updating Go live date");
      }
    },
    [all, model, onCloseEditModal]
  );

  const isGoLiveDateInPast = useMemo(() => {
    if (goLiveDate != null) {
      const now = new Date();
      now.setHours(0, 0, 0, 0);

      if (goLiveDate < now) {
        return true;
      }
    }

    return false;
  }, [goLiveDate]);

  const goLiveDateClass = useMemo((): string => {
    if (!canClickGoLiveDate) {
      return isGoLiveDateInPast
        ? styles.publishedPastGoLiveDate
        : styles.publishedGoLiveDate;
    }
    return styles.goLiveDate;
  }, [isGoLiveDateInPast, canClickGoLiveDate]);

  const onClick = useCallback(() => {
    if (!canClickGoLiveDate) return;

    if (!isGoLiveDateInPast) {
      setEditGoLiveDateModalOpen(true);
      dispatch({
        type: SyncAllChangesActions.SET_MODEL_REVIEWED,
        payload: {
          modelId: model?.modelId,
        },
      });
    } else {
      setConfirmEditModal(true);
    }
  }, [
    canClickGoLiveDate,
    isGoLiveDateInPast,
    setEditGoLiveDateModalOpen,
    setConfirmEditModal,
  ]);

  const onConfirm = useCallback(() => {
    setEditGoLiveDateModalOpen(true);
    dispatch({
      type: SyncAllChangesActions.SET_MODEL_REVIEWED,
      payload: {
        modelId: model?.modelId ?? "",
      },
    });
  }, [setEditGoLiveDateModalOpen]);

  const onCloseConfirmModal = useCallback(
    () => setConfirmEditModal(false),
    [setConfirmEditModal]
  );

  return {
    editGoLiveDateModalOpen,
    confirmEditModal,
    onCloseEditModal,
    onSubmit,
    onCloseConfirmModal,
    onConfirm,
    onClick,
    goLiveDateClass,
    goLiveDate,
    allowEditGoLiveDate,
    canClickGoLiveDate,
    currentGoLiveDate,
  };
};

const GoLiveDate = ({ readOnly, model, all, hideAdminDate }: Props) => {
  const {
    editGoLiveDateModalOpen,
    confirmEditModal,
    onCloseEditModal,
    onSubmit,
    onCloseConfirmModal,
    onConfirm,
    onClick,
    goLiveDateClass,
    goLiveDate,
    allowEditGoLiveDate,
    canClickGoLiveDate,
    currentGoLiveDate,
  } = useGoLiveDate({ readOnly, model, all });
  const { teamStore } = useStores();

  const timePicker =
    process.env.REACT_APP_GO_LIVE_TIME === "true" &&
    teamStore.team.allowEditGoLiveDateTime;

  if (!allowEditGoLiveDate || (readOnly && all)) {
    return null;
  }

  return (
    <div className={styles.goLiveDateCol}>
      <button
        type="button"
        onClick={onClick}
        className={cx(styles.goLiveDateBtn, goLiveDateClass, {
          [styles.goLiveDateBtnAll]: all,
        })}
        data-testid="set-go-live-date-cta"
      >
        <GoLiveDateText
          all={all}
          canClickGoLiveDate={canClickGoLiveDate}
          goLiveDate={goLiveDate}
          currentGoLiveDate={currentGoLiveDate}
          model={model}
        />
        {all && (
          <span className={cx([styles.icon])}>
            <FontAwesomeIcon
              className={cx(styles.iconChevronDown)}
              icon={faChevronDown}
            />
          </span>
        )}
      </button>

      <Modal
        open={editGoLiveDateModalOpen}
        onClose={onCloseEditModal}
        className={styles.goLiveDateModal}
      >
        <EditGoLiveDateModal
          close={onCloseEditModal}
          onSubmit={onSubmit}
          currentGoLiveDate={goLiveDate}
          goLiveDateAdmin={model?.goLiveDate || ""}
          all={all}
          timePicker={timePicker}
          hideAdminDate={hideAdminDate}
        />
      </Modal>

      <ConfirmModal
        headerText="Edit Live Date"
        bodyText="This model is already published and live, are you sure you want to change the date?"
        confirmButtonText="Confirm"
        open={confirmEditModal}
        onClose={onCloseConfirmModal}
        onConfirm={onConfirm}
      />
    </div>
  );
};

export default observer(GoLiveDate);
