import React from "react";
import {
  DateComponent,
  IconTextButton,
  TableCell,
  TableRow,
} from "vapi-ui-common";
import { ReportItem } from "../../models/reports/reports.model";
import styles from "./reportsTable.module.scss";

interface ReportRowProps {
  report: ReportItem;
  onDownload: (item: ReportItem) => void;
}

const ReportRow = ({ report, onDownload }: ReportRowProps) => {
  return (
    <TableRow>
      <TableCell>
        <IconTextButton
          icon="download"
          text="Download Ready"
          className={styles.downloadCta}
          onClick={() => onDownload(report)}
        />
      </TableCell>
      <TableCell>{report.reportType}</TableCell>
      <TableCell>{report.series}</TableCell>
      <TableCell>{report.modelYear}</TableCell>
      <TableCell>{report.version}</TableCell>
      <TableCell>
        <DateComponent format="MM/DD/YYYY hh:mm:ss A" className={styles.date}>
          {report.generatedDate}
        </DateComponent>
      </TableCell>
      <TableCell>{report.generatedBy}</TableCell>
    </TableRow>
  );
};

export default ReportRow;
