import React from "react";
import {
  Spinner,
  StickyContainer,
  StickyHeaderContainer,
} from "vapi-ui-common";
import { observer } from "mobx-react-lite";
import useStores from "../../stores/useStores";
import ActionBarCommonLanguage from "./components/ActionBarCommonLanguage/ActionBarCommonLanguage";
import DraftHeader from "./components/Header/DraftHeader";
import { CLTable, GstTable } from "./components/CommonLanguageTable";
import { useCreateCommonLanguage, useGetDraftCLData } from "./hooks";
import useActionBarCLProps from "./hooks/useActionBarCLProps";
import { AclDocument, useResetAclDraftMutation } from "../../gql/generated";
import {
  ACCESSORY_TEAM,
  DRAFT_CL,
  GST_REGION,
  NATIONAL_REGION,
} from "../../constants/Constants";
import { AccessoryCommonLanguageItem } from "../../models/commonLanguage.model";
import handleErrorResponse from "../../utils/errorHandlingUtils";

const Draft = () => {
  const { userStore, commonLanguageStore } = useStores();
  const readonly = !userStore.langPermissions.EN?.canEdit;
  const isGst = userStore.isGstUser();
  useGetDraftCLData();
  const createCommonLanguage = useCreateCommonLanguage();
  const actionBarProps = useActionBarCLProps();

  const [resetDraft] = useResetAclDraftMutation({
    variables: {
      input: {
        brand: userStore.brand,
        region: userStore.isGstUser() ? GST_REGION : NATIONAL_REGION,
        team: ACCESSORY_TEAM,
      },
    },
  });

  const handleResetDraft = async () => {
    try {
      const response = await resetDraft();

      if (response.errors?.length) {
        throw new Error(response.errors[0]?.message || "");
      }

      if (!response.data?.resetACLDraft) {
        throw new Error("No data returned");
      }

      const accCLItems = response.data.resetACLDraft.map((item) => {
        return AccessoryCommonLanguageItem.fromACLDocument(item as AclDocument);
      });

      commonLanguageStore.setData(
        accCLItems,
        commonLanguageStore.productTypes,
        DRAFT_CL
      );
    } catch (error) {
      handleErrorResponse(
        error,
        `Error adding common language item: ${(error as Error).message}`
      );
    }
  };

  if (commonLanguageStore.isFetching) {
    return <Spinner />;
  }

  return (
    <StickyContainer>
      <StickyHeaderContainer>
        <DraftHeader />
        <ActionBarCommonLanguage
          onCreateCommonLanguage={createCommonLanguage}
          onResetDraft={handleResetDraft}
          {...actionBarProps}
        />
      </StickyHeaderContainer>
      {isGst ? (
        <GstTable readonly={readonly} />
      ) : (
        <CLTable readonly={readonly} />
      )}
    </StickyContainer>
  );
};

export default observer(Draft);
