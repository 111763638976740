import React from "react";
import ChangeLogRichTextDiff from "../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogRichTextDiff";
import ChangeLogApplicability from "../../../../components/ChangeLogTable/ChangeLogChanges/components/ChangeLogApplicability";
import ChangeLogBeforeAfter from "../../../../components/ChangeLogTable/ChangeLogChanges/components/ChangeLogBeforeAfter";
import ChangeLogCommonLang from "../../../../components/ChangeLogTable/ChangeLogChanges/components/ChangeLogCommonLang";
import ChangeLogGradeMsrp from "../../../../components/ChangeLogTable/ChangeLogChanges/components/ChangelogGradeMsrp";
import { Change, RefItem } from "../../../../gql/generated";
import { GradeItem } from "../../../../stores/modelStore";

export enum AccessoryGSTChangeType {
  ACCESSORY_ADDED = "Accessory Added",
  ACCESSORY_DELETED = "Accessory Deleted",
  ABB = "ABB",
  SUPPLIER = "Supplier",
  TITLE = "Title",
  TITLE_ES = "Title es",
  PRODUCT_TYPE = "Product Type",
  PRODUCT_TYPE_CHANGED = "Product Type Changed",
  DESCRIPTION = "Description",
  DESCRIPTION_ES = "Description es",
  DISCLAIMER = "Disclaimer",
  DISCLAIMER_ES = "Disclaimer es",
  MSRP = "MSRP",
  GRADE_MSRP = "Grade MSRP",
  NOTES = "Notes",
  INACTIVE = "Inactive",
  MODEL_APPLICABILITY = "Model Applicability",
  PART_NUMBER = "Part Number",
  REQUIRED = "Required",
  CONFLICTS = "Conflicts",
  WARRANTY = "Warranty",
  WARRANTY_ES = "Warranty es",
  COMMON_LANGUAGE = "Common Language",
}

interface ChangeLogChangesProps {
  changedItem: Change;
  gradesDataItem: GradeItem[];
  refItems: RefItem[] | null | undefined;
}

const ChangeLogChanges = ({
  changedItem,
  gradesDataItem,
  refItems,
}: ChangeLogChangesProps) => {
  switch (changedItem.changeType) {
    case AccessoryGSTChangeType.ABB:
    case AccessoryGSTChangeType.SUPPLIER:
    case AccessoryGSTChangeType.TITLE:
    case AccessoryGSTChangeType.TITLE_ES:
    case AccessoryGSTChangeType.DESCRIPTION:
    case AccessoryGSTChangeType.DESCRIPTION_ES:
    case AccessoryGSTChangeType.DISCLAIMER:
    case AccessoryGSTChangeType.DISCLAIMER_ES:
    case AccessoryGSTChangeType.PART_NUMBER:
    case AccessoryGSTChangeType.REQUIRED:
    case AccessoryGSTChangeType.CONFLICTS:
    case AccessoryGSTChangeType.NOTES:
    case AccessoryGSTChangeType.WARRANTY:
    case AccessoryGSTChangeType.WARRANTY_ES: {
      return (
        <ChangeLogRichTextDiff
          before={changedItem.before || ""}
          after={changedItem.after || ""}
        />
      );
    }

    case AccessoryGSTChangeType.PRODUCT_TYPE: {
      const beforeVal =
        refItems?.find((item) => item.id === changedItem.before)?.name || "";
      const afterVal =
        refItems?.find((item) => item.id === changedItem.after)?.name || "";
      return <ChangeLogBeforeAfter before={beforeVal} after={afterVal} />;
    }

    case AccessoryGSTChangeType.PRODUCT_TYPE_CHANGED: {
      return (
        <ChangeLogBeforeAfter
          before={changedItem.beforeValue || changedItem.before || ""}
          after={changedItem.afterValue || changedItem.after || ""}
        />
      );
    }

    case AccessoryGSTChangeType.GRADE_MSRP: {
      return (
        <ChangeLogGradeMsrp
          gradesDataItem={gradesDataItem || []}
          before={changedItem.before || ""}
          after={changedItem.after || ""}
        />
      );
    }
    case AccessoryGSTChangeType.MODEL_APPLICABILITY: {
      return (
        <ChangeLogApplicability
          before={changedItem.before || ""}
          after={changedItem.after || ""}
          gradesDataItem={gradesDataItem}
        />
      );
    }

    case AccessoryGSTChangeType.INACTIVE: {
      return (
        <ChangeLogBeforeAfter
          before={changedItem.before === "true" ? "true" : "false"}
          after={changedItem.after === "true" ? "true" : "false"}
        />
      );
    }

    case AccessoryGSTChangeType.MSRP: {
      return (
        <ChangeLogRichTextDiff
          before={changedItem.before ? changedItem.before.toString() : ""}
          after={changedItem.after ? changedItem.after.toString() : ""}
        />
      );
    }

    case AccessoryGSTChangeType.COMMON_LANGUAGE: {
      return (
        <ChangeLogCommonLang
          before={changedItem.before}
          after={changedItem.after}
        />
      );
    }

    default: {
      return <>--</>;
    }
  }
};

export default ChangeLogChanges;
