import React, { useCallback, useContext } from "react";
import { areRichTextValuesDifferent, cleanUpRte } from "vapi-ui-common";
import AccessoryRichText from "../../../../../components/AccessoryRichText";
import { Language } from "../../../../../models/user/user.model";
import checkForSyncChanges from "../../../../../utils/checkForSyncChanges";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";

interface IAccessoryRichTextLanguageProps {
  language: Language;
}

const AccessoryRichTextLanguage = ({
  language,
}: IAccessoryRichTextLanguageProps) => {
  const {
    readOnly,
    saveAccessoryItem,
    languagePermissions,
    accessoriesLangMap,
  } = useContext(AccessoriesEntryScreenContext);

  const {
    accessoryItem: { id: accessoryItemId },
    index,
  } = useContext(AccessoryContext);

  const accessoryItem = accessoriesLangMap[accessoryItemId]?.langs?.[language];

  const userPermissions = languagePermissions?.[language];
  const disabled = !userPermissions?.canEdit || readOnly;

  const onBlur = useCallback(
    (title: string) => {
      if (areRichTextValuesDifferent(accessoryItem.title, title)) {
        const acc = accessoryItem;
        acc.title = cleanUpRte(title);
        saveAccessoryItem(acc, language);
      }
    },
    [saveAccessoryItem, accessoryItem]
  );

  return (
    <AccessoryRichText
      tabIndex={index + 1}
      value={accessoryItem.title}
      disabled={disabled}
      required={
        !accessoryItem.title ||
        checkForSyncChanges(accessoryItem.changedAttributes, "title")
      }
      onBlur={onBlur}
    />
  );
};

export default AccessoryRichTextLanguage;
