import { observable } from "mobx";
import { Language, LanguagePermissions } from "../models/user/user.model";
import { VehicleTeam } from "../models/vehicleData/vehicleData.model";

type Team = {
  pageTitle: string;
  param: VehicleTeam;
  allowAddDeleteModels: boolean;
  allowAddDeleteSeries: boolean;
  allowDisclaimerTokens: boolean;
  allowPublish: boolean;
  allowSubmit: boolean;
  useNewDashboardStatus: boolean;
  urlParam: string;
  languages: Language[];
  defaultLanguage: Language;
  langPermissions: LanguagePermissions;
  allowAddDeleteData: boolean;
  showCommonLanguage?: boolean;
  allowUploadImages?: boolean;
  allowEditGoLiveDate?: boolean;
  allowEditGoLiveDateTime?: boolean;
  showSyncAdmin?: boolean;
};

export const NationalTeamToyota: Team = {
  pageTitle: "National Accessory Team",
  param: VehicleTeam.NATIONAL_ACC_TEAM,
  allowAddDeleteModels: true,
  allowAddDeleteSeries: true,
  allowDisclaimerTokens: false,
  allowPublish: true,
  allowSubmit: false,
  useNewDashboardStatus: false,
  urlParam: "national",
  languages: [Language.EN],
  defaultLanguage: Language.EN,
  langPermissions: {},
  allowAddDeleteData: true,
  showCommonLanguage: true,
  allowUploadImages: true,
  allowEditGoLiveDate: true,
  allowEditGoLiveDateTime: true,
  showSyncAdmin: true,
};

export const GSTTeamToyota: Team = {
  pageTitle: "GST Accessory Team",
  param: VehicleTeam.GST_ACC_TEAM,
  allowAddDeleteModels: true,
  allowAddDeleteSeries: true,
  allowDisclaimerTokens: false,
  allowPublish: true,
  allowSubmit: false,
  useNewDashboardStatus: false,
  urlParam: "gst",
  languages: [Language.EN],
  defaultLanguage: Language.EN,
  langPermissions: {},
  allowAddDeleteData: true,
  showCommonLanguage: true,
};

export const NationalTeamLexus: Team = {
  pageTitle: "National Accessory Team",
  param: VehicleTeam.NATIONAL_ACC_TEAM,
  allowAddDeleteModels: true,
  allowAddDeleteSeries: true,
  allowDisclaimerTokens: false,
  allowPublish: true,
  allowSubmit: false,
  useNewDashboardStatus: false,
  urlParam: "national",
  languages: [Language.EN],
  defaultLanguage: Language.EN,
  langPermissions: {},
  allowAddDeleteData: true,
  showCommonLanguage: true,
  allowUploadImages: true,
};

const GSTTeamLexus: Team = {
  pageTitle: "GST Accessory Team",
  param: VehicleTeam.GST_ACC_TEAM,
  allowAddDeleteModels: true,
  allowAddDeleteSeries: true,
  allowDisclaimerTokens: false,
  allowPublish: true,
  allowSubmit: false,
  useNewDashboardStatus: false,
  urlParam: "gst",
  languages: [Language.EN],
  defaultLanguage: Language.EN,
  langPermissions: {},
  allowAddDeleteData: true,
  showCommonLanguage: true,
};

const NationalSpanishTeamToyota: Team = {
  ...NationalTeamToyota,
  languages: [Language.EN, Language.ES],
  defaultLanguage: Language.ES,
  langPermissions: {},
  allowAddDeleteData: false,
  showCommonLanguage: false,
  allowEditGoLiveDate: false,
  allowEditGoLiveDateTime: false,
  showSyncAdmin: false,
};

const getNationalTeamByBrand = (brand: string) => {
  if (brand === "lexus") {
    return NationalTeamLexus;
  }
  return NationalTeamToyota;
};

const getGSTTeamByBrand = (brand: string) => {
  if (brand === "lexus") {
    return GSTTeamLexus;
  }
  return GSTTeamToyota;
};

export class TeamStore {
  @observable team = {} as Team;

  setTeam = (
    team: VehicleTeam,
    brand: string,
    langPermissions: LanguagePermissions
  ) => {
    if (team === VehicleTeam.SPANISH) {
      this.team = NationalSpanishTeamToyota;
    } else if (team === VehicleTeam.NATIONAL_ACC_TEAM) {
      this.team = getNationalTeamByBrand(brand);
    } else {
      this.team = getGSTTeamByBrand(brand);
    }
    this.team.languages.forEach((language) => {
      this.team.langPermissions[language] = langPermissions[language];
    });
    if (team === VehicleTeam.SPANISH) {
      this.team.langPermissions[Language.EN] = { canView: true };
    }
  };
}
