const returnOriginal = process.env.REACT_APP_IMAGE_UPLOAD !== "true";
const cacheUrls = new Map<string, string>();

const addTimestamp = (url: string, timestamp?: number) => {
  return url.concat(timestamp ? `?t=${timestamp}` : "");
};

const removeTimestamp = (url: string) => {
  return url.replace(/\?t=\d+/, "");
};

const getFromCache = (url: string) => {
  return cacheUrls.get(url);
};

const setCache = (url: string, newUrl: string) => {
  cacheUrls.set(url, newUrl);
  cacheUrls.set(newUrl, url);
};

export const uncachedPartNumberImage = (
  url: string | null | undefined,
  timestamp?: number
): string => {
  if (typeof url !== "string") {
    return "";
  }

  if (returnOriginal) {
    return url;
  }

  let uncachedUrl = getFromCache(url);

  if (uncachedUrl) {
    return addTimestamp(uncachedUrl, timestamp);
  }

  uncachedUrl = url.replace("/parts/images/", "/uncached/parts/images/");
  setCache(url, uncachedUrl);

  return addTimestamp(uncachedUrl, timestamp);
};

export const cachedPartNumberImage = (url: string | null | undefined) => {
  if (typeof url !== "string") {
    return "";
  }

  if (returnOriginal) {
    return url;
  }

  const uncachedUrl = removeTimestamp(url);
  let cachedUrl = cacheUrls.get(uncachedUrl);

  if (cachedUrl) {
    return cachedUrl;
  }

  cachedUrl = uncachedUrl.replace("/uncached/parts/images/", "/parts/images/");
  setCache(cachedUrl, uncachedUrl);

  return cachedUrl;
};
