import React, { useState } from "react";
import {
  Button,
  FilterTab,
  FilterTabBody,
  FilterTabFooter,
  FilterTabs,
  FilterTabSection,
} from "vapi-ui-common";
import Filter from "./Filter";

const CommonLanguageFilterTabs = {
  productType: "Product Type",
  installPoint: "Install Point",
};

interface CommonLanguageFilterProps {
  productTypes: string[];
  productTypeFilters: string[];
  installPoints?: string[];
  installPointFilters?: string[];
  setFilters: (
    productTypeFilters: string[],
    installPointFilters: string[]
  ) => void;
  onClose: () => void;
}

const CommonLanguageFilter = ({
  productTypes,
  productTypeFilters,
  installPoints,
  installPointFilters,
  setFilters,
  onClose,
}: CommonLanguageFilterProps) => {
  const [selectedTab, setSelectedTab] = useState(
    CommonLanguageFilterTabs.productType
  );
  const [currentProductTypeFilters, setProductTypeFilters] =
    useState(productTypeFilters);
  const [currentInstallPointFilters, setInstallPointFilters] = useState(
    installPointFilters ?? []
  );

  const handleApplyFilters = () => {
    setFilters(currentProductTypeFilters, currentInstallPointFilters);
    onClose();
  };

  return (
    <FilterTab>
      {installPoints && installPointFilters && (
        <FilterTabs
          tabs={Object.values(CommonLanguageFilterTabs)}
          selectedTab={selectedTab}
          onSelected={setSelectedTab}
        />
      )}
      <FilterTabBody>
        <FilterTabSection
          active={selectedTab === CommonLanguageFilterTabs.productType}
        >
          <Filter
            id="productType-filter"
            title={`${CommonLanguageFilterTabs.productType}s`}
            items={productTypes}
            filters={currentProductTypeFilters}
            onChange={setProductTypeFilters}
          />
        </FilterTabSection>
        {installPoints && installPointFilters && (
          <FilterTabSection
            active={selectedTab === CommonLanguageFilterTabs.installPoint}
          >
            <Filter
              id="installPoint-filter"
              title={`${CommonLanguageFilterTabs.installPoint}s`}
              items={installPoints}
              filters={currentInstallPointFilters}
              onChange={setInstallPointFilters}
            />
          </FilterTabSection>
        )}
      </FilterTabBody>
      <FilterTabFooter>
        <Button variant="primary" onClick={handleApplyFilters}>
          Apply Filters
        </Button>
      </FilterTabFooter>
    </FilterTab>
  );
};

export default CommonLanguageFilter;
