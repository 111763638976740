import React, { useImperativeHandle, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "vapi-ui-common";

interface UploadDraftModalProps {
  handleOnUpload: (file?: File, resultJSON?: object) => void;
  handleDeleteItem: (
    seriesId: string,
    modelYear: number,
    isDraft: boolean
  ) => void;
}

export interface UploadDraftModalRefProps {
  open: (
    file?: File,
    resultJSON?: object,
    seriesId?: string,
    year?: number
  ) => void;
}

const UploadDraftModal = React.forwardRef(
  ({ handleOnUpload, handleDeleteItem }: UploadDraftModalProps, ref) => {
    const [show, setShow] = useState(false);
    const [file, setFile] = useState<File>();
    const [resultJSON, setResultJSON] = useState<object>();
    const [seriesId, setSeriesId] = useState<string>();
    const [year, setYear] = useState<number>();

    const body = `A draft already exists.  Would you like to override the current draft?`;

    useImperativeHandle(
      ref,
      (): UploadDraftModalRefProps => ({
        open(
          inputFile?: File,
          fileAsJSON?: object,
          sId?: string,
          modelYear?: number
        ) {
          setShow(true);
          setFile(inputFile);
          setResultJSON(fileAsJSON);
          setSeriesId(sId);
          setYear(modelYear);
        },
      })
    );

    const onClose = () => {
      setShow(false);
      setFile(undefined);
      setResultJSON(undefined);
      setSeriesId(undefined);
      setYear(undefined);
    };

    const onUpload = async () => {
      if (!seriesId || !year) return undefined;
      await handleDeleteItem(seriesId, year, true);
      handleOnUpload(file, resultJSON);
      return onClose();
    };

    return (
      <Modal open={show} size="auto" onClose={onClose}>
        <ModalHeader onClose={onClose}>Upload New Draft</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <Button variant="transparent" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onUpload}>
            Upload
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);

export default UploadDraftModal;
