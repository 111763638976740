import { useEffect, useState } from "react";
import { getSortedListByString } from "../utils/sortUtils";
import { RefItem } from "../gql/generated";

const useSortedProductTypes = (productTypes: RefItem[]) => {
  const [sortedProductTypes, setSortedProductTypes] = useState<
    RefItem[] | null
  >(null);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const productTypeValues = productTypes.map((item) => item.name).join();

  useEffect(() => {
    setSortedProductTypes(getSortedListByString<RefItem>(productTypes, "name"));
  }, [productTypeValues]);

  useEffect(() => {
    if (isUpdated) {
      setIsUpdated(false);
    }
  }, [isUpdated]);

  return {
    sortedProductTypes,
    setIsUpdated,
  };
};

export default useSortedProductTypes;
