import React, { useState } from "react";
import { Button, IconTextButton, Popover } from "vapi-ui-common";
import styles from "./syncUpdatesPopover.module.scss";
import SyncUpdatesPopoverContent from "./SyncUpdatesPopoverContent";

interface ISyncUpdatesPopover {
  enSourceVersion: string | undefined;
  enCurrentVersion: string | undefined;
  align?: "right" | "left";
  lastSyncDates: string;
  lastNATPublishedDate: string;
  handleUpdateSpanishSync: () => Promise<void>;
}

const SyncUpdatesPopover = ({
  enSourceVersion,
  enCurrentVersion,
  lastSyncDates,
  lastNATPublishedDate,
  handleUpdateSpanishSync,
  align = "right",
}: ISyncUpdatesPopover) => {
  const [tooltipHover, setTooltipHover] = useState(false);

  const onSyncUpdatesHover = (isOn: boolean) => {
    setTooltipHover(isOn);
  };

  if (enSourceVersion === enCurrentVersion) {
    return <></>;
  }

  return (
    <Button
      variant="primary"
      onMouseOver={() => {
        onSyncUpdatesHover(true);
      }}
      onMouseOut={() => {
        onSyncUpdatesHover(false);
      }}
      onClick={() => {
        handleUpdateSpanishSync();
      }}
    >
      <span className={styles.iconSync}>
        <IconTextButton icon="sync" />
      </span>
      Sync Updates
      {tooltipHover && (
        <Popover
          popoverElementClass={styles.popover}
          align={align}
          defaultOpen
          toggleElement={<></>}
          popoverElement={
            <SyncUpdatesPopoverContent
              lastSyncDates={lastSyncDates}
              lastPublishedDates={lastNATPublishedDate}
            />
          }
        />
      )}
    </Button>
  );
};

SyncUpdatesPopover.defaultProps = {
  align: "right",
};

export default SyncUpdatesPopover;
