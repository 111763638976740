import React, { HTMLAttributes, useEffect, useState, useRef } from "react";
import { observer } from "mobx-react-lite";
import useStores from "../../../stores/useStores";

const RightTableSizer = (props: HTMLAttributes<HTMLDivElement>) => {
  const {
    resizeDetectorStore: { mainContentWidth, windowWidth },
    tableSizeStore: { tableWidth },
  } = useStores();

  const [style, setStyle] = useState({});
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current) {
      const scrollBarWidth = 25;
      const width = `${
        windowWidth - ref.current.getBoundingClientRect().left - scrollBarWidth
      }px`;
      setStyle({
        width,
        maxWidth: width,
      });
    }
  }, [windowWidth, mainContentWidth, tableWidth]);

  return <div ref={ref} style={style} {...props} />;
};

export default observer(RightTableSizer);
