import React from "react";
import {
  Spinner,
  StickyContainer,
  StickyHeaderContainer,
} from "vapi-ui-common";
import { observer } from "mobx-react-lite";
import useStores from "../../stores/useStores";
import ActionBarCommonLanguage from "./components/ActionBarCommonLanguage/ActionBarCommonLanguage";
import { CLTable, GstTable } from "./components/CommonLanguageTable";
import { useGetCLData } from "./hooks";
import PublishedHeader from "./components/Header/PublishedHeader";
import useActionBarCLProps from "./hooks/useActionBarCLProps";

const Published = () => {
  const { userStore, commonLanguageStore } = useStores();
  const isGst = userStore.isGstUser();
  useGetCLData();

  const actionBarProps = useActionBarCLProps();

  if (commonLanguageStore.isFetching) {
    return <Spinner />;
  }

  return (
    <StickyContainer>
      <StickyHeaderContainer>
        <PublishedHeader version={commonLanguageStore.currentVersion} />
        <ActionBarCommonLanguage readonly {...actionBarProps} />
      </StickyHeaderContainer>
      {isGst ? <GstTable readonly /> : <CLTable readonly />}
    </StickyContainer>
  );
};

export default observer(Published);
