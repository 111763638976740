import React, { useContext } from "react";
import {
  Checkbox,
  HeaderRow,
  TabPanel,
  Table,
  TableCell,
  Thead,
} from "vapi-ui-common";
import { AccessoryItem } from "../../gql/generated";
import { ChangeLogTypes } from "../../models/accessories/changeLog.model";
import ChangeLogChanges from "../../routes/gst/components/ChangeLogChanges";
import ChangeLogRow from "../ChangeLogTable/ChangeLogRow";
import styles from "./SyncAllChanges.module.scss";
import {
  SyncAllChangesActions,
  SyncAllChangesContext,
} from "./SyncAllChangesContext";

const useVariables = () => {
  const { state, dispatch } = useContext(SyncAllChangesContext);

  const { logData, gradesData, selectedTab, tabCL } = state;

  const toggleCheckbox = (changeType: string, checked: boolean) => {
    dispatch({
      type: SyncAllChangesActions.TOGGLE_CHECKBOX,
      payload: {
        changeType,
        checked,
      },
    });
  };

  const toggleAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: SyncAllChangesActions.TOGGLE_CHECKBOX_ALL,
      payload: {
        checked: e.currentTarget.checked,
      },
    });
  };

  const toggleCheckboxUnlink = (changeType: string, checked: boolean) => {
    dispatch({
      type: SyncAllChangesActions.TOGGLE_CHECKBOX_UNLINK,
      payload: {
        changeType,
        checked,
      },
    });
  };

  const toggleAllCheckboxUnlink = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: SyncAllChangesActions.TOGGLE_CHECKBOX_ALL_UNLINK,
      payload: {
        checked: e.currentTarget.checked,
      },
    });
  };

  return {
    logData,
    gradesData,
    toggleAllCheckbox,
    toggleCheckbox,
    toggleAllCheckboxUnlink,
    toggleCheckboxUnlink,
    selectedTab,
    tabCL,
  };
};

const SyncAllChangesModalBodyCLTab = () => {
  const {
    logData,
    toggleAllCheckbox,
    toggleCheckbox,
    gradesData,
    toggleAllCheckboxUnlink,
    toggleCheckboxUnlink,
    selectedTab,
    tabCL,
  } = useVariables();

  return (
    <TabPanel tab={tabCL} selected={selectedTab === tabCL}>
      <Table fullWidth className={styles.syncAllChangesTable}>
        <Thead>
          <HeaderRow>
            <TableCell className={styles.checkboxCell}>
              <Checkbox
                id="apply-chbox-all-cl"
                className={styles.checkboxAll}
                checked={logData.every((log) => log.checked)}
                onChange={toggleAllCheckbox}
                labelClassName={styles.checkboxLabel}
              >
                <span>Apply</span>
              </Checkbox>
            </TableCell>
            <TableCell className={styles.checkboxCell}>
              <Checkbox
                id="unlink-chbox-all-cl"
                className={styles.checkboxAll}
                checked={logData.every((log) => log.checkedUnlink)}
                onChange={toggleAllCheckboxUnlink}
                labelClassName={styles.checkboxLabel}
              >
                <span>Unlink</span>
              </Checkbox>
            </TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Change Type</TableCell>
            <TableCell>Changes</TableCell>
            <TableCell>Published Date</TableCell>
            <TableCell>Notes</TableCell>
          </HeaderRow>
        </Thead>

        <tbody>
          {logData.map((item) => {
            let changeTypeDisplay = item.changeType;

            if (item.changeType === ChangeLogTypes.TITLE) {
              changeTypeDisplay = ChangeLogTypes.NAME;
            }

            const accItem = item.changedItem as AccessoryItem;

            return (
              <ChangeLogRow
                key={`${item.changeType}${accItem.id}`}
                description={accItem.title}
                changeType={changeTypeDisplay}
                modifiedBy=""
                modifiedDate={item.modifiedDate || ""}
                notes={accItem.notes || ""}
                canRevert={false}
                renderChanges={
                  <ChangeLogChanges
                    gradesDataItem={gradesData}
                    changedItem={item}
                    refItems={[]}
                  />
                }
                onRevert={() => {}}
                apply
                unlink
                handleToggleCheckbox={toggleCheckbox}
                handleToggleCheckboxUnlink={toggleCheckboxUnlink}
                id={item.changeType}
                checked={item.checked}
                checkedUnlink={item.checkedUnlink}
              />
            );
          })}
        </tbody>
      </Table>
    </TabPanel>
  );
};

export default SyncAllChangesModalBodyCLTab;
