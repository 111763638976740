import { trackPromise } from "react-promise-tracker";
import {
  AclProductTypeItem,
  useCreateAclProductTypeMutation,
  useUpdateAclProductTypeMutation,
} from "../../../gql/generated";
import {
  ACCESSORY_TEAM,
  GST_REGION,
  NATIONAL_REGION,
} from "../../../constants/Constants";
import useStores from "../../../stores/useStores";

const useSaveProductType = () => {
  const [createAclProductType] = useCreateAclProductTypeMutation();
  const [updateAclProductType] = useUpdateAclProductTypeMutation();
  const { commonLanguageStore, userStore } = useStores();

  const oppCtx = {
    brand: userStore.brand,
    region: userStore.isGstUser() ? GST_REGION : NATIONAL_REGION,
    team: ACCESSORY_TEAM,
  };

  const addProductType = async (value: string) => {
    commonLanguageStore.addProductType(value);
    const addProductTypeMutationResult = await trackPromise(
      createAclProductType({
        variables: {
          input: {
            ...oppCtx,
            name: value,
          },
        },
      })
    );

    if (addProductTypeMutationResult.errors) {
      throw new Error(addProductTypeMutationResult.errors[0].message);
    }

    const newProductTypes =
      addProductTypeMutationResult.data?.createACLProductType;

    if (!newProductTypes) {
      throw new Error("Error adding product type");
    }

    commonLanguageStore.productTypes = newProductTypes;
  };

  const updateProductType = async (productType: AclProductTypeItem) => {
    commonLanguageStore.updateProductType(productType);

    const updateProductTypeMutationResult = await trackPromise(
      updateAclProductType({
        variables: {
          input: {
            ...oppCtx,
            id: productType.id,
            name: productType.name,
          },
        },
      })
    );

    if (updateProductTypeMutationResult.errors) {
      throw new Error(updateProductTypeMutationResult.errors[0].message);
    }

    const newProductTypes =
      updateProductTypeMutationResult.data?.updateACLProductType;

    if (!newProductTypes) {
      throw new Error("Error adding product type");
    }

    commonLanguageStore.productTypes = newProductTypes;
  };

  return { addProductType, updateProductType };
};

export default useSaveProductType;
