/* eslint-disable no-param-reassign */
import cx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { Checkbox, CheckboxLabel, TableCell, TableRow } from "vapi-ui-common";
import AccessoryRichText from "../../../../components/AccessoryRichText";
import Input from "../../../../components/Input/Input";
import { AccessoryCommonLanguageItem } from "../../../../models/commonLanguage.model";
import useStores from "../../../../stores/useStores";
import useRowHandlers from "../../hooks/useRowHandlers";
import CLFlagsCell from "../CLFlagsCell/CLFlagsCell";
import ProductTypeDropdown from "../ProductTypeDropdown";
import styles from "./commonLanguageTable.module.scss";

interface CLRightRowProps {
  index: number;
  item: AccessoryCommonLanguageItem;
  readonly?: boolean;
}

const CLRightRow = ({ index, item, readonly }: CLRightRowProps) => {
  const { commonLanguageStore, userStore } = useStores();
  const { productTypes } = commonLanguageStore;

  const {
    handleInputChange,
    handleRichTextChange,
    handleInstallPointSelect,
    handleProductTypeSelect,
    handleProductTypeCreate,
    handleProductTypeUpdate,
  } = useRowHandlers(item);

  const selectedProductType = useMemo(
    () => productTypes.find((pt) => pt.id === item.productType),
    [item.productType, productTypes]
  );

  return (
    <TableRow>
      {/* Disclosure */}
      <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
        <AccessoryRichText
          value={item.disclosure}
          onBlur={handleRichTextChange("disclosure")}
          disabled={readonly}
        />
      </TableCell>

      {/* Product Type */}
      <TableCell className={cx(styles.cell)}>
        <ProductTypeDropdown
          value={selectedProductType?.name ?? ""}
          productTypes={productTypes}
          disabled={readonly}
          onAdd={handleProductTypeCreate}
          onSelect={handleProductTypeSelect}
          onEdit={handleProductTypeUpdate}
        />
      </TableCell>

      {/* Install Point */}
      <TableCell className={cx(styles.cell)}>
        <Checkbox
          id={`checkbox-ppo-${item.uid}`}
          checked={item.installPoint?.includes("PPO")}
          disabled={readonly}
          onChange={handleInstallPointSelect("PPO")}
        >
          <CheckboxLabel>PPO</CheckboxLabel>
        </Checkbox>
        <Checkbox
          id={`checkbox-dio-${item.uid}`}
          checked={item.installPoint?.includes("DIO")}
          disabled={readonly}
          onChange={handleInstallPointSelect("DIO")}
        >
          <CheckboxLabel>DIO</CheckboxLabel>
        </Checkbox>
      </TableCell>

      {/* PPO Code */}
      <TableCell className={cx(styles.cell)}>
        <AccessoryRichText
          value={item.ppoCode}
          maxLength={2}
          onBlur={handleRichTextChange("ppoCode")}
          disabled={readonly}
          required={
            !item.ppoCode &&
            !item.installPoint?.includes("DIO") &&
            !item.isNonGenAccessory
          }
        />
      </TableCell>

      {/* Warranty */}
      <TableCell
        className={cx(
          styles.cell,
          styles.textArea,
          styles.wide,
          styles.withLabel
        )}
      >
        <Checkbox
          id={`checkbox-aap-${item.uid}`}
          checked={item.isNonGenAccessory ?? false}
          onChange={handleInputChange("isNonGenAccessory")}
          disabled={readonly}
        >
          <CheckboxLabel>AAP</CheckboxLabel>
        </Checkbox>
        <AccessoryRichText
          value={item.warranty}
          onBlur={handleRichTextChange("warranty")}
          disabled={readonly}
        />
      </TableCell>

      {/* Required/Conflicts */}
      <TableCell className={cx(styles.cell)}>
        <Input
          id={`input-required-${item.id}`}
          label="Required"
          name={`input-required-${item.id}`}
          type="text"
          defaultValue={item.required ?? ""}
          onBlur={handleInputChange("required")}
          disabled={readonly}
        />
        <Input
          id={`input-conflicts-${item.id}`}
          label="Conflicts"
          type="text"
          defaultValue={item.conflicts ?? ""}
          onBlur={handleInputChange("conflicts")}
          disabled={readonly}
        />
      </TableCell>

      {/* Part Number */}
      <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
        <AccessoryRichText
          value={item.partNumber}
          onBlur={handleRichTextChange("partNumber")}
          disabled={readonly}
        />
      </TableCell>

      {/* Dealer Cost */}
      <TableCell className={cx(styles.cell)}>
        <Input
          id={`input-partsOnlyDealerCost-${item.id}`}
          label="Parts Only"
          name={`input-parts-only-${item.id}`}
          type="text"
          defaultValue={item.partsOnlyDealerCost ?? ""}
          onBlur={handleInputChange("partsOnlyDealerCost")}
          disabled={readonly}
        />
        {!userStore.isLexusUser() && (
          <Input
            id={`input-installed-${item.id}`}
            label="Installed"
            type="text"
            defaultValue={item.installedDealerCost ?? ""}
            onBlur={handleInputChange("installedDealerCost")}
            disabled={readonly}
          />
        )}

        {userStore.isLexusUser() && (
          <div className={styles.laborTime}>
            <Input
              id={`input-labor-time-${item.id}`}
              label="Labor Time"
              type="text"
              defaultValue={item.laborTime ?? ""}
              onBlur={handleInputChange("laborTime")}
              disabled={readonly}
            />
          </div>
        )}
      </TableCell>

      {/* MSRP */}
      {!userStore.isLexusUser() && (
        <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
          <AccessoryRichText
            value={item.partsOnlyMSRP}
            onBlur={handleRichTextChange("partsOnlyMSRP")}
            disabled={readonly}
          />
        </TableCell>
      )}

      {/* Row Level Information */}
      <CLFlagsCell index={index} item={item} disabled={readonly} />

      {/* Published/Archived Date */}
      {item.publishedDate && readonly && (
        <TableCell className={cx(styles.cell)}>
          {new Date(item.publishedDate).toLocaleDateString()}
        </TableCell>
      )}
    </TableRow>
  );
};

export default observer(CLRightRow);
