import { toast } from "react-toastify";
import useStores from "../../../stores/useStores";

const useCreateCommonLanguage = () => {
  const { commonLanguageStore } = useStores();

  const createCommonLanguage = async () => {
    commonLanguageStore.addItem();
    toast.error(
      "Please finish filling out all items of the new common language"
    );
  };

  return createCommonLanguage;
};

export default useCreateCommonLanguage;
