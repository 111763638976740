import React from "react";
import { MultiLineRTEditor, processRteForChangeLog } from "vapi-ui-common";
import ChangeLogDiff from "./components/ChangeLogDiff";

const ChangeLogRichTextDiff = ({
  before,
  after,
}: {
  before: string;
  after: string;
}) => {
  const payload = { after, before };

  payload.after = processRteForChangeLog(after);
  payload.before = processRteForChangeLog(before);

  if (!payload.before || (!payload.after && payload.before)) {
    return ChangeLogDiff(payload);
  }

  if (
    payload.before.replaceAll("\n", "") === payload.after.replaceAll("\n", "")
  ) {
    return (
      <>
        <p>Formatting added:</p>

        <MultiLineRTEditor disabled value={after} fullHeight />
      </>
    );
  }

  return ChangeLogDiff(payload);
};

export default ChangeLogRichTextDiff;
