import { BRAND_LEXUS } from "../constants/Brand";
import { SeriesMyItem } from "../gql/generated";
import {
  VehicleModelItem,
  VehicleModelLexus,
  VehicleModelToyota,
} from "../models/accessories/VehicleModel.model";
import IDValueType from "../models/common/IDValueType.model";
import {
  Language,
  UserVDPermissions,
  VehicleDataVersionInfo,
} from "../models/user/user.model";
import {
  DashboardDetail,
  Series,
  SeriesCategories,
  SeriesInfoItem,
  VDStatus,
  VehicleCategories,
  VehicleTeam,
} from "../models/vehicleData/vehicleData.model";

const { CROSSOVERS, LARGE_SEDANS_VANS, SEDANS_SPORTS_CARS, TRUCKS_SUVS } =
  SeriesCategories;

export const dashboardXForm = (
  data: DashboardDetail,
  series: Series,
  // teamModule: UserVDPermissions,
  team?: VehicleTeam
) => {
  const seriesInfoList: SeriesInfoItem[] = [];

  Object.keys(data).forEach((seriesId) => {
    const seriesData = data[seriesId];
    const item: SeriesInfoItem = {
      id: seriesId,
      seriesName: series[seriesId].name,
      // permissions: {},
      modelYears: [],
    };

    // set permissions
    // const seriesPermissions = teamModule.series[series[seriesId].group];
    // if (seriesPermissions) {
    // item.permissions.canEdit = seriesPermissions.canEdit;
    // }

    Object.keys(seriesData.years).forEach((year) => {
      const yearDetail = seriesData.years[year];
      if (yearDetail && yearDetail.length) {
        yearDetail.forEach((yearData: any) => {
          // TODO: remove any above
          const modelYear = { ...yearData };
          // add notes
          if (modelYear.isPendingCLReview || modelYear.isPendingVDReview) {
            modelYear.notes = "A review is pending";
          } else if (modelYear.isSubmitted) {
            modelYear.notes = "Draft is submitted for review";
          }
          if (
            team === VehicleTeam.AGENCY_TEAM &&
            !modelYear.publishDownstreamDate &&
            modelYear.vdStatus &&
            modelYear.vdStatus.currentStatus === VDStatus.APPROVED
          ) {
            modelYear.datePublished = "";
          }
          item.modelYears.push(modelYear);
        });
      }
    });
    seriesInfoList.push(item);
  });

  return seriesInfoList;
};

export const getParamsForVersionInfo = (
  team: VehicleTeam,
  languages: Language[],
  dashboardRowItem: SeriesMyItem
) => {
  const versionInfo: VehicleDataVersionInfo = {};
  if (team === VehicleTeam.SPANISH) {
    versionInfo[Language.EN] = (
      dashboardRowItem.sourceVersion || "1"
    ).toString(); // todo: change the '1' to dashboardRowItem.sourceVersion
    versionInfo[Language.ES] = dashboardRowItem.isDraft
      ? "DRAFT"
      : dashboardRowItem.spanishVersion || 0;
  } else {
    languages.forEach((lang) => {
      versionInfo[lang] = dashboardRowItem.isDraft
        ? "DRAFT"
        : dashboardRowItem.version || undefined;
    });
  }

  let versionInfoParams: string = "";
  if (Object.keys(versionInfo)) {
    const a = Object.keys(versionInfo).map((lang) => {
      return `${lang}:${versionInfo[lang as Language]}`;
    });
    versionInfoParams = a.join("|");
  }

  return { versionInfoParams, versionInfo };
};

export const getVersionInfoFromParams = (
  params: string
): VehicleDataVersionInfo => {
  const versionInfo: VehicleDataVersionInfo = {};
  // expecting params to be in format EN:10|ES:5
  const languages: string[] = params.split("|");

  languages.forEach((lang) => {
    const [a, b] = lang.split(":") as Language[];
    versionInfo[a] = b;
  });

  return versionInfo;
};

export const getEditableVehicleGroups = (
  teamModule: UserVDPermissions,
  brand: string
) => {
  const groups = [];
  if (brand !== BRAND_LEXUS && teamModule.series[LARGE_SEDANS_VANS].canEdit) {
    groups.push(VehicleCategories.LARGE_SEDANS_VANS);
  }
  if (teamModule.series[SEDANS_SPORTS_CARS].canEdit) {
    groups.push(VehicleCategories.SEDANS_SPORTS_CARS);
  }
  if (brand !== BRAND_LEXUS && teamModule.series[CROSSOVERS].canEdit) {
    groups.push(VehicleCategories.CROSSOVERS);
  }
  if (teamModule.series[TRUCKS_SUVS].canEdit) {
    groups.push(VehicleCategories.TRUCKS_SUVS);
  }

  return groups;
};

export const mapEmptyModels = (
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  return vehicleModels.map((mdl) => ({
    id: mdl.id,
    setting: "",
  }));
};

export const mapEmptyValueModels = (
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) => {
  return vehicleModels.map((mdl) => new IDValueType(mdl.id, ""));
};

export const getSortModels = (
  vehicleModels: VehicleModelItem<VehicleModelLexus | VehicleModelToyota>[]
) =>
  vehicleModels.map(({ id, revId, sortOrder }) => ({
    id,
    revId,
    sortOrder,
  }));

export const getSeriesCategories = (brand: string) => {
  if (brand === BRAND_LEXUS) {
    return {
      SEDANS_SPORTS_CARS,
      TRUCKS_SUVS,
    };
  }
  return SeriesCategories;
};

export const getValidSeriesCategories = (brand: string) => {
  if (brand === BRAND_LEXUS) {
    return [SEDANS_SPORTS_CARS, TRUCKS_SUVS];
  }
  return [CROSSOVERS, LARGE_SEDANS_VANS, SEDANS_SPORTS_CARS, TRUCKS_SUVS];
};
