import cx from "clsx";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./input.module.scss";

export interface IInputField {
  id?: string;
  required?: boolean;
  label?: string;
  className?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;
  value: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    setInputValue: React.Dispatch<React.SetStateAction<string>>
  ) => void;
}

const InputField = React.forwardRef(
  (props: IInputField, ref?: React.Ref<HTMLInputElement>) => {
    const {
      className,
      onChange,
      onBlur,
      required,
      value,
      label,
      id,
      disabled,
    } = props;

    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
      setInputValue(value);
    }, [setInputValue, value]);

    const handleOnChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
          onChange(event, setInputValue);
        }
      },
      [onChange, setInputValue]
    );

    const handleOnBlur = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        const e = event;
        e.currentTarget.value = e.currentTarget.value.trim();
        if (onBlur) {
          onBlur(e);
        }
      },
      [onBlur]
    );

    const hasError = useMemo(() => {
      if (required && !inputValue) {
        return true;
      }
      return false;
    }, [inputValue, required]);

    const getCssClass = () => {
      return cx(styles.input, hasError && styles.error, className);
    };

    return (
      <>
        <label htmlFor={id}>{label || ""}</label>
        <input
          ref={ref}
          value={inputValue}
          type="text"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          className={getCssClass()}
          disabled={disabled}
        />
      </>
    );
  }
);

export default InputField;
