import React, { useCallback, useContext, useMemo } from "react";
import { ImageItem } from "vapi-ui-common";
import MsrpFlagsCell from "../../../../../components/MsrpFlagsCell/MsrpFlagcell";
import { GradeMsrpItem } from "../../../../../gql/generated";
import useInactiveMapper from "../../../../../hooks/useInactiveMapper";
import { Language } from "../../../../../models/user/user.model";
import checkForSyncChanges from "../../../../../utils/checkForSyncChanges";
import styles from "../../../AccessoriesEntryScreen.module.scss";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";
import CPopoverElement from "./CPopoverElement";
import CToggleElement from "./CToggleElement";

const CColumn = () => {
  const {
    teamLang,
    accessoriesLangMap,
    readOnly,
    saveAccessoryItem,
    languagePermissions,
    isLexusUser,
    isNationalUser,
    gradesData,
    isSpanish,
  } = useContext(AccessoriesEntryScreenContext);

  const {
    accessoryItem: { id: accessoryItemId },
    index,
  } = useContext(AccessoryContext);

  const { inactiveMappers, inactiveMappersRef, setInactiveMappers } =
    useInactiveMapper();

  const accessoryLangItem = accessoriesLangMap[accessoryItemId]?.langs;
  const accessoryItemEN = accessoryLangItem?.[Language.EN];

  const inactiveStatus = useMemo(() => {
    const inactiveMapper = inactiveMappers.find(
      (ele) => ele.id === accessoryItemEN.id
    );
    if (inactiveMapper) {
      return inactiveMapper.inactive;
    }
    return accessoryItemEN.inactive || false;
  }, [accessoryItemEN, inactiveMappers]);

  const onInProgressChange = useCallback(() => {
    const acc = accessoryItemEN;
    acc.inactive = !acc.inactive;
    setInactiveMappers([
      ...inactiveMappersRef.current.filter(
        (ele) => ele.id !== accessoryItemEN.id
      ),
      { id: accessoryItemEN.id, inactive: acc.inactive },
    ]);
    saveAccessoryItem(acc);
  }, [saveAccessoryItem, accessoryItemEN, setInactiveMappers]);

  const onNotesChange = useCallback(
    (notes: string) => {
      if (notes !== accessoryItemEN.notes) {
        const acc = accessoryItemEN;
        acc.notes = notes;
        saveAccessoryItem(acc);
      }
    },
    [saveAccessoryItem, accessoryItemEN]
  );

  const onDeleteImage = useCallback(() => {
    const acc = accessoryItemEN;
    acc.images = [];
    saveAccessoryItem(acc);
  }, [saveAccessoryItem, accessoryItemEN]);

  const onImageChange = useCallback(
    (image: ImageItem[]) => {
      if (image !== accessoryItemEN.images) {
        const acc = accessoryItemEN;
        acc.images = image;
        saveAccessoryItem(acc);
      }
    },
    [saveAccessoryItem, accessoryItemEN]
  );

  const onExtraCostChange = useCallback(
    (msrp: string, language?: Language) => {
      const accessoryItem = accessoryLangItem[language ?? Language.EN];
      if (msrp !== accessoryItem.msrp) {
        const acc = accessoryItem;
        acc.msrp = msrp;
        saveAccessoryItem(acc, language);
      }
    },
    [saveAccessoryItem, accessoryLangItem]
  );

  const onGradeIdExtraCostChange = useCallback(
    (gradeMsrp: GradeMsrpItem[]) => {
      const acc = accessoryItemEN;
      acc.gradeMsrp = gradeMsrp;
      saveAccessoryItem(acc);
    },
    [saveAccessoryItem, accessoryItemEN]
  );

  const userPermissionsEN = languagePermissions?.[Language.EN];
  const disabled = !userPermissionsEN?.canEdit || readOnly;

  const userPermissions = languagePermissions?.[teamLang];
  const msrpDisabled = !userPermissions?.canEdit || readOnly;

  return (
    <MsrpFlagsCell
      align="right"
      hideAddTrimMsrp={isLexusUser}
      displayImagePopover={isNationalUser}
      tabIndex={index + 1}
      className={styles.accMsrp}
      disabled={disabled}
      msrp={accessoryItemEN.msrp ?? ""}
      modelGrades={gradesData}
      index={index}
      highlighted={false}
      inProgress={inactiveStatus}
      inProgressText="Inactive"
      toggleInProgress={onInProgressChange}
      notes={accessoryItemEN.notes ?? ""}
      images={accessoryItemEN.images}
      onNotesChange={onNotesChange}
      onDeleteImage={onDeleteImage}
      onImageChange={onImageChange}
      gradeMsrp={accessoryItemEN.gradeMsrp ?? []}
      msrpPopover
      onExtraCostChange={onExtraCostChange}
      onGradeIdExtraCostChange={onGradeIdExtraCostChange}
      msrpPopoverToggleElement={isSpanish ? <CToggleElement /> : undefined}
      msrpPopoverPopoverElement={
        isSpanish ? <CPopoverElement onUpdate={onExtraCostChange} /> : undefined
      }
      msrpElement={isSpanish ? <CToggleElement /> : undefined}
      msrpDisabled={msrpDisabled}
      hasMsrpChangedAttributes={checkForSyncChanges(
        accessoryItemEN.changedAttributes,
        "msrp"
      )}
    />
  );
};

export default CColumn;
