import React from "react";
import { NavLink } from "react-router-dom";
import useStores from "../../../stores/useStores";
import activeTeam from "../../../utils/activeTeam";

const ExpandedView = () => {
  const { userStore } = useStores();
  const canViewCommonLanguage =
    process.env.REACT_APP_COMMON_LANGUAGE === "true" &&
    ((userStore.isNationalUser() && !userStore.isSpanishUser()) ||
      userStore.isLexusUser() ||
      userStore.isGstUser());

  return (
    <ul>
      {canViewCommonLanguage && (
        <li>
          <NavLink
            to="/commonLanguage/published"
            isActive={(match, location) => {
              return activeTeam(location.pathname, "commonLanguage/published");
            }}
          >
            Common Language
          </NavLink>

          <ul>
            <li>
              <NavLink
                to="/commonLanguage/draft"
                isActive={(match, location) => {
                  return activeTeam(location.pathname, "commonLanguage/draft");
                }}
              >
                Draft
              </NavLink>
              <NavLink
                to="/commonLanguage/archived"
                isActive={(match, location) => {
                  return activeTeam(
                    location.pathname,
                    "commonLanguage/archived"
                  );
                }}
              >
                Archive
              </NavLink>
            </li>
          </ul>
        </li>
      )}

      {userStore.isNationalUser() && (
        <>
          <NavLink
            to="/national/dashboard"
            isActive={(match, location) => {
              return activeTeam(location.pathname, "national");
            }}
          >
            National
          </NavLink>
          {userStore.isSpanishUser() && (
            <NavLink
              to="/spanish/dashboard"
              isActive={(match, location) => {
                return activeTeam(location.pathname, "spanish");
              }}
            >
              Spanish
            </NavLink>
          )}
        </>
      )}

      {!userStore.isNationalUser() && (
        <>
          <NavLink
            to="/gst/dashboard"
            isActive={(match, location) => activeTeam(location.pathname, "gst")}
          >
            GST
          </NavLink>

          {userStore.isGstUser() && (
            <>
              <a
                href={`${process.env.REACT_APP_ADMIN_URL}#/disclaimers/draft?collapsed=false`}
              >
                Disclaimers
              </a>
              <a
                href={`${process.env.REACT_APP_ADMIN_URL}#/dph?collapsed=false`}
              >
                DPH
              </a>
            </>
          )}
        </>
      )}
      <NavLink to="/reports">Reports</NavLink>
      <NavLink to="/help">Help</NavLink>
    </ul>
  );
};

export default ExpandedView;
