import React from "react";
import { Icon, Status, StatusComponent } from "vapi-ui-common";
import { SeriesItem, SeriesMyItem } from "../../../gql/generated";
import useStores from "../../../stores/useStores";

interface OutOfSyncStatusModelProps {
  model: SeriesMyItem;
  seriesItem: SeriesItem;
}

const OutOfSyncStatusModel = ({
  model,
  seriesItem,
}: OutOfSyncStatusModelProps) => {
  const { teamStore } = useStores();

  const { outOfSync, spanishVersion, comLangIds, year, isDraft } = model;

  if (outOfSync && spanishVersion) {
    return (
      <StatusComponent
        statusText="Out of Sync"
        status={Status.OUT_OF_SYNC}
        statusIcon={Icon.EXCLAMATION_TRIANGLE}
      />
    );
  }

  const showCommonLanguage =
    teamStore.team.showCommonLanguage &&
    process.env.REACT_APP_COMMON_LANGUAGE === "true";

  if (showCommonLanguage && comLangIds?.length) {
    const hasDraft = seriesItem.years?.find(
      (my) => my.year === year && my.isDraft
    );

    if (!isDraft && hasDraft) return null;

    return (
      <StatusComponent
        statusText="Common Language out of sync"
        status={Status.OUT_OF_SYNC}
        statusIcon={Icon.EXCLAMATION_TRIANGLE}
      />
    );
  }

  return null;
};

export default OutOfSyncStatusModel;
