/* eslint-disable no-param-reassign */
import cx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react";
import { FlagsCell, TableCell, TableRow } from "vapi-ui-common";
import styles from "./commonLanguageTable.module.scss";
import { AccessoryCommonLanguageItem } from "../../../../models/commonLanguage.model";
import AccessoryRichText from "../../../../components/AccessoryRichText";
import MsrpPopover from "../../../../components/MsrpPopover/MsrpPopover";
import checkForSyncChanges from "../../../../utils/checkForSyncChanges";
import useRowHandlers from "../../hooks/useRowHandlers";
import ProductTypeDropdown from "../ProductTypeDropdown/ProductTypeDropdown";
import useStores from "../../../../stores/useStores";
import Input from "../../../../components/Input";

interface GstRightRowProps {
  index: number;
  item: AccessoryCommonLanguageItem;
  show: {
    description: boolean;
    descriptionEs: boolean;
    disclaimer: boolean;
    disclaimerEs: boolean;
    warranty: boolean;
    warrantyEs: boolean;
  };
  readonly?: boolean;
}

const GstRightRow = ({ index, item, show, readonly }: GstRightRowProps) => {
  const { commonLanguageStore } = useStores();
  const { productTypes } = commonLanguageStore;
  const {
    handleInputChange,
    handleTextChange,
    handleRichTextChange,
    handleProductTypeSelect,
    handleProductTypeCreate,
    handleProductTypeUpdate,
  } = useRowHandlers(item);

  const selectedProductType = useMemo(
    () => productTypes.find((pt) => pt.id === item.productType),
    [item.productType, productTypes]
  );

  return (
    <TableRow>
      {/* Product Type */}
      <TableCell className={cx(styles.cell)}>
        <ProductTypeDropdown
          value={selectedProductType?.name ?? ""}
          productTypes={productTypes}
          disabled={readonly}
          onAdd={handleProductTypeCreate}
          onSelect={handleProductTypeSelect}
          onEdit={handleProductTypeUpdate}
        />
      </TableCell>

      {/* Description */}
      {show.description && (
        <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
          <AccessoryRichText
            value={item.description}
            onBlur={handleRichTextChange("description")}
            disabled={readonly}
          />
        </TableCell>
      )}

      {/* Description Es */}
      {show.descriptionEs && (
        <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
          <AccessoryRichText
            value={item.description_es}
            onBlur={handleRichTextChange("description_es")}
            disabled={readonly}
          />
        </TableCell>
      )}

      {/* Disclaimer */}
      {show.disclaimer && (
        <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
          <AccessoryRichText
            value={item.disclaimer}
            onBlur={handleRichTextChange("disclaimer")}
            disabled={readonly}
          />
        </TableCell>
      )}

      {/* Disclaimer Es */}
      {show.disclaimerEs && (
        <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
          <AccessoryRichText
            value={item.disclaimer_es}
            onBlur={handleRichTextChange("disclaimer_es")}
            disabled={readonly}
          />
        </TableCell>
      )}

      {/* Warranty */}
      {show.warranty && (
        <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
          <AccessoryRichText
            value={item.warranty}
            onBlur={handleRichTextChange("warranty")}
            disabled={readonly}
          />
        </TableCell>
      )}

      {/* Warranty Es */}
      {show.warrantyEs && (
        <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
          <AccessoryRichText
            value={item.warranty_es}
            onBlur={handleRichTextChange("warranty_es")}
            disabled={readonly}
          />
        </TableCell>
      )}

      {/* Required/Conflicts */}
      <TableCell className={cx(styles.cell)}>
        <Input
          id={`input-required-${item.id}`}
          label="Required"
          name={`input-required-${item.id}`}
          type="text"
          defaultValue={item.required ?? ""}
          onBlur={handleInputChange("required")}
          disabled={readonly}
        />
        <Input
          id={`input-conflicts-${item.id}`}
          label="Conflicts"
          type="text"
          defaultValue={item.conflicts ?? ""}
          onBlur={handleInputChange("conflicts")}
          disabled={readonly}
        />
      </TableCell>

      {/* Part Number */}
      <TableCell className={cx(styles.cell, styles.textArea, styles.wide)}>
        <AccessoryRichText
          value={item.partNumber}
          onBlur={handleRichTextChange("partNumber")}
          disabled={readonly}
        />
      </TableCell>

      <FlagsCell
        index={index}
        notes={item.notes ?? ""}
        msrp={item.msrp ?? ""}
        msrpPopover
        inProgress={false}
        displayInProgressCheckBox={false}
        highlighted={false}
        disabled={readonly}
        onNotesChange={handleTextChange("notes")}
        renderMsrpPopover={() => (
          <MsrpPopover
            title="MSRP"
            align="right"
            msrpPopover
            hasMsrpChangedAttributes={checkForSyncChanges(
              item.changedAttributes,
              "msrp"
            )}
            msrp={item.msrp || ""}
            handleUpdateItem={handleTextChange("msrp")}
            readOnly={readonly}
            popoverClass={styles.msrpPopoverClass}
            hideAddTrimMsrp
          />
        )}
      />

      {/* Published/Archived Date */}
      {item.publishedDate && readonly && (
        <TableCell className={cx(styles.cell)}>
          {new Date(item.publishedDate).toLocaleDateString()}
        </TableCell>
      )}
    </TableRow>
  );
};

export default observer(GstRightRow);
