import React, { useMemo, useState } from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "vapi-ui-common";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import UploadImage from "./UploadImage";
import Input from "../../Input";
import styles from "../Images.module.scss";
import {
  useCreateUploadSignUrlMutation,
  useGetImagesQuery,
} from "../../../gql/generated";
import useStores from "../../../stores/useStores";
import { ACCESSORY_TEAM, NATIONAL_REGION } from "../../../constants/Constants";

interface UploadImageModalProps {
  onClose: () => void;
  onBack: () => void;
  onUpload: (file: File, partNumber: string) => void;
}

const UploadImageModal = ({
  onClose,
  onBack,
  onUpload,
}: UploadImageModalProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [partNumber, setPartNumber] = useState<string>("");

  const { userStore } = useStores();

  const { data: imagesData } = useGetImagesQuery({
    variables: {
      brand: userStore.brand,
      team: ACCESSORY_TEAM,
      region: NATIONAL_REGION,
      searchString: partNumber,
    },
  });
  const queriedParts = useMemo(() => {
    return new Set(
      imagesData?.images?.images?.map(
        ({ name }) => (name ?? "").split(".")[0]
      ) ?? []
    );
  }, [imagesData]);

  const showWarning = useMemo(() => {
    return queriedParts.has(partNumber) ?? false;
  }, [partNumber, queriedParts]);

  const [createUploadSignUrl] = useCreateUploadSignUrlMutation();

  const handleSelectUpload = (newFile: File) => {
    setFile(newFile);
  };

  const handlePartNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPartNumber(event.target.value.toUpperCase());
  };

  const [isUploading, setIsUploading] = useState(false);
  const handleUpload = async () => {
    if (file && partNumber) {
      try {
        setIsUploading(true);
        const createUploadSignUrlResponse = await createUploadSignUrl({
          variables: { filename: `${partNumber}.jpg` },
        });

        if (createUploadSignUrlResponse.data) {
          const { url, fields } =
            createUploadSignUrlResponse.data.createUploadSignUrl;

          const formData = new FormData();
          Object.entries({ ...fields, file }).forEach(([key, value]) => {
            formData.append(key, value as string);
          });

          await fetch(url, {
            method: "POST",
            body: formData,
            mode: "no-cors",
          });

          onUpload(file, partNumber);
        }
        setIsUploading(false);
      } catch (error) {
        toast.error("Error uploading image");
        setIsUploading(false);
      }
    }
  };

  return (
    <>
      <ModalHeader onClose={onClose}>
        Image URL &gt; Upload new image
      </ModalHeader>
      <ModalBody>
        <div className={styles.imageUploadContainer}>
          <UploadImage file={file} onUpload={handleSelectUpload} />
        </div>
        <div className={clsx(styles.formField, styles.form)}>
          <Input
            className={clsx(
              styles.inputPartNumber,
              showWarning && styles.warning
            )}
            id="partNumber"
            label="Enter part number"
            placeholder="Part number"
            value={partNumber}
            onChange={handlePartNumberChange}
          />
          {showWarning && (
            <div className={styles.subTextWarning}>
              <FontAwesomeIcon icon={faExclamationTriangle} />
              <span>
                Image already exists. Image will be overwritten by future
                updates in ADS.
              </span>
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={onBack}>
          Back
        </Button>
        <Button
          variant="primary"
          onClick={handleUpload}
          disabled={!partNumber || !file || isUploading}
        >
          Upload Image
        </Button>
      </ModalFooter>
    </>
  );
};

export default UploadImageModal;
