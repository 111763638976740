import React, { useContext } from "react";
import { Language } from "../../../../../models/user/user.model";
import AccessoriesEntryScreenContext from "../AccessoriesEntryScreenContext";
import CDropdownEditorItem from "./CDropdownEditorItem";
import useSortedProductTypes from "../../../../../hooks/useSortedProductTypes";

type TDropdownEditorListProps = {
  onClose: () => void;
  language: Language;
};

const CDropdownEditorList = ({
  onClose,
  language,
}: TDropdownEditorListProps) => {
  const context = useContext(AccessoriesEntryScreenContext);

  const { productTypeLangMap } = context;

  const productTypes = Object.values(productTypeLangMap).map(
    (productTypeLang) => productTypeLang.langs[language]
  );

  const { sortedProductTypes, setIsUpdated } =
    useSortedProductTypes(productTypes);

  return (
    <>
      {sortedProductTypes?.map((productType) => {
        return (
          <CDropdownEditorItem
            key={productType.id}
            productType={productType}
            language={language}
            onClose={onClose}
            setIsUpdated={setIsUpdated}
          />
        );
      })}
    </>
  );
};

export default CDropdownEditorList;
