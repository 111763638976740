import React, { useMemo } from "react";
import { DropdownList } from "vapi-ui-common";

interface VersionsListProps {
  value: string;
  latestVersion: number | undefined | null;
  hasDraft?: boolean;
  onSelect: (value: string) => void;
  className?: string;
}

const createList = (
  latestVersion: number | undefined | null,
  hasDraft: boolean
) => {
  const list = hasDraft ? ["DRAFT"] : [];
  if (latestVersion) {
    let curs = latestVersion;
    while (curs > 0) {
      list.push(curs.toString());
      curs -= 1;
    }
  }

  return list;
};

const VersionsList = ({
  value,
  latestVersion,
  hasDraft = false,
  onSelect,
  className,
}: VersionsListProps) => {
  const list = useMemo(
    () => createList(latestVersion, hasDraft),
    [latestVersion, hasDraft]
  );
  return (
    <DropdownList
      value={value}
      list={list}
      onSelect={onSelect}
      align="right"
      className={className}
    />
  );
};

VersionsList.defaultProps = {
  hasDraft: false,
  className: undefined,
};

export default VersionsList;
