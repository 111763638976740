import cx from "clsx";
import React, { useEffect, useState } from "react";
import {
  ActionButton,
  Dropdown,
  DropdownButton,
  DropdownContent,
  Input,
  useComponentVisible,
} from "vapi-ui-common";
import { GradeItem } from "../../stores/modelStore";
import msrpPopoverStore, { MsrpVM } from "../../stores/MsrpPopoverStore";
import styles from "./MsrpPopover.module.scss";

interface IMsrpPopoverDropdown {
  msrpItem: MsrpVM;
  onCostChange?: (cost: string) => void;
  selectGrade: (item: GradeItem, msrpItem: MsrpVM) => void;
  modelGrades: GradeItem[];
}

const getFontSize = (word: string | undefined) => {
  if (!word) {
    return 13;
  }
  let wordLength = 0;
  const splitWord = word.split(" ");
  const numWords = splitWord.length;
  for (let i = 0; i < numWords; i += 1) {
    const wordLen = splitWord[i].length;
    if (wordLen > wordLength) {
      wordLength = wordLen;
    }
  }
  if (wordLength < 5) {
    return 13;
  }
  if (wordLength < 8) {
    return 12;
  }
  if (wordLength < 10) {
    return 11;
  }
  if (wordLength < 12) {
    return 10;
  }
  return 9;
};

const MsrpPopoverDropdown = ({
  msrpItem,
  onCostChange = undefined,
  selectGrade,
  modelGrades,
}: IMsrpPopoverDropdown) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
  const [msrpValue, setMsrpValue] = useState<string>("");

  useEffect(() => {
    setMsrpValue(msrpItem.msrpValue);
  }, [msrpItem]);

  const ctrl = msrpItem;
  const fontSize = getFontSize(ctrl.value);
  return (
    <div className={styles.inputContainer}>
      <div className={styles.dropdownInputContainer}>
        <div ref={ref}>
          <Dropdown className={styles.dropdownMaxWidth}>
            <DropdownButton
              className={cx(styles.dropdownButton, {
                [styles.errItem]: false,
              })}
              onClick={() => {
                setIsComponentVisible(true);
              }}
              style={{ fontSize }}
            >
              {ctrl.value}
            </DropdownButton>

            <DropdownContent open={isComponentVisible}>
              {modelGrades?.map((item) => (
                <div className={styles.dropdown} key={item.id}>
                  <li
                    key={item.id}
                    role="presentation"
                    onClick={() => {
                      selectGrade(item, ctrl);
                      setIsComponentVisible(false);
                    }}
                  >
                    {item.value}
                  </li>
                </div>
              ))}
            </DropdownContent>
          </Dropdown>
        </div>
        <div className={styles.gradeCurrencyInput}>
          <Input
            className={styles.input}
            onChange={(e) => {
              ctrl.msrpValue = e.currentTarget.value;
              setMsrpValue(ctrl.msrpValue);
              if (typeof onCostChange === "function") {
                onCostChange(e.currentTarget.value.trim());
              }
            }}
            value={msrpValue}
          />
        </div>

        <div className={styles.deleteBtn}>
          <ActionButton
            icon="trash"
            onClick={() => msrpPopoverStore.removeMsrp(ctrl)}
          />
        </div>
      </div>
    </div>
  );
};
MsrpPopoverDropdown.defaultProps = {
  onCostChange: undefined,
};

export default MsrpPopoverDropdown;
