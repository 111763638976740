import { action, observable } from "mobx";
import { AclChange, AclProductTypeItem } from "../gql/generated";
import toLowerCase from "../utils/toLowerCase";
import sortBy from "../utils/sortBy";

export default class CommonLanguageChangeLogStore {
  @observable data: AclChange[] = [];
  @observable searchText = "";
  @observable filteredData: AclChange[] = [];
  @observable productTypes: AclProductTypeItem[] = [];
  @observable lastVersion: number = 0;
  @observable sortKey: keyof AclChange = "modifiedDate";
  @observable sortReverse: boolean = true;

  @action setData = (
    data: AclChange[],
    productType: AclProductTypeItem[],
    lastVersion: number
  ) => {
    this.data = data;
    this.filteredData = data;
    this.productTypes = productType;
    this.lastVersion = lastVersion;
    this.sortData();
  };

  @action filterData = () => {
    const { searchText } = this;
    const filteredData = this.data.filter((item) => {
      const aclItem = item?.changedItem;

      return (
        toLowerCase(aclItem?.title ?? "").includes(toLowerCase(searchText)) ||
        toLowerCase(aclItem?.notes ?? "").includes(toLowerCase(searchText)) ||
        toLowerCase(item?.modifiedBy ?? "").includes(toLowerCase(searchText)) ||
        toLowerCase(item?.changeType ?? "").includes(toLowerCase(searchText))
      );
    });
    this.filteredData = filteredData;
  };

  @action sortData = () => {
    const sortedData = this.filteredData.sort(
      sortBy(this.sortKey, this.sortReverse, this.sortKey === "modifiedDate")
    );
    this.filteredData = sortedData;
  };

  @action setSearchText = (searchText: string) => {
    this.searchText = searchText;
    this.filterData();
  };

  @action setSortKey = (sortKey: keyof AclChange) => {
    if (this.sortKey === sortKey) {
      this.sortReverse = !this.sortReverse;
    } else {
      this.sortKey = sortKey;
    }
    this.sortData();
  };

  @action reset = () => {
    this.data = [];
    this.searchText = "";
    this.filteredData = [];
    this.productTypes = [];
    this.lastVersion = 0;
  };
}
