import React, { useContext } from "react";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";
import styles from "./MsrpFlagsColumn.module.scss";

interface IToggleElementProps {
  onClick?: () => void;
}

const CToggleElement = ({ onClick }: IToggleElementProps) => {
  const { accessoriesLangMap, selectedLanguages, isSpanish } = useContext(
    AccessoriesEntryScreenContext
  );

  const {
    accessoryItem: { id: accessoryItemId },
  } = useContext(AccessoryContext);

  const accessoryLangItem = accessoriesLangMap[accessoryItemId]?.langs;

  if (isSpanish) {
    return (
      <>
        {selectedLanguages.map((language) => {
          const { msrp } = accessoryLangItem[language];

          if (msrp && msrp !== "") {
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
              <div
                data-for="msrp"
                className={styles.msrpLabel}
                key={`${language}-CToggleElement-msrp`}
                onClick={onClick}
              >
                {`${Number(msrp) > 0 ? "$" : ""}${msrp}`}
              </div>
            );
          }

          return null;
        })}
      </>
    );
  }

  return null;
};

CToggleElement.defaultProps = {
  onClick: () => undefined,
};

export default CToggleElement;
