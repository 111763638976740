import React, { useCallback, useContext } from "react";
import { Input } from "vapi-ui-common";
import styles from "../../../../../components/MsrpPopover/MsrpPopover.module.scss";
import { Language } from "../../../../../models/user/user.model";
import AccessoriesEntryScreenContext from "../AccessoriesEntryScreenContext";

interface IPopoverInputProps {
  language: Language;
  msrp: string;
  onChange: (language: Language, msrp: string) => void;
}

const CCPopoverInput = ({ language, msrp, onChange }: IPopoverInputProps) => {
  const { languagePermissions, readOnly } = useContext(
    AccessoriesEntryScreenContext
  );

  const userPermissions = languagePermissions?.[language];
  const disabled = !userPermissions?.canEdit || readOnly;

  const onMSRPChange = useCallback(
    (e) => {
      onChange(language, e.currentTarget.value);
    },
    [onChange]
  );

  return (
    <div className={styles.inputContainer}>
      <span className={styles.currencyInput}>
        <span data-for="msrp" className={styles.label}>
          {`${language} All`}
        </span>
        <Input
          className={styles.input}
          onChange={onMSRPChange}
          value={msrp}
          disabled={disabled}
        />
      </span>
    </div>
  );
};

export default CCPopoverInput;
