import { observer } from "mobx-react-lite";
import React, { useCallback, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Button, ModalBody, ModalFooter, ModalHeader } from "vapi-ui-common";
import GoLiveDateTimePicker from "./GoLiveDateTimePicker/GoLiveDateTimePicker";
import {
  GoLiveDateTime,
  GoLiveDateTimeHour,
  SERVER_GO_LIVE_DATE_FORMAT,
  formatDate,
  goLiveDateTimeMap,
} from "../../../../utils/dateUtils";
import styles from "./GoLiveDate.module.scss";

interface IEditGoLiveDateModalProps {
  close: () => void;
  onSubmit: (goLiveDate: string) => void;
  currentGoLiveDate: Date | null;
  goLiveDateAdmin: string;
  all?: boolean;
  timePicker?: boolean;
  hideAdminDate?: boolean;
}

interface IUseGoLiveDateProps {
  onSubmit: (goLiveDate: string) => void;
  currentGoLiveDate: Date | null;
  all?: boolean;
  timePicker?: boolean;
}

const useGoLiveDate = ({
  currentGoLiveDate,
  onSubmit,
  all,
  timePicker,
}: IUseGoLiveDateProps) => {
  const [confirmEditModal, setConfirmEditModal] = useState<boolean>(false);
  const [goLiveDate, setGoLiveDate] = useState<Date>(
    currentGoLiveDate ?? new Date()
  );

  const [currentTime, setCurrentTime] = useState<
    GoLiveDateTimeHour | undefined
  >(currentGoLiveDate?.getHours() || GoLiveDateTimeHour.AM_5);

  const onChangeTime = useCallback(
    (time: GoLiveDateTime) => setCurrentTime(time.hours),
    [setCurrentTime]
  );

  const onConfirm = useCallback(() => {
    if (all && !confirmEditModal) {
      setConfirmEditModal(true);
      return;
    }

    let date = goLiveDate.toLocaleDateString();

    if (timePicker) {
      const time = goLiveDateTimeMap[currentTime ?? GoLiveDateTimeHour.AM_5];
      goLiveDate.setHours(time.hours, time.minutes);
      date = formatDate(goLiveDate, SERVER_GO_LIVE_DATE_FORMAT);
    }

    onSubmit(date);
  }, [
    currentTime,
    goLiveDate,
    onSubmit,
    all,
    setConfirmEditModal,
    confirmEditModal,
    timePicker,
  ]);

  return {
    goLiveDate,
    onChange: setGoLiveDate,
    onConfirm,
    confirmEditModal,
    currentTime,
    onChangeTime,
  };
};

const EditGoLiveDateModal = observer(
  ({
    close,
    onSubmit,
    currentGoLiveDate,
    goLiveDateAdmin,
    all,
    timePicker,
    hideAdminDate,
  }: IEditGoLiveDateModalProps) => {
    const {
      goLiveDate,
      onChange,
      onConfirm,
      confirmEditModal,
      currentTime,
      onChangeTime,
    } = useGoLiveDate({
      onSubmit,
      currentGoLiveDate,
      all,
      timePicker,
    });

    return (
      <>
        <ModalHeader onClose={close}>
          {`Set Go Live Date ${all ? "for All Models" : ""}`}
        </ModalHeader>
        <ModalBody>
          <div data-testid="edit-go-live-date-modal-body-content">
            {!confirmEditModal && (
              <Calendar
                value={goLiveDate}
                onChange={onChange}
                minDate={new Date()}
              />
            )}

            {confirmEditModal && (
              <div className={styles.confirmEditModal}>
                <span>
                  Are you sure you want to set the go live date for all models
                  to
                </span>
                <b>
                  {`${goLiveDate.toLocaleDateString()} ${
                    goLiveDateTimeMap[currentTime as GoLiveDateTimeHour].text
                  } CST
                  `}
                </b>
                <span>?</span>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter className={styles.modelFooter}>
          {!confirmEditModal && (
            <GoLiveDateTimePicker
              currentTime={currentTime}
              onChange={onChangeTime}
              timePicker={timePicker}
              goLiveDateAdmin={goLiveDateAdmin}
              hideAdminDate={hideAdminDate}
            />
          )}
          <div className={styles.buttonSection}>
            <Button variant="transparent" onClick={close}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirm}>
              {all && !confirmEditModal && "Next"}
              {all && confirmEditModal && "Confirm Go Live Date"}
              {!all && "Confirm"}
            </Button>
          </div>
        </ModalFooter>
      </>
    );
  }
);

export default EditGoLiveDateModal;
