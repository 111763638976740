import React, { useCallback, useContext } from "react";
import { TableCell, areRichTextValuesDifferent } from "vapi-ui-common";
import Input from "../../../../../components/Input";
import { Language } from "../../../../../models/user/user.model";
import useStores from "../../../../../stores/useStores";
import styles from "../../../AccessoriesEntryScreen.module.scss";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";

const CColumn = () => {
  const { userStore } = useStores();

  const { readOnly, saveAccessoryItem, languagePermissions } = useContext(
    AccessoriesEntryScreenContext
  );

  const { accessoryItem } = useContext(AccessoryContext);

  const userPermissionsEN = languagePermissions?.[Language.EN];
  const disabled = !userPermissionsEN?.canEdit || readOnly;

  const onChangePartsOnlyDealerCost = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = event.target.value;

      if (
        areRichTextValuesDifferent(accessoryItem.partsOnlyDealerCost || "", val)
      ) {
        const acc = accessoryItem;
        acc.partsOnlyDealerCost = val;
        saveAccessoryItem(acc);
      }
    },
    [saveAccessoryItem, accessoryItem]
  );

  const onChangeInstalledDealerCost = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const val = event.target.value;

      if (
        areRichTextValuesDifferent(accessoryItem.installedDealerCost || "", val)
      ) {
        const acc = accessoryItem;
        acc.installedDealerCost = val;
        saveAccessoryItem(acc);
      }
    },
    [saveAccessoryItem, accessoryItem]
  );

  if (!userStore.isToyotaNationalUser()) return null;

  return (
    <TableCell large>
      <Input
        id="accessory-parts-only-dealer-cost-input"
        label="Parts Only"
        disabled={disabled}
        value={accessoryItem.partsOnlyDealerCost || ""}
        onChange={onChangePartsOnlyDealerCost}
      />
      <div className={styles.separator} />
      <Input
        id="accessory-installed-dealer-cost-input"
        label="Installed"
        disabled={disabled}
        value={accessoryItem.installedDealerCost || ""}
        onChange={onChangeInstalledDealerCost}
      />
    </TableCell>
  );
};

export default CColumn;
