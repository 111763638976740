/* eslint-disable react/jsx-one-expression-per-line */
import { observer } from "mobx-react-lite";
import React from "react";
import {
  HeaderCell,
  Table,
  TableRow,
  Thead,
  TwoTableWrapper,
} from "vapi-ui-common";
import { AccessoryCommonLanguageItem } from "../../../../models/commonLanguage.model";
import LanguageToggle, {
  useLanguageToggle,
} from "../../../../components/LanguageToggle";
import styles from "./commonLanguageTable.module.scss";
import useStores from "../../../../stores/useStores";
import GstLeftRow from "./GstLeftRow";
import GstRightRow from "./GstRightRow";

interface GstTableProps {
  readonly?: boolean;
  archived?: boolean;
}

const GstTable = observer(({ readonly, archived }: GstTableProps) => {
  const { commonLanguageStore } = useStores();
  const titleLangToggleProps = useLanguageToggle();
  const descriptionLangToggleProps = useLanguageToggle();
  const disclaimerLangToggleProps = useLanguageToggle();
  const warrantyLangToggleProps = useLanguageToggle();

  const showSecondTitle =
    titleLangToggleProps.enChecked && titleLangToggleProps.esChecked;
  const showSecondDescription =
    descriptionLangToggleProps.enChecked &&
    descriptionLangToggleProps.esChecked;
  const showSecondDisclaimer =
    disclaimerLangToggleProps.enChecked && disclaimerLangToggleProps.esChecked;
  const showSecondWarranty =
    warrantyLangToggleProps.enChecked && warrantyLangToggleProps.esChecked;

  const handleSort = (key: keyof AccessoryCommonLanguageItem) => () => {
    commonLanguageStore.setSort(key);
  };

  return (
    <TwoTableWrapper>
      <Table fullWidth className={styles.sticky}>
        <Thead>
          <TableRow>
            {!readonly && <HeaderCell>{/* Action buttons */}</HeaderCell>}
            <HeaderCell onClick={handleSort("supplier")}>Supplier</HeaderCell>
            <HeaderCell>
              Name
              <LanguageToggle id="title" {...titleLangToggleProps} />
            </HeaderCell>
            {showSecondTitle && <HeaderCell>{/* Name Es */}</HeaderCell>}
          </TableRow>
        </Thead>

        <tbody>
          {commonLanguageStore.filteredData.map((item) => (
            <GstLeftRow
              key={item.uid}
              item={item}
              show={{
                name: titleLangToggleProps.enChecked,
                nameEs: titleLangToggleProps.esChecked,
              }}
              readonly={readonly}
            />
          ))}
        </tbody>
      </Table>
      <Table fullWidth>
        <Thead>
          <TableRow>
            <HeaderCell>Product Type</HeaderCell>
            <HeaderCell>
              Description
              <LanguageToggle
                id="description"
                {...descriptionLangToggleProps}
              />
            </HeaderCell>
            {showSecondDescription && (
              <HeaderCell>{/* Description Es */}</HeaderCell>
            )}

            <HeaderCell>
              Disclaimer
              <LanguageToggle id="disclaimer" {...disclaimerLangToggleProps} />
            </HeaderCell>
            {showSecondDisclaimer && (
              <HeaderCell>{/* Disclaimer Es */}</HeaderCell>
            )}
            <HeaderCell>
              Warranty
              <LanguageToggle id="warranty" {...warrantyLangToggleProps} />
            </HeaderCell>
            {showSecondWarranty && <HeaderCell>{/* Warranty Es */}</HeaderCell>}
            <HeaderCell>Required/Conflicts</HeaderCell>
            <HeaderCell onClick={handleSort("partNumber")}>
              Part Number
            </HeaderCell>
            <HeaderCell>{/* Row Level Information */}</HeaderCell>
            {readonly && (
              <HeaderCell>{archived ? "Archive" : "Publish"} Date</HeaderCell>
            )}
          </TableRow>
        </Thead>

        <tbody>
          {commonLanguageStore.filteredData.map((item, index) => (
            <GstRightRow
              key={item.uid}
              index={index}
              item={item}
              show={{
                description: descriptionLangToggleProps.enChecked,
                descriptionEs: descriptionLangToggleProps.esChecked,
                disclaimer: disclaimerLangToggleProps.enChecked,
                disclaimerEs: disclaimerLangToggleProps.esChecked,
                warranty: warrantyLangToggleProps.enChecked,
                warrantyEs: warrantyLangToggleProps.esChecked,
              }}
              readonly={readonly}
            />
          ))}
        </tbody>
      </Table>
    </TwoTableWrapper>
  );
});

export default GstTable;
