import React from "react";
import { Redirect } from "react-router-dom";
import useStore from '../stores/useStores';


const DashboardRouteController = () => {
  const { userStore } = useStore();
  if (userStore.isNationalUser()) {
    return <Redirect to="/national/dashboard" />
  }
  return (
    <Redirect to="/gst/dashboard" />
  );
}

export default DashboardRouteController;