import React from "react";

interface Props {
  before: string;
  after: string;
}

const ChangeLogImages = ({ before, after }: Props) => {
  const hasBefore = before && Object.keys(JSON.parse(before)).length;
  const hasAfter = after && Object.keys(JSON.parse(after)).length;

  let title = "Image(s) Added";
  if (hasBefore && hasAfter) {
    title = "Image(s) Updated";
  } else if (hasBefore && !hasAfter) {
    title = "Image(s) Deleted";
  }
  return <div>{title}</div>;
};

export default ChangeLogImages;
