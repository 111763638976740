import React from "react";
import { useParams } from "react-router-dom";
import { VehicleTeam } from "../models/vehicleData/vehicleData.model";
import AccessoriesEntryScreenController from "./accessories/AccessoriesEntryScreen";
import GSTAccessories from "./gst/Accessories";

const AccessoriesRouteController = () => {
  const params: { team: string } = useParams();
  if (params.team === VehicleTeam.GST_ACC_TEAM) {
    return <GSTAccessories />;
  }
  return <AccessoriesEntryScreenController />;
};

export default AccessoriesRouteController;
