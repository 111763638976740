import React, { useContext } from "react";
import { TableCell } from "vapi-ui-common";
import styles from "../../../AccessoriesEntryScreen.module.scss";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";
import CDropdownEditor from "./CDropdownEditor";
import CSpanishEditor from "./CSpanishEditor";

const CColumn = () => {
  const { selectedLanguages, isSpanish } = useContext(
    AccessoriesEntryScreenContext
  );

  const {
    accessoryItem: { id: accessoryItemId },
  } = useContext(AccessoryContext);

  return (
    <TableCell noSpan className={styles.productTypeColumn}>
      {selectedLanguages.map((language) => {
        if (isSpanish) {
          return (
            <CSpanishEditor
              key={`${language}-product-type-column-${accessoryItemId}`}
              language={language}
            />
          );
        }

        return (
          <CDropdownEditor
            key={`${language}-product-type-column-${accessoryItemId}`}
            language={language}
          />
        );
      })}
    </TableCell>
  );
};

export default CColumn;
