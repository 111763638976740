import React, { useCallback, useContext } from "react";
import { HeaderCell } from "vapi-ui-common";
import AccessoriesEntryScreenContext from "../AccessoriesEntryScreenContext";

const CHeader = () => {
  const context = useContext(AccessoriesEntryScreenContext);

  const { selectedLanguages, setSort } = context;
  const onClick = useCallback(() => setSort("warranty"), [setSort]);

  return (
    <>
      {selectedLanguages.map((language) => {
        return (
          <HeaderCell key={`${language}-warranty-header`} onClick={onClick}>
            {`${language} Warranty`}
          </HeaderCell>
        );
      })}
    </>
  );
};

export default CHeader;
