import { ChangeLogTypes } from "../../../../models/accessories/changeLog.model";

const changeRegistery: {
  [id in ChangeLogTypes]?: ({
    before,
    after,
  }: {
    before: string;
    after: string;
  }) => any;
} = {
  //
  //
};

export default changeRegistery;
