const getChangeLogExitVersion = (
  queryParam: string | null,
  latestVersion: number | null | undefined,
  hasDraft: boolean | null | undefined
) => {
  if (queryParam) {
    return queryParam;
  }
  if (!hasDraft && latestVersion) {
    return latestVersion.toString();
  }
  return "DRAFT";
};

export default getChangeLogExitVersion;
