/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Dropdown, DropdownContent, useComponentVisible } from "vapi-ui-common";
import MenuButton from "../MenuButton";
import styles from "./menuDropdown.module.scss";

interface DropdownListProps {
  menuButton: React.ReactElement;
  list: Array<React.ReactElement>;
  onSelect: (item: string) => void;
}

const MenuDropdown = ({
  menuButton,
  list = [],
  onSelect,
}: DropdownListProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <Dropdown>
      <MenuButton
        toggled={isComponentVisible}
        onClick={() => setIsComponentVisible(true)}
      >
        {menuButton}
      </MenuButton>
      <DropdownContent open={isComponentVisible} ref={ref}>
        <ul className={styles.list}>
          {list.map((item) => (
            <li
              key={`li-${item.key ?? item.props.text}`}
              onClick={() => {
                onSelect(item.key ?? item.props.text);
                setIsComponentVisible(false);
              }}
            >
              {item}
            </li>
          ))}
        </ul>
      </DropdownContent>
    </Dropdown>
  );
};

export default MenuDropdown;
