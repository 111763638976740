import React from "react";
import {
  Spinner,
  StickyContainer,
  StickyHeaderContainer,
} from "vapi-ui-common";
import { observer } from "mobx-react-lite";
import useStores from "../../stores/useStores";
import ActionBarCommonLanguage from "./components/ActionBarCommonLanguage/ActionBarCommonLanguage";
import { CLTable, GstTable } from "./components/CommonLanguageTable";
import { useGetArchivedCLData } from "./hooks";
import ArchivedHeader from "./components/Header/ArchivedHeader";
import useActionBarCLProps from "./hooks/useActionBarCLProps";

const Archived = () => {
  const { userStore, commonLanguageStore } = useStores();
  const isGst = userStore.isGstUser();
  useGetArchivedCLData();

  const actionBarProps = useActionBarCLProps();

  if (commonLanguageStore.isFetching) {
    return <Spinner />;
  }

  return (
    <StickyContainer>
      <StickyHeaderContainer>
        <ArchivedHeader />
        <ActionBarCommonLanguage readonly {...actionBarProps} />
      </StickyHeaderContainer>
      {isGst ? <GstTable readonly archived /> : <CLTable readonly archived />}
    </StickyContainer>
  );
};

export default observer(Archived);
