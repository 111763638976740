import adminApolloClient from "../adminApolloClient";
import {
  CreateSignedUrlDocument,
  CreateSignedUrlMutation,
  CreateSignedUrlMutationVariables,
  S3Action,
} from "../gql/adminGenerated";

const downloadReport = async (
  variables: Omit<CreateSignedUrlMutationVariables, "action">
): Promise<string> => {
  const { data } = await adminApolloClient.mutate<
    CreateSignedUrlMutation,
    CreateSignedUrlMutationVariables
  >({
    mutation: CreateSignedUrlDocument,
    variables: {
      ...variables,
      action: S3Action.Download,
    },
  });

  if (!data) {
    throw new Error("Failed to generate signed url");
  }

  return data.createSignedUrl.body;
};

export default downloadReport;
