import { useParams } from "react-router";
import { VehicleTeam } from '../models/vehicleData/vehicleData.model';

type ParamProps = {
  team: VehicleTeam;
  [key: string]: string
};

const useRouteParams = ():ParamProps => {
  const params = useParams<ParamProps>();
  const returnParams = {} as ParamProps;

  Object.keys(params).forEach(key => {
    if (key === 'team') {
      returnParams.team = params[key] as VehicleTeam;
    } else {
      returnParams[key] = params[key];
    }
  });

  return returnParams;
};

export default useRouteParams;
