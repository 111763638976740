import React, { useMemo, useState } from "react";
import {
  FlagCellProps,
  FlagsCell,
  ImageItem,
  Modal,
  useDebounce,
} from "vapi-ui-common";
import { GradeMsrpItem } from "../../gql/generated";
import { GradeItem } from "../../stores/modelStore";
import EditImageModal from "../Images/EditImageModal";
import ImageLibraryModal from "../Images/ImageLibraryModal";
import ImageUrlModal from "../Images/ImageUrlModal";
import MsrpPopover from "../MsrpPopover";
import styles from "./MsrpFlagsCell.module.scss";
import UploadImageModal from "../Images/UploadImageModal";
import {
  cachedPartNumberImage,
  uncachedPartNumberImage,
} from "../../utils/partNumberImage";

interface MsrpFlagsCellProps extends FlagCellProps {
  gradeMsrp: GradeMsrpItem[];
  modelGrades: GradeItem[];
  hideAddTrimMsrp: boolean;
  displayImagePopover?: boolean;
  align: "right" | "left";
  msrpPopover: boolean;
  onImageChange?: (image: ImageItem[]) => void;
  msrpElement?: React.ReactElement;
  msrpPopoverToggleElement?: React.ReactElement;
  msrpPopoverPopoverElement?: React.ReactElement;
  msrpDisabled?: boolean;
  hasMsrpChangedAttributes?: boolean;
}

const MsrpFlagsCell = ({
  gradeMsrp,
  modelGrades,
  msrp,
  msrpPopover,
  align,
  onExtraCostChange,
  onImageChange = () => {},
  onGradeIdExtraCostChange,
  disabled,
  hideAddTrimMsrp,
  displayImagePopover = false,
  images,
  msrpDisabled,
  msrpPopoverToggleElement,
  msrpPopoverPopoverElement,
  msrpElement,
  hasMsrpChangedAttributes,
  ...props
}: MsrpFlagsCellProps) => {
  const { debounce } = useDebounce();

  const [openImageModal, setOpenImageModal] = useState(false);
  const [openImageLibraryModal, setOpenImageLibraryModal] = useState(false);
  const [openEditImageModal, setOpenEditImageModal] = useState(false);
  const [openUploadImageModal, setOpenUploadImageModal] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());
  const [searchText, setSearchText] = useState("");

  const heroImage = useMemo(() => {
    return uncachedPartNumberImage(
      images?.find((img) => img.isHero)?.image,
      timestamp
    );
  }, [images, timestamp]);
  const uncachedImages = useMemo(() => {
    return images?.map((img) => ({
      ...img,
      image: uncachedPartNumberImage(img.image, timestamp),
    }));
  }, [images, timestamp]);

  const updateHeroImage = (newHeroImage: string) => {
    if (!images) {
      return;
    }

    const cachedHeroImage = cachedPartNumberImage(newHeroImage);
    onImageChange(
      images.map((img) => ({
        ...img,
        isHero: img.image === cachedHeroImage,
      }))
    );
  };

  const handleSearchFilter = (query: string) => {
    debounce(() => {
      setSearchText(query.toUpperCase());
    });
  };

  return (
    <>
      <FlagsCell
        onClickAddImage={() => setOpenImageLibraryModal(true)}
        onClickEditImage={() => setOpenEditImageModal(true)}
        images={uncachedImages}
        displayImagePopover={displayImagePopover}
        heroImage={heroImage}
        updateHeroImage={updateHeroImage}
        {...props}
        disabled={disabled}
        renderMsrpPopover={() => (
          <MsrpPopover
            align={align}
            msrpPopover={msrpPopover}
            gradeMsrp={gradeMsrp}
            modelGrades={modelGrades}
            hasMsrpChangedAttributes={hasMsrpChangedAttributes}
            msrp={msrp || ""}
            handleUpdateItem={onExtraCostChange}
            handleGradeIdExtraCostChange={onGradeIdExtraCostChange}
            readOnly={msrpDisabled ?? disabled}
            popoverClass={styles.msrpPopoverClass}
            title="MSRP"
            hideAddTrimMsrp={hideAddTrimMsrp}
            toggleElement={msrpPopoverToggleElement}
            popoverElement={msrpPopoverPopoverElement}
            element={msrpElement}
          />
        )}
      />

      <Modal open={openImageModal} onClose={() => setOpenImageModal(false)}>
        <ImageUrlModal
          close={() => setOpenImageModal(false)}
          isSelected={false}
          updateSelectType={() => {
            setOpenImageModal(false);
            setOpenImageLibraryModal(true);
            setTimestamp(Date.now());
          }}
        />
      </Modal>
      <Modal
        open={openImageLibraryModal}
        onClose={() => {
          setOpenImageLibraryModal(false);
          setOpenUploadImageModal(false);
        }}
      >
        {openUploadImageModal ? (
          <UploadImageModal
            onClose={() => {
              setOpenImageLibraryModal(false);
              setOpenUploadImageModal(false);
            }}
            onBack={() => {
              setOpenUploadImageModal(false);
            }}
            onUpload={() => {
              setOpenUploadImageModal(false);
              setOpenImageLibraryModal(true);
              setTimestamp(Date.now());
            }}
          />
        ) : (
          <ImageLibraryModal
            close={() => setOpenImageLibraryModal(false)}
            userImages={images || []}
            searchText={searchText}
            setSearch={setSearchText}
            onSearch={handleSearchFilter}
            onImageChange={(newSelectedImages) => {
              onImageChange(newSelectedImages);
              setTimestamp(Date.now());
            }}
            onOpenUploadImageModal={() => {
              setOpenUploadImageModal(true);
            }}
          />
        )}
      </Modal>
      <Modal
        open={openEditImageModal}
        onClose={() => setOpenEditImageModal(false)}
      >
        <EditImageModal
          onClickBrowse={() => setOpenImageLibraryModal(true)}
          targetObj={images?.find((el) => el.isHero) as ImageItem}
          close={() => setOpenEditImageModal(false)}
        />
      </Modal>
    </>
  );
};

MsrpFlagsCell.defaultProps = {
  displayImagePopover: false,
  onImageChange: () => {},
  msrpPopoverToggleElement: undefined,
  msrpPopoverPopoverElement: undefined,
  msrpElement: undefined,
  msrpDisabled: undefined,
  hasMsrpChangedAttributes: false,
};

export default MsrpFlagsCell;
