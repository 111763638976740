import { action, computed, observable } from "mobx";
import { ReportItem, VehicleModel } from "../models/reports/reports.model";
import sortBy from "../utils/sortBy";

class ReportsStore {
  @observable reportType = "";

  @observable allReports: ReportItem[] = [];

  @observable models: VehicleModel[] = [];

  @observable seriesList: string[] = [];

  @observable seriesYearList: string[] = [];

  @observable versionList: string[] = [];

  @observable reverse = true;

  @observable sortField = "generatedDate";

  loaded = false;

  @computed get sortedReports() {
    const reports = this.allReports
      .slice()
      .sort(
        sortBy(this.sortField, this.reverse, this.sortField === "generatedDate")
      );

    return reports;
  }

  @action reset() {
    this.reportType = "";
    this.allReports = [];
    this.models = [];
    this.seriesList = [];
    this.seriesYearList = [];
    this.versionList = [];
    this.reverse = true;
    this.sortField = "generatedDate";
  }
}

export default ReportsStore;
