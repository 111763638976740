import { useEffect } from "react";
import { toast } from "react-toastify";
import useStores from "../../../stores/useStores";
import logger from "../../../log";
import {
  AclDocument,
  useAclCurrentVersionDocumentsQuery,
  useGetAclProductTypesQuery,
} from "../../../gql/generated";
import {
  ACCESSORY_TEAM,
  GST_REGION,
  NATIONAL_REGION,
} from "../../../constants/Constants";
import { AccessoryCommonLanguageItem } from "../../../models/commonLanguage.model";

const useGetCLData = () => {
  const { userStore, commonLanguageStore } = useStores();

  const oppCtx = {
    brand: userStore.brand,
    region: userStore.isGstUser() ? GST_REGION : NATIONAL_REGION,
    team: ACCESSORY_TEAM,
  };

  const getAclResult = useAclCurrentVersionDocumentsQuery({
    skip: !oppCtx.brand || !oppCtx.region,
    fetchPolicy: "network-only",
    variables: oppCtx,
    onError: (e) => {
      logger.error(e);
      toast.error("Error loading common language data");
    },
  });
  const getAclProductTypesResult = useGetAclProductTypesQuery({
    skip: !oppCtx.brand || !oppCtx.region,
    fetchPolicy: "network-only",
    variables: {
      input: oppCtx,
    },
    onError: (e) => {
      logger.error(e);
      toast.error("Error loading product type data");
    },
  });

  useEffect(() => {
    const aclItems = getAclResult.data?.aclCurrentVersionDocuments;
    const productTypes = getAclProductTypesResult.data?.getACLProductTypes;

    if (!aclItems || !productTypes) {
      commonLanguageStore.isFetching = true;

      return () => {};
    }

    const accCLItems = aclItems.map((item) => {
      return AccessoryCommonLanguageItem.fromACLDocument(item as AclDocument);
    });

    commonLanguageStore.setData(
      accCLItems,
      productTypes,
      getAclResult.data?.aclHeader.toString() ?? "0"
    );

    return () => {
      commonLanguageStore.reset();
    };
  }, [getAclResult.data, getAclProductTypesResult.data]);
};

export default useGetCLData;
