import cx from "clsx";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { toast } from "react-toastify";
import { Button, Checkbox, Header, useChecklist } from "vapi-ui-common";
import { ChecklistItem } from "vapi-ui-common/dist/src/hooks/useChecklist/useChecklist";
import useRouteParams from "../../hooks/useRouteParams";
import {
  Series,
  SeriesManagerVehicleChecklist,
  UserSeries,
} from "../../models/vehicleData/vehicleData.model";
import useStores from "../../stores/useStores";
import { updateUserSeries } from "../../webservices/adminApi";
import image from "./car_jelly.png";
import styles from "./seriesManager.module.scss";
import { Brand } from "../../gql/adminGenerated";

const SeriesManager = () => {
  const { checklist, setChecklist, selectItem, selectAll } = useChecklist();
  const { userStore, dashboardStore } = useStores();
  const [redirectToDashboard, setRedirectToDashboard] = useState(false);
  const { team } = useRouteParams();
  const carImage = image;

  const createChecklist = (series: Series, userSeries: UserSeries[]) => {
    const vehicleModels = Object.keys(series).map((key) => ({
      ...series[key],
      id: key,
    }));
    const vehiclesChecklist: SeriesManagerVehicleChecklist[] = [];

    vehicleModels.forEach((model) => {
      // fetch/add category;
      let category = vehiclesChecklist.filter(
        (listItem) => listItem.name === model.group
      )[0];
      if (!category) {
        category = {
          name: model.group as string,
          items: [],
        };
        vehiclesChecklist.push(category);
      }
      // fetch/add series;
      if (!category.items.filter((item) => item.name === model.group).length) {
        category.items.push({
          id: model.id,
          name: model.name,
          selected:
            userSeries.filter((item) => item.id === model.id).length > 0,
          carImage,
        });
      }
    });

    return vehiclesChecklist;
  };

  useEffect(() => {
    setChecklist(
      createChecklist(dashboardStore.allSeries, dashboardStore.userSeries)
    );
  }, [setChecklist, dashboardStore]);

  const [tabIndex, setTabIndex] = useState(0);

  const canEdit = true;

  const handleOnSave = async () => {
    const selectedSeries: string[] = [];
    checklist.forEach(({ items = [] }) => {
      items.forEach((item) => {
        if (item.selected) {
          selectedSeries.push(item.id);
        }
      });
    });

    try {
      await Promise.all([
        updateUserSeries({
          brand: userStore.brand as Brand,
          payload: {
            objectId: userStore.objectId,
            seriesSelections: selectedSeries,
          },
        }),
        dashboardStore.fetchSeries(userStore.brand),
        dashboardStore.fetchUserSeries(userStore.brand, userStore.objectId),
      ]);

      if (selectedSeries.length > 0) {
        setRedirectToDashboard(true);
      }
    } catch (e) {
      toast.error("Error saving user series");
    }
  };

  if (redirectToDashboard) {
    return <Redirect to={`/${team}/dashboard`} />;
  }

  return (
    <>
      <Header moduleTitle="" moduleSubTitle="Dashboard Data">
        <Button
          variant="transparent"
          onClick={() => setRedirectToDashboard(true)}
        >
          Back to Dashboard
        </Button>
      </Header>
      <h1 className={styles.heading}>Which series(s) will you be managing?</h1>
      <>
        <div className={styles.seriesManagerContainer}>
          {checklist.map((category, index) => (
            // Category
            <div className={styles.categoriesContainer} key={category.name}>
              <button
                type="button"
                className={cx(styles.categories, {
                  [styles.selected]: tabIndex === index,
                  [styles.long]: category.name === "Crossover Utility Vehicles",
                  [styles.trucks]: category.name === "Trucks/SUVs",
                })}
                onClick={() => setTabIndex(index)}
              >
                {category.name}
              </button>
            </div>
          ))}

          {checklist.map((category, index) => (
            <div
              className={cx({ [styles.tilesContainer]: tabIndex === index })}
              key={category.name}
            >
              <div className={styles.tilesWrapper}>
                {tabIndex === index &&
                  category.items &&
                  category.items.map((item: ChecklistItem) => (
                    // Vehicle Checkbox
                    <div key={item.name} className={styles.tile}>
                      <img
                        src={item.carImage}
                        alt="car"
                        className={styles.carJellyImage}
                      />
                      <Checkbox
                        id={`chbox${item.name}`}
                        className={styles.checkbox}
                        checked={item.selected}
                        disabled={!canEdit}
                        onChange={(e) => {
                          selectItem(item, e.currentTarget.checked);
                        }}
                      >
                        {item.name}
                      </Checkbox>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
        {/* Selections */}
        <div className={styles.selections}>
          <div data-for="selections" className={styles.selectionsLabel}>
            Your Selections:
          </div>
          <span className={styles.list}>
            {checklist.map((category) => (
              <span key={category.name}>
                {category.items &&
                  category.items.map((item) => (
                    <span key={item.name}>
                      {item.selected && (
                        <Button
                          variant="selection"
                          className={styles.selectionCta}
                          disabled={!canEdit}
                          onClick={() => selectItem(item, false)}
                        >
                          <div
                            className={styles.selectionCtaText}
                            title={item.name}
                          >
                            {item.name}
                          </div>
                        </Button>
                      )}
                    </span>
                  ))}
              </span>
            ))}
          </span>
          {/* Clear All */}
          {canEdit && (
            <Button variant="transparent" onClick={() => selectAll(false)}>
              Clear All
            </Button>
          )}
        </div>
        {/* Save Vehicle(s) */}
        {canEdit && (
          <div className={styles.saveVehicles}>
            <Button variant="primary" onClick={() => handleOnSave()}>
              Save Vehicle(s)
            </Button>
          </div>
        )}
      </>
    </>
  );
};

export default SeriesManager;
