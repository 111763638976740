import { faLongArrowAltLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./BackButton.module.scss";

interface BackButtonProps {
  to: string;
}

const BackButton = ({ to }: BackButtonProps) => {
  return (
    <NavLink to={to} className={styles.back}>
      <FontAwesomeIcon icon={faLongArrowAltLeft} className={styles.leftArrow} />
      Back
    </NavLink>
  );
};

export default BackButton;
