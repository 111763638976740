import React from "react";
import styles from "../changeLogChanges.module.scss";

interface Props {
  before: string;
  after: string;
}

const ChangeLogBeforeAfter = ({ before, after }: Props) => {
  return (
    <>
      <div className={styles.changeRemoved}>{before}</div>
      <div className={styles.changeAdded}>{after}</div>
    </>
  );
};

export default ChangeLogBeforeAfter;
