export interface SeriesManagerVehicle {
  name: string;
  group?: string;
}

export interface Series {
  [key: string]: SeriesInfo;
}

export interface SeriesInfo {
  id: string;
  revId: string;
  name: string;
  group: SeriesCategories;
}

export interface UserSeries {
  id: string;
  revId: string;
  name: string;
}

export interface SeriesManagerVehicleChecklist {
  name: string;
  items: Array<{
    name: string;
    id: string;
    selected: boolean;
    carImage: string;
  }>;
}

export enum VehicleTeam {
  PRODUCT_TEAM = "product-team",
  AGENCY_TEAM = "agency-team",
  COMMON_LANGUAGE_TEAM = "cl",
  NATIONAL_ACC_TEAM = "national",
  GST_ACC_TEAM = "gst",
  SPANISH = "spanish",
}

export enum VehicleCategories {
  LARGE_SEDANS_VANS = "Large Sedans/Vans",
  SEDANS_SPORTS_CARS = "Sedans/Sports Cars",
  CROSSOVERS = "Crossover Utility Vehicles",
  TRUCKS_SUVS = "Trucks/SUVs",
}

export enum SeriesCategories {
  TRUCKS_SUVS = "Trucks/SUVs",
  LARGE_SEDANS_VANS = "Large Sedans/Vans",
  CROSSOVERS = "Crossover Utility Vehicles",
  SEDANS_SPORTS_CARS = "Sedans/Sports Cars",
}

export type AdminVehicleData = {
  status: string;
  seriesId: string;
  modelYear: number;
  isPendingVDReview: boolean;
  isPendingCLReview: boolean;
  isSubmitted?: boolean;
  version?: string;
  lastPublishedVersion?: number;
};

export interface DashboardDetail {
  [key: string]: DashboardDetailData;
}

export interface DashboardDetailData {
  name: string;
  years: {
    [key: string]: DashboardDetailYear[];
  };
}

export interface DashboardDetailYear {
  year: number;
  isDraft: boolean;
  datePublished: string;
  version: number;
  isPendingVDReview?: boolean;
  isPendingCLReview?: boolean;
  isSubmitted?: boolean;
  notes?: string;
  vdStatus?: DashboardDetailStatus;
  publishDownstreamDate?: string;
  publishDownstreamVersion?: number;
  createdDate?: string;
}

type DashboardDetailStatus = {
  currentStatus: VDStatus;
  modifiedDate: string;
  modifiedUser: string;
};

export enum VDStatus {
  APPROVED = "Approved",
  SUBMITTED = "Submitted",
  REJECTED = "Rejected",
  ARCHIVE = "archive",
  DRAFT = "draft",
  PUBLISHED = "published",
}

export interface DashboardVehicle {
  modelYear: string;
  seriesId: string;
  seriesName: string;
  group?: string;
}

export interface SeriesInfoItem {
  id: string;
  seriesName: string;
  // permissions: UserPermissions;
  modelYears: DashboardDetailYear[];
}
