import React, { useCallback, useContext } from "react";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { DropdownEditItem } from "vapi-ui-common";
import {
  RefItem,
  useUpdateProductTypeMutation,
} from "../../../../../gql/generated";
import AccessoryItemVM from "../../../../../models/accessories/AccessoryItemVM";
import { Language } from "../../../../../models/user/user.model";
import handleErrorResponse from "../../../../../utils/errorHandlingUtils";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";

type TDropdownEditorItemProps = {
  onClose: () => void;
  language: Language;
  productType: RefItem;
  setIsUpdated: (val: boolean) => void;
};

const CDropdownEditorItem = ({
  onClose,
  language,
  productType,
  setIsUpdated,
}: TDropdownEditorItemProps) => {
  const [updateProductType] = useUpdateProductTypeMutation();
  const { userDetails, draftDetails, saveAccessoryItem, accessoriesLangMap } =
    useContext(AccessoriesEntryScreenContext);

  const {
    accessoryItem: { id: accessoryItemId },
  } = useContext(AccessoryContext);

  const accessoryItem = accessoriesLangMap[accessoryItemId]?.langs?.[language];
  const isSelected = accessoryItem.productType === productType.id;

  const onUpdateProductType = useCallback(
    async (name: string, pType: RefItem) => {
      try {
        await trackPromise(
          updateProductType({
            variables: {
              ...userDetails,
              ...draftDetails,
              lang: language.toLowerCase(),
              id: pType.id,
              revId: pType.revId,
              name,
            },
            update: (_cache, { data: newData }) => {
              const pt = pType;
              pt.name = name;
              pt.revId = newData?.updateRefItem.refItem.revId || "";
            },
          })
        );
        setIsUpdated(true);
        toast.success("Successfully updated Product Type");
      } catch (e) {
        handleErrorResponse(e);
      }
    },
    [userDetails, draftDetails, language, updateProductType]
  );

  const onProductTypeSelect = useCallback(
    (pType: RefItem, accItem: AccessoryItemVM) => {
      if (pType.id !== accItem.productType) {
        const acc = accItem;
        acc.productType = pType.id;
        saveAccessoryItem(acc, language);
      }
    },
    [saveAccessoryItem]
  );

  const onEdit = useCallback(
    (_oldValue: string, newValue: string) => {
      if (productType.name !== newValue) {
        onUpdateProductType(newValue, productType);
      }
    },
    [productType, onUpdateProductType]
  );

  const onSelect = useCallback(() => {
    onProductTypeSelect(productType, accessoryItem);
    onClose();
  }, [productType, onProductTypeSelect, onClose, accessoryItem]);

  return (
    <DropdownEditItem
      value={productType.name}
      isSelected={isSelected}
      onEdit={onEdit}
      onClose={onClose}
      onSelect={onSelect}
    />
  );
};

export default CDropdownEditorItem;
