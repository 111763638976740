import React, { useState } from "react";
import { Button, Popover } from "vapi-ui-common";
import { GradeMsrpItem } from "../../gql/generated";
import styles from "./MsrpPopover.module.scss";
import MsrpPopoverContent from "./MsrpPopoverContent";

interface MsrpPopoverProps {
  msrp: string;
  readOnly?: boolean;
  gradeMsrp?: GradeMsrpItem[];
  modelGrades?: any[];
  handleUpdateItem?: (msrp: string) => void;
  handleGradeIdExtraCostChange?: (gradeMsrp: GradeMsrpItem[]) => void;
  popoverClass?: string;
  defaultOpen?: boolean;
  title?: string;
  align: "right" | "left";
  tabIndex?: number;
  hideAddTrimMsrp: boolean;
  msrpPopover: boolean;
  toggleElement?: React.ReactElement;
  popoverElement?: React.ReactElement;
  element?: React.ReactElement;
  hasMsrpChangedAttributes?: boolean;
}

const MsrpPopover = ({
  msrp,
  readOnly,
  popoverClass,
  gradeMsrp,
  modelGrades,
  handleUpdateItem,
  handleGradeIdExtraCostChange,
  hasMsrpChangedAttributes,
  defaultOpen = false,
  title = "",
  msrpPopover,
  align,
  tabIndex,
  hideAddTrimMsrp,
  toggleElement,
  popoverElement,
  element,
}: MsrpPopoverProps) => {
  const [ctaStyling, setCtaStyling] = useState(false);

  const renderMsrp = () => {
    if (gradeMsrp && Object.values(gradeMsrp).length > 0) {
      return <div className={styles.msrpLabel}>MSRP(*)</div>;
    }

    if (msrp && msrp !== "") {
      return (
        <div data-for="msrp" className={styles.msrpLabel}>
          {`${Number(msrp) > 0 ? "$" : ""}${msrp}`}
        </div>
      );
    }

    return (
      <Button
        tabIndex={tabIndex}
        variant="extraCost"
        toggled={ctaStyling}
        disabled={readOnly}
        className={hasMsrpChangedAttributes ? styles.hasSyncChanges : ""}
      >
        MSRP
      </Button>
    );
  };

  return (
    <>
      {!readOnly && (
        <Popover
          align={align}
          msrpPopover={msrpPopover}
          popoverClass={popoverClass}
          defaultOpen={defaultOpen}
          toggleElement={toggleElement ?? renderMsrp()}
          popoverElement={
            popoverElement ?? (
              <MsrpPopoverContent
                msrp={msrp}
                modelGrades={modelGrades}
                data={gradeMsrp}
                onUpdate={(msrp1) => {
                  if (typeof handleUpdateItem === "function") {
                    handleUpdateItem(msrp1);
                  }
                }}
                onCostChange={(cost) => {
                  setCtaStyling(Number(cost) > 0);
                }}
                title={title}
                hideAddTrimMsrp={hideAddTrimMsrp}
                onGradeUpdate={(gradeMsrpData: GradeMsrpItem[]) => {
                  if (typeof handleGradeIdExtraCostChange === "function") {
                    handleGradeIdExtraCostChange(gradeMsrpData);
                  }
                }}
              />
            )
          }
        />
      )}
      {readOnly &&
        (element ?? (
          <div data-for="msrp" className={styles.msrpLabel}>
            {msrp}
          </div>
        ))}
    </>
  );
};

MsrpPopover.defaultProps = {
  readOnly: false,
  gradeMsrp: [],
  modelGrades: [],
  handleUpdateItem: undefined,
  handleGradeIdExtraCostChange: undefined,
  popoverClass: "",
  defaultOpen: false,
  title: "",
  tabIndex: undefined,
  toggleElement: undefined,
  popoverElement: undefined,
  element: undefined,
  hasMsrpChangedAttributes: false,
};

export default MsrpPopover;
