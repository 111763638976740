import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { SyncAllChangesContext } from "../../../../components/SyncAllChangesModal";
import { GoLiveDateItem } from "../../../../models/accessories/AccessoryItemVM";
import { ModelItem } from "../../../../stores/modelStore";
import {
  GoLiveDateTimeHour,
  goLiveDateTimeMap,
} from "../../../../utils/dateUtils";
import styles from "./GoLiveDate.module.scss";

interface Props {
  all?: boolean;
  model?: ModelItem;
  canClickGoLiveDate: boolean;
  goLiveDate: Date | null;
  currentGoLiveDate?: GoLiveDateItem;
}

const GoLiveDateComponent = ({
  canClickGoLiveDate,
  goLiveDate,
  currentGoLiveDate,
}: Omit<Props, "all" | "model">) => {
  if (!goLiveDate) {
    const noGoLiveDateText = !canClickGoLiveDate ? "" : "Set Go Live Date";
    return <span>{noGoLiveDateText}</span>;
  }

  const goLiveHour = currentGoLiveDate?.goLiveDate?.split(" ")[1]
    ? new Date(currentGoLiveDate.goLiveDate).getHours()
    : null;

  if (goLiveHour === null) {
    return (
      <div className={styles.goLiveDateText}>
        {goLiveDate.toLocaleDateString()}
      </div>
    );
  }

  return (
    <>
      <div className={styles.goLiveDateText}>
        {goLiveDate.toLocaleDateString()}
      </div>
      <div className={styles.goLiveTimeText}>
        {`${goLiveDateTimeMap[
          goLiveHour as GoLiveDateTimeHour
        ].text.toLocaleUpperCase()} CST`}
      </div>
    </>
  );
};

const GoLiveDateText = ({ all, model, ...rest }: Props) => {
  const {
    state: { showSyncAdmin, modelMap },
  } = useContext(SyncAllChangesContext);

  if (all) return <span>Set All Go Live Dates</span>;

  const hasModelChanges = model && modelMap[model.modelId];
  if (!showSyncAdmin || !hasModelChanges) {
    return <GoLiveDateComponent {...rest} />;
  }

  return (
    <div className={cx(styles.goLiveDateSyncAdmin)}>
      <div>
        <GoLiveDateComponent {...rest} />
      </div>
      <FontAwesomeIcon
        className={cx(styles.iconSyncAdmin)}
        icon={faExclamationTriangle}
      />
    </div>
  );
};

export default observer(GoLiveDateText);
