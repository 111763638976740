import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cx from "clsx";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Modal,
  TableCell,
  areRichTextValuesDifferent,
  cleanUpRte,
} from "vapi-ui-common";
import AccessoryRichText from "../../../../components/AccessoryRichText";
import CommonLanguageBadge from "../../../../components/CommonLanguageBadge";
import SyncNatChangesModal from "../../../../components/SyncNatChangesModal";
import { GST_REGION } from "../../../../constants/Constants";
import { AclDocument, RefItem } from "../../../../gql/generated";
import AccessoryItemVM from "../../../../models/accessories/AccessoryItemVM";
import { RouteParams } from "../../../../models/routes.model";
import { Language } from "../../../../models/user/user.model";
import useStores from "../../../../stores/useStores";
import { getVersionInfoFromParams } from "../../../../utils/vehicleDataUtils";
import styles from "../../accessories.module.scss";

type TTitleColumnProps = {
  readOnly: boolean;
  index: number;
  accessories: AccessoryItemVM[];
  accessoryItem: AccessoryItemVM;
  saveAccessoryItem: (item: AccessoryItemVM) => Promise<void>;
  productTypes: RefItem[];
  saveSyncAccessory: (
    item: AccessoryItemVM,
    newProductType: string
  ) => Promise<void>;
  sortMode?: boolean;
};

const TitleColumn = ({
  readOnly,
  accessories,
  accessoryItem,
  index,
  saveAccessoryItem,
  productTypes,
  saveSyncAccessory,
  sortMode,
}: TTitleColumnProps) => {
  const {
    teamStore: {
      team: { langPermissions, defaultLanguage: teamLang, showCommonLanguage },
    },
    modelStore,
    userStore,
  } = useStores();
  const params: RouteParams = useParams();
  const [showSyncNatChangesModal, setShowSyncNatChangesModal] = useState(false);
  const [syncedAccessory, setSyncedAccessory] = useState<AccessoryItemVM>(
    {} as AccessoryItemVM
  );

  const vdVersionInfo = getVersionInfoFromParams(params.version);
  const version = (vdVersionInfo[teamLang] as string) || "DRAFT";
  const userPermissions = langPermissions[teamLang];
  const disabled = !userPermissions?.canEdit || readOnly;

  const userDetails = {
    brand: userStore.brand,
    team: "AT",
    region: GST_REGION,
    lang: teamLang.toLowerCase(),
  };

  const draftDetails = {
    seriesId: params.seriesId,
    modelYear: Number(params.year),
    version,
  };

  const onUnlink = useCallback(() => {
    const acc = accessoryItem;
    acc.comLangId = "";
    saveAccessoryItem(acc);
  }, [saveAccessoryItem, accessoryItem]);

  const onLink = useCallback(
    (aclDoc: AclDocument) => {
      const acc = accessoryItem;
      if (!aclDoc || !aclDoc.publishedVersion) {
        return;
      }
      acc.comLangId = aclDoc.sk;
      acc.comLangVersion = aclDoc.publishedVersion;
      acc.hasComLangChanges = false;
      saveAccessoryItem(acc);
    },
    [saveAccessoryItem, accessoryItem]
  );

  const onOpenSyncNatChangesModal = useCallback(() => {
    if (!accessoryItem.hasAAPSyncChanges || readOnly) return;
    setSyncedAccessory(accessoryItem);
    setShowSyncNatChangesModal(true);
  }, [accessoryItem, setSyncedAccessory, setShowSyncNatChangesModal]);

  const onCloseSyncNatChangesModal = useCallback(() => {
    setSyncedAccessory({} as AccessoryItemVM);
    setShowSyncNatChangesModal(false);
  }, [setSyncedAccessory, setShowSyncNatChangesModal]);

  const sCommonLanguage =
    showCommonLanguage && process.env.REACT_APP_COMMON_LANGUAGE === "true";

  const ACLComponent = () => {
    if (!sCommonLanguage) return null;

    return (
      <CommonLanguageBadge
        variables={{ ...userDetails, ...draftDetails }}
        productTypes={productTypes}
        accessoryItem={accessoryItem}
        disabled={disabled}
        readOnly={readOnly || !langPermissions?.[Language.EN]?.canEdit}
        onUnlink={onUnlink}
        onSync={saveSyncAccessory}
        accessories={accessories}
        onLink={onLink}
        sortMode={sortMode}
      />
    );
  };

  return (
    <>
      <TableCell large className={cx(styles.stickySection, styles.titleCell)}>
        <ACLComponent />

        {accessoryItem.nationalId && (
          <Button
            className={cx(styles.nationalBtn, {
              [styles.hasUpdatesBtn]:
                !readOnly && accessoryItem.hasAAPSyncChanges,
              [styles.noUpdatesBtn]:
                readOnly || !accessoryItem.hasAAPSyncChanges,
              [styles.readOnlyBtn]: readOnly || sortMode,
            })}
            onClick={onOpenSyncNatChangesModal}
          >
            {accessoryItem.hasAAPSyncChanges && !readOnly && (
              <FontAwesomeIcon
                className={styles.warningIcon}
                icon={faExclamationTriangle}
              />
            )}
            NAT
          </Button>
        )}

        <AccessoryRichText
          tabIndex={index + 1}
          value={accessoryItem.abb || ""}
          disabled={readOnly}
          required={!accessoryItem.abb}
          maxLength={accessoryItem.isNonGenAccessory ? undefined : 2}
          onBlur={(abb) => {
            if (areRichTextValuesDifferent(accessoryItem.abb || "", abb)) {
              const acc = accessoryItem;
              acc.abb = cleanUpRte(abb);
              saveAccessoryItem(acc);
            }
          }}
        />
      </TableCell>

      <Modal
        size="auto"
        open={showSyncNatChangesModal}
        onClose={() => onCloseSyncNatChangesModal()}
      >
        <SyncNatChangesModal
          accessory={syncedAccessory}
          variables={{ ...userDetails, ...draftDetails }}
          close={() => onCloseSyncNatChangesModal()}
          gradesData={modelStore.gradesData}
          productTypes={productTypes}
          saveSyncedAAPChanges={saveSyncAccessory}
        />
      </Modal>
    </>
  );
};

export default TitleColumn;
