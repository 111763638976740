import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  ActionBar,
  ActionBarSection,
  Button,
  Checkbox,
  ImageItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SearchInput,
  Spinner,
  useChecklist,
} from "vapi-ui-common";
import { NATIONAL_REGION } from "../../../constants/Constants";
import { useGetImagesQuery } from "../../../gql/generated";
import useStores from "../../../stores/useStores";
import removeFileFomat from "../../../utils/removeFileFormat";
import PageError from "../../PageError";
import styles from "../Images.module.scss";
import { uncachedPartNumberImage } from "../../../utils/partNumberImage";

interface IImageLibraryModal {
  close: () => void;
  searchText: string;
  userImages: ImageItem[];
  setSearch: (text: string) => void;
  onImageChange?: (images: ImageItem[]) => void;
  onSearch?: (value: string) => void;
  onOpenUploadImageModal?: () => void;
}

interface ImageItemMap {
  [key: string]: ImageItem;
}

const ImageLibraryModal = ({
  close,
  onImageChange,
  onSearch,
  searchText = "",
  setSearch,
  userImages,
  onOpenUploadImageModal,
}: IImageLibraryModal) => {
  const { userStore, teamStore } = useStores();
  const timestamp = useMemo(() => Date.now(), []);

  const userDetails = {
    brand: userStore.brand,
    team: "AT",
    region: NATIONAL_REGION,
    lang: "en",
  };

  const { data, error, loading } = useGetImagesQuery({
    variables: {
      brand: userDetails.brand,
      team: userDetails.team,
      region: userDetails.region,
      searchString: searchText,
    },
    fetchPolicy: "network-only",
  });

  const { checklist, setChecklist } = useChecklist();
  const targetImages = useRef({} as ImageItemMap);
  const mapInitialized = useRef(false);
  const [imageSelected, setImageSelected] = useState({} as ImageItem);

  const updateChecklist = (imageMap: ImageItemMap) => {
    if (data?.images?.images) {
      setChecklist(
        data.images.images.map((item) => {
          const image = imageMap[item.url];
          return {
            id: item.url,
            name: item.name ?? "",
            url: item.url,
            selected: image !== undefined,
            isHero: !!image?.isHero,
          };
        })
      );
    }
    targetImages.current = imageMap;
  };

  useEffect(() => {
    updateChecklist(targetImages.current);
  }, [data, setChecklist]);

  useEffect(() => {
    if (!mapInitialized.current && userImages.length) {
      const imageMap = {} as ImageItemMap;
      userImages.forEach((img) => {
        imageMap[img.image] = img;
      });
      updateChecklist(imageMap);
      mapInitialized.current = true;
    }
  }, [userImages]);

  const renderBody = () => {
    if (loading) {
      return <Spinner />;
    }
    if (error && searchText.length >= 2) {
      return <PageError />;
    }

    const validChecklist = searchText.length >= 2 ? checklist : [];

    return (
      <>
        <div className={styles.selectedPartName}>
          {imageSelected.name ? `For ${imageSelected.name}` : ""}
        </div>
        <span className={styles.tableHeader}>Image Library</span>
        <div className={styles.ImageTable}>
          <ul>
            {validChecklist?.map((item) => {
              return (
                <li
                  key={item.name}
                  className={
                    item.selected || item.name === imageSelected.name
                      ? styles.selected
                      : ""
                  }
                >
                  <Button
                    className={styles.btnStyles}
                    type="button"
                    onClick={() => {
                      setImageSelected({
                        name: item.name || "",
                        image: item.url,
                        isHero: false,
                      });
                    }}
                  >
                    <Checkbox
                      id={item.url}
                      className={styles.imageLibraryCheckbox}
                      checked={item.selected}
                      onChange={(e) => {
                        const imageMap = targetImages.current;
                        if (e.currentTarget.checked) {
                          imageMap[item.url] = {
                            name: item.name,
                            image: item.url,
                            isHero: item.isHero,
                          };
                        } else if (imageMap[item.url]) {
                          delete imageMap[item.url];
                        }
                        updateChecklist(imageMap);
                      }}
                    />
                    <img
                      className={styles.modalImage}
                      src={uncachedPartNumberImage(item.url, timestamp)}
                      alt=""
                    />
                    <span className={styles.imageName}>
                      {removeFileFomat(item.name)}
                    </span>
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <img
            className={styles.selectedImage}
            src={uncachedPartNumberImage(imageSelected.image, timestamp)}
            alt=""
          />
        </div>
      </>
    );
  };

  return (
    <>
      <ModalHeader onClose={close}>Image URL</ModalHeader>
      <ActionBar>
        <ActionBarSection>
          <SearchInput
            className={styles.input}
            placeholder="Search by Part Number"
            value=""
            onSearch={(e) => {
              if (onSearch) {
                onSearch(e);
              }
            }}
          />
        </ActionBarSection>
      </ActionBar>
      <ModalBody>{renderBody()}</ModalBody>
      <ModalFooter className={styles.footerSplit}>
        <div>
          {process.env.REACT_APP_IMAGE_UPLOAD === "true" &&
            teamStore.team.allowUploadImages && (
              <Button
                variant="outline"
                onClick={() => {
                  onOpenUploadImageModal?.();
                }}
              >
                Upload New Image
              </Button>
            )}
        </div>
        <div>
          <Button
            variant="transparent"
            onClick={() => {
              setSearch("");
              close();
            }}
          >
            close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (onImageChange) {
                const selectedImages: ImageItem[] = Object.values(
                  targetImages.current
                );
                let foundHero = false;
                const numImages = selectedImages.length;
                for (let i = 0; i < numImages; i += 1) {
                  if (selectedImages[i].isHero) {
                    foundHero = true;
                    break;
                  }
                }
                if (!foundHero && selectedImages.length) {
                  selectedImages[0].isHero = true;
                }
                onImageChange(selectedImages);
              }
              close();
            }}
          >
            {`Select (${Object.keys(targetImages.current).length})`}
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};

ImageLibraryModal.defaultProps = {
  onImageChange: () => {},
  onSearch: () => {},
};

export default ImageLibraryModal;
