import cx from "clsx";
import * as differ from "diff";
import React from "react";
import styles from "../changeLogChanges.module.scss";

interface Props {
  before: string;
  after: string;
}

const ChangeLogDiff = ({ before, after }: Props) => {
  const changes = differ.diffWordsWithSpace(before || "", after || "");
  return (
    <>
      {changes.map((change, index) => {
        const key = `key${index}`;
        // process line breaks for non-removed items and after values
        const vals = change.removed
          ? [change.value]
          : change.value.split(/(?=[\n])|(?<=[\n])/g);

        return (
          <span
            className={cx({
              [styles.changeRemoved]: change.removed,
              [styles.changeAdded]: change.added,
            })}
            key={key}
          >
            {" "}
            {vals.map((val, valIndex) => {
              const valKey = valIndex;
              return (
                <React.Fragment key={valKey}>
                  {val === "\n" ? <br /> : val}
                </React.Fragment>
              );
            })}
            <> </>
          </span>
        );
      })}
    </>
  );
};

export default ChangeLogDiff;
