import React, { useEffect } from "react";
import { Spinner, useIsLoading } from "vapi-ui-common";
import useRouteParams from "../../hooks/useRouteParams";
import SeriesManager from "../../modules/SeriesManager";

import useStores from "../../stores/useStores";

const SeriesManagerController = () => {
  const { isLoading, initLoading } = useIsLoading();
  const { dashboardStore, userStore } = useStores();
  const { team } = useRouteParams();

  useEffect(() => {
    dashboardStore.searchText = "";
  }, [dashboardStore]);

  useEffect(() => {
    userStore.setTeam(team);

    initLoading(async () => {
      // await both of these to make sure that checklist gets generated with all proper info
      await dashboardStore.fetchSeries(userStore.brand);
      await dashboardStore.fetchUserSeries(userStore.brand, userStore.objectId);
    });
  }, [team]);

  if (isLoading) {
    return <Spinner />;
  }

  return <SeriesManager />;
};

export default SeriesManagerController;
