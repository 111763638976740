import React, { useContext } from "react";
import { ModalHeader } from "vapi-ui-common";
import {
  SyncAllChangesActions,
  SyncAllChangesContext,
} from "./SyncAllChangesContext";

const useVariables = () => {
  const { dispatch } = useContext(SyncAllChangesContext);

  const onClose = () =>
    dispatch({
      type: SyncAllChangesActions.HIDE_MODAL,
    });

  return {
    onClose,
  };
};

const SyncAllChangesModalHeader = () => {
  const { onClose } = useVariables();

  return <ModalHeader onClose={onClose}>Sync Changes from Admin</ModalHeader>;
};

export default SyncAllChangesModalHeader;
