import React, { useContext } from "react";
import { SearchInput } from "vapi-ui-common";
import styles from "./SyncAllChanges.module.scss";
import {
  SyncAllChangesActions,
  SyncAllChangesContext,
} from "./SyncAllChangesContext";

const useVariables = () => {
  const { state, dispatch } = useContext(SyncAllChangesContext);

  const { searchText } = state;

  const onSearch = (sText: string) =>
    dispatch({
      type: SyncAllChangesActions.SEARCH,
      payload: {
        searchText: sText,
      },
    });

  return {
    onSearch,
    searchText,
  };
};

const SyncAllChangesModalBodyHeader = () => {
  const { onSearch, searchText } = useVariables();

  return (
    <div className={styles.syncAllChangesModalBodyHeader}>
      <span>You have to update changes on both tabs before proceeding.</span>

      <SearchInput
        value={searchText}
        onSearch={onSearch}
        className={styles.searchInput}
        placeholder="Search"
      />
    </div>
  );
};

export default SyncAllChangesModalBodyHeader;
