import clsx from "clsx";
import React from "react";
import { NavLink } from "react-router-dom";
import { SideMenuIcon } from "vapi-ui-common";
import useStores from "../../../stores/useStores";
import activeTeam from "../../../utils/activeTeam";
import styles from "./CollapsedView.module.scss";

const CollapsedView = () => {
  const { userStore } = useStores();
  const canViewCommonLanguage =
    process.env.REACT_APP_COMMON_LANGUAGE === "true" &&
    ((userStore.isNationalUser() && !userStore.isSpanishUser()) ||
      userStore.isLexusUser() ||
      userStore.isGstUser());

  return (
    <ul>
      {canViewCommonLanguage && (
        <NavLink
          to="/commonLanguage/published"
          isActive={(match, location) => {
            return activeTeam(location.pathname, "commonLanguage/published");
          }}
          className={styles.sideMenuIcon}
        >
          <SideMenuIcon icon="commonLang" />
        </NavLink>
      )}

      {userStore.isNationalUser() && (
        <>
          <NavLink
            to="/national/dashboard"
            isActive={(match, location) => {
              return activeTeam(location.pathname, "national");
            }}
            className={styles.sideMenuIcon}
          >
            <SideMenuIcon icon="nat" />
          </NavLink>
          {userStore.isSpanishUser() && (
            <NavLink
              to="/spanish/dashboard"
              isActive={(match, location) => {
                return activeTeam(location.pathname, "spanish");
              }}
              className={styles.sideMenuIcon}
            >
              <SideMenuIcon icon="spanish" />
            </NavLink>
          )}
        </>
      )}

      {!userStore.isNationalUser() && (
        <NavLink
          to="/gst/dashboard"
          isActive={(match, location) => activeTeam(location.pathname, "gst")}
          className={styles.sideMenuIcon}
        >
          <SideMenuIcon icon="productTeam" />
        </NavLink>
      )}
      {/* link to admin */}
      {userStore.isGstUser() && (
        <>
          <a
            href={`${process.env.REACT_APP_ADMIN_URL}/#/disclaimers/draft?collapsed=true`}
            className={styles.sideMenuIcon}
          >
            <SideMenuIcon icon="disclaimer" />
          </a>
          <a
            href={`${process.env.REACT_APP_ADMIN_URL}#/dph?collapsed=true`}
            className={styles.sideMenuIcon}
          >
            <SideMenuIcon icon="dph" />
          </a>
        </>
      )}
      <NavLink to="/reports" className={styles.sideMenuIcon}>
        <SideMenuIcon icon="reports" />
      </NavLink>
      <NavLink
        to="/help"
        className={clsx(styles.helpMenuIcon, styles.sideMenuIcon)}
      >
        <SideMenuIcon icon="help" />
      </NavLink>
    </ul>
  );
};

export default CollapsedView;
