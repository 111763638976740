import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ActionBar,
  ActionBarFilterButton,
  ActionBarSection,
  Button,
  Dropdown,
  DropdownContent,
  IconTextButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  SearchInput,
  useComponentVisible,
} from "vapi-ui-common";
import ResetModal from "../../../../components/ResetModal";
import MenuDropdown from "../../../../components/menuModule/MenuDropdown/MenuDropdown";
import GoLiveDate from "../GoLiveDate";

interface ActionBarAccessoriesProps {
  changeLogLink: string;
  renderFilter: (onClose: () => void) => React.ReactNode;
  onAdd: () => void;
  onSearch: (arg0: string) => void;
  onPublish: () => void;
  hasCertifyValidationErrors: () => boolean;
  onOpenCommonLanguage: () => void;
  canPublish: boolean;
  readOnly: boolean;
  allowAddDeleteData: boolean;
  renderButtons?: React.ReactNode;
}

const ActionBarAccessories = ({
  changeLogLink,
  renderFilter,
  onAdd,
  onSearch,
  onPublish,
  hasCertifyValidationErrors,
  canPublish,
  readOnly,
  allowAddDeleteData,
  renderButtons = <></>,
  onOpenCommonLanguage,
}: ActionBarAccessoriesProps) => {
  const [openResetModal, setOpenResetModal] = useState(false);
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [updateCount] = useState(0);

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const handleOnOpenResetDraft = async () => {
    setOpenResetModal(true);
  };

  const handlePublish = () => {
    onPublish();
    setOpenPublishModal(false);
  };

  return (
    <>
      <ActionBar>
        <ActionBarSection>
          <SearchInput value="" onSearch={onSearch} />
          <Dropdown>
            <ActionBarFilterButton onClick={() => setIsComponentVisible(true)}>
              Filter
            </ActionBarFilterButton>
            <DropdownContent ref={ref} open={isComponentVisible}>
              {renderFilter(() => setIsComponentVisible(false))}
            </DropdownContent>
          </Dropdown>
          {!readOnly && allowAddDeleteData && (
            <MenuDropdown
              menuButton={<IconTextButton icon="plus" text="Add Accessory" />}
              list={[
                <IconTextButton
                  key="create_new_accessory"
                  icon="plus"
                  text="Create new accessory"
                />,
                <IconTextButton
                  key="add_common_language"
                  icon="link"
                  text="Add Common Language"
                />,
              ]}
              onSelect={(value) => {
                switch (value) {
                  case "create_new_accessory": {
                    onAdd();
                    break;
                  }
                  case "add_common_language": {
                    onOpenCommonLanguage();
                    break;
                  }
                  default:
                    break;
                }
              }}
            />
          )}
          {renderButtons}
        </ActionBarSection>
        <ActionBarSection>
          <GoLiveDate all readOnly={readOnly} hideAdminDate />

          <NavLink to={changeLogLink}>
            <Button variant="transparent">Change Log</Button>
          </NavLink>
          {/* Reset Button/Modal */}
          {!readOnly && allowAddDeleteData && (
            <>
              <IconTextButton
                icon="undo"
                text="Reset Draft"
                onClick={() => handleOnOpenResetDraft()}
              />

              <Modal open={openResetModal} onClose={() => {}}>
                <ResetModal
                  changeLogLocation="/changelog "
                  close={() => setOpenResetModal(false)}
                  pageName="Product Type"
                  updateCount={updateCount}
                  resetDraft={() => {}}
                />
              </Modal>
            </>
          )}
          {/* Publish Button/Modal */}
          {!readOnly && (
            <>
              <Button
                variant="primary"
                onClick={() => {
                  if (!hasCertifyValidationErrors()) {
                    setOpenPublishModal(true);
                  }
                }}
                disabled={!canPublish}
                title={
                  !canPublish
                    ? "The model year has not been published by the Product Team. Cannot Certify"
                    : ""
                }
              >
                Certify
              </Button>

              <Modal
                open={openPublishModal}
                onClose={() => setOpenPublishModal(false)}
              >
                <ModalHeader onClose={() => setOpenPublishModal(false)}>
                  Certify Draft
                </ModalHeader>
                <ModalBody>
                  Are you sure you want to certify this draft?
                </ModalBody>
                <ModalFooter>
                  <Button
                    variant="transparent"
                    onClick={() => setOpenPublishModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={() => handlePublish()}>
                    Certify
                  </Button>
                </ModalFooter>
              </Modal>
            </>
          )}
        </ActionBarSection>
      </ActionBar>
    </>
  );
};

ActionBarAccessories.defaultProps = {
  renderButtons: <></>,
};

export default ActionBarAccessories;
