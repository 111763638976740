import { RefItem } from "../gql/generated";
import { KeyValueType } from "../models/common/Common.model";

export const getSortPayload = (list: any) => {
  const payload = {
    sortList: {} as KeyValueType<number>,
  };
  if (Array.isArray(list)) {
    list.forEach((item, index) => {
      payload.sortList[item.id] = index;
    });
  }

  return payload;
};

export const sortHelperFunction = <T>(
  itemFn: (item: T) => string,
  list: RefItem[]
) => {
  return (item1: T, item2: T) => {
    const item1Id = itemFn(item1);
    const item2Id = itemFn(item2);

    const item1Index = list.findIndex((listItem) => listItem.id === item1Id);
    const item2Index = list.findIndex((listItem) => listItem.id === item2Id);

    if (item1Index === -1 && item2Index === -1) {
      return 0;
    }
    if (item1Index === -1) {
      return 1;
    }
    if (item2Index === -1) {
      return -1;
    }
    return item1Index - item2Index;
  };
};

export const getSortedCopy = <T>(
  items: T[],
  itemFn: (item: T) => string,
  list: RefItem[]
) => {
  const copy = items.slice();
  copy.sort(sortHelperFunction(itemFn, list));

  return copy;
};

export const getSortedListByString = <T>(list: T[], prop: string) => {
  return list.sort((a: T, b: T) => {
    const x = a[prop as keyof T];
    const y = b[prop as keyof T];
    let first = "";
    let second = "";

    if (typeof x === "string") {
      first = x.toLowerCase();
    }

    if (typeof y === "string") {
      second = y.toLocaleLowerCase();
    }

    if (first < second) {
      return -1;
    }
    if (first > second) {
      return 1;
    }
    return 0;
  });
};

export const isSortReverse = (
  oldField: string,
  newField: string,
  isReverse: boolean
) => {
  return oldField !== newField ? false : !isReverse;
};
