import React from "react";
import { Button, ModalBody, ModalFooter, ModalHeader } from "vapi-ui-common";
import styles from "../Images.module.scss";

interface IImageUrlModal {
  close: () => void;
  isSelected: boolean;
  updateSelectType: (item: object) => void;
}

const data = [
  {
    id: "1",
    feature: "All terrain wheels",
    title: "Features",
    images: ["img1", "img2", "img3"],
  },
  {
    id: "2",
    feature: "Custom Steering wheel",
    title: "Accessories",
    images: ["img4", "img5", "img6"],
  },
  {
    id: "3",
    feature: "Twin turbo exhaust pipes",
    title: "Specs",
    images: ["img7", "img8", "img9"],
  },
];

const ImageUrlModal = ({
  close,
  updateSelectType,
  isSelected,
}: IImageUrlModal) => {
  return (
    <>
      <ModalHeader onClose={close}>Image URL</ModalHeader>
      <ModalBody>
        <span className={styles.tableHeader}>Browse</span>
        <div className={styles.ImageTable}>
          <ul>
            {data.map((item) => {
              return (
                <li key={item.id} className={isSelected ? styles.selected : ""}>
                  <Button
                    className={styles.btnList}
                    id={item.id}
                    type="button"
                    onClick={() => updateSelectType(item)}
                  >
                    {item.title}
                  </Button>
                </li>
              );
            })}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={() => close()}>
          Back
        </Button>
      </ModalFooter>
    </>
  );
};

export default ImageUrlModal;
