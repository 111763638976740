import React from "react";
import { Popover } from "vapi-ui-common";
import styles from "./NATUpdatesPopover.module.scss";
import NATUpdatesPopoverContent, {
  INatUpdatesPopover,
} from "./NATUpdatesPopoverContent";

const NATUpdatesPopover = ({
  lastSyncDate,
  lastNATPublishedDate,
}: INatUpdatesPopover) => {
  return (
    <Popover
      popoverElementClass={styles.popover}
      align="right"
      defaultOpen
      toggleElement={<></>}
      popoverElement={
        <NATUpdatesPopoverContent
          lastSyncDate={lastSyncDate}
          lastNATPublishedDate={lastNATPublishedDate}
        />
      }
    />
  );
};

export default NATUpdatesPopover;
