import React from "react";
import styles from "./syncUpdatesPopover.module.scss";

export interface ISyncUpdatesPopover {
  lastSyncDates: string;
  lastPublishedDates: string;
}

const SyncUpdatesPopoverContent = ({
  lastSyncDates,
  lastPublishedDates,
}: ISyncUpdatesPopover) => {
  const getDateString = (date: string) => {
    const parsedDate = new Date(date).toLocaleDateString();
    return parsedDate === "Invalid Date" ? "N/A" : parsedDate;
  };

  const syncDates = () => {
    return <div>{`Last Synced: ${getDateString(lastSyncDates)}`}</div>;
  };

  const publishedDates = () => {
    return (
      <div>{`Last NAT Published: ${getDateString(lastPublishedDates)}`}</div>
    );
  };

  return (
    <section className={styles.dateSection}>
      {syncDates()}
      {publishedDates()}
    </section>
  );
};

export default SyncUpdatesPopoverContent;
