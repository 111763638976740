import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, Slide } from "react-toastify";
import styles from "./Toast.module.scss";

const CloseToastButton = () => (
  <FontAwesomeIcon style={{ marginRight: "8px" }} icon={faTimesCircle} />
);
export const Toast = () => (
  <section className={styles.toast}>
    <ToastContainer
      closeButton={<CloseToastButton />}
      draggable={false}
      position="top-center"
      hideProgressBar
      transition={Slide}
    />
  </section>
);

export const CheckMessageToast = ({ message }: { message: string }) => {
  return (
    <>
      <FontAwesomeIcon style={{ marginRight: "8px" }} icon={faTimesCircle} />
      {message}
    </>
  );
};

export default Toast;
