import { ChangeLogBase } from "../../../models/accessories/changeLog.model";
import changeRegistery from "./changeRegistery";

export const ChangeLogChanges = ({
  changeItem,
}: {
  changeItem: ChangeLogBase;
}) => {
  const changeComponent = changeRegistery[changeItem.changeType];
  if (!changeComponent) {
    return false;
  }
  return changeComponent({
    before: changeItem.before,
    after: changeItem.after,
  });
};

export default ChangeLogChanges;
