import cx from "clsx";
import { observer } from "mobx-react-lite";
import React, { HTMLAttributes, ReactNode } from "react";
import { Checkbox, HeaderRow, LeftTableTH, Thead } from "vapi-ui-common";
import styles from "../../accessories.module.scss";

interface AccessoriesEntryProps {
  children: ReactNode;
  toggleDescEn: boolean;
  toggleDescEs: boolean;
  toggleDiscEn: boolean;
  toggleDiscEs: boolean;
  toggleWarrEn: boolean;
  toggleWarrEs: boolean;
  setSort: (prop: string) => void;
  toggleDescLangES: () => void;
  toggleDiscLangES: () => void;
  toggleWarrLangES: () => void;
  toggleDescLangEN: () => void;
  toggleDiscLangEN: () => void;
  toggleWarrLangEN: () => void;
}
const Header = ({ children, ...rest }: HTMLAttributes<HTMLElement>) => {
  return <div {...rest}>{children}</div>;
};
const AccessoriesTable = ({
  children,
  toggleDescEn,
  toggleDescEs,
  toggleDiscEn,
  toggleDiscEs,
  toggleWarrEn,
  toggleWarrEs,
  setSort = () => {},
  toggleDescLangES = () => {},
  toggleDiscLangES = () => {},
  toggleWarrLangES = () => {},
  toggleDescLangEN = () => {},
  toggleDiscLangEN = () => {},
  toggleWarrLangEN = () => {},
}: AccessoriesEntryProps) => {
  return (
    <>
      <Thead className={cx(styles.removeBorderLeft, styles.stickySection)}>
        <HeaderRow>
          <LeftTableTH
            onClick={() => {
              setSort("productType");
            }}
          >
            Product Type
          </LeftTableTH>
          <LeftTableTH>
            <Header
              onClick={() => setSort("description")}
              className={styles.headerLabel}
            >
              Description
            </Header>
            <Checkbox
              id="dsc-en"
              className={styles.descCheckbox}
              checked={toggleDescEn}
              onChange={toggleDescLangEN}
            >
              <span className={styles.checkboxLabel}>English</span>
            </Checkbox>
            <Checkbox
              id="desc-es"
              className={styles.descCheckbox}
              checked={toggleDescEs}
              onChange={toggleDescLangES}
            >
              <span className={styles.checkboxLabel}>Spanish</span>
            </Checkbox>
          </LeftTableTH>
          <LeftTableTH>
            <Header
              onClick={() => setSort("disclaimer")}
              className={styles.headerLabel}
            >
              Disclaimer
            </Header>
            <Checkbox
              id="disc-en"
              className={styles.descCheckbox}
              checked={toggleDiscEn}
              onChange={toggleDiscLangEN}
            >
              <span className={styles.checkboxLabel}>English</span>
            </Checkbox>
            <Checkbox
              id="disc-es"
              className={styles.descCheckbox}
              checked={toggleDiscEs}
              onChange={toggleDiscLangES}
            >
              <span className={styles.checkboxLabel}>Spanish</span>
            </Checkbox>
          </LeftTableTH>
          <LeftTableTH>
            <Header
              onClick={() => setSort("warranty")}
              className={styles.headerLabel}
            >
              Warranty
            </Header>
            <Checkbox
              id="warr-en"
              className={styles.descCheckbox}
              checked={toggleWarrEn}
              onChange={toggleWarrLangEN}
            >
              <span className={styles.checkboxLabel}>English</span>
            </Checkbox>
            <Checkbox
              id="warr-es"
              className={styles.descCheckbox}
              checked={toggleWarrEs}
              onChange={toggleWarrLangES}
            >
              <span className={styles.checkboxLabel}>Spanish</span>
            </Checkbox>
          </LeftTableTH>

          <LeftTableTH onClick={() => setSort("required")}>
            Required/Conflicts
          </LeftTableTH>
          <LeftTableTH onClick={() => setSort("partNumber")}>
            Part Number
          </LeftTableTH>
          <LeftTableTH />
        </HeaderRow>
      </Thead>
      <tbody>{children}</tbody>
    </>
  );
};

export default observer(AccessoriesTable);
