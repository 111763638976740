import cx from "clsx";
import React, { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { Button, DropdownList, Header, Spinner } from "vapi-ui-common";
import apolloClient from "../../apolloClient";
import { BRAND_TOYOTA, BRAND_LEXUS } from "../../constants/Brand";
import { NATIONAL_REGION, GST_REGION } from "../../constants/Constants";
import {
  GetGeneratedReportsDocument,
  GetGeneratedReportsQuery,
  useGenerateReportMutation,
  useGetGeneratedReportsQuery,
  useGetLatestVersionLazyQuery,
} from "../../gql/generated";
import {
  getReportTypesByBrand,
  ReportTypes,
  ReportItem,
} from "../../models/reports/reports.model";
import useStores from "../../stores/useStores";
import handleErrorResponse from "../../utils/errorHandlingUtils";
import {
  bindGetHandlerFn,
  listOrganizer,
  formatReportType,
  processReportData,
} from "../../utils/reportsUtils";
import downloadReport from "../../webservices/reportsApi";
import getAvailableModels from "../../webservices/vehicleAccApi";
import styles from "./reports.module.scss";
import ReportsTable from "./ReportsTable";
import { Brand } from "../../gql/adminGenerated";

const ReportsController = () => {
  const {
    userStore: { brand, isNationalUser },
    reportsStore,
  } = useStores();

  const userDetails = {
    brand,
    team: "AT",
    region: isNationalUser() ? NATIONAL_REGION : GST_REGION,
    lang: "en",
  };

  const { data: reportsData, loading: reportsLoading } =
    useGetGeneratedReportsQuery({
      variables: {
        brand: userDetails.brand,
        team: userDetails.team,
        region: userDetails.region,
      },
      fetchPolicy: "network-only",
    });

  const reportTypes = getReportTypesByBrand(brand);
  const [generateReport] = useGenerateReportMutation();
  const [getLatestVersion, { data: versionData, loading: versionLoading }] =
    useGetLatestVersionLazyQuery();

  const [isLoaded, setIsLoaded] = useState(false);
  const [reportType, setReportType] = useState("");
  const [seriesYear, setSeriesYear] = useState("");
  const [series, setSeries] = useState("");
  const [selectedSeriesId, setSelectedSeriesId] = useState("");
  const [version, setVersion] = useState("");
  const [reportsGenerated, setReportsGenerated] = useState(false);

  useEffect(() => {
    reportsStore.reset();
  }, [reportsStore]);

  useEffect(() => {
    if (!reportsLoading) {
      reportsStore.allReports = processReportData(
        reportsData,
        reportsStore.models
      );
    }
  }, [reportsData, reportsLoading]);

  // Get available models and populate models, seriesList, seriesYearList
  // sets IsLoaded
  useEffect(() => {
    (async () => {
      setIsLoaded(false);

      try {
        const modelsResponse = await getAvailableModels(brand);
        if (modelsResponse?.data) {
          reportsStore.models = modelsResponse.data;
          reportsStore.seriesList = [];
          reportsStore.seriesYearList = [];
          reportsStore.models.forEach((model) => {
            if (!reportsStore.seriesList.includes(model.seriesName)) {
              reportsStore.seriesList.push(model.seriesName);
            }

            if (
              !reportsStore.seriesYearList.includes(model.modelYear.toString())
            ) {
              reportsStore.seriesYearList.push(model.modelYear.toString());
            }
          });
        }
      } catch (e) {
        toast.error("Error loading reports");
      }

      setIsLoaded(true);
    })();
  }, [brand, reportsStore]);

  useEffect(() => {
    if (!versionLoading && versionData?.latestVersion) {
      if (versionData?.latestVersion?.hasDraft) {
        reportsStore.versionList.push("DRAFT");
      }
      const versionNum = versionData?.latestVersion?.latestVersion || 0;
      for (let i = versionNum; i > 0; i -= 1) {
        reportsStore.versionList.push(i.toString());
      }
    }
  }, [versionLoading, versionData]);

  const reportTypeOnSelect = async (type: string) => {
    setReportsGenerated(false);
    setReportType(type);
    setSeriesYear("");
    setSelectedSeriesId("");
    setReportsGenerated(true);
  };

  const seriesOnSelect = (selectedSeries: string) => {
    setSeries(selectedSeries);
    setSeriesYear("");
    setSelectedSeriesId("");
    reportsStore.seriesYearList = [];
    reportsStore.versionList = [];
    reportsStore.models.forEach((model) => {
      if (model.seriesName.toLowerCase() === selectedSeries.toLowerCase()) {
        reportsStore.seriesYearList.push(model.modelYear.toString());
        setSelectedSeriesId(model.seriesId);
      }
    });
  };

  const seriesYearOnSelect = (selectedSeriesYear: string) => {
    setSeriesYear(selectedSeriesYear);
    reportsStore.versionList = [];
    try {
      getLatestVersion({
        variables: {
          brand: userDetails.brand,
          team: userDetails.team,
          region: userDetails.region,
          lang: userDetails.lang,
          seriesId: selectedSeriesId,
          modelYear: Number(selectedSeriesYear),
        },
      });
    } catch (e) {
      toast.error("Error loading versions");
    }
  };

  // generate all other toyota
  const reportBySeriesYearAndVersion = () => {
    const seriesSelected = series !== "";
    const yearSelected = seriesYear !== "";
    if (!seriesSelected || !yearSelected) {
      return undefined;
    }
    return setReportsGenerated(true);
  };

  // throw handlers for each report type in a config object
  const generateReportHanders = {
    [BRAND_TOYOTA]: {
      [ReportTypes.ACCESSORIES]: reportBySeriesYearAndVersion,
    },
    [BRAND_LEXUS]: {
      [ReportTypes.ACCESSORIES]: reportBySeriesYearAndVersion,
    },
  };

  // partially bind error text and config to a function
  // call again with brand and reportType to get correct handler
  const getGenerateReportHandler = bindGetHandlerFn(
    "error generating report",
    generateReportHanders
  );

  // generate report
  const generateReports = async () => {
    setReportsGenerated(false);
    const versionSelected = version !== "";
    if (!versionSelected) {
      return;
    }

    const reportHandler = getGenerateReportHandler(brand, reportType);
    if (!reportHandler) {
      return;
    }
    await reportHandler();
    let seriesId = "";

    reportsStore.models.forEach((model) => {
      if (model.seriesName.toLowerCase() === series.toLowerCase()) {
        seriesId = model.seriesId;
      }
      return seriesId;
    });

    try {
      await trackPromise(
        generateReport({
          variables: {
            ...userDetails,
            seriesId,
            modelYear: +seriesYear,
            version,
          },
          update: (_cache, { data: newData }) => {
            const query =
              apolloClient.cache.readQuery<GetGeneratedReportsQuery>({
                query: GetGeneratedReportsDocument,
                variables: { ...userDetails },
              });
            const existingData = JSON.parse(
              JSON.stringify(query)
            ) as GetGeneratedReportsQuery;

            if (
              existingData.readReportLog?.reports &&
              newData?.generateAccReport.reportLogResponse
            ) {
              existingData.readReportLog.reports = [
                ...existingData.readReportLog.reports,
                newData.generateAccReport.reportLogResponse,
              ];
              reportsStore.allReports = processReportData(
                existingData,
                reportsStore.models
              );
            }
          },
        })
      );
      toast.success("Successfully Generated Report");
    } catch (e) {
      handleErrorResponse(e);
    }
  };

  // end generate report logic
  const onDownloadReport = async (report: ReportItem) => {
    const s3Url = await trackPromise(
      downloadReport({
        brand: brand as Brand,
        key: report.bucketKey,
      })
    );
    const anchor = document.createElement("a");

    anchor.href = s3Url;
    anchor.download = report.fileName;
    anchor.click();
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <Header moduleTitle="" moduleSubTitle="Reports" />
      <section className={styles.reportTypeContainer}>
        <div>
          <span className={styles.reportTypeLabel}>Select a Report Type</span>
          <div className={styles.reportTypeBtn}>
            <DropdownList
              value={formatReportType(reportType)}
              list={listOrganizer(reportTypes)}
              onSelect={(item) => reportTypeOnSelect(item.toLowerCase())}
            />
          </div>
        </div>
        {reportType !== "" && (
          <>
            {reportType && (
              <div className={styles.seriesContainer}>
                <span className={styles.reportTypeLabel}>Series</span>
                <div className={styles.seriesBtn}>
                  <DropdownList
                    value={series}
                    list={reportsStore.seriesList}
                    onSelect={(item) => seriesOnSelect(item)}
                  />
                </div>
                {series !== "" && (
                  <div className={styles.modelBtn}>
                    <DropdownList
                      value={seriesYear}
                      list={reportsStore.seriesYearList}
                      onSelect={(item) => seriesYearOnSelect(item)}
                    />
                  </div>
                )}
              </div>
            )}

            {!versionLoading && (
              <>
                <div
                  className={cx(
                    styles.versionContainer,
                    styles.generateCtaContainer
                  )}
                >
                  <span className={styles.reportTypeLabel}>Latest Version</span>
                  <div className={styles.versionBtn}>
                    <DropdownList
                      value={version}
                      list={reportsStore.versionList}
                      onSelect={(item) => setVersion(item)}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )}

        <div>
          <Button
            className={styles.generateReportCta}
            variant="primary"
            onClick={generateReports}
          >
            Generate Report
          </Button>
        </div>

        {reportsGenerated && (
          <ReportsTable
            reports={reportsStore.sortedReports}
            onDownload={onDownloadReport}
          />
        )}
      </section>
    </>
  );
};

export default ReportsController;
