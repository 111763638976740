import React, { useCallback, useContext, useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { Input } from "vapi-ui-common";
import { useUpdateProductTypeMutation } from "../../../../../gql/generated";
import { Language } from "../../../../../models/user/user.model";
import checkForSyncChanges from "../../../../../utils/checkForSyncChanges";
import handleErrorResponse from "../../../../../utils/errorHandlingUtils";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";
import styles from "./DropdownEditorCell.module.scss";

type TSpanishEditorProps = {
  language: Language;
};

const CSpanishEditor = ({ language }: TSpanishEditorProps) => {
  const {
    productTypeLangMap,
    languagePermissions,
    readOnly,
    userDetails,
    draftDetails,
    accessoriesLangMap,
  } = useContext(AccessoriesEntryScreenContext);

  const {
    accessoryItem: { id: accessoryItemId },
    index,
  } = useContext(AccessoryContext);

  const [updateProductType] = useUpdateProductTypeMutation();

  const accessoryItem = accessoriesLangMap[accessoryItemId]?.langs?.[language];

  const productType =
    productTypeLangMap[accessoryItem.productType ?? ""]?.langs?.[language];
  const [name, setName] = useState(productType?.name || "");

  const userPermissions = languagePermissions?.[language];
  const disabled = !userPermissions?.canEdit || readOnly || !productType;

  useEffect(() => {
    setName(productType?.name || "");
  }, [setName, productType?.name]);

  const onBlur = useCallback(async () => {
    if (productType.name === name) {
      return;
    }

    try {
      await trackPromise(
        updateProductType({
          variables: {
            ...userDetails,
            ...draftDetails,
            lang: language.toLowerCase(),
            id: productType.id,
            revId: productType.revId,
            name,
          },
          update: (_cache, { data: newData }) => {
            const pt = productType;
            pt.name = name;
            pt.revId = newData?.updateRefItem.refItem.revId || "";
          },
        })
      );
      toast.success("Successfully updated Product Type");
    } catch (e) {
      handleErrorResponse(e);
    }
  }, [
    updateProductType,
    language,
    productType,
    name,
    userDetails,
    draftDetails,
  ]);

  const onChange = useCallback(
    (event) => setName(event.currentTarget.value),
    [setName]
  );

  return (
    <Input
      tabIndex={index + 1}
      onBlur={onBlur}
      onChange={onChange}
      value={name}
      disabled={disabled}
      className={
        checkForSyncChanges(accessoryItem.changedAttributes, "productType")
          ? styles.hasSyncChanges
          : undefined
      }
    />
  );
};

export default CSpanishEditor;
