import React, { useCallback } from "react";
import { Checkbox } from "vapi-ui-common";
import { Language } from "../../../../models/user/user.model";
import { LanguageCheckbox } from "./useTeamLanguagesCheckbox";

type LanguageCheckboxComponentProps = {
  languageCheckbox: LanguageCheckbox;
  setLanguageCheckboxSelected: (language: Language, selected: boolean) => void;
};

const LanguageCheckboxComponent = ({
  languageCheckbox,
  setLanguageCheckboxSelected,
}: LanguageCheckboxComponentProps) => {
  const { language, selected, description } = languageCheckbox;

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setLanguageCheckboxSelected(language, e.currentTarget.checked),
    [language, setLanguageCheckboxSelected]
  );

  return (
    <Checkbox id={`chbox${language}`} checked={selected} onChange={onChange}>
      {description}
    </Checkbox>
  );
};

export default LanguageCheckboxComponent;
