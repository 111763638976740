import React from "react";
import { PostSpinner, SideMenu } from "vapi-ui-common";
import { logout } from "./authConfig";
import CollapsedView from "./components/sideMenu/CollapsedView/CollapsedView";
import ExpandedView from "./components/sideMenu/ExpandedView/ExpandedView";

import Routes from "./Routes";
import useQuery from "./hooks/useQuery";

const SideMenuWrapper = () => {
  const query = useQuery();

  return (
    <SideMenu
      collapsedView={<CollapsedView />}
      expandedView={<ExpandedView />}
      mainContent={
        <PostSpinner>
          <Routes />
        </PostSpinner>
      }
      onLogout={() => logout()}
      collapsed={query.get("collapsed")}
    />
  );
};

export default SideMenuWrapper;
