import cx from "clsx";
import React, { useCallback, useContext, useState } from "react";
import { DraggableProvided } from "react-beautiful-dnd";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { IconTextButton, TableCell, TableDragIcon } from "vapi-ui-common";
import inputStyles from "../../../../../components/Input/input.module.scss";
import ContextMenuCell from "../../../../../components/tableCells/tableCells/ContextMenuCell";
import AccessoryItemVM from "../../../../../models/accessories/AccessoryItemVM";
import { Language } from "../../../../../models/user/user.model";
import handleErrorResponse from "../../../../../utils/errorHandlingUtils";
import { handleOnSortNumberUpdate } from "../../../../../utils/sortBy";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";
import styles from "./Actions.module.scss";

interface ActionsColumnProps {
  draggableProvided: DraggableProvided;
  handleAcceptChanges?: (item: AccessoryItemVM) => Promise<void>;
}

const ActionsColumn = ({
  draggableProvided,
  handleAcceptChanges,
}: ActionsColumnProps) => {
  const {
    readOnly,
    sortMode,
    deleteItem,
    deleteAccessoryItem,
    addCopiedItem,
    filterItems,
    allData,
    accessoriesLangMap,
    teamLang,
  } = useContext(AccessoriesEntryScreenContext);
  const [clearCta, setClearCta] = useState(false);

  const {
    accessoryItem: { id: accessoryItemId },
    index,
  } = useContext(AccessoryContext);

  const accessoryLangItem = accessoriesLangMap[accessoryItemId]?.langs;
  const accessoryItem = accessoryLangItem[Language.EN];

  const handleDeleteItem = useCallback(async () => {
    if (!accessoryItem.revId) {
      deleteItem(accessoryItem);
    } else {
      try {
        await trackPromise(deleteAccessoryItem(accessoryItem));
        toast.success("Successfully deleted accessory");
      } catch (e) {
        handleErrorResponse(e);
      }
    }
  }, [deleteItem, accessoryItem, deleteAccessoryItem]);

  const itemHasChangedAttributes = () => {
    let hasChanges = false;
    Object.keys(accessoryLangItem).forEach((lang) => {
      if (accessoryLangItem[lang].changedAttributes?.length) {
        hasChanges = true;
      }
    });
    return hasChanges;
  };

  const handleCopyItem = useCallback(async () => {
    if (accessoryItem.id) {
      try {
        await addCopiedItem(accessoryItem);
        toast.success("Successfully Copied Item");
      } catch (e) {
        handleErrorResponse(e, "Item failed to copy");
      }
    }
  }, [addCopiedItem, accessoryItem]);

  const onBlur = (e: any) =>
    handleOnSortNumberUpdate(allData, e.target.value, index, filterItems);

  const onChange = (e: any) => {
    const tempItem = accessoryItem;

    const newValue = parseInt(e.currentTarget.value, 10);
    tempItem.sortOrder = newValue > 0 ? newValue : "";

    filterItems("", [], false, false);
  };

  if (readOnly) return null;

  const changes = itemHasChangedAttributes() && !clearCta;

  if (!sortMode) {
    return (
      <>
        <TableCell changes={changes}>
          <div className={styles.container}>
            {teamLang === Language.EN && (
              <ContextMenuCell
                disabled={readOnly}
                hide={!!accessoryItem.nationalId}
                description={accessoryItem.title}
                itemType="Product Type"
                deleteItem={handleDeleteItem}
                copyItem={handleCopyItem}
              />
            )}
            {changes && (
              <IconTextButton
                className={styles.thumbsUp}
                id=""
                icon="thumbsUp"
                text=""
                tooltipText="Accept Changes?"
                align="right"
                onClick={() => {
                  if (handleAcceptChanges) {
                    handleAcceptChanges(accessoryLangItem[Language.ES]);
                    setClearCta(true);
                  }
                }}
              />
            )}
          </div>
        </TableCell>
      </>
    );
  }

  return (
    <>
      <TableCell {...draggableProvided.dragHandleProps} border center>
        <TableDragIcon />
      </TableCell>
      <TableCell border center>
        <input
          className={cx(inputStyles.input, inputStyles.smallInput)}
          value={accessoryItem.sortOrder}
          onBlur={onBlur}
          onChange={onChange}
        />
      </TableCell>
    </>
  );
};

ActionsColumn.defaultProps = {
  handleAcceptChanges: () => {},
};

export default ActionsColumn;
