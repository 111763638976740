import UserGroups from "../common/UserGroups";
import { UserPermissions } from "../common/UserPermissions";
import {
  SeriesCategories,
  VehicleTeam,
} from "../vehicleData/vehicleData.model";

export type Brand = "toyota" | "lexus";
export const BRAND_TOYOTA: Brand = "toyota";
export const BRAND_LEXUS: Brand = "lexus";
export const VALID_BRANDS: Brand[] = [BRAND_TOYOTA, BRAND_LEXUS];

export enum Language {
  EN = "EN",
  ES = "ES",
}

export interface UserInfo {
  brand: string;
  groups: UserGroups;
  modules: UserModules;
  langPermissions: LanguagePermissions;
  series: string[];
  objectId: string;
}

export type UserModules = {
  Accessories: UserAccPermissions;
  CommonLanguage: UserPermissions;
  ProductTeam: UserVDPermissions;
  AgencyTeam: UserVDPermissions;
  Disclaimers: UserPermissions;
  SeriesManager: UserPermissions;
  SeriesSelections: UserPermissions;
};

export interface UserVDPermissions extends UserPermissions {
  series: {
    [SeriesCategories.LARGE_SEDANS_VANS]: UserPermissions;
    [SeriesCategories.SEDANS_SPORTS_CARS]: UserPermissions;
    [SeriesCategories.CROSSOVERS]: UserPermissions;
    [SeriesCategories.TRUCKS_SUVS]: UserPermissions;
  };
}

export interface UserAccPermissions extends UserPermissions {
  teams: {
    [VehicleTeam.NATIONAL_ACC_TEAM]: UserPermissions;
    [VehicleTeam.GST_ACC_TEAM]: UserPermissions;
  };
}

export type LanguagePermissions = {
  [k in Language]?: UserPermissions;
};

export type VehicleDataVersionInfo = {
  [lang in Language]?: string | number;
};
