import { action, observable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { GradeMsrpItem } from "../gql/generated";
import { GradeItem } from "./modelStore";

export interface MsrpVM {
  id: string;
  uid: string;
  value: string;
  msrpValue: string;
  isVisible: boolean;
}
class MsrpPopoverStore {
  @observable data: MsrpVM[] | undefined = [];

  @observable gradesData: GradeItem[] = [];

  @action addMsrp = () => {
    if (this.data) {
      this.data.push({
        uid: uuidv4(),
        msrpValue: "",
        isVisible: false,
      } as MsrpVM);
    }
  };

  @action removeMsrp = (msrp: MsrpVM) => {
    this.data = this.data?.filter((x) => x.id !== msrp.id);
  };

  @action init = (msrp: GradeMsrpItem[]) => {
    this.reset();
    if (this.data) {
      this.data = msrp.map((el) => {
        const item = this.gradesData.find((x: GradeItem) => {
          return x.id === el.gradeId;
        });
        return {
          uid: uuidv4(),
          id: el.gradeId,
          msrpValue: el.msrp,
          isVisible: false,
          value: item?.value,
        } as MsrpVM;
      });
    }
  };

  @action payload = () => {
    return this.data?.map((item) => {
      return { gradeId: item.id, msrp: item.msrpValue } as GradeMsrpItem;
    });
  };

  @action reset() {
    this.data = [];
  }
}

const msrpPopoverStore = new MsrpPopoverStore();
export default msrpPopoverStore;
