import React, { ReactNode } from "react";

interface PageErrorProps {
  message?: ReactNode;
}

const defaultMessage =
  "Error loading page. Please refresh the page or contact an administrator.";

const PageError = ({ message }: PageErrorProps) => {
  return <h2>{message}</h2>;
};

PageError.defaultProps = {
  message: defaultMessage,
};

export default PageError;
