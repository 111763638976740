import React, { useCallback, useContext } from "react";
import { TableCell, areRichTextValuesDifferent } from "vapi-ui-common";
import { InputField } from "../../../../../components/Input";
import { Language } from "../../../../../models/user/user.model";
import useStores from "../../../../../stores/useStores";
import styles from "../../../AccessoriesEntryScreen.module.scss";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";

const CColumn = () => {
  const { userStore } = useStores();

  const { readOnly, saveAccessoryItem, languagePermissions } = useContext(
    AccessoriesEntryScreenContext
  );

  const { accessoryItem } = useContext(AccessoryContext);

  const userPermissionsEN = languagePermissions?.[Language.EN];
  const disabled = !userPermissionsEN?.canEdit || readOnly;

  const onChangeDealerCost = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      setInputValue: React.Dispatch<React.SetStateAction<string>>
    ) => {
      const val = event.target.value;

      const regex = /^[0-9]*(\.[0-9]{0,2})?$/;
      const isValid = regex.test(val);

      if (isValid) {
        setInputValue(val);
      }
    },
    []
  );

  const onBlurDealerCost = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const val = event.target.value;

      if (
        areRichTextValuesDifferent(accessoryItem.partsOnlyDealerCost || "", val)
      ) {
        const acc = accessoryItem;
        acc.partsOnlyDealerCost = val;
        saveAccessoryItem(acc);
      }
    },
    [saveAccessoryItem, accessoryItem]
  );

  const onChangeLaborTime = useCallback(
    (
      event: React.ChangeEvent<HTMLInputElement>,
      setInputValue: React.Dispatch<React.SetStateAction<string>>
    ) => setInputValue(event.target.value),
    []
  );

  const onBlurLaborTime = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const val = event.target.value;

      if (areRichTextValuesDifferent(accessoryItem.laborTime || "", val)) {
        const acc = accessoryItem;
        acc.laborTime = val;
        saveAccessoryItem(acc);
      }
    },
    [saveAccessoryItem, accessoryItem]
  );

  if (!userStore.isLexusUser()) return null;

  return (
    <TableCell className={styles.lexusDealerCostColumn}>
      <span className={styles.currencyInput}>
        <InputField
          id="accessory-dealer-cost-input"
          disabled={disabled}
          value={accessoryItem.partsOnlyDealerCost || ""}
          onChange={onChangeDealerCost}
          onBlur={onBlurDealerCost}
        />
      </span>

      <span className={styles.nonGen}>
        <InputField
          id="labor-time-input"
          disabled={disabled}
          value={accessoryItem.laborTime || ""}
          onChange={onChangeLaborTime}
          onBlur={onBlurLaborTime}
          label="Labor Time"
        />
      </span>
    </TableCell>
  );
};

export default CColumn;
