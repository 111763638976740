export enum VDSortableEntity {
  FEATURES = "features",
  FEATURES_CATEGORIES = "features-categories",
  FEATURES_SUBCATEGORIES = "features-subcategories",
  OPTIONS = "options",
  OPTIONS_CATEGORIES = "options-categories",
  PACKAGES = "packages",
  COLORS_EXTERIOR = "colors-exterior",
}

export interface ISortList {
  sortList: { [key: string]: number };
}

export enum SortType {
  ROWS = "Rows",
  PRODUCT_TYPE = "Product Type",
  ALPHABETICAL = "Alphabetical",
  GENUINE_AAP = "Genuine/AAP",
  GENUINE = "Genuine",
  AAP = "AAP",
  NONE = "None",
}
