import React from "react";
import { Redirect, Route } from "react-router-dom";
import AccessoriesRouteController from "./routes/AccessoriesRouteController";
import ChangeLogRouteController from "./routes/ChangeLogRouteController";
import DashboardRouteController from "./routes/DashboardRouteController";
import HelpController from "./routes/help/HelpController";
import DashboardController from "./routes/national/DashboardController";
import SeriesManagerController from "./routes/national/SeriesManagerController";
import ReportsController from "./routes/reports/ReportsController";
import Published from "./routes/commonLanguage/Published";
import Draft from "./routes/commonLanguage/Draft";
import Archived from "./routes/commonLanguage/Archived";
import ChangeLog from "./routes/commonLanguage/ChangeLog";

const Routes = () => {
  return (
    <>
      <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
      <Route exact path="/dashboard" component={DashboardRouteController} />
      <Route exact path="/commonLanguage/published" component={Published} />
      <Route exact path="/commonLanguage/draft" component={Draft} />
      <Route exact path="/commonLanguage/archived" component={Archived} />
      <Route
        exact
        path="/commonLanguage/changelog/:version"
        component={ChangeLog}
      />
      <Route exact path="/commonLanguage/changelog" component={ChangeLog} />
      <Route exact path="/:team/dashboard" component={DashboardController} />
      <Route
        exact
        path="/:team/seriesManager"
        component={SeriesManagerController}
      />
      <Route exact path="/help" component={HelpController} />
      <Route exact path="/reports" component={ReportsController} />
      <Route
        exact
        path="/vehicleData/:team/accessories/:seriesId/:year/:series/:version"
        component={AccessoriesRouteController}
      />
      <Route
        exact
        path="/vehicleData/:team/changelog/:seriesId/:year/:series/:version"
        component={ChangeLogRouteController}
      />
      <Route exact path="/seriesManager" component={ReportsController} />
    </>
  );
};

export default Routes;
