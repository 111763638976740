import cx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  ActionBar,
  ActionBarSection,
  Button,
  Checkbox,
  CheckboxLabel,
  HeaderRow,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ScrollableContainer,
  SearchInput,
  Spinner,
  Table,
  TableCell,
  TableRow,
} from "vapi-ui-common";
import { RefItem } from "../../gql/generated";
import AccessoryItemVM from "../../models/accessories/AccessoryItemVM";
import { KeyValueType } from "../../models/common/Common.model";
import { AccessoryCommonLanguageItem } from "../../models/commonLanguage.model";
import useAccessoryACL from "../../routes/commonLanguage/hooks/useAccessoryACL";
import useStores from "../../stores/useStores";
import AccessoryRichText from "../AccessoryRichText";
import styles from "./CommonLanguageModal.module.scss";
import { uncachedPartNumberImage } from "../../utils/partNumberImage";

interface Props {
  close: () => void;
  addNewAccessories: (items: AccessoryItemVM[]) => void;
  accessories: AccessoryItemVM[];
  addNewProductTypes: (items: RefItem[]) => void;
}

const CommonLanguageModal = ({
  close,
  accessories,
  addNewAccessories,
  addNewProductTypes,
}: Props) => {
  const { userStore } = useStores();
  const [importedAccMap, setImportedAccMap] = useState<KeyValueType<boolean>>(
    {}
  );
  const {
    isLoaded: isACLDataLoaded,
    aclListPublished,
    addCommonLanguage,
  } = useAccessoryACL({
    addNewAccessories,
    addNewProductTypes,
  });

  const [selectedMap, setSelected] = useState<KeyValueType<boolean>>({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [publishedList, setPublishedList] = useState<
    AccessoryCommonLanguageItem[]
  >([]);

  useEffect(() => {
    if (aclListPublished) {
      setPublishedList(aclListPublished);
    }
  }, [aclListPublished]);

  useEffect(() => {
    if (isACLDataLoaded && !isLoaded) {
      const importedMap: KeyValueType<boolean> = {};

      accessories.forEach((acc) => {
        if (acc.comLangId) {
          importedMap[acc.comLangId] = true;
        }
      });

      setImportedAccMap(importedMap);
      setIsLoaded(true);
    }
  }, [isACLDataLoaded, isLoaded, setImportedAccMap, setIsLoaded, accessories]);

  const addSelectedAccessories = () => {
    close();
    addCommonLanguage(Object.keys(selectedMap));
  };

  const handleToggleCheckbox = (aclId: string, checked: boolean) =>
    setSelected({ ...selectedMap, [aclId]: checked });

  const getDescription = (item: AccessoryCommonLanguageItem) => {
    if (userStore.isGstUser()) return item.description ?? "";
    if (userStore.isNationalUser()) return item.copy ?? "";
    return "";
  };

  const handleSearch = (value: string) => {
    if (value) {
      setPublishedList(
        aclListPublished.filter(
          (item) =>
            item.title.toLowerCase().includes(value.toLowerCase()) ||
            getDescription(item).toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setPublishedList(aclListPublished);
    }
  };

  const isDIO = (item: AccessoryCommonLanguageItem) =>
    !!item.installPoint?.includes("DIO");

  const hasSelected = () =>
    !!Object.values(selectedMap).find((isChecked) => isChecked);

  return (
    <>
      <ModalHeader onClose={() => close()}>
        {"Add Accessory > Add from Common Language"}
      </ModalHeader>
      {!isLoaded ? (
        <Spinner short />
      ) : (
        <>
          <ModalBody>
            <ActionBar>
              <ActionBarSection>
                <SearchInput value="" onSearch={handleSearch} />
              </ActionBarSection>
            </ActionBar>
            <ScrollableContainer style={{ overflow: "scroll" }}>
              <Table fullWidth className={styles.aclTable}>
                <thead className={styles.aclHead}>
                  <HeaderRow className={styles.stickyNav}>
                    <TableCell noSpan />
                    <TableCell noSpan>Name</TableCell>
                    <TableCell noSpan>Copy</TableCell>
                    {userStore.isNationalUser() && (
                      <TableCell noSpan>PPO Code</TableCell>
                    )}
                    <TableCell noSpan>Warranty</TableCell>
                    {userStore.isNationalUser() && (
                      <TableCell noSpan>Image</TableCell>
                    )}
                  </HeaderRow>
                </thead>

                <tbody className={styles.aclBody}>
                  {publishedList.map((item) => (
                    <TableRow zebra key={item.id}>
                      {/* checkbox */}
                      <TableCell noSpan>
                        <Checkbox
                          id={`addCl-chbox-${item.id}`}
                          checked={
                            importedAccMap[item.id] ?? selectedMap[item.id]
                          }
                          onChange={(e) => {
                            handleToggleCheckbox(
                              item.id,
                              e.currentTarget.checked
                            );
                          }}
                          disabled={importedAccMap[item.id]}
                          labelClassName={cx({
                            [styles.checkboxDisabled]: importedAccMap[item.id],
                          })}
                        />
                      </TableCell>

                      {/* Accessory Name */}
                      <TableCell noSpan>
                        <AccessoryRichText value={item.title} disabled />
                      </TableCell>

                      {/* Copy */}
                      <TableCell noSpan>
                        <AccessoryRichText
                          value={getDescription(item)}
                          disabled
                        />
                      </TableCell>

                      {/* PPO Code */}
                      {userStore.isNationalUser() && (
                        <TableCell noSpan>
                          {!isDIO(item) ? (
                            <AccessoryRichText
                              value={item.ppoCode ?? ""}
                              disabled
                            />
                          ) : (
                            <span>DIO</span>
                          )}
                        </TableCell>
                      )}

                      {/* Warranty */}
                      <TableCell noSpan>
                        {userStore.isNationalUser() && (
                          <Checkbox
                            id={`checkbox-aap-${item.id}`}
                            checked={item.isNonGenAccessory ?? false}
                            disabled
                            labelClassName={cx({
                              [styles.checkboxDisabled]: item.isNonGenAccessory,
                            })}
                          >
                            <CheckboxLabel>AAP</CheckboxLabel>
                          </Checkbox>
                        )}
                        <AccessoryRichText value={item.warranty} disabled />
                      </TableCell>

                      {/* Image */}
                      {userStore.isNationalUser() && (
                        <TableCell noSpan>
                          <img
                            className={styles.flageCellImage}
                            src={uncachedPartNumberImage(
                              item.images?.find((el) => el.isHero)?.image
                            )}
                            alt=""
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </ScrollableContainer>
          </ModalBody>
          <ModalFooter>
            <Button variant="transparent" onClick={() => close()}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!hasSelected()}
              onClick={addSelectedAccessories}
            >
              Add Selection(s)
            </Button>
          </ModalFooter>
        </>
      )}
    </>
  );
};

export default observer(CommonLanguageModal);
