import { observer } from "mobx-react-lite";
import React, { ReactElement, ReactNode, useRef } from "react";
import ResizeDetector from "react-resize-detector";
import { ModelCodeHeaderRow, Table, Thead } from "vapi-ui-common";
import useStores from "../../stores/useStores";
import styles from "./leftTable.module.scss";

/*
 Generic table for left side of
 model table on product team
 and agency tabs
*/
interface LeftTableProps {
  viewModelCodes?: boolean;
  renderHeaderRow?: () => ReactNode;
  renderRows?: () => ReactNode; 
  children: ReactElement
};

const LeftTable :React.FC<LeftTableProps> = ({
  viewModelCodes ,
  renderHeaderRow,
  renderRows,
  children,
  
}: LeftTableProps) => {
  
  const {
    tableSizeStore
  } = useStores();

  const ref = useRef<HTMLDivElement>(null);

  const onResize = (width: number) => {
    tableSizeStore.tableWidth = width;
  };

  return (
    <div ref={ref}>
      <Table className={styles.featureTable}>
        {renderHeaderRow && (
          <Thead>
            <>
              {renderHeaderRow()}
              {viewModelCodes && (
                <ModelCodeHeaderRow className={styles.modelHeaderRow}>
                  {/* <th /> */}
                </ModelCodeHeaderRow>
              )}
            </>
          </Thead>
        )}
        {renderRows && <tbody>{renderRows()}</tbody>}
        {children}
      </Table>

      <ResizeDetector
        handleWidth
        onResize={(width) => onResize(width)}
        targetDomEl={ref === null ? undefined : ref.current as HTMLElement}
      />
    </div>
  );
};
LeftTable.defaultProps = {
  viewModelCodes: false,
  renderHeaderRow: undefined,
  renderRows: undefined
}
export default observer(LeftTable);
