import { createContext, useContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { cleanUpRte, convertToRichTextObject } from "vapi-ui-common";
import {
  RefItem,
  SortItemInput,
  useCreateAccessoryMutation,
  useDeleteAccessoryMutation,
  useUpdateAccessoryMutation,
  useUpdateGoLiveDateMutation,
  useUpdateProductTypeSortMutation,
  useUpdateSortMutation,
} from "../../gql/generated";
import AccessoryItemVM, {
  GoLiveDateItem,
} from "../../models/accessories/AccessoryItemVM";
import { Language, VehicleDataVersionInfo } from "../../models/user/user.model";
import AccessoryStore from "./AccessoryStore";
import getAccessoryData from "./getAccessoryData";

interface Props {
  variables: {
    brand: string;
    team: string;
    region: string;
    lang: string;
    seriesId: string;
    modelYear: number;
    version: string;
  };
  vdVersionInfo: VehicleDataVersionInfo;
}

const AccStoreContext = createContext(new AccessoryStore());

const AccessoriesEntryScreenService = ({ variables, vdVersionInfo }: Props) => {
  const {
    allData,
    filteredData,
    forceUpdate,
    productTypes,
    readOnly,
    setSort,
    reset,
    deleteItem,
    addEmptyItem,
    filterItems,
    filterItemsGST,
    addProductType,
    updateData,
    addNewAccessories,
    lastSyncDate,
    updateLastSyncDate,
    lastNATPublishedDate,
    setDefaultLanguage,
    setAccessoriesLangMapData,
    accessoriesLangMap,
    productTypeLangMap,
    isReloadingDraft,
    setIsReloadingDraft,
    enCurrentVersion,
    enSourceVersion,
    updateProductTypes,
    commonLanguageIds,
    commonLanguageUpdatedIds,
    cleanCommonLanguageUpdatedIds,
    addProductTypes,
    goLiveDateLangEnMapData,
    setGoLiveDateLangEnMapData,
  } = useContext(AccStoreContext);

  const [createAccessory] = useCreateAccessoryMutation();
  const [updateAccessory] = useUpdateAccessoryMutation();
  const [deleteAccessory] = useDeleteAccessoryMutation();
  const [updateSorting] = useUpdateSortMutation();
  const [updateProductTypeSorting] = useUpdateProductTypeSortMutation();
  const [updateGoLiveDateMutation] = useUpdateGoLiveDateMutation();

  const [error, setError] = useState<boolean | undefined>();
  const [loading, setLoading] = useState(true);

  const getESData = !!vdVersionInfo[Language.ES];

  useEffect(() => {
    reset();
    (async () => {
      if (loading) {
        const {
          enData,
          esData,
          enVersionData,
          error: err,
        } = await getAccessoryData(
          variables,
          vdVersionInfo,
          undefined,
          !!vdVersionInfo[Language.ES]
        );
        setAccessoriesLangMapData({ enData, esData, enVersionData, getESData });
        setError(err);
        setLoading(false);
      }
    })();
  }, []);

  const getAccessoryPayload = (item: AccessoryItemVM) => {
    const payload = {
      id: item.id,
      title: cleanUpRte(item.title),
      title_es: cleanUpRte(item.title_es),
      notes: item.notes,
      copy: cleanUpRte(item.copy),
      disclosure: cleanUpRte(item.disclosure),
      modelApplicability:
        item.modelApplicability?.map(({ modelId }) => ({ modelId })) ||
        undefined,
      productType: item.productType,
      installPoint: item.installPoint,
      ppoCode: cleanUpRte(item.ppoCode),
      msrp: item.msrp,
      inactive: item.inactive,
      disclaimer: cleanUpRte(item.disclaimer),
      disclaimer_es: cleanUpRte(item.disclaimer_es),
      description: cleanUpRte(item.description),
      description_es: cleanUpRte(item.description_es),
      abb: cleanUpRte(item.abb),
      supplier: cleanUpRte(item.supplier),
      partNumber: cleanUpRte(item.partNumber),
      partsOnlyDealerCost: item.partsOnlyDealerCost,
      partsOnlyMSRP: cleanUpRte(item.partsOnlyMSRP),
      installedDealerCost: item.installedDealerCost,
      required: item.required,
      conflicts: item.conflicts,
      isNonGenAccessory: item.isNonGenAccessory,
      warranty: cleanUpRte(item.warranty),
      warranty_es: cleanUpRte(item.warranty_es),
      nationalId: item.nationalId,
      hasAAPSyncChanges: item.hasAAPSyncChanges,
      images: item.images?.map((img) => {
        return {
          name: img.name,
          image: img.image,
          isHero: img.isHero,
        };
      }),
      gradeMsrp: item.gradeMsrp?.map((gm) => {
        return {
          gradeId: gm.gradeId,
          msrp: gm.msrp,
        };
      }),
      comLangId: item.comLangId,
      comLangVersion: item.comLangVersion,
      hasComLangChanges: item.hasComLangChanges,
      laborTime: item.laborTime,
    };

    return payload;
  };

  const updateAccessoryItem = async (
    item: AccessoryItemVM,
    language: Language,
    acceptChanges?: boolean
  ) => {
    return updateAccessory({
      variables: {
        ...variables,
        lang: language.toLowerCase(),
        acceptChanges,
        accessory: {
          revId: item.revId,
          ...getAccessoryPayload(item),
        },
      },
      update: (_cache, { data: newData }) => {
        const acc = item;
        if (acceptChanges) {
          acc.changedAttributes = [];
        }
        acc.revId = newData?.updateAcc.accessory.revId || "";
      },
    });
  };

  const updateGoLiveDates = async (goLiveDates: GoLiveDateItem[]) => {
    return updateGoLiveDateMutation({
      variables: {
        ...variables,
        goLiveDates,
      },
      update: (_cache, { data }) => {
        setGoLiveDateLangEnMapData(data?.updateGoLiveDate);
      },
    });
  };

  const sortByTitle = (a: AccessoryItemVM, b: AccessoryItemVM) => {
    const aLower = convertToRichTextObject(a.title).text.toLowerCase();
    const bLower = convertToRichTextObject(b.title).text.toLowerCase();
    if (aLower < bLower) {
      return -1;
    }
    if (aLower > bLower) {
      return 1;
    }
    return 0;
  };

  const sortByGenuine = (a: AccessoryItemVM, b: AccessoryItemVM) => {
    const aNum = a.isNonGenAccessory ? 1 : 0;
    const bNum = b.isNonGenAccessory ? 1 : 0;
    if (aNum < bNum) {
      return -1;
    }
    if (aNum > bNum) {
      return 1;
    }
    return 0;
  };

  const sortByAAP = (a: AccessoryItemVM, b: AccessoryItemVM) => {
    const aNum = a.isNonGenAccessory ? 1 : 0;
    const bNum = b.isNonGenAccessory ? 1 : 0;
    if (aNum > bNum) {
      return -1;
    }
    if (aNum < bNum) {
      return 1;
    }
    return 0;
  };

  const sortByNoneNationalId = (a: AccessoryItemVM, b: AccessoryItemVM) => {
    const aNum = a.nationalId ? 1 : 0;
    const bNum = b.nationalId ? 1 : 0;
    if (aNum < bNum) {
      return -1;
    }
    if (aNum > bNum) {
      return 1;
    }
    return 0;
  };

  const sortByNationalId = (a: AccessoryItemVM, b: AccessoryItemVM) => {
    const aNum = a.nationalId ? 1 : 0;
    const bNum = b.nationalId ? 1 : 0;
    if (aNum > bNum) {
      return -1;
    }
    if (aNum < bNum) {
      return 1;
    }
    return 0;
  };

  const sortBySortOrder = (
    a: { sortOrder: string | number },
    b: { sortOrder: string | number }
  ) => {
    const aLower = a.sortOrder;
    const bLower = b.sortOrder;
    if (aLower < bLower) {
      return -1;
    }
    if (aLower > bLower) {
      return 1;
    }
    return 0;
  };

  const updateSort = async (
    sortAtoZ: boolean,
    baseArray: AccessoryItemVM[],
    ptCategories?: boolean
  ) => {
    if (sortAtoZ) {
      baseArray.sort(sortByTitle);
      baseArray.forEach((x, index) => {
        const acc = x;
        acc.sortOrder = index + 1;
      });
      updateData(baseArray);
    }

    if (ptCategories) {
      updateData(baseArray);
    }

    const sortListData = baseArray.map((x, index) => {
      return { id: x.id, sortOrder: index } as SortItemInput;
    });

    return updateSorting({
      variables: {
        ...variables,
        sortList: sortListData,
      },
    });
  };

  const updateProductTypeSort = async (productTypeCategories: RefItem[]) => {
    updateProductTypes(productTypeCategories);

    const sortListData = productTypeCategories.map((x, index) => {
      return { id: x.id, sortOrder: index } as SortItemInput;
    });

    return updateProductTypeSorting({
      variables: {
        ...variables,
        sortList: sortListData,
      },
    });
  };

  const addAccessoryItem = async (
    items: AccessoryItemVM | AccessoryItemVM[],
    importingFromNtnl: boolean = false
  ) => {
    const itemArr = Array.isArray(items) ? items : [items];
    let sortOrderSorted: AccessoryItemVM[] = allData
      .slice()
      .sort(sortBySortOrder);

    const promises = itemArr.map((item) => {
      // if the item you are adding doesn't exist then we need to add it for proper sorting
      if (sortOrderSorted.indexOf(item) === -1) {
        sortOrderSorted.unshift(item);
      }
      sortOrderSorted = sortOrderSorted.sort(sortBySortOrder);
      const alphaSorted = sortOrderSorted.slice().sort(sortByTitle);
      const numData = alphaSorted.length;

      let destinationIndex = -1;
      for (let i = 0; i < numData; i += 1) {
        // find the item you are adding in the alphabetized sorted array
        if (alphaSorted[i].uid === item.uid) {
          // set the destination index to the sort order of the next item in the array (i.e. the next item that comes after the item you are adding alphabetically) minus one. this ensures that the item you are adding is sorted before the item that comes after it alphabetically
          destinationIndex =
            i < numData - 1 ? Number(alphaSorted[i + 1].sortOrder) - 1 : i;
          break;
        }
      }

      // remove the item you are adding. the sourceIndex will always be 0 b/c the default value for an AccessoryItemVM.sortOrder is 0.
      const sourceIndex = sortOrderSorted.indexOf(item);
      const [removed] = sortOrderSorted.splice(sourceIndex, 1);
      // add the new item at the destination index
      sortOrderSorted.splice(destinationIndex, 0, removed);
      // assign new sort orders
      sortOrderSorted.forEach(
        (accItem: { sortOrder: number | string }, index: number) => {
          const acc = accItem;
          acc.sortOrder = index + 1;
        }
      );

      const { comLangId, comLangVersion, hasComLangChanges, ...accessory } =
        getAccessoryPayload(item);

      return createAccessory({
        variables: {
          ...variables,
          accessory,
        },
        update: async (_cache, { data: newData }) => {
          const acc = item;
          acc.id = newData?.createAcc.accessory.id || "";
          acc.revId = newData?.createAcc.accessory.revId || "";

          if (importingFromNtnl) {
            acc.title_es = newData?.createAcc.accessory.title_es || "";
            acc.disclaimer_es =
              newData?.createAcc.accessory.disclaimer_es || "";
            acc.description_es =
              newData?.createAcc.accessory.description_es || "";
            acc.warranty_es = newData?.createAcc.accessory.warranty_es || "";
          }
        },
      });
    });

    const res = await Promise.all(promises);
    if (res.every(({ errors }) => !errors)) {
      await updateSort(false, sortOrderSorted);
    }

    return res;
  };

  const addCopiedItem = async (item: AccessoryItemVM) => {
    const accessoryArr = [] as AccessoryItemVM[];
    const filteredArr = [] as AccessoryItemVM[];

    // id's are set on the backend
    const copiedItem = {
      ...item,
      uid: uuidv4(),
      id: "",
      revId: "",
      modelApplicability: [],
    } as AccessoryItemVM;

    // this was added because the sort was breaking
    const newItem = new AccessoryItemVM({ accessoryItem: copiedItem });

    allData.forEach((el) => {
      accessoryArr.push(el);
      if (el.id === item.id) {
        // add the new item to the accessory array right after its copied equivalent
        accessoryArr.push(newItem);
      }
    });

    // needs to be done to have filtered data as well have the sort order
    filteredData.forEach((fData) => {
      filteredArr.push(fData);
      if (fData.id === item.id) {
        filteredArr.push(newItem);
      }
    });

    await addAccessoryItem(newItem, false);
    // after adding the accessory to the backend via addAccessoryItem we need to add it to the frontend by calling updateData
    updateData(accessoryArr, filteredArr, newItem);
  };

  const deleteAccessoryItem = async (item: AccessoryItemVM) => {
    return deleteAccessory({
      variables: {
        ...variables,
        id: item.id,
      },
      update: () => {
        deleteItem(item);
      },
    });
  };

  const reloadDraft = async (sourceVersion: string) => {
    setIsReloadingDraft(true);
    const {
      enData,
      esData,
      enVersionData,
      error: err,
    } = await getAccessoryData(
      variables,
      vdVersionInfo,
      sourceVersion,
      !!vdVersionInfo[Language.ES]
    );
    setAccessoriesLangMapData({
      enData,
      esData,
      enVersionData,
      getESData,
    });
    setError(err);
    setIsReloadingDraft(false);
  };

  return {
    allData,
    forceUpdate,
    updateSort,
    updateProductTypeSort,
    filteredData,
    productTypes,
    error,
    isLoaded: !loading && !isReloadingDraft,
    readOnly,
    setSort,
    addEmptyItem,
    addCopiedItem,
    addAccessoryItem,
    deleteItem,
    deleteAccessoryItem,
    updateAccessoryItem,
    filterItems,
    filterItemsGST,
    addProductType,
    sortByTitle,
    sortByGenuine,
    sortByAAP,
    sortByNationalId,
    sortByNoneNationalId,
    sortBySortOrder,
    addNewAccessories,
    lastSyncDate,
    updateLastSyncDate,
    lastNATPublishedDate,
    setDefaultLanguage,
    enCurrentVersion,
    enSourceVersion,
    reloadDraft,
    accessoriesLangMap,
    productTypeLangMap,
    commonLanguageIds,
    commonLanguageUpdatedIds,
    cleanCommonLanguageUpdatedIds,
    addProductTypes,
    updateGoLiveDates,
    goLiveDateLangEnMapData,
  };
};

export default AccessoriesEntryScreenService;
