import React, { useCallback, useContext } from "react";
import { areRichTextValuesDifferent, cleanUpRte } from "vapi-ui-common";
import AccessoryRichText from "../../../../../components/AccessoryRichText";
import { Language } from "../../../../../models/user/user.model";
import checkForSyncChanges from "../../../../../utils/checkForSyncChanges";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";

interface IAccessoryRichTextLanguageProps {
  language: Language;
}

const CAccessoryRichTextLanguage = ({
  language,
}: IAccessoryRichTextLanguageProps) => {
  const {
    readOnly,
    saveAccessoryItem,
    languagePermissions,
    accessoriesLangMap,
  } = useContext(AccessoriesEntryScreenContext);

  const {
    accessoryItem: { id: accessoryItemId },
    index,
  } = useContext(AccessoryContext);

  const userPermissions = languagePermissions?.[language];
  const disabled = !userPermissions?.canEdit || readOnly;

  const accessoryItem = accessoriesLangMap[accessoryItemId]?.langs?.[language];

  const onBlur = useCallback(
    (warranty: string) => {
      if (areRichTextValuesDifferent(accessoryItem.warranty, warranty)) {
        const acc = accessoryItem;
        acc.warranty = cleanUpRte(warranty);
        saveAccessoryItem(acc, language);
      }
    },
    [saveAccessoryItem, accessoryItem]
  );

  return (
    <AccessoryRichText
      tabIndex={index + 1}
      value={accessoryItem.warranty || ""}
      disabled={!accessoryItem.isNonGenAccessory || disabled}
      required={checkForSyncChanges(
        accessoryItem.changedAttributes,
        "warranty"
      )}
      onBlur={onBlur}
    />
  );
};

export default CAccessoryRichTextLanguage;
