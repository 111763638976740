import { action, computed, observable } from "mobx";
import {
  Series,
  SeriesInfoItem,
  UserSeries,
  VehicleTeam,
} from "../models/vehicleData/vehicleData.model";
import { dashboardXForm } from "../utils/vehicleDataUtils";
import {
  // getDashboardSeries,
  getDashboardDetails,
  getSeries,
  getUserSeries,
} from "../webservices/adminApi";
import { Brand } from "../gql/adminGenerated";

class DashboardStore {
  @observable seriesInfoData: SeriesInfoItem[] = [];

  @observable searchText = "";

  @observable dashboardSeries: any = [];

  @observable userSeries: UserSeries[] = [];

  @observable userSeriesObj: { [id: string]: SeriesInfoItem } = {};

  @observable allSeries: Series = {};

  @action fetchDebugDashboardSeries = () => {
    // TODO: remove localstorage and hook in API
    this.getLocalStorage();
  };

  @action fetchDashboardSeries = async () => {
    const dashboardResponse = await getDashboardDetails(
      "toyota",
      "product-team" as VehicleTeam
    );
    this.seriesInfoData = dashboardXForm(
      dashboardResponse.data,
      this.allSeries,
      // userStore.teamModule,
      // teamStore.team.param
      "product-team" as VehicleTeam
    );
  };

  @action fetchSeries = async (brand: string) => {
    this.allSeries = (await getSeries({ brand: brand as Brand })).series;
  };

  @action fetchUserSeries = async (brand: string, objectId: string) => {
    const userSeriesResponse = await getUserSeries({
      brand: brand as Brand,
      objectId,
    });
    this.userSeries = userSeriesResponse.seriesSelections.map(
      (series) => series
    );
  };

  localStorageKey = `dashboardstore-series`;

  @action getLocalStorage = () => {
    this.localStorageKey = `dashboardstore-series`;
    let lsItem = localStorage.getItem(this.localStorageKey);

    if (!lsItem) {
      lsItem = "{}";
    }

    const dataObj = JSON.parse(lsItem) as { [id: string]: SeriesInfoItem };

    const seriesInfoList: SeriesInfoItem[] = Object.keys(dataObj).map(
      (i) => dataObj[i]
    );
    this.userSeriesObj = {};
    this.userSeries.forEach((series) => {
      if (dataObj[series.id]) {
        this.userSeriesObj[series.id] = dataObj[series.id];
      } else {
        const newInfo = {
          id: series.id,
          seriesName: series.name,
          modelYears: [],
        };
        seriesInfoList.push(newInfo);
        this.userSeriesObj[series.id] = newInfo;
      }
    });

    this.seriesInfoData = seriesInfoList;
  };

  setLocalStorage = () => {
    const dataObj: { [id: string]: SeriesInfoItem } = {};
    this.seriesInfoData.forEach((item) => {
      dataObj[item.id] = item;
    });
    localStorage.setItem(this.localStorageKey, JSON.stringify(dataObj));
  };

  @action populateDashboard = () => {
    const seriesInfoList: SeriesInfoItem[] = [];
    if (this.userSeries.length) {
      this.userSeries.forEach((series) => {
        seriesInfoList.push({
          id: series.id,
          seriesName: series.name,
          modelYears: [],
        });
      });
    }

    this.seriesInfoData = seriesInfoList;
  };

  @computed get filteredDashboardData() {
    const loweredSearchText = this.searchText.toLowerCase();
    return this.seriesInfoData.filter(
      (item: SeriesInfoItem) =>
        item.seriesName.toLowerCase().indexOf(loweredSearchText) !== -1 &&
        !!this.userSeriesObj[item.id]
    );
  }
}

export default DashboardStore;
