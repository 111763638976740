import { useRef, useState } from "react";

interface InactiveMapper {
  id: string;
  inactive: boolean;
}

const useInactiveMapper = () => {
  const [inactiveMappers, updateInactiveMappers] = useState<InactiveMapper[]>(
    []
  );
  const inactiveMappersRef = useRef<InactiveMapper[]>([]);
  const setInactiveMappers = (mappers: InactiveMapper[]) => {
    inactiveMappersRef.current = mappers;
    updateInactiveMappers(mappers);
  };

  return { inactiveMappers, setInactiveMappers, inactiveMappersRef };
};

export default useInactiveMapper;
