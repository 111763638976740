import { action, computed, observable } from "mobx";
import { GSTItem } from "../models/gst/GSTAccessories.model";
import { Series } from "../models/vehicleData/vehicleData.model";

class GSTStore {
  @observable accessoryItems: GSTItem[] = [];

  @observable reverseSort = false;

  @observable sortField = "id";

  @observable searchText = "";

  @observable seriesId: string = "";

  @observable seriesYear: string = "";

  @observable allSeries = {} as Series;

  @observable models: any = [];

  setSearch = (query: string) => {
    this.searchText = query;
  };

  addItem = () => {};

  isValid = (item: GSTItem) => {
    return item.abb !== "" && item.title !== "";
  };

  @action resetFilters = () => {
    this.searchText = "";
    this.accessoryItems = [];
  };

  @computed get filteredData() {
    const loweredSearchText = this.searchText.toLowerCase();
    return this.accessoryItems.filter((item) => {
      return item.title.toLowerCase().indexOf(loweredSearchText) !== -1;
    });
  }

  localStorageKey = `gst-${this.seriesId}-${this.seriesYear}`;

  @action getLocalStorage = () => {
    this.localStorageKey = `gst-${this.seriesId}-${this.seriesYear}`;
    const lsItem = localStorage.getItem(this.localStorageKey);
    this.accessoryItems = (lsItem ? JSON.parse(lsItem) : []) as GSTItem[];
  };

  setLocalStorage = () => {
    localStorage.setItem(
      this.localStorageKey,
      JSON.stringify(this.accessoryItems)
    );
  };
}

export default GSTStore;
