import React from "react";
import ChangeLogRichTextDiff from "../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogRichTextDiff";
import ChangeLogApplicability from "../../../../components/ChangeLogTable/ChangeLogChanges/components/ChangeLogApplicability";
import ChangeLogBeforeAfter from "../../../../components/ChangeLogTable/ChangeLogChanges/components/ChangeLogBeforeAfter";
import ChangeLogCommonLang from "../../../../components/ChangeLogTable/ChangeLogChanges/components/ChangeLogCommonLang";
import ChangeLogImage from "../../../../components/ChangeLogTable/ChangeLogChanges/components/ChangeLogImages";
import ChangeLogGradeMsrp from "../../../../components/ChangeLogTable/ChangeLogChanges/components/ChangelogGradeMsrp";
import { Change, GoLiveDate, RefItem } from "../../../../gql/generated";
import { GradeItem } from "../../../../stores/modelStore";
import ChangeLogGoLiveDate from "../../../../components/ChangeLogTable/ChangeLogChanges/components/ChangeLogGoLiveDate";

export enum AccessoryChangeType {
  ACCESSORY_ADDED = "Accessory Added",
  ACCESSORY_DELETED = "Accessory Deleted",
  TITLE = "Title",
  COPY = "Copy",
  DISCLOSURE = "Disclosure",
  PRODUCT_TYPE = "Product Type",
  PRODUCT_TYPE_CHANGED = "Product Type Changed",
  INSTALL_POINT = "Install Point",
  PPO_CODE = "PPO Code",
  DESCRIPTION = "Description",
  GRADE_MSRP = "Grade MSRP",
  MSRP = "MSRP",
  NOTES = "Notes",
  INACTIVE = "Inactive",
  MODEL_APPLICABILITY = "Model Applicability",
  PART_NUMBER = "Part Number",
  PARTS_ONLY_DEALER_COST = "Parts Only Dealer Cost",
  PARTS_ONLY_MSRP = "Parts Only MSRP",
  INSTALLED_DEALER_COST = "Installed Dealer Cost",
  REQUIRED = "Required",
  CONFLICTS = "Conflicts",
  SPECS = "Specs",
  NON_GENUINE = "Non Genuine",
  IMAGES = "Images",
  WARRANTY = "Warranty",
  COMMON_LANGUAGE = "Common Language",
  LABOR_TIME = "Labor Time",
  GO_LIVE_DATE = "Go Live Date",
}

interface ChangeLogChangesProps {
  changedItem: Change;
  gradesDataItem: GradeItem[];
  refItems: RefItem[] | null | undefined;
}

const ChangeLogChanges = ({
  changedItem,
  gradesDataItem,
  refItems,
}: ChangeLogChangesProps) => {
  switch (changedItem.changeType) {
    case AccessoryChangeType.TITLE:
    case AccessoryChangeType.COPY:
    case AccessoryChangeType.DISCLOSURE:
    case AccessoryChangeType.PPO_CODE:
    case AccessoryChangeType.DESCRIPTION:
    case AccessoryChangeType.PART_NUMBER:
    case AccessoryChangeType.PARTS_ONLY_DEALER_COST:
    case AccessoryChangeType.PARTS_ONLY_MSRP:
    case AccessoryChangeType.INSTALLED_DEALER_COST:
    case AccessoryChangeType.REQUIRED:
    case AccessoryChangeType.CONFLICTS:
    case AccessoryChangeType.NOTES:
    case AccessoryChangeType.LABOR_TIME:
    case AccessoryChangeType.WARRANTY: {
      return (
        <ChangeLogRichTextDiff
          before={changedItem.before || ""}
          after={changedItem.after || ""}
        />
      );
    }

    case AccessoryChangeType.PRODUCT_TYPE_CHANGED:
    case AccessoryChangeType.INSTALL_POINT: {
      return (
        <ChangeLogBeforeAfter
          before={changedItem.beforeValue || changedItem.before || ""}
          after={changedItem.afterValue || changedItem.after || ""}
        />
      );
    }

    case AccessoryChangeType.GRADE_MSRP: {
      return (
        <ChangeLogGradeMsrp
          gradesDataItem={gradesDataItem || []}
          before={changedItem.before || ""}
          after={changedItem.after || ""}
        />
      );
    }

    case AccessoryChangeType.PRODUCT_TYPE: {
      const beforeVal =
        refItems?.find((item) => item.id === changedItem.before)?.name || "";
      const afterVal =
        refItems?.find((item) => item.id === changedItem.after)?.name || "";
      return <ChangeLogBeforeAfter before={beforeVal} after={afterVal} />;
    }

    case AccessoryChangeType.MODEL_APPLICABILITY: {
      return (
        <ChangeLogApplicability
          before={changedItem.before || ""}
          after={changedItem.after || ""}
          gradesDataItem={gradesDataItem || []}
        />
      );
    }

    case AccessoryChangeType.INACTIVE:
    case AccessoryChangeType.NON_GENUINE: {
      return (
        <ChangeLogBeforeAfter
          before={changedItem.before === "true" ? "true" : "false"}
          after={changedItem.after === "true" ? "true" : "false"}
        />
      );
    }

    case AccessoryChangeType.MSRP: {
      return (
        <ChangeLogRichTextDiff
          before={changedItem.before ? changedItem.before.toString() : ""}
          after={changedItem.after ? changedItem.after.toString() : ""}
        />
      );
    }

    case AccessoryChangeType.IMAGES: {
      return (
        <ChangeLogImage
          before={changedItem.before || ""}
          after={changedItem.after || ""}
        />
      );
    }

    case AccessoryChangeType.COMMON_LANGUAGE: {
      return (
        <ChangeLogCommonLang
          before={changedItem.before}
          after={changedItem.after}
        />
      );
    }

    case AccessoryChangeType.GO_LIVE_DATE: {
      return (
        <ChangeLogGoLiveDate
          before={changedItem.before || ""}
          after={changedItem.after || ""}
          changedItem={changedItem.changedItem as GoLiveDate}
          gradesDataItem={gradesDataItem || []}
        />
      );
    }

    default: {
      return <>--</>;
    }
  }
};

export default ChangeLogChanges;
