import { convertToRichTextObject } from "vapi-ui-common";

const dotToObject = (obj: any, prop: any) => {
  return prop.split(".").reduce((o: any, i: any) => o[i], obj);
};

const sortBy = (prop: any, reverse?: boolean, isDate?: boolean) => {
  return (a: any, b: any) => {
    const aProp = dotToObject(a, prop);
    const bProp = dotToObject(b, prop);

    let aItem;
    let bItem;

    if (isDate) {
      aItem = Math.round(new Date(aProp).getTime() / 1000);
      bItem = Math.round(new Date(bProp).getTime() / 1000);
    } else if (typeof aProp === "string" && typeof bProp === "string") {
      const aText = convertToRichTextObject(aProp);
      const bText = convertToRichTextObject(bProp);
      aItem = aText.text.toLowerCase();
      bItem = bText.text.toLowerCase();
    } else {
      aItem = aProp;
      bItem = bProp;
    }
    aItem = Number(aItem) ? Number(aItem) : aItem;
    bItem = Number(bItem) ? Number(bItem) : bItem;

    if (aItem > bItem) {
      return reverse ? -1 : 1;
    }

    if (aItem < bItem) {
      return reverse ? 1 : -1;
    }

    return 0;
  };
};

export default sortBy;

export const handleOnSortNumberUpdate = (
  dataset: { sortOrder: number | string }[],
  newIndex: string,
  oldIndex: number,
  filterItems: (arg1: string, arg2: [], arg3: boolean, arg4?: boolean) => void
) => {
  let parsedIndex = parseInt(newIndex, 10);
  if (parsedIndex <= 0) {
    parsedIndex = 1;
  }

  if (Number.isInteger(parsedIndex)) {
    const [removed] = dataset.splice(oldIndex, 1);
    dataset.splice(parsedIndex - 1, 0, removed);
  }

  filterItems("", [], false, false);

  dataset.forEach((item: { sortOrder: number | string }, index: number) => {
    const acc = item;
    acc.sortOrder = index + 1;
  });
};
