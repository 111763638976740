/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import {
  ActionBar,
  ActionBarSection,
  Button,
  Header,
  HeaderCell,
  HeaderRow,
  SearchInput,
  Spinner,
  Table,
  Thead,
} from "vapi-ui-common";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react-lite";
import useStores from "../../stores/useStores";
import VersionsList from "../../components/VersionList";
import {
  AccCommonLanguage,
  useAclChangesQuery,
  useRevertAclChangeMutation,
} from "../../gql/generated";
import {
  ACCESSORY_TEAM,
  GST_REGION,
  NATIONAL_REGION,
} from "../../constants/Constants";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";
import ChangeLogRow from "../../components/ChangeLogTable/ChangeLogRow";
import ChangeLogChanges, {
  ACLChangeType,
  ACL_CHANGE_TYPE_ATTRIBUTE_MAP,
} from "./components/ChangeLogChanges";
import getQueryParams from "../../utils/getQueryParams";

const STATUS_VERSIONS_MAP: Record<string, string> = {
  draft: "DRAFT_CL",
  archived: "ARCHIVED_CL",
};

interface ChangeLogParams {
  version?: string;
}

const ChangeLog = () => {
  const history = useHistory();
  const { userStore, commonLanguageChangeLogStore } = useStores();
  const { version = "draft" } = useParams<ChangeLogParams>();
  const exitVersion = getQueryParams().get("exitVersion") ?? "draft";
  const aclChangesResponse = useAclChangesQuery({
    skip: !userStore.brand || !version,
    variables: {
      brand: userStore.brand,
      region: userStore.isGstUser() ? GST_REGION : NATIONAL_REGION,
      team: ACCESSORY_TEAM,
      version: STATUS_VERSIONS_MAP[version] ?? version,
    },
    fetchPolicy: "network-only",
  });

  const [revertACLChange] = useRevertAclChangeMutation({
    refetchQueries: ["aclChanges"],
  });

  const deletedAcc = useMemo(
    () =>
      (aclChangesResponse.data?.aclChanges.changes || []).reduce(
        (acc, item) => {
          if (item.changeType === ACLChangeType.ACL_DELETED) {
            acc.add(item.changedItemId);
          }
          return acc;
        },
        new Set<string>()
      ),
    [aclChangesResponse.data?.aclChanges.changes]
  );

  useEffect(() => {
    if (!aclChangesResponse.loading && aclChangesResponse.data) {
      commonLanguageChangeLogStore.setData(
        aclChangesResponse.data.aclChanges.changes,
        aclChangesResponse.data.getACLProductTypes,
        aclChangesResponse.data.aclHeader
      );
    }

    return () => {
      commonLanguageChangeLogStore.reset();
    };
  }, [
    aclChangesResponse.loading,
    aclChangesResponse.data,
    commonLanguageChangeLogStore,
  ]);

  const team = userStore.isGstUser() ? "GST" : "National";

  if (aclChangesResponse.loading) {
    return <Spinner />;
  }

  return (
    <>
      <Header
        moduleTitle={`${team} Accessories Team`}
        moduleSubTitle="Change Log"
      />
      <ActionBar>
        <ActionBarSection>
          <SearchInput
            value={commonLanguageChangeLogStore.searchText}
            onSearch={commonLanguageChangeLogStore.setSearchText}
          />
        </ActionBarSection>
        <ActionBarSection>
          <NavLink to={`/commonLanguage/${exitVersion}`}>
            <Button variant="transparent">Exit Change Log</Button>
          </NavLink>
          <VersionsList
            value={capitalizeFirstLetter(version)}
            latestVersion={commonLanguageChangeLogStore.lastVersion}
            hasDraft
            onSelect={(selectedVersion) => {
              history.push(
                `/commonLanguage/changelog/${selectedVersion.toLocaleLowerCase()}?exitVersion=${exitVersion}`
              );
            }}
          />
        </ActionBarSection>
      </ActionBar>
      <Table fullWidth>
        <Thead>
          <HeaderRow>
            <HeaderCell>
              {userStore.isGstUser() ? "Name" : "Accessory Title"}
            </HeaderCell>
            <HeaderCell
              onClick={() => {
                commonLanguageChangeLogStore.setSortKey("changeType");
              }}
            >
              Change Type
            </HeaderCell>
            <HeaderCell>Changes</HeaderCell>
            <HeaderCell
              onClick={() => {
                commonLanguageChangeLogStore.setSortKey("modifiedBy");
              }}
            >
              Modified By
            </HeaderCell>
            <HeaderCell
              onClick={() => {
                commonLanguageChangeLogStore.setSortKey("modifiedDate");
              }}
            >
              Modified Date
            </HeaderCell>
            <HeaderCell>Notes</HeaderCell>
            <HeaderCell />
          </HeaderRow>
        </Thead>
        <tbody>
          {commonLanguageChangeLogStore.filteredData.map((item) => {
            const accItem = item.changedItem;
            const key = ACL_CHANGE_TYPE_ATTRIBUTE_MAP.get(
              item.changeType
            ) as keyof AccCommonLanguage;
            const currentValue = key ? accItem[key] : undefined;

            let changeTypeDisplay = item.changeType;

            if (
              userStore.isGstUser() &&
              changeTypeDisplay === ACLChangeType.TITLE
            ) {
              changeTypeDisplay = "Name";
            } else if (
              userStore.isGstUser() &&
              changeTypeDisplay === ACLChangeType.TITLE_ES
            ) {
              changeTypeDisplay = "Name es";
            }

            return (
              <ChangeLogRow
                key={`row-change-${item.changedItemId}-${item.changeType}-${item.modifiedDate}`}
                description={accItem?.title ?? ""}
                changeType={changeTypeDisplay}
                modifiedBy={item.modifiedBy || ""}
                modifiedDate={item.modifiedDate || ""}
                notes={accItem?.notes || ""}
                canRevert={
                  version === "draft" &&
                  !deletedAcc.has(item.changedItemId) &&
                  item.before !== currentValue &&
                  ![
                    ACLChangeType.ACL_ADDED,
                    ACLChangeType.ACL_DELETED,
                    ACLChangeType.ACL_ARCHIVED,
                  ].includes(item.changeType as ACLChangeType)
                }
                renderChanges={
                  <ChangeLogChanges
                    changedItem={item}
                    productTypes={commonLanguageChangeLogStore.productTypes}
                  />
                }
                onRevert={() => {
                  revertACLChange({
                    variables: {
                      input: {
                        id: item.changedItemId,
                        brand: userStore.brand,
                        region: userStore.isGstUser()
                          ? GST_REGION
                          : NATIONAL_REGION,
                        team: ACCESSORY_TEAM,
                        changeType: item.changeType,
                        from: item.after,
                        to: item.before,
                      },
                    },
                  });
                }}
              />
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default observer(ChangeLog);
