import React from "react";
import { Button, Header } from "vapi-ui-common";
import { NavLink } from "react-router-dom";

interface PublishedHeaderProps {
  version: string;
}

const PublishedHeader = ({ version }: PublishedHeaderProps) => {
  return (
    <Header moduleTitle="Common Language" moduleSubTitle="Published">
      <NavLink
        to={`/commonLanguage/changeLog/${version}?exitVersion=published`}
      >
        <Button variant="transparent">Change Log</Button>
      </NavLink>
      <NavLink to="/commonLanguage/draft">
        <Button variant="primary">Edit</Button>
      </NavLink>
    </Header>
  );
};

export default PublishedHeader;
