import React from "react";
import { Button, Header } from "vapi-ui-common";
import { NavLink } from "react-router-dom";

const ArchivedHeader = () => {
  return (
    <Header moduleTitle="Common Language" moduleSubTitle="Archived">
      <NavLink to="/commonLanguage/changeLog/archived?exitVersion=archived">
        <Button variant="transparent">Change Log</Button>
      </NavLink>
    </Header>
  );
};

export default ArchivedHeader;
