import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import SideMenu from "./SideMenuWrapper";
import { Toast } from "./components/Toast";

const App = () => {
  return (
    <Router>
      <Toast />
      <SideMenu />
    </Router>
  );
};

export default App;
