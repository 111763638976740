import { createContext } from "react";
import { Change, RefItem } from "../../gql/generated";
import {
  ChangeLogTypes,
  ModelChange,
} from "../../models/accessories/changeLog.model";
import { KeyValueType } from "../../models/common/Common.model";
import { GradeItem, ModelItem } from "../../stores/modelStore";

export interface ModelCheckedChanges extends ModelChange {
  checked: boolean;
  checkedIgnore: boolean;
}

export interface CheckedChanges extends Change {
  checked: boolean;
  checkedUnlink: boolean;
}

/* State */
export enum SyncAllChangesTab {
  COMMON_LANGUAGE = "Common Language",
  GO_LIVE_DATE = "Go Live Date",
}

export enum SyncAllChangesActions {
  INIT = "init",
  SHOW_MODAL = "SHOW_MODAL",
  HIDE_MODAL = "HIDE_MODAL",
  IS_LOADED = "IS_LOADED",
  IS_LOADED_ADMIN = "IS_LOADED_ADMIN",
  TOGGLE_CHECKBOX = "TOGGLE_CHECKBOX",
  TOGGLE_CHECKBOX_ALL = "TOGGLE_CHECKBOX_ALL",
  TOGGLE_CHECKBOX_UNLINK = "TOGGLE_CHECKBOX_UNLINK",
  TOGGLE_CHECKBOX_ALL_UNLINK = "TOGGLE_CHECKBOX_ALL_UNLINK",
  SET_SELECTED_TAB = "SET_SELECTED_TAB",
  TOGGLE_CHECKBOX_ADMIN = "TOGGLE_CHECKBOX_ADMIN",
  TOGGLE_CHECKBOX_ALL_ADMIN = "TOGGLE_CHECKBOX_ALL_ADMIN",
  TOGGLE_CHECKBOX_ADMIN_IGNORE = "TOGGLE_CHECKBOX_ADMIN_IGNORE",
  TOGGLE_CHECKBOX_ALL_ADMIN_IGNORE = "TOGGLE_CHECKBOX_ALL_ADMIN_IGNORE",
  SEARCH = "SEARCH",
  SET_MODEL_MAP = "SET_MODEL_MAP",
  SET_MODEL_REVIEWED = "SET_MODEL_REVIEWED",
}

type ReqVars = {
  brand: string;
  team: string;
  region: string;
  lang: string;
  seriesId: string;
  modelYear: number;
  version: string;
};

type SyncAllChangesActionInit = {
  type: typeof SyncAllChangesActions.INIT;
  payload: {
    showSyncAdmin?: boolean | undefined;
    variables?: ReqVars;
    productTypes?: RefItem[];
    gradesData?: GradeItem[];
  };
};

type SyncAllChangesActionShowModal = {
  type: typeof SyncAllChangesActions.SHOW_MODAL;
};

type SyncAllChangesActionHideModal = {
  type: typeof SyncAllChangesActions.HIDE_MODAL;
};

type SyncAllChangesActionIsLoaded = {
  type: typeof SyncAllChangesActions.IS_LOADED;
};

type SyncAllChangesActionIsLoadedAdmin = {
  type: typeof SyncAllChangesActions.IS_LOADED_ADMIN;
};

type SyncAllChangesActionToggleCheckbox = {
  type: typeof SyncAllChangesActions.TOGGLE_CHECKBOX;
  payload: {
    changeType: string;
    checked: boolean;
  };
};

type SyncAllChangesActionToggleCheckboxAll = {
  type: typeof SyncAllChangesActions.TOGGLE_CHECKBOX_ALL;
  payload: {
    checked: boolean;
  };
};

type SyncAllChangesActionToggleCheckboxUnlink = {
  type: typeof SyncAllChangesActions.TOGGLE_CHECKBOX_UNLINK;
  payload: {
    changeType: string;
    checked: boolean;
  };
};

type SyncAllChangesActionToggleCheckboxAllUnlink = {
  type: typeof SyncAllChangesActions.TOGGLE_CHECKBOX_ALL_UNLINK;
  payload: {
    checked: boolean;
  };
};

type SyncAllChangesActionSetSelectedTab = {
  type: typeof SyncAllChangesActions.SET_SELECTED_TAB;
  payload: {
    tab: string;
  };
};

type SyncAllChangesActionToggleCheckboxAdmin = {
  type: typeof SyncAllChangesActions.TOGGLE_CHECKBOX_ADMIN;
  payload: {
    id: string;
    checked: boolean;
  };
};

type SyncAllChangesActionToggleCheckboxAllAdmin = {
  type: typeof SyncAllChangesActions.TOGGLE_CHECKBOX_ALL_ADMIN;
  payload: {
    checked: boolean;
  };
};

type SyncAllChangesActionToggleCheckboxAdminIgnore = {
  type: typeof SyncAllChangesActions.TOGGLE_CHECKBOX_ADMIN_IGNORE;
  payload: {
    id: string;
    checked: boolean;
  };
};

type SyncAllChangesActionToggleCheckboxAllAdminIgnore = {
  type: typeof SyncAllChangesActions.TOGGLE_CHECKBOX_ALL_ADMIN_IGNORE;
  payload: {
    checked: boolean;
  };
};

type SyncAllChangesActionSearch = {
  type: typeof SyncAllChangesActions.SEARCH;
  payload: {
    searchText: string;
  };
};

type SyncAllChangesActionModelMap = {
  type: typeof SyncAllChangesActions.SET_MODEL_MAP;
  payload: {
    modelItemList: ModelItem[];
  };
};

type SyncAllChangesActionModelReviewed = {
  type: typeof SyncAllChangesActions.SET_MODEL_REVIEWED;
  payload: {
    modelId?: string;
  };
};

export type SyncAllChangesAction =
  | SyncAllChangesActionInit
  | SyncAllChangesActionShowModal
  | SyncAllChangesActionHideModal
  | SyncAllChangesActionIsLoaded
  | SyncAllChangesActionIsLoadedAdmin
  | SyncAllChangesActionToggleCheckbox
  | SyncAllChangesActionToggleCheckboxAll
  | SyncAllChangesActionToggleCheckboxUnlink
  | SyncAllChangesActionToggleCheckboxAllUnlink
  | SyncAllChangesActionSetSelectedTab
  | SyncAllChangesActionToggleCheckboxAdmin
  | SyncAllChangesActionToggleCheckboxAllAdmin
  | SyncAllChangesActionToggleCheckboxAdminIgnore
  | SyncAllChangesActionToggleCheckboxAllAdminIgnore
  | SyncAllChangesActionSearch
  | SyncAllChangesActionModelMap
  | SyncAllChangesActionModelReviewed;

type SyncAllChangesState = {
  isLoaded: boolean;
  isLoadedAdmin: boolean;
  showSyncAdmin: boolean | undefined;
  productTypes: RefItem[];
  gradesData: GradeItem[];
  variables: ReqVars;
  showModal: boolean;
  data: Change[];
  logData: CheckedChanges[];
  numberChecked: number;
  numberCheckedUnlink: number;
  selectedTab: string;
  tabs: string[];
  dataAdmin: ModelChange[];
  logDataAdmin: ModelCheckedChanges[];
  numberCheckedAdmin: number;
  numberCheckedIgnoreAdmin: number;
  tabAdmin: string;
  tabCL: string;
  searchText: string;
  modelMap: KeyValueType<boolean>;
};

export const getInitialSyncAllChangesState = (): SyncAllChangesState => {
  return {
    isLoaded: false,
    isLoadedAdmin: false,
    showSyncAdmin: false,
    productTypes: [],
    gradesData: [],
    variables: {} as ReqVars,
    showModal: false,
    data: [
      {
        after: "Daytime Running Lights (DRL)",
        before: "Projectorbeam headlights headlamps with turn",
        changedItem: {
          title: "All Weather Floor Liners",
          notes: "Some title note",
        },
        changeType: ChangeLogTypes.TITLE,
        modifiedDate: "12/29/2018 12:58:00 PM UTC",
      },

      {
        after:
          "Engineered to precisely fit your vehicle, all weather floor liners are made from durable, flexible, weather-resistant material that cleans easily",
        changedItem: {
          title: "All Weather Floor Liners",
          notes: "Some description note",
        },
        changeType: ChangeLogTypes.DESCRIPTION,
        modifiedDate: "12/29/2018 12:58:00 PM UTC",
      },
    ] as Change[],
    logData: [],
    numberChecked: 0,
    numberCheckedUnlink: 0,
    selectedTab: SyncAllChangesTab.GO_LIVE_DATE,
    tabs: [],
    tabCL: SyncAllChangesTab.COMMON_LANGUAGE,
    tabAdmin: SyncAllChangesTab.GO_LIVE_DATE,
    dataAdmin: [
      {
        after: "2028-05-02 05:00",
        changedItem: {
          id: "1",
          model: "LE",
          modelCode: "7946",
        },
        changeType: ChangeLogTypes.GO_LIVE_DATE,
        modifiedDate: "12/29/2018 12:58:00 PM UTC",
      },
      {
        after: "2024-06-05 08:00",
        before: "2024-07-03 08:00",
        changedItem: {
          id: "2",
          model: "Hybrid 5",
          modelCode: "7951",
        },
        changeType: ChangeLogTypes.GO_LIVE_DATE,
        modifiedDate: "12/29/2018 12:58:00 PM UTC",
      },
      {
        after: "2024-07-03 10:00",
        before: "2024-07-03 08:00",
        changedItem: {
          id: "3",
          model: "Hybrid SE",
          modelCode: "7954",
        },
        changeType: ChangeLogTypes.GO_LIVE_DATE,
        modifiedDate: "12/29/2018 12:58:00 PM UTC",
      },
      {
        after: "2024-06-05 14:00",
        before: "2024-07-03 08:00",
        changedItem: {
          id: "4",
          model: "Hybrid 5",
          modelCode: "7951",
        },
        changeType: ChangeLogTypes.GO_LIVE_DATE,
        modifiedDate: "12/29/2018 12:58:00 PM UTC",
      },
    ] as ModelChange[],
    logDataAdmin: [],
    numberCheckedAdmin: 0,
    numberCheckedIgnoreAdmin: 0,
    searchText: "",
    modelMap: {},
  };
};

export const syncAllChangesReducer = (
  state: SyncAllChangesState,
  action: SyncAllChangesAction
): SyncAllChangesState => {
  switch (action.type) {
    case SyncAllChangesActions.INIT:
      return { ...state, ...action.payload };

    case SyncAllChangesActions.SHOW_MODAL:
      return {
        ...state,
        showModal: true,
        selectedTab: state.tabAdmin,
        searchText: "",
      };

    case SyncAllChangesActions.HIDE_MODAL:
      return {
        ...state,
        showModal: false,
        isLoaded: false,
        isLoadedAdmin: false,
      };

    case SyncAllChangesActions.IS_LOADED: {
      const logData: CheckedChanges[] = state.data.map((change) => {
        return {
          ...change,
          checked: false,
          checkedUnlink: false,
        };
      });

      const tabCL = `${SyncAllChangesTab.COMMON_LANGUAGE} (${logData.length})`;

      return {
        ...state,
        isLoaded: true,
        logData,
        numberChecked: 0,
        numberCheckedUnlink: 0,
        tabCL,
        tabs: [tabCL, state.tabAdmin],
      };
    }

    case SyncAllChangesActions.IS_LOADED_ADMIN: {
      const logDataAdmin: ModelCheckedChanges[] = state.dataAdmin.map(
        (change) => {
          return {
            ...change,
            checked: false,
            checkedIgnore: false,
          };
        }
      );

      const tabAdmin = `${SyncAllChangesTab.GO_LIVE_DATE} (${logDataAdmin.length})`;

      return {
        ...state,
        isLoadedAdmin: true,
        logDataAdmin,
        numberCheckedAdmin: 0,
        numberCheckedIgnoreAdmin: 0,
        tabAdmin,
        tabs: [state.tabCL, tabAdmin],
      };
    }

    case SyncAllChangesActions.TOGGLE_CHECKBOX: {
      const { changeType, checked } = action.payload;

      const logData = state.logData.map((log) =>
        log.changeType === changeType
          ? { ...log, checked, checkedUnlink: false }
          : log
      );

      return {
        ...state,
        logData,
        numberChecked: logData.filter((log) => log.checked).length,
        numberCheckedUnlink: logData.filter((log) => log.checkedUnlink).length,
      };
    }

    case SyncAllChangesActions.TOGGLE_CHECKBOX_ALL: {
      const { checked } = action.payload;

      const logData = state.logData.map((log) => ({
        ...log,
        checked,
        checkedUnlink: false,
      }));

      return {
        ...state,
        logData,
        numberChecked: logData.filter((log) => log.checked).length,
        numberCheckedUnlink: logData.filter((log) => log.checkedUnlink).length,
      };
    }

    case SyncAllChangesActions.TOGGLE_CHECKBOX_UNLINK: {
      const { changeType, checked } = action.payload;

      const logData = state.logData.map((log) =>
        log.changeType === changeType
          ? { ...log, checkedUnlink: checked, checked: false }
          : log
      );

      return {
        ...state,
        logData,
        numberChecked: logData.filter((log) => log.checked).length,
        numberCheckedUnlink: logData.filter((log) => log.checkedUnlink).length,
      };
    }

    case SyncAllChangesActions.TOGGLE_CHECKBOX_ALL_UNLINK: {
      const { checked } = action.payload;

      const logData = state.logData.map((log) => ({
        ...log,
        checkedUnlink: checked,
        checked: false,
      }));

      return {
        ...state,
        logData,
        numberChecked: logData.filter((log) => log.checked).length,
        numberCheckedUnlink: logData.filter((log) => log.checkedUnlink).length,
      };
    }

    case SyncAllChangesActions.SET_SELECTED_TAB: {
      const nTab = action.payload.tab;
      const selectedTab =
        state.tabs.find((tab) => tab === nTab) ?? state.selectedTab;

      return {
        ...state,
        selectedTab,
      };
    }

    case SyncAllChangesActions.TOGGLE_CHECKBOX_ADMIN: {
      const { id, checked } = action.payload;

      const logDataAdmin = state.logDataAdmin.map((log) =>
        log.changedItem.id === id
          ? { ...log, checked, checkedIgnore: false }
          : log
      );

      return {
        ...state,
        logDataAdmin,
        numberCheckedAdmin: logDataAdmin.filter((log) => log.checked).length,
        numberCheckedIgnoreAdmin: logDataAdmin.filter(
          (log) => log.checkedIgnore
        ).length,
      };
    }

    case SyncAllChangesActions.TOGGLE_CHECKBOX_ALL_ADMIN: {
      const { checked } = action.payload;

      const logDataAdmin = state.logDataAdmin.map((log) => ({
        ...log,
        checked,
        checkedIgnore: false,
      }));

      return {
        ...state,
        logDataAdmin,
        numberCheckedAdmin: logDataAdmin.filter((log) => log.checked).length,
        numberCheckedIgnoreAdmin: logDataAdmin.filter(
          (log) => log.checkedIgnore
        ).length,
      };
    }

    case SyncAllChangesActions.TOGGLE_CHECKBOX_ADMIN_IGNORE: {
      const { id, checked } = action.payload;

      const logDataAdmin = state.logDataAdmin.map((log) =>
        log.changedItem.id === id
          ? { ...log, checkedIgnore: checked, checked: false }
          : log
      );

      return {
        ...state,
        logDataAdmin,
        numberCheckedAdmin: logDataAdmin.filter((log) => log.checked).length,
        numberCheckedIgnoreAdmin: logDataAdmin.filter(
          (log) => log.checkedIgnore
        ).length,
      };
    }

    case SyncAllChangesActions.TOGGLE_CHECKBOX_ALL_ADMIN_IGNORE: {
      const { checked } = action.payload;

      const logDataAdmin = state.logDataAdmin.map((log) => ({
        ...log,
        checkedIgnore: checked,
        checked: false,
      }));

      return {
        ...state,
        logDataAdmin,
        numberCheckedAdmin: logDataAdmin.filter((log) => log.checked).length,
        numberCheckedIgnoreAdmin: logDataAdmin.filter(
          (log) => log.checkedIgnore
        ).length,
      };
    }

    case SyncAllChangesActions.SEARCH:
      return {
        ...state,
        searchText: action.payload.searchText,
      };

    case SyncAllChangesActions.SET_MODEL_MAP: {
      const { modelItemList } = action.payload;

      const modelMap: KeyValueType<boolean> = modelItemList.reduce(
        (map, model) => ({ ...map, [model.modelId]: true }),
        {}
      );

      return {
        ...state,
        modelMap,
      };
    }

    case SyncAllChangesActions.SET_MODEL_REVIEWED: {
      const { modelId } = action.payload;
      if (!modelId) return state;

      return {
        ...state,
        modelMap: { ...state.modelMap, [modelId]: false },
      };
    }

    default:
      return state;
  }
};

/* Context */

type SyncAllChangesContextType = {
  state: SyncAllChangesState;
  dispatch: React.Dispatch<SyncAllChangesAction>;
};

export const SyncAllChangesContext = createContext<SyncAllChangesContextType>({
  state: getInitialSyncAllChangesState(),
  dispatch: () => undefined,
});
