import React, { useState, useEffect } from "react";
import cx from "clsx";
import { GradeItem, ModelItem } from "../../../../stores/modelStore";
import styles from "../changeLogChanges.module.scss";
import { GoLiveDate } from "../../../../gql/generated";
import {
  GoLiveDateTimeHour,
  goLiveDateTimeMap,
} from "../../../../utils/dateUtils";

interface Props {
  before: string;
  after: string;
  changedItem: GoLiveDate;
  gradesDataItem: GradeItem[];
}

const ChangeLogGoLiveDate = ({
  before,
  after,
  changedItem,
  gradesDataItem,
}: Props) => {
  const [modelItems, setModelItems] = useState<ModelItem[]>([]);

  useEffect(() => {
    const items = gradesDataItem
      .map((gradeItem) => gradeItem.modelItems)
      .reduce((acc, item) => [...acc, ...item], []);

    setModelItems(items);
  }, [gradesDataItem]);

  const getModelCode = (id: string) => {
    return modelItems.find((item) => item.modelId === id)?.code || "";
  };

  const getDescriptionBlock = () => {
    const beforeTime = before.split(" ")[1] || "";
    const beforeDateString = new Date(before).toLocaleDateString();
    const afterTime = after.split(" ")[1] || "";
    const afterDateString = new Date(after).toLocaleDateString();

    if (
      !before.split(" ")[0] ||
      (beforeDateString === afterDateString &&
        (!beforeTime || beforeTime === afterTime))
    ) {
      return (
        <div>
          <span>{afterDateString}</span>
          &nbsp;
          <span>
            {`${
              goLiveDateTimeMap[
                new Date(after).getHours() as GoLiveDateTimeHour
              ].text
            } CST`}
          </span>
        </div>
      );
    }

    if (!after) {
      return (
        <div>
          <span className={cx(styles.changeRemoved)}>
            {beforeDateString}
          </span>
          &nbsp;
          <span className={cx(styles.changeRemoved)}>
            {`${
              goLiveDateTimeMap[
                new Date(before).getHours() as GoLiveDateTimeHour
              ].text
            } CST`}
          </span>
        </div>
      )
    }

    return (
      <>
        <div>
          <span
            className={cx({
              [styles.changeRemoved]: beforeDateString !== afterDateString,
            })}
          >
            {beforeDateString}
          </span>
          &nbsp;
          {beforeTime && (
            <span
              className={cx({
                [styles.changeRemoved]: beforeTime !== afterTime,
              })}
            >
              {`${
                goLiveDateTimeMap[
                  new Date(before).getHours() as GoLiveDateTimeHour
                ].text
              } CST`}
            </span>
          )}
        </div>
        <div>
          <span>{afterDateString}</span>
          &nbsp;
          <span>
            {`${
              goLiveDateTimeMap[
                new Date(after).getHours() as GoLiveDateTimeHour
              ].text
            } CST`}
          </span>
        </div>
      </>
    );
  };

  return (
    <div className={styles.goLiveDateContainer}>
      <div className={styles.modeCode}>
        {`${getModelCode(changedItem.id)}:`}
      </div>
      <div>{getDescriptionBlock()}</div>
    </div>
  );
};

export default ChangeLogGoLiveDate;
