import clsx from "clsx";
import React from "react";
import { RTEditor, RTEditorProps } from "vapi-ui-common";
import styles from "./AccessoryRichText.module.scss";

const AccessoryRichText = ({ editorStyles, ...rest }: RTEditorProps) => {
  return (
    <RTEditor
      {...rest}
      editorStyles={clsx(editorStyles, styles.accessoryEditor)}
    />
  );
};

export default AccessoryRichText;
