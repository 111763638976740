import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { ApolloError } from "@apollo/client";
import { AccessoryCommonLanguageItem } from "../../../models/commonLanguage.model";
import useStores from "../../../stores/useStores";
import handleErrorResponse from "../../../utils/errorHandlingUtils";
import {
  AclDocument,
  useCreateAclDraftMutation,
  useUpdateAclDraftMutation,
} from "../../../gql/generated";
import {
  ACCESSORY_TEAM,
  GST_REGION,
  NATIONAL_REGION,
} from "../../../constants/Constants";

const useSaveCommonLanguage = (item: AccessoryCommonLanguageItem) => {
  const [updateACLDraft] = useUpdateAclDraftMutation();
  const [createAclDraft] = useCreateAclDraftMutation();
  const { userStore, commonLanguageStore } = useStores();

  const oppCtx = {
    brand: userStore.brand,
    region: userStore.isGstUser() ? GST_REGION : NATIONAL_REGION,
    team: ACCESSORY_TEAM,
  };

  const createCommonLanguage = async () => {
    try {
      const res = await createAclDraft({
        variables: {
          input: { ...oppCtx, accessory: item.toAccCommonLanguage() },
        },
      });

      if (res.errors?.length) {
        throw new Error(res.errors[0]?.message || "");
      }

      item.updateFromACLDocument(res.data?.createACLDraft as AclDocument);
      commonLanguageStore.countChanges();

      toast.success("Successfully created common language draft item.");
    } catch (e) {
      handleErrorResponse(
        e,
        `Error adding common language item: ${(e as ApolloError).message}`
      );
    }
  };

  const updateCommonLanguage = async () => {
    try {
      const res = await trackPromise(
        updateACLDraft({
          variables: {
            input: {
              ...oppCtx,
              accessory: {
                id: item.id,
                revId: item.revId,
                ...item.toAccCommonLanguage(),
              },
            },
          },
        })
      );

      if (res.errors?.length) {
        throw new Error(res.errors[0]?.message || "");
      }

      item.updateFromACLDocument(res.data?.updateACLDraft as AclDocument);
      commonLanguageStore.countChanges();

      toast.success("Successfully updated item");
    } catch (e) {
      handleErrorResponse(
        e,
        `Error adding common language item: ${(e as ApolloError).message}`
      );
    }
  };

  const saveCommonLanguage = () => {
    try {
      commonLanguageStore.saveItem(item);

      item.isValid(userStore.isGstUser());

      if (item.id) {
        updateCommonLanguage();
      } else {
        createCommonLanguage();
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  return saveCommonLanguage;
};

export default useSaveCommonLanguage;
