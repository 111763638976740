import React from "react";
import { v4 as uuidv4 } from "uuid";
import { GradeItem } from "../../../../stores/modelStore";
import styles from "../changeLogChanges.module.scss";

interface Props {
  before: string;
  after: string;
  gradesDataItem: GradeItem[];
}

const ChangeLogGradeMsrp = ({ before, after, gradesDataItem }: Props) => {
  const beforeObj = before ? JSON.parse(before) : {};
  const afterObj = after ? JSON.parse(after) : {};

  const transformation = (obj: []) => {
    return Object.values(obj)
      .map((value: any) => {
        const item = gradesDataItem.find((x: GradeItem) => {
          return x.id === value.gradeId;
        });
        return {
          ...value,
          gradeId: item ? item.value : value.gradeId,
        };
      })
      .map((item) => {
        return `${item.gradeId}: ${item.msrp}`;
      });
  };
  const transformedBefore = transformation(beforeObj);
  const transformedAfter = transformation(afterObj);

  const beforeValues = beforeObj ? transformedBefore : [];
  const afterValues = afterObj ? transformedAfter : [];

  return (
    <>
      <div>
        <span>Before: </span>
        <span>
          {beforeValues.map((item, index) => (
            <span
              key={`before-${item}-${uuidv4()}`}
              className={
                afterValues.indexOf(item) === -1
                  ? styles.changeRemoved
                  : undefined
              }
            >
              {item}
              {index !== beforeValues.length - 1 && ", "}
            </span>
          ))}
        </span>
      </div>

      <div>
        <span>After: </span>
        <span>
          {afterValues.map((item, index) => (
            <span
              key={`after-${item}-${uuidv4()}`}
              className={
                beforeValues.indexOf(item) === -1
                  ? styles.changeAdded
                  : undefined
              }
            >
              {item}

              {index !== afterValues.length - 1 && ", "}
            </span>
          ))}
        </span>
      </div>
    </>
  );
};

export default ChangeLogGradeMsrp;
