import apolloClient from "../../apolloClient";
import {
  GetAccessoriesWithProductTypeDocument,
  GetCommonLanguageIdsDocument,
  GetLatestVersionDocument,
} from "../../gql/generated";
import { Language, VehicleDataVersionInfo } from "../../models/user/user.model";

const getAccessoryData = async (
  variables: {
    brand: string;
    team: string;
    region: string;
    lang: string;
    seriesId: string;
    modelYear: number;
    version: string;
  },
  vdVersionInfo: VehicleDataVersionInfo,
  enVersion = vdVersionInfo[Language.EN]?.toString(),
  getESData: boolean
): Promise<any> => {
  const enData = await apolloClient.query({
    query: GetAccessoriesWithProductTypeDocument,
    variables: {
      ...variables,
      lang: Language.EN.toLowerCase(),
      version: enVersion || "",
    },
    fetchPolicy: "network-only",
  });

  const esData = getESData
    ? await apolloClient.query({
        query: GetAccessoriesWithProductTypeDocument,
        variables: {
          ...variables,
          lang: Language.ES.toLowerCase(),
          version: vdVersionInfo[Language.ES]?.toString() || "",
        },
        fetchPolicy: "network-only",
      })
    : undefined;

  const enVersionData = getESData
    ? await apolloClient.query({
        query: GetLatestVersionDocument,
        variables: {
          brand: variables.brand,
          team: variables.team,
          seriesId: variables.seriesId,
          modelYear: variables.modelYear,
          region: variables.region,
          lang: Language.EN.toLowerCase(),
        },
        fetchPolicy: "network-only",
      })
    : undefined;

  const comLangIdData = await apolloClient.query({
    query: GetCommonLanguageIdsDocument,
    variables: {
      brand: variables.brand,
      team: variables.team,
      seriesId: variables.seriesId,
      modelYear: variables.modelYear,
      region: variables.region,
      lang: variables.lang,
      version: variables.version,
    },
  });
  return {
    enData: enData.data,
    esData: esData?.data,
    enVersionData: enVersionData?.data,
    comLangIdData: comLangIdData.data,
    error:
      enData.error ||
      comLangIdData.error ||
      (getESData ? esData?.error || enVersionData?.error : undefined),
  };
};

export default getAccessoryData;
