import React from "react";
import { useParams } from "react-router-dom";
import { VehicleTeam } from "../models/vehicleData/vehicleData.model";
import GSTChangeLogController from "./gst/ChangeLogController";
import NationalChangeLogController from "./national/ChangeLogController";

const ChangeLogRouteController = () => {
  const params: { team: string } = useParams();
  if (params.team === VehicleTeam.GST_ACC_TEAM) {
    return <GSTChangeLogController />;
  }
  return <NationalChangeLogController />;
};

export default ChangeLogRouteController;
