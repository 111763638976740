import clsx from "clsx";
import React, { useCallback, useContext, useState } from "react";
import { Button } from "vapi-ui-common";
import styles from "../../../../../components/MsrpPopover/MsrpPopover.module.scss";
import { Language } from "../../../../../models/user/user.model";
import AccessoriesEntryScreenContext, {
  AccessoryContext,
} from "../AccessoriesEntryScreenContext";
import CCPopoverInput from "./CPopoverInput";
import msrpFlagsColumnStyles from "./MsrpFlagsColumn.module.scss";

interface IPopoverElementProps {
  closePopover?: () => void;
  onUpdate: (cost: string, language?: Language) => void;
}

const CPopoverElement = ({ closePopover, onUpdate }: IPopoverElementProps) => {
  const { accessoriesLangMap, teamLang, selectedLanguages, isSpanish } =
    useContext(AccessoriesEntryScreenContext);

  const {
    accessoryItem: { id: accessoryItemId },
  } = useContext(AccessoryContext);

  const accessoryLangItem = accessoriesLangMap[accessoryItemId]?.langs;

  const defaultLangMSRP: { [key: string]: string } = {};
  Object.entries(accessoryLangItem).forEach(([key, value]) => {
    defaultLangMSRP[key] = value.msrp ?? "";
  });

  const [langMSRP, setLangMSRP] = useState(defaultLangMSRP);

  const onChange = useCallback((language: Language, msrp: string) => {
    setLangMSRP({ ...langMSRP, [language]: msrp });
  }, []);

  const onSave = useCallback(() => {
    onUpdate(langMSRP[teamLang].trim(), teamLang);
    if (typeof closePopover === "function") {
      closePopover();
    }
  }, [onUpdate, closePopover, langMSRP, teamLang]);

  const onCancel = useCallback(() => {
    if (typeof closePopover === "function") {
      closePopover();
    }
  }, [closePopover]);

  if (!isSpanish) {
    return null;
  }

  return (
    <section
      className={clsx(styles.msrpSection, msrpFlagsColumnStyles.msrpSection)}
    >
      <header className={styles.header}>MSRP</header>
      {selectedLanguages.map((language) => (
        <CCPopoverInput
          language={language}
          msrp={langMSRP[language]}
          onChange={onChange}
          key={`${language}-CCPopoverInput-msrp`}
        />
      ))}
      <footer className={styles.footer}>
        <Button variant="transparent" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSave}>
          Save
        </Button>
      </footer>
    </section>
  );
};

export default CPopoverElement;

CPopoverElement.defaultProps = {
  closePopover: undefined,
};
