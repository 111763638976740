const cleanUpTypename = (value: any) => {
  if (typeof value === 'object') {
    const obj = value;

    Object.keys(obj).forEach(key => {
      if (key === '__typename') {
        delete obj[key];
      }
    });
  }

  if (Array.isArray(value)) {
    value.forEach(item => {
      const arrayList = Object.values(item).filter(prop => Array.isArray(prop))
                        .filter(list => list !== null);
      const objectList = Object.values(item).filter(prop => typeof prop === 'object')
                        .filter(list => list !== null);

      if (arrayList.length > 0) {
        Object.values(arrayList).forEach(val => {
          cleanUpTypename(val);
        });
      }
      if (objectList.length > 0) {
        Object.values(objectList).forEach(val => {
          cleanUpTypename(val);
        });
      }
      cleanUpTypename(item);
    });
  }

  return value;
};

export default cleanUpTypename;