import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type ArchiveCommonLanguageSpecInput = {
  id: Scalars['ID'];
  revId: Scalars['ID'];
};

export type ArchiveDisclaimerInput = {
  id: Scalars['String'];
  revId: Scalars['String'];
};

export type BodyJson = {
  __typename?: 'BodyJSON';
  body: Scalars['JSON'];
};

export enum Brand {
  Lexus = 'lexus',
  Tdpr = 'tdpr',
  Toyota = 'toyota'
}

export type CommonLanguageVehicleInput = {
  modelYear: Scalars['String'];
  seriesId: Scalars['ID'];
  seriesName: Scalars['String'];
};

export type CreateCommonLanguageSpecInput = {
  categoryId: Scalars['ID'];
  description: Scalars['String'];
  isInProgress: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  subCategory: Scalars['String'];
  vehicles?: Maybe<Array<CommonLanguageVehicleInput>>;
};

export type CreateDphCategoryInput = {
  dph: Scalars['Int'];
  name: Scalars['String'];
  nextDph?: Maybe<Scalars['String']>;
  nextDphLiveDate?: Maybe<Scalars['String']>;
  series: Array<Scalars['String']>;
};

export type CreateReportInput = {
  modelYear?: Maybe<Scalars['String']>;
  seriesId?: Maybe<Scalars['String']>;
  version: Scalars['String'];
};

export type CreateSelectionsInput = {
  objectId: Scalars['String'];
  seriesSelections: Array<Scalars['String']>;
};

export type CreateSeriesCategoryInput = {
  name: Scalars['String'];
  nameES: Scalars['String'];
  seriesId: Scalars['String'];
};

export type CreateSeriesInput = {
  group: Scalars['String'];
  name: Scalars['String'];
  sourceBrand?: Maybe<Scalars['String']>;
  sourceSeriesId?: Maybe<Scalars['String']>;
};

export type DeleteCommonLanguageSpecInput = {
  id: Scalars['ID'];
  revId: Scalars['ID'];
};

export type DeleteDphCategoryInput = {
  categoryId: Scalars['String'];
  revId: Scalars['String'];
};

export type Disclaimer = {
  __typename?: 'Disclaimer';
  description: Scalars['String'];
  description_es?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived?: Maybe<Scalars['Boolean']>;
  lastPublishedDate?: Maybe<Scalars['String']>;
  lastPublishedVersion?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  token: Scalars['String'];
};

export type DisclaimerResponse = {
  __typename?: 'DisclaimerResponse';
  body: Disclaimer;
};

export type FilterInput = {
  status?: Maybe<Status>;
  version?: Maybe<Scalars['Int']>;
};

export type INode = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};


export type KeyFeatureInput = {
  category: Scalars['String'];
  name: Scalars['String'];
  nameES: Scalars['String'];
};

export enum Language {
  En = 'EN',
  Es = 'ES'
}

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  _utilCreateGSTDisclaimers: BodyJson;
  _utilDeleteGSTLioDisclaimer: BodyJson;
  addSystemSpec: BodyJson;
  archiveCommonLanguageSpec: BodyJson;
  archiveDisclaimer: DisclaimerResponse;
  createCommonLanguageCategory: BodyJson;
  createCommonLanguageSpec: BodyJson;
  createDisclaimer: BodyJson;
  createDPHCategory: BodyJson;
  createKeyFeature: BodyJson;
  createKeyFeatures: BodyJson;
  createReport: BodyJson;
  createSeries: BodyJson;
  createSeriesCategory: BodyJson;
  createSeriesSelections: BodyJson;
  createSignedUrl: BodyJson;
  createSystemSpecs: BodyJson;
  deleteCommonLanguageCategory: BodyJson;
  deleteCommonLanguageSpec: BodyJson;
  deleteDisclaimer: BodyJson;
  deleteDPHCategory: BodyJson;
  deleteSeriesCategory: BodyJson;
  generateDisclaimerReport: BodyJson;
  publishCommonLanguageDraft: BodyJson;
  publishDisclaimersToDownstream: BodyJson;
  publishDPH: BodyJson;
  publishDraftDisclaimer: BodyJson;
  publishSeriesCategories: Scalars['Boolean'];
  resetCommonLanguageDraft: BodyJson;
  resetDraftDisclaimers: BodyJson;
  revertCommonLanguageChange: BodyJson;
  revertDisclaimerChange: BodyJson;
  sendSNSMessage: Scalars['Boolean'];
  updateCommonLanguageCategory: BodyJson;
  updateCommonLanguageSpec: BodyJson;
  updateDisclaimer: BodyJson;
  updateDPHCategory: BodyJson;
  updateSeries: BodyJson;
  updateSeriesCategory: BodyJson;
  uploadDisclaimers: BodyJson;
  userPermissions: BodyJson;
};


export type Mutation_UtilCreateGstDisclaimersArgs = {
  brand: Brand;
  region: Region;
};


export type Mutation_UtilDeleteGstLioDisclaimerArgs = {
  brand: Brand;
  region: Region;
};


export type MutationAddSystemSpecArgs = {
  brand: Brand;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  systemSpecs: Array<Scalars['String']>;
  team: Team;
};


export type MutationArchiveCommonLanguageSpecArgs = {
  brand: Brand;
  payload: ArchiveCommonLanguageSpecInput;
};


export type MutationArchiveDisclaimerArgs = {
  brand: Brand;
  payload: ArchiveDisclaimerInput;
  region: Region;
};


export type MutationCreateCommonLanguageCategoryArgs = {
  brand: Brand;
  categories: Array<Scalars['String']>;
};


export type MutationCreateCommonLanguageSpecArgs = {
  brand: Brand;
  payload: CreateCommonLanguageSpecInput;
};


export type MutationCreateDisclaimerArgs = {
  brand: Brand;
  payload: Scalars['JSON'];
  region: Region;
};


export type MutationCreateDphCategoryArgs = {
  brand: Brand;
  payload: CreateDphCategoryInput;
  region: Region;
};


export type MutationCreateKeyFeatureArgs = {
  brand: Brand;
  payload: KeyFeatureInput;
};


export type MutationCreateKeyFeaturesArgs = {
  brand: Brand;
  payload: Array<KeyFeatureInput>;
};


export type MutationCreateReportArgs = {
  brand: Brand;
  reportData: CreateReportInput;
  reportType: Scalars['String'];
};


export type MutationCreateSeriesArgs = {
  brand: Brand;
  payload: CreateSeriesInput;
};


export type MutationCreateSeriesCategoryArgs = {
  brand: Brand;
  payload: CreateSeriesCategoryInput;
};


export type MutationCreateSeriesSelectionsArgs = {
  brand: Brand;
  payload: CreateSelectionsInput;
};


export type MutationCreateSignedUrlArgs = {
  action: S3Action;
  brand: Brand;
  key: Scalars['String'];
  read?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateSystemSpecsArgs = {
  brand: Brand;
};


export type MutationDeleteCommonLanguageCategoryArgs = {
  brand: Brand;
  categoryId: Scalars['ID'];
};


export type MutationDeleteCommonLanguageSpecArgs = {
  brand: Brand;
  payload: DeleteCommonLanguageSpecInput;
};


export type MutationDeleteDisclaimerArgs = {
  brand: Brand;
  payload: Scalars['JSON'];
  region: Region;
};


export type MutationDeleteDphCategoryArgs = {
  brand: Brand;
  payload: DeleteDphCategoryInput;
  region: Region;
};


export type MutationDeleteSeriesCategoryArgs = {
  brand: Brand;
  seriesCategoryId: Scalars['String'];
};


export type MutationGenerateDisclaimerReportArgs = {
  brand: Brand;
  region: Region;
  version: Scalars['String'];
};


export type MutationPublishCommonLanguageDraftArgs = {
  brand: Brand;
};


export type MutationPublishDisclaimersToDownstreamArgs = {
  brand: Brand;
  lang: Language;
  region: Region;
};


export type MutationPublishDphArgs = {
  brand: Brand;
  region: Region;
};


export type MutationPublishDraftDisclaimerArgs = {
  brand: Brand;
  region: Region;
};


export type MutationPublishSeriesCategoriesArgs = {
  brand: Brand;
};


export type MutationResetCommonLanguageDraftArgs = {
  brand: Brand;
};


export type MutationResetDraftDisclaimersArgs = {
  brand: Brand;
  region: Region;
};


export type MutationRevertCommonLanguageChangeArgs = {
  brand: Brand;
  payload: RevertCommonLanguageChangeInput;
};


export type MutationRevertDisclaimerChangeArgs = {
  brand: Brand;
  payload: Scalars['JSON'];
  region: Region;
};


export type MutationSendSnsMessageArgs = {
  message: Scalars['String'];
  topic: Scalars['String'];
};


export type MutationUpdateCommonLanguageCategoryArgs = {
  brand: Brand;
  payload: UpdateCommonLanguageCategoryInput;
};


export type MutationUpdateCommonLanguageSpecArgs = {
  brand: Brand;
  payload: UpdateCommonLanguageSpecInput;
};


export type MutationUpdateDisclaimerArgs = {
  brand: Brand;
  payload: Scalars['JSON'];
  region: Region;
};


export type MutationUpdateDphCategoryArgs = {
  brand: Brand;
  payload: UpdateDphCategoryInput;
  region: Region;
};


export type MutationUpdateSeriesArgs = {
  brand: Brand;
  payload: UpdateSeriesInput;
};


export type MutationUpdateSeriesCategoryArgs = {
  brand: Brand;
  payload: UpdateSeriesCategoryInput;
};


export type MutationUploadDisclaimersArgs = {
  brand: Brand;
  lang: Language;
  payload: Scalars['JSON'];
  region: Region;
  version: Scalars['Int'];
};


export type MutationUserPermissionsArgs = {
  request: UserPermissionsRequest;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  downloadDisclaimers: BodyJson;
  generateCommonLanguageReport: BodyJson;
  getCommonLanguageVersions: BodyJson;
  getDisclaimerCurrentVersion: BodyJson;
  getDisclaimerVersions: BodyJson;
  getFuelTypes: BodyJson;
  getSystemSpecs: BodyJson;
  permissions: BodyJson;
  readAllKeyFeatures: BodyJson;
  readCommonLanguageCategories: BodyJson;
  readDPHCategories: BodyJson;
  readKeyFeatures: BodyJson;
  readReportLogs: BodyJson;
  readSeries: BodyJson;
  readSeriesCategories: BodyJson;
  readSeriesSelections: BodyJson;
  searchCommonLanguageChangeLog: BodyJson;
  searchCommonLanguageSpecs: BodyJson;
  searchDisclaimerChangeLog: BodyJson;
  searchDisclaimers: BodyJson;
  searchDisclaimerTokens: BodyJson;
};


export type QueryDownloadDisclaimersArgs = {
  brand: Brand;
  lang: Language;
  region: Region;
  version: Scalars['String'];
};


export type QueryGenerateCommonLanguageReportArgs = {
  brand: Brand;
  version: Scalars['String'];
};


export type QueryGetCommonLanguageVersionsArgs = {
  brand: Brand;
};


export type QueryGetDisclaimerCurrentVersionArgs = {
  brand: Brand;
  region: Region;
};


export type QueryGetDisclaimerVersionsArgs = {
  brand: Brand;
  region: Region;
};


export type QueryGetFuelTypesArgs = {
  brand: Brand;
};


export type QueryGetSystemSpecsArgs = {
  brand: Brand;
  filter: FilterInput;
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryPermissionsArgs = {
  objectId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryReadAllKeyFeaturesArgs = {
  brand: Brand;
};


export type QueryReadCommonLanguageCategoriesArgs = {
  brand: Brand;
};


export type QueryReadDphCategoriesArgs = {
  brand: Brand;
  region: Region;
};


export type QueryReadKeyFeaturesArgs = {
  brand: Brand;
  filter: Scalars['String'];
  language: Language;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Team;
};


export type QueryReadReportLogsArgs = {
  brand: Brand;
  reportType: Scalars['String'];
};


export type QueryReadSeriesArgs = {
  brand: Brand;
};


export type QueryReadSeriesCategoriesArgs = {
  brand: Brand;
  getLatestVersion: Scalars['Boolean'];
};


export type QueryReadSeriesSelectionsArgs = {
  brand: Brand;
  objectId: Scalars['String'];
};


export type QuerySearchCommonLanguageChangeLogArgs = {
  brand: Brand;
  filter: FilterInput;
};


export type QuerySearchCommonLanguageSpecsArgs = {
  brand: Brand;
  filter: FilterInput;
  modelYear?: Maybe<Scalars['String']>;
  seriesId?: Maybe<Scalars['String']>;
};


export type QuerySearchDisclaimerChangeLogArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  region: Region;
};


export type QuerySearchDisclaimersArgs = {
  brand: Brand;
  filter?: Maybe<FilterInput>;
  region: Region;
};


export type QuerySearchDisclaimerTokensArgs = {
  brand: Brand;
  region: Region;
};

export enum Region {
  Gst = 'GST',
  National = 'NATIONAL',
  Set = 'SET',
  Tdpr = 'TDPR',
  Usvi = 'USVI'
}

export type RevertCommonLanguageChangeInput = {
  after?: Maybe<Scalars['JSON']>;
  before?: Maybe<Scalars['JSON']>;
  changeType: Scalars['String'];
  id: Scalars['ID'];
  revId: Scalars['ID'];
};

export enum S3Action {
  Download = 'DOWNLOAD',
  Upload = 'UPLOAD'
}

export enum Status {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export enum Team {
  AgencyTeam = 'agency_team',
  ProductTeam = 'product_team'
}

export type UpdateCommonLanguageCategoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateCommonLanguageSpecInput = {
  categoryId: Scalars['ID'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isInProgress: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  revId: Scalars['ID'];
  subCategory: Scalars['String'];
  vehicles?: Maybe<Array<CommonLanguageVehicleInput>>;
};

export type UpdateDphCategoryInput = {
  categoryId: Scalars['String'];
  dph: Scalars['Int'];
  name: Scalars['String'];
  nextDph?: Maybe<Scalars['String']>;
  nextDphLiveDate?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  series: Array<Scalars['String']>;
};

export type UpdateSeriesCategoryInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  nameES: Scalars['String'];
  revId: Scalars['String'];
  seriesId: Scalars['String'];
};

export type UpdateSeriesInput = {
  group: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  revId: Scalars['String'];
};

export type UserPermissionsRequest = {
  id: Scalars['String'];
  isGSTAcc: Scalars['Boolean'];
  isLexus: Scalars['Boolean'];
  isSpanish: Scalars['Boolean'];
  isTdpr: Scalars['Boolean'];
  isToyota: Scalars['Boolean'];
};

export type CreateSeriesSelectionsMutationVariables = Exact<{
  brand: Brand;
  payload: CreateSelectionsInput;
}>;


export type CreateSeriesSelectionsMutation = (
  { __typename?: 'Mutation' }
  & { createSeriesSelections: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type CreateSignedUrlMutationVariables = Exact<{
  brand: Brand;
  action: S3Action;
  key: Scalars['String'];
  read?: Maybe<Scalars['Boolean']>;
}>;


export type CreateSignedUrlMutation = (
  { __typename?: 'Mutation' }
  & { createSignedUrl: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type GetFuelTypesQueryVariables = Exact<{
  brand: Brand;
}>;


export type GetFuelTypesQuery = (
  { __typename?: 'Query' }
  & { getFuelTypes: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadSeriesQueryVariables = Exact<{
  brand: Brand;
}>;


export type ReadSeriesQuery = (
  { __typename?: 'Query' }
  & { readSeries: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);

export type ReadSeriesSelectionsQueryVariables = Exact<{
  brand: Brand;
  objectId: Scalars['String'];
}>;


export type ReadSeriesSelectionsQuery = (
  { __typename?: 'Query' }
  & { readSeriesSelections: (
    { __typename?: 'BodyJSON' }
    & Pick<BodyJson, 'body'>
  ) }
);


export const CreateSeriesSelectionsDocument = gql`
    mutation CreateSeriesSelections($brand: Brand!, $payload: CreateSelectionsInput!) {
  createSeriesSelections(brand: $brand, payload: $payload) {
    body
  }
}
    `;
export type CreateSeriesSelectionsMutationFn = Apollo.MutationFunction<CreateSeriesSelectionsMutation, CreateSeriesSelectionsMutationVariables>;
export type CreateSeriesSelectionsMutationResult = Apollo.MutationResult<CreateSeriesSelectionsMutation>;
export type CreateSeriesSelectionsMutationOptions = Apollo.BaseMutationOptions<CreateSeriesSelectionsMutation, CreateSeriesSelectionsMutationVariables>;
export const CreateSignedUrlDocument = gql`
    mutation CreateSignedUrl($brand: Brand!, $action: S3Action!, $key: String!, $read: Boolean) {
  createSignedUrl(brand: $brand, action: $action, key: $key, read: $read) {
    body
  }
}
    `;
export type CreateSignedUrlMutationFn = Apollo.MutationFunction<CreateSignedUrlMutation, CreateSignedUrlMutationVariables>;
export type CreateSignedUrlMutationResult = Apollo.MutationResult<CreateSignedUrlMutation>;
export type CreateSignedUrlMutationOptions = Apollo.BaseMutationOptions<CreateSignedUrlMutation, CreateSignedUrlMutationVariables>;
export const GetFuelTypesDocument = gql`
    query GetFuelTypes($brand: Brand!) {
  getFuelTypes(brand: $brand) {
    body
  }
}
    `;
export type GetFuelTypesQueryResult = Apollo.QueryResult<GetFuelTypesQuery, GetFuelTypesQueryVariables>;
export const ReadSeriesDocument = gql`
    query ReadSeries($brand: Brand!) {
  readSeries(brand: $brand) {
    body
  }
}
    `;
export type ReadSeriesQueryResult = Apollo.QueryResult<ReadSeriesQuery, ReadSeriesQueryVariables>;
export const ReadSeriesSelectionsDocument = gql`
    query ReadSeriesSelections($brand: Brand!, $objectId: String!) {
  readSeriesSelections(brand: $brand, objectId: $objectId) {
    body
  }
}
    `;
export type ReadSeriesSelectionsQueryResult = Apollo.QueryResult<ReadSeriesSelectionsQuery, ReadSeriesSelectionsQueryVariables>;