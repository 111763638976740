import React, { useCallback, useContext } from "react";
import { HeaderCell } from "vapi-ui-common";
import cx from "clsx";
import styles from "../../../AccessoriesEntryScreen.module.scss";
import AccessoriesEntryScreenContext from "../AccessoriesEntryScreenContext";

interface ITitleHeader {
  sortMode: boolean;
}

const TitleHeader = ({ sortMode }: ITitleHeader) => {
  const context = useContext(AccessoriesEntryScreenContext);

  const { selectedLanguages, setSort } = context;
  const onClick = useCallback(() => setSort("title"), [setSort]);

  return (
    <>
      {selectedLanguages.map((language) => {
        return (
          <HeaderCell
            key={`${language}-title-header`}
            onClick={onClick}
            cellClassName={cx(
              styles.stickyHeader,
              !sortMode ? styles.titleHeaderWidth : styles.sortTitleHeaderWidth
            )}
          >
            {`${language} Accessory Title`}
          </HeaderCell>
        );
      })}
    </>
  );
};

export default TitleHeader;
