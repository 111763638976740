import React, { useCallback, useContext, useMemo } from "react";
import { Button, ModalFooter } from "vapi-ui-common";
import {
  SyncAllChangesActions,
  SyncAllChangesContext,
} from "./SyncAllChangesContext";

const useVariables = () => {
  const { state, dispatch } = useContext(SyncAllChangesContext);
  const {
    numberChecked,
    numberCheckedUnlink,
    numberCheckedAdmin,
    numberCheckedIgnoreAdmin,
    logData,
    logDataAdmin,
  } = state;

  const ignoreChanges = useCallback(() => {
    dispatch({
      type: SyncAllChangesActions.HIDE_MODAL,
    });
  }, []);

  const applyChanges = useCallback(() => {
    dispatch({
      type: SyncAllChangesActions.HIDE_MODAL,
    });
  }, []);

  const total = useMemo(
    () => logData.length + logDataAdmin.length,
    [logData, logDataAdmin]
  );

  const disabled = useMemo(() => {
    const selected =
      numberChecked +
      numberCheckedUnlink +
      numberCheckedAdmin +
      numberCheckedIgnoreAdmin;

    return selected < 1 || selected !== total;
  }, [
    numberChecked,
    numberCheckedUnlink,
    numberCheckedAdmin,
    numberCheckedIgnoreAdmin,
    total,
  ]);

  return {
    disabled,
    ignoreChanges,
    applyChanges,
  };
};

const SyncAllChangesModalFooter = () => {
  const { disabled, ignoreChanges, applyChanges } = useVariables();

  return (
    <ModalFooter>
      <Button variant="transparent" onClick={ignoreChanges}>
        Cancel
      </Button>
      <Button variant="primary" onClick={applyChanges} disabled={disabled}>
        Update
      </Button>
    </ModalFooter>
  );
};

export default SyncAllChangesModalFooter;
