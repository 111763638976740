import { observable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { ReportLogResponse } from "../../gql/generated";
import { BRAND_LEXUS } from "../user/user.model";

export class ReportItem {
  uid = uuidv4();

  @observable fileName = "";

  @observable bucketKey = "";

  @observable generatedDate = "";

  @observable modelYear = "";

  @observable generatedBy = "";

  @observable seriesId = "";

  @observable version = "";

  @observable reportType = "";

  @observable series = "";

  @observable year = "";

  constructor(report?: ReportLogResponse) {
    if (report) {
      this.fileName = report.fileName;
      this.bucketKey = report.bucketKey;
      this.generatedDate = report.generatedDate;
      this.modelYear = report.modelYear;
      this.generatedBy = report.generatedBy;
      this.seriesId = report.seriesId;
      this.version = report.version;
    }
  }
}

export enum ReportTypes {
  ACCESSORIES = "accessories",
  PRODUCT_TEAM = "product-team",
  AGENCY_TEAM = "agency-team",
}

export const getReportTypesByBrand = (brand: string) => {
  if (brand === BRAND_LEXUS) {
    return [ReportTypes.ACCESSORIES];
  }
  return [ReportTypes.ACCESSORIES];
};

export interface VehicleModel {
  modelYear: string;
  seriesId: string;
  seriesName: string;
  group?: string;
}
