import React, { useMemo, useState } from "react";
import { FlagsCell, ImageItem, Modal, useDebounce } from "vapi-ui-common";
import { observer } from "mobx-react-lite";
import MsrpPopover from "../../../../components/MsrpPopover/MsrpPopover";
import checkForSyncChanges from "../../../../utils/checkForSyncChanges";
import ImageUrlModal from "../../../../components/Images/ImageUrlModal/ImageUrlModal";
import ImageLibraryModal from "../../../../components/Images/ImageLibraryModal/ImageLibraryModal";
import EditImageModal from "../../../../components/Images/EditImageModal/EditImageModal";
import { AccessoryCommonLanguageItem } from "../../../../models/commonLanguage.model";
import useStores from "../../../../stores/useStores";
import useRowHandlers from "../../hooks/useRowHandlers";
import styles from "./clFlagCell.module.scss";
import UploadImageModal from "../../../../components/Images/UploadImageModal";
import {
  cachedPartNumberImage,
  uncachedPartNumberImage,
} from "../../../../utils/partNumberImage";

interface CLFlagsCellProps {
  index: number;
  item: AccessoryCommonLanguageItem;
  disabled?: boolean;
}

const CLFlagsCell = ({ index, item, disabled }: CLFlagsCellProps) => {
  const { debounce } = useDebounce();
  const { userStore } = useStores();
  const [searchText, setSearchText] = useState("");
  const [openImageModal, setOpenImageModal] = useState(false);
  const [openImageLibraryModal, setOpenImageLibraryModal] = useState(false);
  const [openEditImageModal, setOpenEditImageModal] = useState(false);
  const [openUploadImageModal, setOpenUploadImageModal] = useState(false);
  const [timestamp, setTimestamp] = useState(Date.now());
  const uncachedImages = useMemo(() => {
    return (
      item.images?.map((img) => ({
        ...img,
        image: uncachedPartNumberImage(img.image, timestamp),
      })) || []
    );
  }, [item.images, timestamp]);
  const heroImage = useMemo(() => {
    return uncachedPartNumberImage(
      item.images?.find((img) => img.isHero)?.image,
      timestamp
    );
  }, [item.images, timestamp]);

  const { handleImageChange, handleDeleteImage, handleTextChange } =
    useRowHandlers(item);

  const handleSearchFilter = (query: string) => {
    debounce(() => {
      setSearchText(query.toUpperCase());
    });
  };

  const handleUpdateHeroImage = (image: string) => {
    if (!item.images) return;

    const cachedHeroImage = cachedPartNumberImage(image);
    handleImageChange(
      item.images.map((img) => {
        return {
          ...img,
          isHero: img.image === cachedHeroImage,
        };
      })
    );
  };

  return (
    <>
      <FlagsCell
        index={index}
        images={uncachedImages}
        heroImage={heroImage}
        notes={item.notes ?? ""}
        msrp={item.msrp ?? ""}
        msrpPopover
        displayImagePopover={userStore.isNationalUser()}
        highlighted={false}
        inProgress={false}
        displayInProgressCheckBox={false}
        disabled={disabled}
        onClickAddImage={() => setOpenImageLibraryModal(true)}
        onClickEditImage={() => setOpenEditImageModal(true)}
        updateHeroImage={handleUpdateHeroImage}
        onNotesChange={handleTextChange("notes")}
        onDeleteImage={handleDeleteImage}
        renderMsrpPopover={() => (
          <MsrpPopover
            title="MSRP"
            align="right"
            msrpPopover
            hasMsrpChangedAttributes={checkForSyncChanges(
              item.changedAttributes,
              "msrp"
            )}
            msrp={item.msrp || ""}
            handleUpdateItem={handleTextChange("msrp")}
            readOnly={disabled}
            popoverClass={styles.msrpPopoverClass}
            hideAddTrimMsrp
          />
        )}
      />
      <Modal open={openImageModal} onClose={() => setOpenImageModal(false)}>
        <ImageUrlModal
          close={() => setOpenImageModal(false)}
          isSelected={false}
          updateSelectType={() => {
            setOpenImageModal(false);
            setOpenImageLibraryModal(true);
            setTimestamp(Date.now());
          }}
        />
      </Modal>
      <Modal
        open={openImageLibraryModal}
        onClose={() => {
          setOpenImageLibraryModal(false);
          setOpenUploadImageModal(false);
        }}
      >
        {openUploadImageModal ? (
          <UploadImageModal
            onClose={() => {
              setOpenImageLibraryModal(false);
              setOpenUploadImageModal(false);
            }}
            onBack={() => {
              setOpenUploadImageModal(false);
            }}
            onUpload={() => {
              setOpenUploadImageModal(false);
              setOpenImageLibraryModal(true);
              setTimestamp(Date.now());
            }}
          />
        ) : (
          <ImageLibraryModal
            close={() => setOpenImageLibraryModal(false)}
            searchText={searchText}
            setSearch={setSearchText}
            onSearch={handleSearchFilter}
            onImageChange={handleImageChange}
            userImages={item.images || []}
            onOpenUploadImageModal={() => {
              setOpenUploadImageModal(true);
            }}
          />
        )}
      </Modal>
      <Modal
        open={openEditImageModal}
        onClose={() => setOpenEditImageModal(false)}
      >
        <EditImageModal
          onClickBrowse={() => setOpenImageLibraryModal(true)}
          targetObj={item.images?.find((el) => el.isHero) as ImageItem}
          close={() => setOpenEditImageModal(false)}
        />
      </Modal>
    </>
  );
};

export default observer(CLFlagsCell);
