import { Brand } from "../constants/Brand";
import {
  SeriesItem,
  GetGeneratedReportsQuery,
  GetDashboardQuery,
} from "../gql/generated";
import {
  VehicleModel,
  ReportTypes,
  ReportItem,
} from "../models/reports/reports.model";

export const formatReportType = (type: string) => {
  if (type) {
    return type.charAt(0).toUpperCase() + type.slice(1);
  }
  return "";
};
export const listOrganizer = (reportTypesParam: ReportTypes[]) => {
  return reportTypesParam.sort().map((type: string) => {
    return formatReportType(type);
  });
};

export const proccessReportsModels = (
  filteredData: SeriesItem[],
  models: VehicleModel[],
  seriesList: string[],
  allReports: string[]
) => {
  models.forEach((model) => {
    const targetSeries = filteredData.find(
      (series) =>
        series.seriesName.toLowerCase() === model.seriesName.toLowerCase()
    );
    if (targetSeries) {
      if (!seriesList.includes(model.seriesName)) {
        seriesList.push(model.seriesName);
      }
      if (targetSeries.years) {
        const targetYear = targetSeries.years.find(
          (year) => year.year.toString() === model.modelYear
        );
        if (targetYear) {
          if (!allReports.includes(model.modelYear)) {
            allReports.push(model.modelYear);
          }
        }
      }
    }
  });
};

export const processDashboardSeries = (
  data: GetDashboardQuery | undefined,
  seriesList: string[],
  allReports: string[]
) => {
  const dashboardSeriesArray = (data?.dashboard?.series as SeriesItem[]) ?? [];
  dashboardSeriesArray.forEach((model) => {
    if (!seriesList.includes(model.seriesName)) {
      seriesList.push(model.seriesName);
    }
    if (model.years) {
      model.years.forEach((year) => {
        if (!allReports.includes(year.year.toString())) {
          allReports.push(year.year.toString());
        }
      });
    }
  });
  return dashboardSeriesArray;
};

export const processReportData = (
  reportsData: GetGeneratedReportsQuery | undefined,
  models: VehicleModel[],
) => {
  if (reportsData?.readReportLog?.reports) {
    return reportsData.readReportLog.reports.map((report) => {
      const newItem = new ReportItem(report!);
      newItem.reportType = ReportTypes.ACCESSORIES;
      newItem.series =
        models.find((item) => item.seriesId === report?.seriesId)
          ?.seriesName || "";
      return newItem;
    });
  }
  return [];
};

type SeriesMatchFn = (
  model: VehicleModel,
  seriesYear: string,
  series: string
) => boolean;
const compareModelWithSelected: SeriesMatchFn = (
  { modelYear, seriesName },
  seriesYear,
  series
) => {
  const sameYear = modelYear.toString() === seriesYear;
  const sameName = seriesName.toLowerCase() === series.toLowerCase();
  return sameYear && sameName;
};

export const filterSelectedSeries = (seriesYear: string, series: string) => (
  model: VehicleModel
) => compareModelWithSelected(model, seriesYear, series);

type HandlerMap = {
  [key: string]: () => Promise<void>;
};
export type BrandMap = {
  [K in Brand]?: HandlerMap;
};

export const bindGetHandlerFn = (
  errorPrefix: string = "error",
  reportHandlersMap: BrandMap
) => {
  const createGetHandlerFn = (brand: string, reportType: string) => {
    const errorFn = (errorMessage: string) =>
      console.error(`${errorPrefix}: ${errorMessage}`);

    const thisBrandHandlers = reportHandlersMap[brand as keyof BrandMap];
    if (!thisBrandHandlers) {
      return errorFn(`cannot find report handlers for brand ${brand}`);
    }

    const reportHandler =
      thisBrandHandlers[reportType as keyof typeof thisBrandHandlers];
    if (!reportHandler) {
      return errorFn(
        `cannot find report handlers for report type ${reportType} and brand ${brand}`
      );
    }

    return reportHandler;
  };
  return createGetHandlerFn;
};
